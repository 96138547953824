import { endOfDay, format, hoursToMinutes, millisecondsToMinutes, startOfDay } from 'date-fns';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';

import Time from '@shared/time';

export class DateTimeManager extends Time {
  private date: Date;

  constructor(timezone: string, initialDateValue?: string | Date) {
    const date = initialDateValue ? toZonedTime(initialDateValue, timezone) : toZonedTime(new Date(), timezone);
    super({ date });
    this.date = date;
  }

  static getWeekDays(timezone: string, dateString?: string): string[] {
    const currentDate = dateString
      ? toZonedTime(dateString, timezone)
      : toZonedTime(new Date().toISOString(), timezone);

    const day = currentDate.getDay();

    const firstDayOfTheWeek = toZonedTime(currentDate, timezone);
    firstDayOfTheWeek.setDate(currentDate.getDate() - day);

    const dateStrings: string[] = [];

    for (let i = 0; i < 7; i++) {
      const day = new Date(firstDayOfTheWeek);

      const localISOTime = formatInTimeZone(new Date(day.setDate(day.getDate() + i)), timezone, 'yyyy-MM-dd');

      dateStrings.push(localISOTime.split('T')[0]);
    }

    return dateStrings;
  }

  static today(currentDate: string, timezone: string): boolean {
    return currentDate === formatInTimeZone(new Date(), timezone, 'yyyy-MM-dd');
  }

  static getMinuteValueFromTime(timeString: string, timezone: string): number {
    if (!timeString) return 0;

    const hour = formatInTimeZone(new Date(timeString), timezone, 'HH');
    const minute = formatInTimeZone(new Date(timeString), timezone, 'mm');

    return hoursToMinutes(Number(hour)) + Number(minute);
  }

  static durationInMinutes(endTime: string, startTime: string): number {
    return millisecondsToMinutes(new Date(endTime).getTime() - new Date(startTime).getTime());
  }

  static toLocalISOString(date: Date) {
    return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS");
  }

  navigateWeeks(direction: 'previous' | 'next'): string {
    const targetDate = this.date;

    const adjustment = direction === 'next' ? 7 : -7;

    targetDate.setDate(targetDate.getDate() + adjustment);

    return format(targetDate, 'yyyy-MM-dd');
  }

  navigateDays(direction: 'previous' | 'next'): string {
    const targetDate = this.date;

    const adjustment = direction === 'next' ? 1 : -1;

    targetDate.setDate(targetDate.getDate() + adjustment);

    return format(targetDate, 'yyyy-MM-dd');
  }

  day() {
    return format(this.date, 'MMMM d');
  }

  dayNameInWeek() {
    return format(this.date, 'EEEE');
  }

  dayNumber() {
    return format(this.date, 'dd');
  }

  weekNumber() {
    return format(this.date, 'w');
  }

  month() {
    return format(this.date, 'MMMM');
  }

  year() {
    return format(this.date, 'yyyy');
  }

  shortTime() {
    return format(this.date, 'h:mma').toLowerCase();
  }

  getDate() {
    return this.date;
  }

  getStartOfDay() {
    return startOfDay(this.date);
  }

  getEndOfDay() {
    return endOfDay(this.date);
  }
}

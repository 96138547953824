import type { FC } from 'react';

import { twMerge } from 'tailwind-merge';
import last from 'lodash/last';

import { tw } from '@/utilities';

import type { Attribute, PossibleValues } from './types';
import { assignTQLStringToURL, generateTQLString, getValueAndValueName } from './utilities';
import FilterBlock from './FilterBlock';

import { FILTER_OPERATORS } from '@shared/constants';
import { displayDuration } from '@shared/text';
import type { Filter } from '@models/Filter';
import type { StateChangeCallback } from '@shared/hooks/useStateWithCallback';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';

const NUMERICAL_OPERATORS = [
  FILTER_OPERATORS['EQ'],
  FILTER_OPERATORS['NOT_EQ'],
  FILTER_OPERATORS['GT'],
  FILTER_OPERATORS['LT'],
  FILTER_OPERATORS['GTE'],
  FILTER_OPERATORS['LTE'],
];

export const POSSIBLE_OPERATORS = {
  id: [FILTER_OPERATORS['EQ']],
  string: [FILTER_OPERATORS['EQ'], FILTER_OPERATORS['NOT_EQ'], FILTER_OPERATORS['LIKE'], FILTER_OPERATORS['NOT_LIKE']],
  number: NUMERICAL_OPERATORS,
  duration: NUMERICAL_OPERATORS,
  equality: [FILTER_OPERATORS['EQ'], FILTER_OPERATORS['NOT_EQ']],
  boolean: [FILTER_OPERATORS['EQ'], FILTER_OPERATORS['NOT_EQ']],
  datetime: [
    { ...FILTER_OPERATORS['LTE'], name: 'Before or on' },
    { ...FILTER_OPERATORS['GTE'], name: 'On or after' },
  ],
};

export const VALUE_FORMATTER = {
  datetime: (value: string) => {
    return new Date(value)
      .toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' })
      .replace(/ /g, ' ');
  },
  duration: (value: string) => {
    return displayDuration(Number(value));
  },
};

const CONTAINER_CLASS_NAMES = tw`flex flex-wrap gap-2`;
const ADD_FILTER_BUTTON_CLASS_NAMES = tw`text-tz-gray-800 flex h-auto shrink-0 items-center border-0 p-2 ps-0 no-underline`;

interface Props {
  attributes: Attribute[];
  className?: string;
  filters: Filter[];
  possibleValues: PossibleValues;
  onSetFilter: (filter: Filter[], callback?: StateChangeCallback<Filter[]> | undefined) => void;
  onUpdateTqlString: (tqlString: string) => void;
}

const FilterControl: FC<Props> = ({
  attributes,
  filters,
  className,
  possibleValues,
  onSetFilter,
  onUpdateTqlString,
}) => {
  const newFilterAllowed = filters.length === 0 || last(filters)?.persisted;

  const handleAddNewFilterBlock = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    if (!newFilterAllowed) return;

    const [newFilterBlockValue, newFilterBlockValueName] = getValueAndValueName(possibleValues, attributes);

    const operator = attributes[0].type === 'string' ? 'LIKE' : POSSIBLE_OPERATORS[attributes[0].type][0].value;

    onSetFilter([
      ...filters,
      {
        attribute: attributes[0].value,
        operator,
        value: newFilterBlockValue,
        valueName: newFilterBlockValueName,
        persisted: false,
      },
    ]);
  };

  const processUpdatedFilters = (updatedFilters: Filter[]) => {
    const tqlString = generateTQLString(updatedFilters);

    assignTQLStringToURL(tqlString);
    onUpdateTqlString(tqlString);
  };

  const handleApplyFilterBlock = (index: number) => (filter: Filter) => {
    const changesAppliedFilter = {
      ...filter,
      persisted: true,
    };

    onSetFilter(
      filters.map((filter, i) => (i === index ? changesAppliedFilter : filter)),
      processUpdatedFilters
    );
  };

  const handleDeleteFilterBlock = (index: number) => (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    onSetFilter(
      filters.filter((_, i) => i !== index),
      processUpdatedFilters
    );
  };

  return (
    <div className={twMerge(CONTAINER_CLASS_NAMES, className || 'mb-3')}>
      {filters.map((filter: Filter, index: number) => (
        <FilterBlock
          key={`filter-block-${index}`}
          index={index}
          filter={filter}
          attributes={attributes}
          possibleValues={possibleValues}
          onApplyFilter={handleApplyFilterBlock(index)}
          onDeleteFilter={handleDeleteFilterBlock(index)}
        />
      ))}
      <button
        type="button"
        className={twMerge(ADD_FILTER_BUTTON_CLASS_NAMES, !newFilterAllowed && 'cursor-not-allowed')}
        onClick={handleAddNewFilterBlock}
      >
        <FontAwesomeIcon className="me-1 dark:text-gray-200" icon="plus" height={14} />
        <TextSmall>Add Filter</TextSmall>
      </button>
    </div>
  );
};

export default FilterControl;

import type { FC, PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props {
  disabled?: boolean
  className?: string
}

const TextSmall: FC<PropsWithChildren<Props>> = ({ children, className, disabled }) => {
  return (
    <p
      className={twMerge(
        'text-sm font-normal leading-5',
        disabled ? 'text-tz-gray-300 dark:text-tz-gray-500' : 'dark:text-tz-gray-300',
        className
      )}
    >
      {children}
    </p>
  );
};

export default TextSmall;

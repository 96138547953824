import type { FC } from 'react';

import { useDraggable } from '@dnd-kit/core';

import { Option } from '@/EmailEditor/Properties/SectionProperties';
import { useAppDispatch } from '@/EmailEditor/hooks';
import { setDraggingElement } from '@/EmailEditor/actions';

const SectionWithTwoColumns: FC<{}> = () => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: 'new-section-2',
    data: {
      type: 'mjml-section',
      defaultProperties: {
        backgroundColor: '#ffffff',
        padding: { setAll: false, all: '0px', left: '20px', right: '20px', top: '0px', bottom: '0px' },
        width: '600px',
        columnProperties: [
          {
            backgroundColor: '',
            width: '50%',
            padding: { setAll: false, all: '0px', left: '0px', right: '10px', top: '0px', bottom: '0px' },
          },
          {
            backgroundColor: '',
            width: '50%',
            padding: { setAll: false, all: '0px', left: '10px', right: '0px', top: '0px', bottom: '0px' },
          },
        ],
      },
    },
  });

  const dispatch = useAppDispatch();

  return (
    <Option className="list-unstyled" ref={setNodeRef} {...listeners} {...attributes}>
      <button
        type="button"
        className="w-100 p-0 border border-dark opacity-25 d-flex justify-content-center align-items-center"
        onMouseDown={() => dispatch(setDraggingElement({ draggingElement: true }))}
      >
        {Array(2)
          .fill(0)
          .map((i, index) => (
            <div key={index.toString()} className="border border-dark flex-grow-1 py-4" />
          ))}
      </button>
    </Option>
  );
};

export default SectionWithTwoColumns;

import type { FC } from 'react';

import AppointmentInformation from './AppointmentInformation';
import AppointmentCancellation from './AppointmentCancellation';

import { useAppSelector } from 'admin_ui/screens/NewUi/DispatchHQ/hooks';
import { selectFlyoutMode } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/uiReducer';

const MainSection: FC = () => {
  const flyoutMode = useAppSelector(selectFlyoutMode);

  switch (flyoutMode) {
    case 'info':
      return <AppointmentInformation />;
    case 'cancel':
      return <AppointmentCancellation />;
    default:
      return <AppointmentInformation />;
  }
};

export default MainSection;

import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';

import UsersRow from './UsersRow';
import CalendarContainer from './CalendarContainer';

import ZoomEngine from '../../Tools/ZoomEngine';
import PanEngine from '../../Tools/PanEngine';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectZoom } from '../../Reducers/uiReducer';
import { setCellWidth } from '../../actions';
import { selectVisibleUserIds } from '../../Reducers/calendarReducer';

const PLACEHOLDER_CELL_WIDTH = 83;
const SCROLL_BAR_WIDTH = 5;

const LayoutEngine: FC = () => {
  const dispatch = useAppDispatch();

  const usersRowRef = useRef<HTMLDivElement>(null);
  const calendarContainerRef = useRef<HTMLDivElement>(null);

  const [calendarContainer, setCalendarContainer] = useState<HTMLDivElement | null>(null);
  const [usersRow, setUsersRow] = useState<HTMLDivElement | null>(null);

  const visibleUsers = useAppSelector(selectVisibleUserIds);
  const zoom = useAppSelector(selectZoom);

  const isZooming = zoom > 1;

  useEffect(() => {
    setCalendarContainer(calendarContainerRef.current);
    setUsersRow(usersRowRef.current);
  }, []);

  useEffect(() => {
    const handleSetCellWidth = () => {
      if (!calendarContainerRef.current) return;

      dispatch(
        setCellWidth({
          cellWidth:
            (calendarContainerRef.current.getBoundingClientRect().width - PLACEHOLDER_CELL_WIDTH - SCROLL_BAR_WIDTH) /
            visibleUsers.length,
        })
      );
    };

    window.addEventListener('resize', handleSetCellWidth);

    handleSetCellWidth();

    return () => {
      window.removeEventListener('resize', handleSetCellWidth);
    };
  }, [dispatch, visibleUsers.length]);

  return (
    <ZoomEngine calendarHeader={usersRow} calendarContainer={calendarContainer}>
      <PanEngine calendarHeader={usersRow} calendarContainer={calendarContainer} isZooming={isZooming}>
        <UsersRow ref={usersRowRef} />
        <CalendarContainer ref={calendarContainerRef} />
      </PanEngine>
    </ZoomEngine>
  );
};

export default LayoutEngine;

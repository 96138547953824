import type { FC } from 'react';

import { DateTimeManager } from '@/screens/NewUi/DispatchHQ/DateTimeManager';

import Limit from './Limit';

import { useAppSelector } from 'admin_ui/screens/NewUi/DispatchHQ/hooks';
import {
  selectAreaSpecificTimezone,
  selectSelectedCalendarEntry,
  selectTimezone,
} from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/calendarReducer';
import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';
import Description from 'admin_ui/screens/NewUi/DispatchHQ/Components/Flyout/DescriptionList/Description';
import DescriptionList from 'admin_ui/screens/NewUi/DispatchHQ/Components/Flyout/DescriptionList/index';
import { displayDuration, sentence } from '@shared/text';

const TimeZestAppointmentDetails: FC = () => {
  const areaSpecificTimezone = useAppSelector(selectAreaSpecificTimezone);
  const timezone = useAppSelector(selectTimezone);
  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);

  const startTime = new DateTimeManager(timezone, selectedCalendarEntry?.startTime);
  const shortTime = startTime.shortTime();
  const dayNameInWeek = startTime.dayNameInWeek();
  const dayNumber = startTime.dayNumber();
  const month = startTime.month();
  const year = startTime.year();

  const invitedGuests =
    (selectedCalendarEntry?.endUserEmails.slice(1) || []).length > 0
      ? sentence(selectedCalendarEntry?.endUserEmails.slice(1) || [])
      : '(None)';

  return (
    <DescriptionList>
      <Description title="Scheduled Time" span={2}>
        <TextSmall>
          {shortTime} on {dayNameInWeek}, {dayNumber} {month} {year} · {areaSpecificTimezone}
        </TextSmall>
      </Description>
      <Description title="Scheduled User">
        <TextSmall>{selectedCalendarEntry?.endUserNames[0]}</TextSmall>
      </Description>
      <Description title="Scheduled Resource(s)">
        <TextSmall>{sentence(selectedCalendarEntry?.resourceNames || [])}</TextSmall>
      </Description>
      <Description title="Appointment Type">
        <TextSmall>{selectedCalendarEntry?.appointmentTypeName}</TextSmall>
      </Description>
      <Description title="Limit">
        <Limit />
      </Description>
      <Description title="Duration">
        <TextSmall>{displayDuration(selectedCalendarEntry?.duration || 0)}</TextSmall>
      </Description>
      <Description title="Invited Guests">
        <TextSmall>{invitedGuests}</TextSmall>
      </Description>
    </DescriptionList>
  );
};

export default TimeZestAppointmentDetails;

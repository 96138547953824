import type { FC } from 'react';

import Alert from 'design_system/Components/Alert';

const ErrorMessage: FC = () => {
  return (
    <div className="mx-auto mt-11 w-1/2">
      <Alert
        color="gray"
        icon="circle-xmark"
        title="No matching agents found."
        content={<>No agents exist that match your filters.</>}
      />
    </div>
  );
};

export default ErrorMessage;

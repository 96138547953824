import type { FC } from 'react';

import type { IconName } from '@fortawesome/free-solid-svg-icons';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import H3 from 'design_system/Typography/Headings/H3';
import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';

interface Props {
  actionButton?: JSX.Element;
  icon?: IconName;
  description: string;
  title: string;
}

const BlankSlate: FC<Props> = ({ actionButton, description, icon, title }) => {
  return (
    <div className="grid grid-cols-1 gap-5 text-center">
      {icon && (
        <FontAwesomeIcon icon={icon} className="mx-auto text-tz-gray-800 dark:text-tz-gray-100" width={48} height={48} />
      )}
      <div className="mx-auto max-w-96">
        <H3>{title}</H3>
      </div>
      <div className="mx-auto max-w-lg">
        <TextSmall>{description}</TextSmall>
      </div>

      {actionButton}
    </div>
  );
};

export default BlankSlate;

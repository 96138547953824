import type { FC } from 'react';

import type Event from '@models/Event';
import Badge from '@ui/Badge';

interface Props {
  event: Event;
}

export const CLIENT_ACTIONS = [
  'created',
  'created_via_url',
  'created_via_shareable_url',
  'generated_url',
  'viewed',
  'agreed',
  'rescheduled',
  'cancelled_by_technician',
  'cancelled_by_client',
  'cancelled_from_dispatch_hq',
  'rescheduled_from_dispatch_hq',
];

const EventBadge: FC<Props> = ({ event }) => {
  const badgeColor = (() => {
    const eventType = event.eventType;

    if (eventType.match(/trigger\..*?\.fired/)) {
      return 'purple';
    }

    if (eventType.match(/trigger\..*?\.timeout/)) {
      return 'red';
    }

    if (eventType.match(/\.succeeded/)) {
      return 'green';
    }

    if (eventType.match(/error/) || eventType.match(/failed/)) {
      return 'red';
    }

    if (eventType.match(/skipped/)) {
      return 'yellow';
    }

    if (CLIENT_ACTIONS.includes(eventType)) {
      return 'blue';
    }

    return 'grey';
  })();

  return <Badge color={badgeColor}>{event.badgeDescription}</Badge>;
};

export default EventBadge;

import type { FC } from 'react';

import { useDebounce } from '@react-hook/debounce';

import Row from '@/ui/Row';
import SearchableDropdownMenu from '@/ui/SearchableDropdownMenu';

import { idStringifier } from '@shared/text';
import { useGetHaloPsaCompaniesQuery } from '@graphql/generated';

interface Props {
  clientId: string | undefined;
  clientName: string;
  error: string;
  readOnly: boolean;
  templateMode: boolean;
  onChange: (clientId: string, clientName: string) => void;
}

const CustomerSelect: FC<Props> = ({ clientId, clientName, error, readOnly, templateMode, onChange }) => {
  const [searchTerm, setSearchTerm] = useDebounce('', 250);

  const {
    data,
    loading,
    error: queryError,
  } = useGetHaloPsaCompaniesQuery({ variables: { query: searchTerm }, skip: templateMode });

  const handleSearchTermChange = (value: string): void => {
    setSearchTerm(value);
  };

  const clients = data?.haloPsa.companies || [];
  const selectedClient = clients.find(c => c.id.toString() === clientId);

  return (
    <div>
      <Row label="Customer" helpText="TimeZest will create a new user in HaloPSA with this customer.">
        <div className="position-relative">
          <SearchableDropdownMenu
            data={(clients || []).map(c => idStringifier(c))}
            displayName={clientName}
            error={error}
            readOnly={readOnly}
            name="psa_client_id"
            value={clientId}
            loading={loading}
            filterPlaceholder="Filter by customer name..."
            emptyDataMessage="No customers found."
            queryErrorMessage={queryError && 'Error loading customers from HaloPSA.'}
            loadingMessage="Loading customers from HaloPSA..."
            onSearchTermChange={handleSearchTermChange}
            onChange={onChange}
          />
        </div>
      </Row>
      <input type="hidden" name="psa_client_name" value={selectedClient?.name || ''} />
    </div>
  );
};

export default CustomerSelect;

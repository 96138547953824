import type { FC } from 'react';

import { useAppSelector } from '@/screens/NewUi/DispatchHQ/hooks';
import { selectSelectedCalendarEntry } from '@/screens/NewUi/DispatchHQ/Reducers/calendarReducer';

import Descriptions from './Descriptions';

import { useGetScheduleEntrySuspenseQuery } from '@graphql/generated';

const ScheduleEntry: FC = () => {
  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);

  const { data } = useGetScheduleEntrySuspenseQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id: selectedCalendarEntry?.psaId || '0',
    },
  });

  const scheduleEntry = data?.connectWise?.scheduleEntry;

  return (
    <Descriptions
      location={scheduleEntry?.location}
      reminder={scheduleEntry?.reminder}
      status={scheduleEntry?.status}
      type={scheduleEntry?.type}
    />
  );
};

export default ScheduleEntry;

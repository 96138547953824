import type { CSSProperties, FC } from 'react';

import classNames from 'classnames';

import type {
  CalendarEntryGroup,
  CalendarEntryMap,
  IndexedCalendarEntry,
} from 'admin_ui/screens/NewUi/DispatchHQ/types';
import { selectZoom } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/uiReducer';
import { useAppSelector } from 'admin_ui/screens/NewUi/DispatchHQ/hooks';
import { selectTimezone } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/calendarReducer';
import { DateTimeManager } from 'admin_ui/screens/NewUi/DispatchHQ/DateTimeManager';
import CalendarEntry from 'admin_ui/screens/NewUi/DispatchHQ/CalendarView/CalendarEntry';

const ONE_MINUTE_IN_REM = 0.1;

interface Props {
  entryGroup: CalendarEntryGroup;
  entryMap: CalendarEntryMap;
}

const EntryGroup: FC<Props> = ({ entryGroup, entryMap }) => {
  const timezone = useAppSelector(selectTimezone);
  const zoom = useAppSelector(selectZoom);

  const { rootEntryId, maxOverlapStreak } = entryGroup;

  const style: CSSProperties = {
    top: `${ONE_MINUTE_IN_REM * zoom * entryGroup.earliestStartTime}rem`,
  };

  const innerContainerStyle: CSSProperties = {
    margin: `${zoom}px`,
  };

  return (
    <div className="absolute w-full" style={style}>
      <div style={innerContainerStyle}>
        {renderCalendarEntry(entryMap, rootEntryId, zoom, 0, maxOverlapStreak, timezone)}
      </div>
    </div>
  );
};

export default EntryGroup;

// eslint-disable-next-line max-params
function renderCalendarEntry(
  entryMap: CalendarEntryMap,
  id: string,
  zoom: number,
  offsetTop: number,
  columns: number,
  timezone: string
): JSX.Element {
  const entry = entryMap[id];

  const childEntries = entry.childIds.map((id, index) => {
    const offsetTop = determineOffsetTopValue(index, entryMap, id, entry, timezone);

    return renderCalendarEntry(entryMap, id, zoom, offsetTop, columns - 1, timezone);
  });

  const hasChildEntries = childEntries.length > 0;

  const parentEntryStyle: CSSProperties = {
    gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
    marginTop: `${offsetTop * ONE_MINUTE_IN_REM * zoom}rem`,
  };

  const childEntryStyle: CSSProperties = {
    gridColumn: `2 / ${columns + 1}`,
  };

  return (
    <div key={id} className={classNames({ 'grid gap-x-px': hasChildEntries })} style={parentEntryStyle}>
      <div style={{ gridColumn: hasChildEntries ? '1 / 2' : 'auto' }}>
        <CalendarEntry key={entry.id} entry={entry} />
      </div>

      {hasChildEntries && <div style={childEntryStyle}>{childEntries}</div>}
    </div>
  );
}

// eslint-disable-next-line max-params
function determineOffsetTopValue(
  index: number,
  entryMap: CalendarEntryMap,
  id: string,
  parentEntry: IndexedCalendarEntry,
  timezone: string
): number {
  const offsetTop =
    index === 0
      ? DateTimeManager.getMinuteValueFromTime(entryMap[id].startTime, timezone) -
        DateTimeManager.getMinuteValueFromTime(parentEntry.startTime, timezone)
      : DateTimeManager.getMinuteValueFromTime(parentEntry.startTime, timezone) -
        DateTimeManager.getMinuteValueFromTime(entryMap[parentEntry.childIds[index - 1]].endTime, timezone);

  return offsetTop;
}

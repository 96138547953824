import { useMemo } from 'react';

import range from 'lodash/fp/range';

const SIBLING_COUNT = 2;

interface PaginationProps {
  totalPages: number;
  currentPage: number;
}

export const usePagination = ({ totalPages, currentPage }: PaginationProps) => {
  const paginationRange = useMemo(() => {
    // This size is determined by siblingCount + firstPage + lastPage + currentPage + prev button + next button
    const paginationPillSize = SIBLING_COUNT + 5;

    if (paginationPillSize >= totalPages) {
      return range(1, totalPages + 1);
    }

    // Left and right sibling indexes are the lefftmost or rightmost page number that is sibling to the current page
    const leftSiblingIndex = Math.max(currentPage - SIBLING_COUNT, 1);
    const rightSiblingIndex = Math.min(currentPage + SIBLING_COUNT, totalPages);

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 2;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemsCount = 1 + 2 * SIBLING_COUNT;
      const leftRange = range(1, leftItemsCount + 1);
      return [...leftRange, 'rightDot', totalPages];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemsCount = 1 + 2 * SIBLING_COUNT;
      const rightRange = range(totalPages - rightItemsCount + 1, totalPages + 1);
      return [1, 'leftDot', ...rightRange];
    } else {
      const middleRange = range(leftSiblingIndex + 1, rightSiblingIndex + 1);
      return [1, 'leftDot', ...middleRange, 'rightDot', totalPages];
    }
  }, [totalPages, currentPage]);

  return paginationRange;
};

import type { FC } from 'react';

import PhoneAndAddressPsa from '../PhoneAndAddress';

import { selectSelectedCalendarEntry } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/calendarReducer';
import { useAppSelector } from 'admin_ui/screens/NewUi/DispatchHQ/hooks';
import type { Address } from '@graphql/generated';
import { useGetAutotaskContactSuspenseQuery } from '@graphql/generated';
import { clientAddress } from '@shared/utilities';

const PhoneAndAddress: FC = () => {
  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);
  const contactId = Number(selectedCalendarEntry?.psaClientId || '');
  const { data } = useGetAutotaskContactSuspenseQuery({
    skip: !contactId,
    fetchPolicy: 'no-cache',
    variables: { contactId },
  });
  const phone = data?.autotask?.contact?.phone;
  const address = clientAddress(data?.autotask?.contact?.address as Address);

  return <PhoneAndAddressPsa phone={phone} address={address} />;
};

export default PhoneAndAddress;

import { createAction } from '@reduxjs/toolkit';

import type { BroadcastOperation, CalendarEntry, Filter, FlyoutMode, User, ViewMode } from './types';

import type { DispatchHqCalendarEntry } from '@graphql/generated';

// Calendar Actions
// ----------------------------------------------------------------------------
export type CalendarAction =
  | LoadCalendarEntriesAction
  | LoadActiveUsersAction
  | LoadSelectedCalendarEntryAction
  | RemoveCalendarEntryAction
  | SelectDateRangeAction
  | SetTimezoneAction
  | SetVisibleUserIdsAction
  | SelectCalendarEntryAction
  | UpdateCalendarEntriesOnBroadcastAction;

interface LoadCalendarEntriesData {
  calendarEntries: CalendarEntry[];
  viewMode: ViewMode;
}

export const loadCalendarEntries = createAction<LoadCalendarEntriesData, 'LOAD_CALENDAR_ENTRIES'>(
  'LOAD_CALENDAR_ENTRIES'
);
type LoadCalendarEntriesAction = ReturnType<typeof loadCalendarEntries>;

interface LoadActiveUsersData {
  users: User[];
}

export const loadActiveUsers = createAction<LoadActiveUsersData, 'LOAD_ACTIVE_USERS'>('LOAD_ACTIVE_USERS');
type LoadActiveUsersAction = ReturnType<typeof loadActiveUsers>;

interface SelectDateRangeData {
  direction: 'previous' | 'next';
  viewMode: ViewMode;
}

export const selectDateRange = createAction<SelectDateRangeData, 'SELECT_DATE_RANGE'>('SELECT_DATE_RANGE');
type SelectDateRangeAction = ReturnType<typeof selectDateRange>;

interface UpdateCalendarEntriesOnBroadcastData {
  calendarEntry: CalendarEntry;
  operation: BroadcastOperation;
  viewMode: ViewMode;
}

export const updateCalendarEntriesOnBroadcast = createAction<
  UpdateCalendarEntriesOnBroadcastData,
  'UPDATE_CALENDAR_ENTRIES_ON_BROADCAST'
>('UPDATE_CALENDAR_ENTRIES_ON_BROADCAST');
type UpdateCalendarEntriesOnBroadcastAction = ReturnType<typeof updateCalendarEntriesOnBroadcast>;

interface SetVisibleUserIdsData {
  visibleUserIds: string[];
}

export const setVisibleUserIds = createAction<SetVisibleUserIdsData, 'SET_VISIBLE_USER_IDS'>('SET_VISIBLE_USER_IDS');
type SetVisibleUserIdsAction = ReturnType<typeof setVisibleUserIds>;

interface SetTimezone {
  timezone: string;
}

export const setTimezone = createAction<SetTimezone, 'SET_TIMEZONE'>('SET_TIMEZONE');
type SetTimezoneAction = ReturnType<typeof setTimezone>;

interface SelectCalendarEntry {
  calendarEntryId: string | null;
  userId: number | null;
}

export const selectCalendarEntry = createAction<SelectCalendarEntry, 'SELECT_CALENDAR_ENTRY'>('SELECT_CALENDAR_ENTRY');
type SelectCalendarEntryAction = ReturnType<typeof selectCalendarEntry>;

interface LoadSelectedCalendarEntry {
  data: DispatchHqCalendarEntry;
}

export const loadSelectedCalendarEntry = createAction<LoadSelectedCalendarEntry, 'LOAD_SELECTED_CALENDAR_ENTRY'>(
  'LOAD_SELECTED_CALENDAR_ENTRY'
);
export type LoadSelectedCalendarEntryAction = ReturnType<typeof loadSelectedCalendarEntry>;

interface RemoveCalendarEntryData {
  calendarEntryId: string;
  userId: number;
}

export const removeCalendarEntry = createAction<RemoveCalendarEntryData, 'REMOVE_CALENDAR_ENTRY'>(
  'REMOVE_CALENDAR_ENTRY'
);
export type RemoveCalendarEntryAction = ReturnType<typeof removeCalendarEntry>;

// UI Actions
// ----------------------------------------------------------------------------
export type UIAction =
  | ToggleViewModeAction
  | SetFilterAction
  | SetZoomAction
  | SetCellWidthAction
  | SetDisableInteractionAction
  | SetFlyoutModeAction;

export const toggleViewMode = createAction<undefined, 'TOGGLE_VIEW_MODE'>('TOGGLE_VIEW_MODE');
type ToggleViewModeAction = ReturnType<typeof toggleViewMode>;

interface SetFlyoutModeData {
  mode: FlyoutMode;
}

export const setFlyoutMode = createAction<SetFlyoutModeData, 'SET_FLYOUT_MODE'>('SET_FLYOUT_MODE');
type SetFlyoutModeAction = ReturnType<typeof setFlyoutMode>;

interface SetFilterData {
  filter: Filter;
}

export const setFilter = createAction<SetFilterData, 'SET_FILTER'>('SET_FILTER');
type SetFilterAction = ReturnType<typeof setFilter>;

interface SetZoomData {
  zoom: number;
}

export const setZoom = createAction<SetZoomData, 'SET_ZOOM'>('SET_ZOOM');
type SetZoomAction = ReturnType<typeof setZoom>;

interface SetCellWidthData {
  cellWidth: number;
}

export const setCellWidth = createAction<SetCellWidthData, 'SET_CELL_WIDTH'>('SET_CELL_WIDTH');
type SetCellWidthAction = ReturnType<typeof setCellWidth>;

interface SetDisableInteractionData {
  disableInteraction: boolean;
}

const setDisableInteraction = createAction<SetDisableInteractionData, 'SET_DISABLE_INTERACTION'>(
  'SET_DISABLE_INTERACTION'
);
export const disableInteraction = (disableInteraction: boolean) => setDisableInteraction({ disableInteraction });
type SetDisableInteractionAction = ReturnType<typeof setDisableInteraction>;

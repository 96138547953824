import { type FC, useContext, useState } from 'react';

import AccountContext from '@/AccountContext';
import Form from '@/Form';

import PlanOffice365 from './PlanOffice365';
import FreePlanChoice from './FreePlanChoice';
import PlansList from './PlansList';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  pricingModel: string;
  chargebeePlanId: string;
  eligibleFreePlanChange: boolean;
}

const Plans: FC<Props> = ({ pricingModel, chargebeePlanId, eligibleFreePlanChange }) => {
  const { psaType } = useContext(AccountContext);

  const [selectedPlan, setSelectedPlan] = useState(chargebeePlanId);

  const handleSelectPlan = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPlan(e.target.value);
  };

  if (psaType === 'office_365') {
    return <PlanOffice365 />;
  }

  return (
    <Form url="/settings/account/upgrade" method="get">
      <PlansList
        pricingModel={pricingModel}
        selectedPlan={selectedPlan}
        chargebeePlanId={chargebeePlanId}
        handleSelectPlan={handleSelectPlan}
      />

      <FreePlanChoice
        eligibleFreePlanChange={eligibleFreePlanChange}
        selectedPlan={selectedPlan}
        chargebeePlanId={chargebeePlanId}
        handleSelectPlan={handleSelectPlan}
      />

      <div className="mt-3">
        <button type="submit" className="btn btn-primary">
          Preview Plan Changes&nbsp;
          <FontAwesomeIcon icon="angle-right" />
        </button>
      </div>
    </Form>
  );
};

export default Plans;

import type { FC, PropsWithChildren } from 'react';
import { Fragment, useState } from 'react';

import { Transition } from '@headlessui/react';
import { twJoin } from 'tailwind-merge';

import { tw } from '@/utilities';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const CONTAINER_CLASS_NAMES = tw`group relative flex w-max items-center`;

const POPUP_CLASS_NAMES = {
  above: tw`bottom-0 mb-6 flex flex-col`,
  center: tw`left-0 ml-6`,
  below: tw`top-0 mt-6 flex flex-col`,
};

interface Props {
  position: 'above' | 'center' | 'below';
}

/* eslint-disable jsx-a11y/mouse-events-have-key-events */
const HelpPopup: FC<PropsWithChildren<Props>> = ({ children, position }) => {
  const [showing, setShowing] = useState(false);

  return (
    <div className="inline-block">
      <div
        className={twJoin(CONTAINER_CLASS_NAMES, (position === 'above' || position === 'below') && 'flex-col')}
        onMouseOver={() => setShowing(true)}
        onMouseLeave={() => setShowing(false)}
      >
        <FontAwesomeIcon icon="question-circle" height={14} className="text-tz-gray-600 dark:text-tz-gray-400" />
        <Transition
          show={showing}
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-200"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div className={twJoin('absolute z-50 hidden items-center group-hover:flex', POPUP_CLASS_NAMES[position])}>
            {position === 'below' && <div className="bg-tz-gray-700 -mb-2 h-3 w-3 rotate-45" />}
            {position === 'center' && <div className="bg-tz-gray-700 -mr-2 h-3 w-3 rotate-45" />}
            <span className="whitespace-no-wrap bg-tz-gray-700 text-tz-gray-300 w-72 rounded-md p-3 shadow-lg">
              {children}
            </span>
            {position === 'above' && <div className="bg-tz-gray-700 -mt-2 h-3 w-3 rotate-45" />}
          </div>
        </Transition>
      </div>
    </div>
  );
};

export default HelpPopup;

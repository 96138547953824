import type { PropsWithChildren } from 'react';
import { forwardRef, Fragment } from 'react';

import { CloseButton, Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

import { tw } from '@/utilities';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import H5 from 'design_system/Typography/Headings/H5';
import LoadingSpinner from 'design_system/Components/LoadingSpinner';

const BUTTON_CLASS_NAMES = tw`bg-tz-green-600 text-tz-gray-100 hover:bg-tz-green-700 hover:text-tz-gray-200 h-max w-max rounded-md px-2.5 py-1.5 text-xs font-semibold outline-none`;
const PANEL_CLASS_NAMES = tw`border-tz-gray-100 z-50 flex flex-col space-y-3 rounded-md border bg-white p-3 shadow-lg`;
const PANEL_HEADER_SECTION_CLASS_NAMES = tw`flex items-center gap-x-1`;
const CLOSE_ICON_CLASS_NAMES = tw`hover:bg-tz-gray-100 h-6 rounded-md p-1`;

interface Props {
  popoverButton: JSX.Element;
  title: string;
  disabled?: boolean;
  panelClassName?: string;
}

const SelectorPopover = forwardRef<HTMLButtonElement, PropsWithChildren<Props>>(
  ({ children, disabled, panelClassName, popoverButton, title }, ref) => {
    return (
      <Popover className="relative">
        <PopoverButton className={BUTTON_CLASS_NAMES}>
          <div className={PANEL_HEADER_SECTION_CLASS_NAMES}>{popoverButton}</div>
        </PopoverButton>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <PopoverPanel
            anchor="bottom"
            className={twMerge(PANEL_CLASS_NAMES, panelClassName, disabled && 'pointer-events-none')}
          >
            {disabled && (
              <div className="bg-tz-gray-200/30 pointer-events-none absolute inset-0 z-20 flex h-full w-full cursor-default items-center justify-center">
                <LoadingSpinner size="small" />
              </div>
            )}
            <div className="flex items-center justify-between">
              <H5 className="m-0">{title}</H5>
              <CloseButton ref={ref} as="button">
                <FontAwesomeIcon icon="xmark" className={CLOSE_ICON_CLASS_NAMES} />
              </CloseButton>
            </div>
            {children}
          </PopoverPanel>
        </Transition>
      </Popover>
    );
  }
);

export default SelectorPopover;

import { useEffect, type FC, type PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

import { tw } from '@/utilities';

const FOCUS_CLASSNAMES = tw`bg-tz-gray-100 dark:bg-tz-gray-600 cursor-pointer rounded-md`;
const SELECTED_CLASSNAMES = tw`bg-tz-gray-200 dark:bg-tz-gray-700 rounded-md`;
const OPTION_CLASSNAMES = tw`flex items-center justify-start space-x-3 p-2`;

interface Props {
  focus: boolean;
  selected: boolean;
  emitFocusValue?: () => void;
}

const Option: FC<PropsWithChildren<Props>> = ({ children, focus, selected, emitFocusValue }) => {
  useEffect(() => {
    if (focus && emitFocusValue) {
      emitFocusValue();
    }
  }, [focus, emitFocusValue]);

  return (
    <div className={twMerge(OPTION_CLASSNAMES, focus && FOCUS_CLASSNAMES, selected && SELECTED_CLASSNAMES)}>
      {children}
    </div>
  );
};

export default Option;

import type { FC } from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import { DirtyContext } from '@/DirtyContext';

import InputGroup from '../InputGroup';

import UnitSelector from './UnitSelector';

import useStateWithCallback from '@shared/hooks/useStateWithCallback';
import type { ItemModel } from '@models/SelectItem';
import useRefWithClickOutside from '@shared/hooks/useRefWithClickOutside';

export type Unit = 'minutes' | 'hours' | 'days' | 'weeks' | 'business_minutes' | 'business_hours' | 'business_days';

interface Props {
  name: string;
  initialValue: number;
  unit: 'minutes' | 'days';
  units: Unit[];
  disabled?: boolean;
  error?: string | null;
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
  onChange?: (value: string) => void;
}

const Duration: FC<Props> = ({
  name,
  initialValue,
  unit: underlyingUnit,
  disabled,
  error,
  units,
  onKeyDown: handleKeyDown,
  onChange,
}) => {
  const context = useContext(DirtyContext);
  const [dropdownShowing, setDropdownShowing] = useState<boolean>(false);
  const [unit, setUnit] = useState<Unit>(detectUnit(initialValue, underlyingUnit, units));
  const [value, setValue] = useStateWithCallback(initialValue);
  const [valueStr, setValueStr] = useState<string>(convert(initialValue, underlyingUnit, unit).toString());

  const unitOptions = useMemo(() => {
    return units.map(u => ({ id: u, name: u }));
  }, [units]);

  const unitsDropdownMenuRef = useRefWithClickOutside<HTMLDivElement>(() => setDropdownShowing(false));

  useEffect(() => {
    const unit = detectUnit(initialValue, underlyingUnit, units);
    setUnit(unit);
    setValueStr(convert(initialValue, underlyingUnit, unit).toString());
  }, [initialValue, underlyingUnit, units]);

  const handleKeyPressed = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (!e.key.match(/\d/)) e.preventDefault();
  };

  const handleChange = (value: string): void => {
    if (context.handleDirty) context.handleDirty();

    if (Number(value) < 0) {
      setValue(0);
      setValueStr('');
      return;
    }

    setValue(convert(Number(value), unit, underlyingUnit), (newValue: number) => {
      if (onChange) onChange(newValue.toString());
    });
    setValueStr(value);
  };

  const handleDropdownClick = (): void => {
    setDropdownShowing(!dropdownShowing);
  };

  const handleUnitSelection = (newUnit: ItemModel): void => {
    const newSelectedUnit = newUnit.id as Unit;

    setUnit(newSelectedUnit);
    setValue(convert(Number(valueStr), newSelectedUnit, underlyingUnit));
    setDropdownShowing(false);

    if (onChange) onChange(convert(Number(valueStr), newSelectedUnit, underlyingUnit).toString());
  };

  return (
    <>
      <InputGroup
        mode="controlled"
        type="number"
        name={name}
        error={error || undefined}
        disabled={disabled}
        value={valueStr}
        attachmentClassName={classNames(disabled ? 'bg-tz-gray-100' : 'bg-white', { '!rounded-b-none': dropdownShowing })}
        attachment={
          <UnitSelector
            name={name}
            items={unitOptions}
            disabled={disabled || false}
            initialItemId={unit}
            ref={unitsDropdownMenuRef}
            onChange={handleUnitSelection}
            onClick={handleDropdownClick}
          />
        }
        placement="end"
        onChange={handleChange}
        onKeyPress={handleKeyPressed}
        onKeyDown={handleKeyDown}
      />

      <input type="hidden" name={name} value={value} />
    </>
  );
};

const UNITS = {
  minutes: { minutes: 1, hours: 1 / 60, days: 1 / 24 / 60, weeks: 1 / 7 / 24 / 60 },
  hours: { minutes: 60, hours: 1, days: 1 / 24, weeks: 1 / 24 / 7 },
  days: { minutes: 60 * 24, hours: 24, days: 1, weeks: 1 / 7 },
  weeks: { minutes: 7 * 24 * 60, hours: 7 * 24, days: 7, weeks: 1 },
};

function convert(value: number, from: Unit, to: Unit): number {
  const rate = UNITS[from][to];
  return Number((value * rate).toFixed(3));
}

function detectUnit(value: number, unit: Unit, units: Unit[]): Unit {
  const mins = convert(value, unit, 'minutes');
  let detected: Unit = 'minutes';

  if (mins > 10080) {
    detected = 'weeks';
  } else if (mins > 1440) {
    detected = 'days';
  } else if (mins > 60) {
    detected = 'hours';
  }

  return units.includes(detected) ? detected : closestUnit(unit, units);
}

function closestUnit(unit: Unit, units: Unit[]): Unit {
  const i = Object.keys(UNITS).indexOf(unit);
  const start = Object.keys(UNITS).indexOf(units[0]);

  return i < start ? units[0] : units[units.length - 1];
}

export default Duration;

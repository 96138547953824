import type { FC } from 'react';
import { useContext, useMemo } from 'react';

import { List } from 'immutable';
import type { EditorPlugin } from '@draft-js-plugins/editor';
import type { CharacterMetadata } from 'draft-js';
import { ContentBlock, ContentState, EditorState, genKey } from 'draft-js';

import { PlainText } from '@/utilities';

import Paragraph from './Paragraph';

import { deserialize as deserializeContent, serialize as serializeContent } from '@shared/Editor/serialization';
import type { Leaf, Paragraph as ParagraphType } from '@shared/Editor/serialization';
import Editor from '@shared/Editor';
import VariablesContext from '@shared/VariablesContext';
import type Variable from '@models/Variable';

interface Props {
  name: string;
  initialValue: ParagraphType;
  readOnly: boolean;
  error?: string | null;
}

const LineEditor: FC<Props> = ({ name, initialValue, readOnly, error }) => {
  const { variables } = useContext(VariablesContext);
  const value = useMemo(() => {
    return EditorState.createWithContent(deserializeContent([initialValue], variables));
  }, [initialValue, variables]);

  const plugins = useMemo(() => {
    return [createSingleLinePlugin()];
  }, []);

  const serialize = (contentState: ContentState): string => {
    return JSON.stringify(serializeContent(contentState)[0]);
  };

  if (readOnly) {
    const content = readOnlyContent(serializeContent(value.getCurrentContent())[0].children, variables);

    if (content.length === 0) return <PlainText>(None)</PlainText>;

    return <PlainText>{content.map(c => c)}</PlainText>;
  }

  return (
    <Editor
      name={name}
      plugins={plugins}
      initialValue={value}
      paragraph={Paragraph}
      readOnly={readOnly}
      serialize={serialize}
      error={error}
    />
  );
};

function createSingleLinePlugin(): EditorPlugin {
  return {
    onChange(editorState: EditorState): EditorState {
      const blocks = editorState.getCurrentContent().getBlocksAsArray();

      if (blocks.length <= 1) return editorState;

      let characterList = List<CharacterMetadata>();
      let text = List();

      blocks.forEach(block => {
        characterList = characterList.concat(block.getCharacterList()).toList() as List<CharacterMetadata>;
        text = text.push(block.getText().replace(/\n/g, ''));
      });

      const contentBlock = new ContentBlock({
        key: genKey(),
        text: text.join(''),
        type: 'unstyled',
        characterList,
        depth: 0,
      });
      const contentState = ContentState.createFromBlockArray([contentBlock]);
      const newEditorState = EditorState.push(editorState, contentState, 'remove-range');
      return EditorState.moveFocusToEnd(newEditorState);
    },
    handleReturn(_) {
      return 'handled';
    },
  };
}

export const defaultTemplate: ParagraphType = {
  type: 'paragraph',
  children: [{ text: '' }],
};

export { ParagraphType as Content };

export default LineEditor;

function readOnlyContent(contentLeafs: Leaf[], variables: Variable[]): JSX.Element[] {
  let content: JSX.Element[] = [];

  contentLeafs.forEach((leaf, i) => {
    switch (leaf.type) {
      case undefined:
        leaf.text && content.push(<span key={`text-leaf-${i}`}>{leaf.text}</span>);
        break;
      case 'variable':
        content.push(
          <span key={`variable-leaf-${i}`} className="DraftEditor__Variable">
            {variables.find(v => v.variable === leaf.variable)?.name || 'Unknown Variable'}
          </span>
        );
        break;
      default:
        break;
    }
  });

  return content;
}

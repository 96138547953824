import type { FC } from 'react';
import { Suspense, useState } from 'react';

import { ErrorBoundary } from 'react-error-boundary';

import Controls from '../Components/Controls';
import DateSelector from '../Tools/DateSelector';
import Calendar from '../Components/Calendar';
import WebsocketReceiver from '../Components/WebsocketReceiver';
import TopBar from '../Components/TopBar';
import ViewToggler from '../Tools/ViewToggler';
import { useAppDispatch, useAppSelector } from '../hooks';
import { selectViewMode } from '../Reducers/uiReducer';
import { loadActiveUsers, setTimezone } from '../actions';
import type { User } from '../types';
import UsersSelector from '../Tools/UsersSelector';
import ErrorFallback from '../Components/ErrorFallback';

import WeekView from './WeekView';
import DayView from './DayView';
import CalendarBlurOverlay from './CalendarBlurOverlay';

import ComponentLoading from 'design_system/Components/ComponentLoading';

interface Props {
  scheduleableUsers: User[];
}

const CalendarView: FC<Props> = ({ scheduleableUsers }) => {
  const dispatch = useAppDispatch();
  const [componentLoaded, setComponentLoaded] = useState(false);

  const weekView = useAppSelector(selectViewMode) === 'week';

  if (!componentLoaded && scheduleableUsers) {
    dispatch(loadActiveUsers({ users: scheduleableUsers }));

    const autoDetectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    dispatch(setTimezone({ timezone: autoDetectedTimezone }));

    setComponentLoaded(true);
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <WebsocketReceiver />
      <TopBar>
        <Controls>
          <UsersSelector />
          <DateSelector />
          <ViewToggler />
        </Controls>
      </TopBar>
      <Calendar>
        <Suspense
          fallback={
            <CalendarBlurOverlay>
              <ComponentLoading />
            </CalendarBlurOverlay>
          }
        >
          {weekView && <WeekView />}
          {!weekView && <DayView />}
        </Suspense>
      </Calendar>
    </ErrorBoundary>
  );
};

export default CalendarView;

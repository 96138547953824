import { useContext, useEffect, useMemo, type FC } from 'react';

import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';

import asScreen from '@/Screen';
import Flash from '@/Flash';
import AccountContext from '@/AccountContext';

import uiReducer from './Reducers/uiReducer';
import calendarReducer from './Reducers/calendarReducer';
import CalendarView from './CalendarView';
import type { User } from './types';
import TimePeriod from './Components/TimePeriod';
import Flyout from './Components/Flyout';
import CalendarBlurOverlay from './CalendarView/CalendarBlurOverlay';

import Navigation from 'design_system/Components/Navigation';
import MainContainer from 'design_system/Components/MainContainer';
import H2 from 'design_system/Typography/Headings/H2';
import BlankSlate from 'design_system/Components/BlankSlate';
import Button from 'design_system/Components/Button';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  scheduleableUsers: User[];
}

const DispatchHQ: FC<Props> = ({ scheduleableUsers }) => {
  const { enabledTimeStashCaching } = useContext(AccountContext);

  const store = useMemo(() => {
    return configureStore({
      reducer: {
        ui: uiReducer,
        calendar: calendarReducer,
      },
    });
  }, []);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const {
        calendar: { visibleUserIds },
        ui: { mode },
      } = store.getState();
      localStorage.setItem('dhq:visibleUsers', JSON.stringify(visibleUserIds));
      localStorage.setItem('dhq:viewMode', mode);
    });

    return () => unsubscribe();
  }, [store]);

  return (
    <Provider store={store}>
      <Navigation activeItem="dispatch_hq" />
      <MainContainer>
        <div className="flex items-center justify-start space-x-4">
          <H2>Dispatch HQ</H2>
          {enabledTimeStashCaching && <TimePeriod />}
        </div>
        <Flash />
        {enabledTimeStashCaching ? (
          <>
            <Flyout />
            <CalendarView scheduleableUsers={scheduleableUsers} />
          </>
        ) : (
          <div className="mt-16">
            <CalendarBlurOverlay>
              <BlankSlate
                description="To use this feature, please follow the link below to allow TimeZest cache your calendar appointments."
                title="Dispatch HQ is currently unavailable"
                actionButton={
                  <Button
                    url="/settings/security"
                    dataMethod="GET"
                    className="mx-auto"
                    label={
                      <div className="flex items-center gap-x-1">
                        <FontAwesomeIcon icon="arrow-right-long" height={10} />
                        <p>Enable calendar appointments caching</p>
                      </div>
                    }
                    size="medium"
                  />
                }
              />
            </CalendarBlurOverlay>
          </div>
        )}
      </MainContainer>
    </Provider>
  );
};

export default asScreen(DispatchHQ);

import type { FC } from 'react';

import Logo from './timezest_logo_navy.png';
import DarkLogo from './timezest_logo_yellow.png';

const LogoItem: FC = () => {
  return (
    <>
      <div className="mx-auto w-[22px] h-[22px] pb-1 dark:hidden">
        <img src={Logo} alt="TimeZest Logo" />
      </div>
      <div className="mx-auto hidden w-[22px] h-[22px] pb-1 dark:block">
        <img src={DarkLogo} alt="TimeZest Logo" />
      </div>
    </>
  );
};

export default LogoItem;

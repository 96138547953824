import type { FC, PropsWithChildren } from 'react';

import { tw } from '@/utilities';

interface Props {
  href: string;
}

const LINK_CLASS_NAMES = tw`text-tz-blue-500 dark:text-tz-blue-400 text-sm font-bold hover:underline`;

const Link: FC<PropsWithChildren<Props>> = ({ children, href }) => {
  return (
    <a className={LINK_CLASS_NAMES} href={href}>
      {children}
    </a>
  );
};

export default Link;

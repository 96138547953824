import type { FC } from 'react';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';

import classNames from 'classnames';
import styled from 'styled-components';
import type PluginEditor from '@draft-js-plugins/editor/lib/Editor';
import { EditorState } from 'draft-js';
import DraftEditor from '@draft-js-plugins/editor';
import { useSortable } from '@dnd-kit/sortable';

import { useAppDispatch, useAppSelector } from '../hooks';
import { updateTextContent } from '../actions';
import { richTextPlugin } from '../richTextPlugin';
import { deserialize, serialize } from '../serialization';
import DragHandle from '../DragHandle';
import { ConfigContext } from '../EmailEditor';
import type { IndexedMjmlButton } from '../EmailTemplate';
import { selectDraggingElement, selectSelectedElementId } from '../Reducers/uiReducer';
import { selectTemplateIndex } from '../Reducers/templateReducer';

import { paddingStyle, selectElementHandler } from './utilities';

interface Props {
  node: IndexedMjmlButton;
  selected: boolean;
}

const Container = styled.div`
  height: fit-content;
  position: relative;
  display: flex;
`;

const MjmlButton: FC<Props> = ({ node, selected }) => {
  const { readOnly } = useContext(ConfigContext);

  const editorRef = useRef<PluginEditor>(null);
  const [editorState, setEditorState] = useState(() => {
    return EditorState.createWithContent(deserialize(node.content!));
  });
  const [hovering, setHovering] = useState(false);
  const dispatch = useAppDispatch();

  const draggingElement = useAppSelector(selectDraggingElement);
  const index = useAppSelector(selectTemplateIndex);
  const { initialSelectedElementId, selectedElementId } = useAppSelector(selectSelectedElementId);

  const { attributes, listeners, setNodeRef, transition, isDragging } = useSortable({
    id: node.id,
    data: {
      type: 'mjml-button',
    },
  });

  const style = useMemo(() => {
    return {
      backgroundColor: node.buttonColor,
      color: node.textColor,
      margin: `0 ${node.alignment === 'right' ? 0 : 'auto'} 0 ${node.alignment === 'left' ? 0 : 'auto'}`,
      padding: paddingStyle(node.innerPadding),
    };
  }, [node.buttonColor, node.textColor, node.innerPadding, node.alignment]);

  const handleBlur = () => {
    dispatch(updateTextContent({ elementId: node.id, content: serialize(editorState.getCurrentContent()) }));
  };

  const handleMouseEvents = (event: React.MouseEvent) => {
    if (selected && !draggingElement) {
      event.stopPropagation();
    }
  };

  const handleMouseOver = (event: React.MouseEvent) => {
    event.stopPropagation();
    setHovering(true);
  };

  const handleMouseOut = (event: React.MouseEvent) => {
    event.stopPropagation();
    setHovering(false);
  };

  const handleClick = (event: React.MouseEvent) => {
    selectElementHandler(dispatch, node.id, index, selectedElementId, initialSelectedElementId)(event);

    editorRef.current?.focus();
  };

  useEffect(() => {
    if (selected) {
      editorRef.current?.focus();
      setHovering(true);
    } else {
      editorRef.current?.blur();
      setHovering(false);
    }
  }, [selected]);

  return (
    <Container
      id={node.id}
      ref={setNodeRef}
      className={classNames({
        EmailEditor__Element: true,
        DraftEditor: true,
        'EmailEditor__Element--highlighted': (hovering && !draggingElement) || selected,
      })}
      style={{
        zIndex: selected ? 999 : 'unset',
        transition,
        opacity: isDragging ? 0.5 : 1,
        padding: paddingStyle(node.outerPadding),
        backgroundColor: node.backgroundColor,
      }}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onPointerMove={handleMouseEvents}
    >
      <button className="rounded border-0" style={style}>
        <DraftEditor
          ref={editorRef}
          blockStyleFn={() => `${!selected && 'user-select-none'}`}
          textAlignment="center"
          readOnly={(!selected && !hovering) || readOnly}
          editorState={editorState}
          plugins={[richTextPlugin]}
          onChange={setEditorState}
          onBlur={handleBlur}
        />
      </button>
      <div className="EmailEditor__Element_Identifier">
        Button
        <span className={classNames({ 'pe-none': readOnly })} {...attributes} {...listeners}>
          <DragHandle selected={selected} />
        </span>
      </div>
    </Container>
  );
};

export default MjmlButton;

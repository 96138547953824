import type { CSSProperties, FC } from 'react';

import { twMerge } from 'tailwind-merge';

import { tw } from '@/utilities';

import EntryGroup from './EntryGroup';

import { useAppSelector } from '../../../hooks';
import { selectZoom } from '../../../Reducers/uiReducer';
import { selectCalendarEntryTreesByUser } from '../../../Reducers/calendarReducer';

const CONTAINER_CLASS_NAMES = tw`relative grid grid-cols-1`;
const GRID_CONTAINER_CLASS_NAMES = tw`absolute inset-0 grid`;
const GRID_CELL_CLASS_NAMES = tw`border-tz-gray-200 border-b border-dashed`;

const NUMBER_OF_ROWS = 48;
const BASE_ROW_HEIGHT = 3;

interface Props {
  firstColumn: boolean;
  userId: string;
}

const CalendarColumn: FC<Props> = ({ firstColumn, userId }) => {
  const zoom = useAppSelector(selectZoom);

  const calendarEntryGroupsByUser = useAppSelector(selectCalendarEntryTreesByUser);

  const currentCalendarEntryGroupsByUser = calendarEntryGroupsByUser[userId];

  const style: CSSProperties = {
    gridTemplateRows: `repeat(${NUMBER_OF_ROWS}, ${BASE_ROW_HEIGHT * zoom}rem)`,
  };

  return (
    <div className={CONTAINER_CLASS_NAMES} style={style}>
      <div className={GRID_CONTAINER_CLASS_NAMES}>
        {[...Array(NUMBER_OF_ROWS)].map((_, j) => (
          <div
            key={`cell-${j}`}
            className={twMerge(
              GRID_CELL_CLASS_NAMES,
              firstColumn ? 'border-l-0' : '[border-left:solid_1px_theme(colors.tz-gray.200)]',
              j === NUMBER_OF_ROWS - 1 && 'border-b-0'
            )}
          />
        ))}
      </div>
      {currentCalendarEntryGroupsByUser && (
        <>
          {Object.keys(currentCalendarEntryGroupsByUser.calendarEntryGroupMap).map(id => (
            <EntryGroup
              key={`group-${id}`}
              entryGroup={currentCalendarEntryGroupsByUser.calendarEntryGroupMap[id]}
              entryMap={currentCalendarEntryGroupsByUser.calendarEntryMap}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default CalendarColumn;

import type { FC } from 'react';

import Form from '@/Form';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  integrationUserId: string;
  hasAppInstalled: boolean;
  processing: boolean;
}

const UserStatus: FC<Props> = ({ integrationUserId, hasAppInstalled, processing }) => {
  if (processing) {
    return <div className="spinner-border spinner-border-sm me-2" />;
  }

  if (hasAppInstalled) {
    return (
      <div>
        <span className="text-success">
          <FontAwesomeIcon icon="check" />
        </span>
        &nbsp; This user can receive messages. &nbsp;
      </div>
    );
  }

  return (
    <Form url="/settings/integrations/teams_messaging/enable_messages">
      <input type="hidden" name="integration_user_id" value={integrationUserId} />
      <button type="submit" className="btn btn-primary btn-sm" data-disable-with="Enabling">
        Enable messaging
      </button>
    </Form>
  );
};

export default UserStatus;

import type { FC, PropsWithChildren } from 'react';

import { tw } from '@/utilities';

const CONTAINER_CLASSNAMES = tw`relative h-[calc(100vh-140px)] w-full`;
const INNER_CONTAINER_CLASSNAMES = tw`flex h-full w-full bg-white`;
const LEFT_GRID_AREA_CLASSNAMES = tw`w-28 p-2`;
const CHILDREN_CLASSNAMES = tw`absolute inset-0 z-20 mt-40`;
const OVERLAY_CLASSNAMES = tw`bg-tz-gray-100/30 pointer-events-none absolute inset-0 z-10 h-full w-full cursor-default backdrop-blur-sm`;
const MOCK_GRID_CELL_CLASSNAMES = tw`border-tz-gray-300 h-auto w-auto border-l`;
const RIGHT_GRID_AREA_CLASSNAMES = tw`grid grow grid-cols-7`;

const CalendarBlurOverlay: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className={CONTAINER_CLASSNAMES}>
      <div className={CHILDREN_CLASSNAMES}>{children}</div>
      <div className={OVERLAY_CLASSNAMES} />
      <div className={INNER_CONTAINER_CLASSNAMES}>
        <div className={LEFT_GRID_AREA_CLASSNAMES} />
        <div className={RIGHT_GRID_AREA_CLASSNAMES}>
          {[...Array(7)].map((_, j) => (
            <div key={j} className={MOCK_GRID_CELL_CLASSNAMES} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CalendarBlurOverlay;

import type { FC } from 'react';

import classNames from 'classnames';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import H6 from 'design_system/Typography/Headings/H6';

type Icon = 'lightbulb' | 'triangle-exclamation' | 'circle-xmark' | 'circle-check';

type Color = 'green' | 'blue' | 'yellow' | 'red' | 'aqua' | 'gray';

interface Props {
  icon: Icon | null;
  color: Color;
  title?: string;
  className?: string;
  content?: JSX.Element | string;
}

const ICON_COLORS = {
  blue: 'text-blue-800 dark:text-blue-500',
  green: 'text-green-700 dark:text-green-600',
  red: 'text-red-600',
  yellow: 'text-yellow-500',
  aqua: 'text-cyan-400',
  gray: 'text-tz-gray-400',
};

const Alert: FC<Props> = ({ icon: iconName, title, content, color, className }) => {
  return (
    <div className={classNames('me-1 rounded p-4 text-xs leading-4', determineColor(color), className)} role="alert">
      <div className="flex items-start">
        {iconName && (
          <div className="me-2 mt-px min-w-[15px]">
            <FontAwesomeIcon icon={iconName} size="lg" width={15} height={15} className={ICON_COLORS[color]} />
          </div>
        )}

        <div className="grid grid-cols-1 gap-3">
          {title && <H6 className="mb-1">{title}</H6>}

          {content}
        </div>
      </div>
    </div>
  );
};

export default Alert;

function determineColor(color: Color): string {
  switch (color) {
    case 'green':
      return 'border-l-4 border-green-600 bg-green-50 dark:border-green-700 dark:bg-green-900/80';
    case 'blue':
      return 'border-l-4 border-blue-700 bg-blue-50 dark:border-blue-700 dark:bg-blue-900/80';
    case 'red':
      return 'border-l-4 border-red-600 bg-red-50 dark:border-red-600 dark:bg-red-900/80';
    case 'yellow':
      return 'border-l-4 border-yellow-400 bg-yellow-50 dark:border-yellow-600 dark:bg-yellow-900/80';
    case 'gray':
      return 'border-l-4 border-tz-gray-400 bg-tz-gray-50 dark:border-gray-600 dark:bg-gray-900/80';
    default:
      return 'border-l-4 border-cyan-300 bg-cyan-50 dark:border-cyan-600 dark:bg-cyan-900';
  }
}

import type { FC, PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props {
  disabled?: boolean;
  className?: string;
}

const TextExtraSmall: FC<PropsWithChildren<Props>> = ({ children, className, disabled }) => {
  return (
    <p
      className={twMerge(
        'text-xs font-normal leading-5',
        disabled ? 'text-tz-gray-300 dark:text-tz-gray-500' : 'text-tz-gray-700 dark:text-tz-gray-300',
        className
      )}
    >
      {children}
    </p>
  );
};

export default TextExtraSmall;

import type { FC } from 'react';

import type { QueryResult } from '@apollo/client';
import get from 'lodash/fp/get';
import sortBy from 'lodash/fp/sortBy';

import PlainTextRow from './ui/PlainTextRow';
import SelectRow from './ui/SelectRow';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import RowError from '@shared/ui/RowError';
import { idStringifier } from '@shared/text';

interface Props {
  name: string;
  label: string;
  helpText: string;
  error: string | undefined;
  value: string | undefined;
  field: string;
  queryResult: QueryResult;
  disabled?: boolean;
  readOnly?: boolean;
  templateMode?: boolean;
  prompt?: string;
  emptyDataMessage?: string;
  loadingMessage?: string;
  queryErrorMessage?: string | JSX.Element;
  warning?: string;
  optionRenderer?: (s: { [key: string]: string | number }) => { name: string; value: string };
  onChange?: (value: string | undefined, name: string | undefined) => void;
}

const SelectRowFromGraphQL: FC<Props> = ({
  name,
  label,
  helpText,
  loadingMessage,
  value,
  error,
  field,
  disabled,
  prompt,
  readOnly,
  emptyDataMessage,
  queryErrorMessage,
  templateMode,
  queryResult,
  warning,
  optionRenderer,
  onChange,
}) => {
  if (templateMode) {
    return (
      <PlainTextRow label={label} helpText={helpText}>
        To be selected when using this workflow template.
      </PlainTextRow>
    );
  }

  const { error: queryError, loading, data } = queryResult;

  const optionsIterator = optionRenderer || idStringifier;
  const queryData = get(field, data) || [];
  const sortedOptions = sortBy('name', queryData.map(optionsIterator));

  const options = prompt ? [{ value: '', name: prompt }, ...sortedOptions] : sortedOptions;

  if (readOnly) {
    return (
      <PlainTextRow label={label} helpText={helpText}>
        {loading
          ? 'Loading...'
          : options.find(data => data.value.toString() === value?.toString())?.name || '(Unknown)'}
        {warning && <small className="form-text text-warning d-block">{warning}</small>}
      </PlainTextRow>
    );
  }

  if (loading) {
    return (
      <PlainTextRow label={label} helpText={helpText}>
        <FontAwesomeIcon spin icon="circle-notch" />
        &nbsp;&nbsp; {loadingMessage || 'Loading...'}
      </PlainTextRow>
    );
  }

  if (queryError) {
    return typeof queryErrorMessage === 'string' ? (
      <RowError error={queryErrorMessage || ''} label={label} helpText={helpText} />
    ) : (
      queryErrorMessage
    );
  }

  if (!queryData.length) {
    return <RowError label={label} helpText={helpText} error={emptyDataMessage || ''} />;
  }

  return (
    <SelectRow
      error={error}
      helpText={helpText}
      disabled={disabled}
      label={label}
      name={name}
      options={options}
      value={value || ''}
      warning={warning}
      onChange={onChange}
    />
  );
};

export default SelectRowFromGraphQL;

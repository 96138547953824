import type { FC } from 'react';

import { Field, Switch as HeadlessSwitch, Label } from '@headlessui/react';
import classNames from 'classnames';
import { twJoin } from 'tailwind-merge';

import { tw } from '@/utilities';

import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';
import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';

type Size = 'small' | 'large';

interface Props {
  checked: boolean;
  label: string | JSX.Element;
  size: Size;
  reverseLabel?: boolean;
  className?: string;
  disabled?: boolean;
  onChange: () => void;
}

const SIZES = {
  small: {
    switch: 'h-3 w-6',
    indicator: 'h-2 w-2',
    translateX: 'translate-x-3.5',
  },
  large: {
    switch: 'h-4 w-8',
    indicator: 'h-3 w-3',
    translateX: 'translate-x-[18px]',
  },
};

const CONTAINER_CLASS_NAMES = tw`dark:text-tz-gray-300 mb-2 flex items-start`;
const SWITCH_CLASS_NAMES = tw`relative mt-0.5 inline-flex shrink-0 items-center rounded-full outline-none transition-colors`;
const SWITCH_INDICATOR_CLASS_NAMES = tw`inline-block transform rounded-full bg-white transition-transform`;

const Switch: FC<Props> = ({ checked, label, size, className, disabled, onChange, reverseLabel }) => {
  return (
    <Field className={twJoin(CONTAINER_CLASS_NAMES, reverseLabel ? 'flex-row-reverse' : 'space-x-1.5')}>
      <HeadlessSwitch
        disabled={disabled}
        checked={checked}
        className={twJoin(
          SWITCH_CLASS_NAMES,
          checked ? 'bg-tz-green-600' : 'bg-tz-gray-200 dark:bg-tz-gray-700',
          SIZES[size]['switch'],
          disabled && 'opacity-75 dark:opacity-50',
          className
        )}
        onChange={onChange}
      >
        <span
          className={twJoin(
            SWITCH_INDICATOR_CLASS_NAMES,
            SIZES[size]['indicator'],
            checked ? SIZES[size]['translateX'] : 'translate-x-0.5'
          )}
        />
      </HeadlessSwitch>
      <Label className={classNames(reverseLabel && 'flex flex-grow flex-col')}>
        {typeof label === 'string' ? <TextLabel disabled={disabled} size={size} label={label} /> : label}
      </Label>
    </Field>
  );
};

export default Switch;

const TextLabel: FC<{ disabled?: boolean; size: Size; label: string }> = ({ disabled, size, label }) => {
  return (
    <>
      {size === 'large' ? (
        <TextSmall disabled={disabled}>{label}</TextSmall>
      ) : (
        <TextExtraSmall disabled={disabled}>{label}</TextExtraSmall>
      )}
    </>
  );
};

import { useEffect, type FC } from 'react';

import DayViewLayout from './LayoutEngine';

import { useGetCalendarEntriesSuspenseQuery } from '@graphql/generated';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { selectDatesRange, selectTimezone, selectVisibleUserIds } from '../../Reducers/calendarReducer';
import type { CalendarEntry } from '../../types';
import { loadCalendarEntries } from '../../actions';
import { getEndDateThreshold } from '../../Utils/getEndDateThreshold';

const DayView: FC = () => {
  const dispatch = useAppDispatch();

  const datesRange = useAppSelector(selectDatesRange);
  const visibleUserIds = useAppSelector(selectVisibleUserIds);
  const timezone = useAppSelector(selectTimezone);

  const { data } = useGetCalendarEntriesSuspenseQuery({
    fetchPolicy: 'no-cache',
    variables: {
      userIds: visibleUserIds.map(Number),
      from: datesRange.day,
      to: getEndDateThreshold(datesRange.day),
      timezone,
    },
  });

  const calendarEntries = data.timezest.calendarEntries as CalendarEntry[];

  useEffect(() => {
    dispatch(loadCalendarEntries({ calendarEntries, viewMode: 'day' }));
  }, [dispatch, calendarEntries]);

  return <DayViewLayout />;
};

export default DayView;

import type { FC } from 'react';
import { useContext } from 'react';

import { useDraggable } from '@dnd-kit/core';

import ConfigContext from '../ConfigContext';

import NewToolCard from './NewToolCard';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const Logo: FC<{}> = () => {
  const { readOnly } = useContext(ConfigContext);

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: 'new-logo',
    data: {
      type: 'mjml-logo',
      defaultProperties: {
        padding: { setAll: true, all: '0px', left: '0px', right: '0px', top: '0px', bottom: '0px' },
      },
    },
  });

  return (
    <NewToolCard ref={setNodeRef} {...listeners} {...attributes}>
      <p className="m-0 mb-2 small text-muted">Logo</p>
      <FontAwesomeIcon icon="copyright" size="3x" color="#585858" opacity={readOnly ? 0.5 : 1} />
    </NewToolCard>
  );
};

export default Logo;

import type { FC } from 'react';

import Badge from '@ui/Badge';
import { capitalize } from '@shared/text';

interface Props {
  status: string;
}

const Status: FC<Props> = ({ status }) => {
  if (status === 'sent') {
    return <Badge color="blue">Sent</Badge>
  }

  if (status === 'scheduled') {
    return <Badge color="green">Scheduled</Badge>;
  }

  if (status === 'cancelled') {
    return <Badge color="red">Cancelled</Badge>;
  }

  return <Badge color="grey">{capitalize(status)}</Badge>;
};

export default Status;

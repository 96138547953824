import type { FC } from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import { useSortable } from '@dnd-kit/sortable';

import { paddingStyle, selectElementHandler, useLogo } from '../utilities';

import ConfigContext from '../../ConfigContext';
import { useAppDispatch, useAppSelector } from '../../hooks';
import DragHandle from '../../DragHandle';
import type { IndexedMjmlLogo } from '../../EmailTemplate';
import { selectDraggingElement, selectSelectedElementId } from '../../Reducers/uiReducer';
import { selectTemplateIndex } from '../../Reducers/templateReducer';

interface Props {
  node: IndexedMjmlLogo;
  selected: boolean;
}

const MjmlLogo: FC<Props> = ({ node, selected }) => {
  const { readOnly, logoUrl } = useContext(ConfigContext);

  const { url, width, height } = useLogo(logoUrl);

  const { attributes, listeners, setNodeRef, transition, isDragging } = useSortable({
    id: node.id,
    data: {
      type: 'mjml-logo',
    },
  });

  const draggingElement = useAppSelector(selectDraggingElement);
  const index = useAppSelector(selectTemplateIndex);
  const { initialSelectedElementId, selectedElementId } = useAppSelector(selectSelectedElementId);

  const [hovering, setHovering] = useState(false);

  useEffect(() => {
    if (selectedElementId !== node.id) {
      setHovering(false);
    }
  }, [node.id, selectedElementId]);

  const dispatch = useAppDispatch();

  const style = useMemo(() => {
    return {
      zIndex: selected ? 999 : 'unset',
      transition,
      opacity: isDragging ? 0.5 : 1,
      padding: paddingStyle(node.padding),
    };
  }, [node.padding, selected, transition, isDragging]);

  const handleMouseOver = (event: React.MouseEvent) => {
    event.stopPropagation();
    setHovering(true);
  };

  const handleMouseOut = (event: React.MouseEvent) => {
    event.stopPropagation();
    setHovering(false);
  };

  /* eslint-disable jsx-a11y/mouse-events-have-key-events, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
  return (
    <div
      id={node.id}
      ref={setNodeRef}
      style={style}
      className={classNames({
        'position-relative': true,
        EmailEditor__Element: true,
        'EmailEditor__Element--highlighted': (hovering && !draggingElement) || selected,
      })}
      onClick={selectElementHandler(dispatch, node.id, index, selectedElementId, initialSelectedElementId)}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <img height={height} width={width} src={url} className="d-block pe-none mx-auto" alt="account-logo" />
      <div className="EmailEditor__Element_Identifier">
        Logo
        <span className={classNames({ 'pe-none': readOnly })} {...attributes} {...listeners}>
          <DragHandle selected={selected} />
        </span>
      </div>
    </div>
  );
};

export default MjmlLogo;

import type { FC, PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

import { tw } from '@/utilities';
interface Props {
  className?: string;
}

const BASE_H5_CLASS = tw`text-md mb-2 font-semibold tracking-tight text-tz-gray-600 dark:text-tz-gray-300`;

const H5: FC<PropsWithChildren<Props>> = ({ children, className }) => {
  return <h5 className={twMerge(BASE_H5_CLASS, className)}>{children}</h5>;
};

export default H5;

import { useEffect, useState, type FC, type PropsWithChildren } from 'react';

import asScreen from '@/Screen';
import Flash from '@/Flash';

import Agent from './Agent';
import ErrorMessage from './ErrorMessage';
import NoAgentsFound from './NoAgentsFound';

import type { Filter } from '@models/Filter';
import H2 from 'design_system/Typography/Headings/H2';
import MainContainer from 'design_system/Components/MainContainer';
import Navigation from 'design_system/Components/Navigation';
import { type User, useGetUsersTqlQuery } from '@graphql/generated';
import { generateTQLString } from '@shared/ui/Filter/utilities';
import Pagination from 'design_system/Components/Pagination';
import StackedList from 'design_system/Components/StackedList';
import ComponentLoading from 'design_system/Components/ComponentLoading';

const PAGE_SIZE = 20;

interface Props {
  tqlFilters: Filter[];
}

interface Pagination {
  page: number;
  pages: number;
}

const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <Navigation activeItem="agents" />
      <MainContainer>
        <H2>Agents</H2>
        <Flash />
        {children}
      </MainContainer>
    </>
  );
};

const AgentsIndex: FC<Props> = ({ tqlFilters }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [pagination, setPagination] = useState<Pagination>({ page: 1, pages: 1 });
  const [tqlString, _setTqlString] = useState(generateTQLString(tqlFilters));
  const [offset, setOffset] = useState(0);

  const {
    data: _data,
    error: requestError,
    loading,
  } = useGetUsersTqlQuery({
    variables: { filter: tqlString, limit: PAGE_SIZE, offset },
    onCompleted(data) {
      const userData = data?.timezest.users;
      setUsers(userData.nodes);
      setPagination({ page: userData.page || 1, pages: userData.pages || 1 });
    },
  });

  useEffect(() => {
    setOffset(0);
  }, [tqlString]);

  const sortedAgents = users.slice().sort((a, b) => a.name.localeCompare(b.name));

  const handlePageChange = selected => {
    setOffset((selected - 1) * PAGE_SIZE);
  };

  if (loading) {
    return (
      <Layout>
        <ComponentLoading />
      </Layout>
    );
  }

  if (requestError) {
    return (
      <Layout>
        <ErrorMessage />
      </Layout>
    );
  }

  if (sortedAgents.length === 0) {
    return (
      <Layout>
        <NoAgentsFound />
      </Layout>
    );
  }

  return (
    <Layout>
      <StackedList>
        {sortedAgents.map(user => (
          <Agent key={`agent-${user.id}`} user={user} />
        ))}
      </StackedList>
      <Pagination totalPages={pagination.pages} onPageChange={handlePageChange} />
    </Layout>
  );
};

export default asScreen(AgentsIndex);

import type { FC } from 'react';

import BlankSlate from 'design_system/Components/BlankSlate';

const DomainsBlankSlate: FC = () => {
  return (
    <BlankSlate
      title="No Email Domains configured."
      description="Add an email domain to start sending emails from TimeZest using your own domain."
    />
  );
};

export default DomainsBlankSlate;

import type { FC } from 'react';
import { Fragment } from 'react';

import TimeAgo from 'react-time-ago';

import EventBadge, { CLIENT_ACTIONS } from './EventBadge';

import type EventModel from '@models/Event';

interface Props {
  event: EventModel;
  index: number;
}

const ErrorDetails: FC<any> = error => {
  if (Array.isArray(error)) {
    return (
      <>
        {error.map((e, i) => (
          <Fragment key={i}>{JSON.stringify(e)}</Fragment>
        ))}
      </>
    );
  } else if (error !== null && typeof error === 'object') {
    return (
      <>
        {Object.keys(error).map(key => (
          <Fragment key={key}>
            {key}:&nbsp;{ErrorDetails(error[key])}&nbsp;
          </Fragment>
        ))}
      </>
    );
  } else {
    return <>{error || '(None)'}</>;
  }
};

const ErrorData: FC<{ errorData: Record<string, any> }> = ({ errorData }) => {
  if (!errorData['body'] && !errorData['error'] && !errorData['http_status'] && !errorData['path']) {
    return null;
  }

  return (
    <>
      {errorData['http_status'] && (
        <div className="row text-muted small mt-1">
          <div className="col-2 font-weight-bold">HTTP Status</div>
          <div className="col-10">{errorData['http_status']}</div>
        </div>
      )}
      {errorData['path'] && (
        <div className="row text-muted small mt-1">
          <div className="col-2 font-weight-bold">Request Path</div>
          <div className="col-10">{errorData['path']}</div>
        </div>
      )}
      <div className="row text-muted small mt-1">
        {errorData['error'] && (
          <>
            <div className="col-2 font-weight-bold">Error Message</div>
            <div className="col-10">{ErrorDetails(errorData['error'])}</div>
          </>
        )}
        {errorData['body'] && (
          <>
            <div className="col-2 font-weight-bold">Response Body</div>
            <div className="col-10">{errorData['body']}</div>
          </>
        )}
      </div>
    </>
  );
};

const Event: FC<Props> = ({ event, index }) => {
  const renderHorizontalRule = CLIENT_ACTIONS.includes(event.eventType) && index !== 0;

  return (
    <>
      {renderHorizontalRule && <hr />}
      <div className={index === 0 ? '' : 'mt-3'}>
        <div className="d-flex">
          <div className="flex-grow-1">
            <EventBadge event={event} />
          </div>
          <div className="text-muted small">
            <TimeAgo date={new Date(event.createdAt)} />
          </div>
        </div>
        <div className="small">{event.description}</div>
        <ErrorData errorData={event.errorData} />
      </div>
    </>
  );
};

export default Event;

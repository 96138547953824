import type { FC } from 'react';

import Flash from '@/Flash';
import Form from '@/Form';
import asScreen from '@/Screen';

import Domains from './Domains';
import ExternalEmailControl from './ExternalEmailControl';
import FromAddressControl from './FromAddressControl';
import ReplyToAddressHelp from './ReplyToAddressHelp';

import type DomainModel from '@models/Domain';
import Button from 'design_system/Components/Button';
import FormControl from 'design_system/Components/FormControl';
import FormDivider from 'design_system/Components/FormDivider';
import FormSection from 'design_system/Components/FormSection';
import H2 from 'design_system/Typography/Headings/H2';
import MainContainer from 'design_system/Components/MainContainer';
import Navigation from 'design_system/Components/Navigation';
import SideBarMenu from 'design_system/Components/SidebarMenu';
import TextInput from 'design_system/Components/TextInput';

type MailSendingMode = 'postmark' | 'domain';

interface Props {
  domains: DomainModel[];
  errors: Record<string, string>;
  externalEmailAddress: {
    singleExternalEmail: string | null;
    useSingleExternalEmail: boolean;
  };
  mailSendingConfiguration: {
    emailDomainId: number;
    fromLocalPart: string | null;
    mailSendingMode: MailSendingMode;
  };
  replyToAddress?: string;
}

const EmailSettingsShow: FC<Props> = ({
  domains,
  errors,
  externalEmailAddress,
  mailSendingConfiguration,
  replyToAddress,
}) => {
  return (
    <>
      <Navigation activeItem="settings" />
      <SideBarMenu activeItem="email" />
      <MainContainer>
        <H2>Email Settings</H2>
        <Flash />
        <FormDivider />
        <Form url="/settings/email" method="patch">
          <FormSection title="Email Domains" description="TimeZest can send emails using your own domain.">
            <Domains domains={domains} />
          </FormSection>
          <FormDivider />
          <FormSection
            title="Address Options"
            description="Settings that determine which email addresses TimeZest uses when sending emails."
          >
            <FromAddressControl domains={domains} {...mailSendingConfiguration} />
            <FormControl helpPopup={<ReplyToAddressHelp />} label="Reply-To Address" name="account[reply_to_address]">
              <TextInput
                error={errors['replyToAddress']}
                initialValue={replyToAddress || ''}
                mode="uncontrolled"
                name="account[reply_to_address]"
              />
            </FormControl>
            <ExternalEmailControl {...externalEmailAddress} error={errors['singleExternalEmail']} />
          </FormSection>
          <FormDivider />
          <FormSection title="">
            <Button submit label="Save" size="large" />
          </FormSection>
        </Form>
      </MainContainer>
    </>
  );
};

export default asScreen(EmailSettingsShow);

import type { FC } from 'react';

import Row from '@/ui/Row';

import Plans from '../Plan';

import type { BillingModel } from '@models/Billing';

interface Props {
  billing: BillingModel;
}

const TrialExpiredAccount: FC<Props> = ({ billing }) => {
  const { eligibleFreePlanChange, chargebeePlanId } = billing;

  return (
    <>
      <Row label="Trial Status" withInput={false}>
        <div>
          Your trial of TimeZest has expired. To continue using TimeZest, upgrade your account to a paid account.
        </div>
      </Row>
      <Row label="Upgrade Account" width={6} withInput={false}>
        <Plans
          chargebeePlanId={chargebeePlanId}
          eligibleFreePlanChange={eligibleFreePlanChange}
          pricingModel="tiered"
        />
      </Row>
    </>
  );
};

export default TrialExpiredAccount;

import type { FC, PropsWithChildren } from 'react';

import { tw } from '@/utilities';

const DESCRIPTION_LIST_CLASS_NAMES = tw`grid grid-cols-2 gap-x-5 gap-y-6`;

const DescriptionList: FC<PropsWithChildren> = ({ children }) => {
  return <dl className={DESCRIPTION_LIST_CLASS_NAMES}>{children}</dl>;
};

export default DescriptionList;

import type { FC } from 'react';

import { twJoin } from 'tailwind-merge';

import { tw } from '@/utilities';

import H2 from 'design_system/Typography/Headings/H2';
import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';

import { useAppSelector } from '../../hooks';
import { selectDatesRange, selectTimezone } from '../../Reducers/calendarReducer';
import { DateTimeManager } from '../../DateTimeManager';

const CONTAINER_CLASS_NAMES = tw`border-tz-gray-400 mb-2 flex h-6 items-center space-x-2 border-e border-s px-4`;

const DayPeriod: FC = () => {
  const datesRange = useAppSelector(selectDatesRange);
  const timezone = useAppSelector(selectTimezone);

  const currentDate = new DateTimeManager(timezone, datesRange.day);
  const dayNumber = currentDate.dayNumber();
  const month = currentDate.month();
  const dayNameInWeek = currentDate.dayNameInWeek();

  const today = DateTimeManager.today(datesRange.day, timezone);
  const todayClassNames = today && 'text-tz-blue-600';

  return (
    <div className={CONTAINER_CLASS_NAMES}>
      <H2 className={twJoin(todayClassNames, 'm-0')}>{dayNumber}</H2>
      <TextSmall className={twJoin(todayClassNames)}>{month}</TextSmall>
      <span>·</span>
      <TextSmall className={twJoin(todayClassNames)}>{today ? 'Today' : dayNameInWeek}</TextSmall>
    </div>
  );
};

export default DayPeriod;

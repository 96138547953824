import type { FC } from 'react';

import { twMerge } from 'tailwind-merge';

import { tw } from '@/utilities';

import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';

const FOCUS_CLASSNAMES = tw`bg-tz-gray-100 dark:bg-tz-gray-600 cursor-pointer`;
const SELECTED_CLASSNAMES = tw`bg-tz-gray-200 dark:bg-tz-gray-700`;
const TAG_OPTION_CLASSNAMES = tw`rounded-md p-2`;

interface Props {
  focus: boolean;
  selected: boolean;
  newTagName: string;
}

const NewTagOption: FC<Props> = ({ focus, selected, newTagName }) => {
  return (
    <div className={twMerge(TAG_OPTION_CLASSNAMES, focus && FOCUS_CLASSNAMES, selected && SELECTED_CLASSNAMES)}>
      <TextExtraSmall className="mb-1 font-bold">Create a new tag:</TextExtraSmall>
      <div className="flex items-center justify-start space-x-1">
        <TextExtraSmall className="w-fit rounded-md bg-white p-1">{newTagName}</TextExtraSmall>
      </div>
    </div>
  );
};

export default NewTagOption;

import { forwardRef, type PropsWithChildren } from 'react';

import { getCSRFToken } from './utilities';

import useForwardedRef from '@shared/hooks/useForwardedRef';

interface Props {
  enctype?: 'multipart/form-data' | 'application/x-www-form-urlencoded';
  className?: string;
  method?: 'delete' | 'get' | 'patch' | 'post';
  url: string;
}

const Form = forwardRef<HTMLFormElement, PropsWithChildren<Props>>(
  ({ children, className, enctype, method, url }, ref) => {
    const formRef = useForwardedRef(ref);
    const requiresHiddenMethod = method && method !== 'post' && method !== 'get';

    return (
      <form
        action={url}
        className={className}
        encType={enctype || 'application/x-www-form-urlencoded'}
        method={method === 'get' ? 'GET' : 'POST'}
        ref={formRef}
      >
        {requiresHiddenMethod && <input type="hidden" name="_method" value={method} />}
        <input type="hidden" name="authenticity_token" value={getCSRFToken()} />

        {children}
      </form>
    );
  }
);

export default Form;

import type { FC, PropsWithChildren } from 'react';
import React, { useContext, useEffect, useState } from 'react';

import classNames from 'classnames';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { ErrorBoundary } from 'react-error-boundary';

import FontAwesomeIcon from '@/FontAwesomeIcon';
import YesNoConfirmation from '@/YesNoConfirmation';
import { DirtyContext } from '@/DirtyContext';

import Badge from '@ui/Badge';
import Row from '@ui/Row';
import type { Action as ActionModel } from '@graphql/generated';
import { useDeleteActionMutation } from '@graphql/generated';
import './action.css';
import { WorkflowActionContext } from '@shared/WorkflowActionContext';
import useReviewableAction from '@shared/hooks/useReviewableAction';

const EXPANDED_CONDITION = {
  all: () => true,
  new: (action: ActionModel) => action.id === 0,
  none: () => false,
  withErrors: (action: ActionModel) => Object.keys(action.errors || {}).length > 0,
};

interface Props {
  action: ActionModel;
  icon: string;
  summary: string | JSX.Element;
  readOnly?: boolean;
  saving?: boolean;
  saveable?: boolean;
  succeeded?: boolean;
  details?: JSX.Element;
  disabled?: boolean;
}

const Action: FC<PropsWithChildren<Props>> = ({
  action,
  children,
  details,
  icon,
  summary,
  readOnly,
  saving,
  saveable,
  succeeded: initialSucceded,
  disabled,
}) => {
  const { handleDirty } = useContext(DirtyContext);
  const {
    expandable,
    initialExpanded,
    actionReviewable,
    handleActionId: onExpandAction,
    refetchWorkflowTriggers,
  } = useContext(WorkflowActionContext);

  const [deleteAction] = useDeleteActionMutation({
    refetchQueries: ['getWorkflowTriggers'],
  });
  const [expanded, setExpanded] = useState(EXPANDED_CONDITION[initialExpanded](action));
  const [succeeded, setSucceeded] = useState(false);

  const { needsReview, reviewed } = useReviewableAction(action, expanded);

  const persisted = action.id > 0;
  const deletable = refetchWorkflowTriggers && !readOnly && !actionReviewable;
  const showUnsaved = !persisted && saveable && !actionReviewable;

  const handleDeleteAction = () => {
    if (action.id) {
      deleteAction({
        variables: { actionId: action.id },
      });
    } else {
      if (refetchWorkflowTriggers) refetchWorkflowTriggers();
    }
  };

  const handleExpandToggle = (event: React.MouseEvent | React.KeyboardEvent<HTMLDivElement>): void => {
    event.preventDefault();
    if (onExpandAction) onExpandAction(action.id);

    setExpanded(!expanded);
  };

  useEffect(() => {
    if (!initialSucceded) return;

    setExpanded(false);
    setSucceeded(true);

    if (handleDirty) {
      handleDirty(true);
    }

    const timer = setTimeout(() => {
      setSucceeded(false);
    }, 3000);

    return function () {
      clearTimeout(timer);
    };
  }, [initialSucceded, handleDirty, setExpanded]);

  const content = (
    <div className="Action">
      <div className="Action__Header" role="button" tabIndex={0}>
        <div
          className="Action__IconContainer"
          role="none"
          onClick={handleExpandToggle}
          onKeyDown={e => {
            if (e.key === 'Enter') handleExpandToggle(e);
          }}
        >
          <i className={`budicon budicon-${icon}`} />
        </div>
        {expandable ? (
          <div
            className="Action__Summary"
            role="none"
            onClick={handleExpandToggle}
            onKeyDown={e => {
              if (e.key === 'Enter') handleExpandToggle(e);
            }}
          >
            {summary}
          </div>
        ) : (
          <div className="Action__Summary">{summary}</div>
        )}
        {succeeded && (
          <div className="Action__Badge">
            <div className="text-success">
              <FontAwesomeIcon icon="check" className="me-1" />
              Changes saved
            </div>
          </div>
        )}
        {showUnsaved && (
          <div className="Action__Badge">
            <Badge color="grey">Unsaved</Badge>
          </div>
        )}
        {needsReview && (
          <div className="Action__Badge">
            <div className="badge text-bg-primary p-1 px-2">Needs review</div>
          </div>
        )}
        {reviewed && (
          <div className="Action__Badge">
            <div className="text-success">
              <FontAwesomeIcon icon="check" className="me-1" />
            </div>
          </div>
        )}
        {deletable && (
          <div className="Action__Delete">
            <YesNoConfirmation
              text={
                <OverlayTrigger placement="left" overlay={<Tooltip>Delete this action</Tooltip>}>
                  <i className="budicon budicon-trash text-muted" />
                </OverlayTrigger>
              }
              onConfirm={handleDeleteAction}
            />
          </div>
        )}
      </div>
      <div className={classNames('Action__Collapsible', { 'Action__Collapsible--collapsed': !expanded })}>
        <div className="Action__Container">
          {children}
          {saveable && !readOnly && !actionReviewable && (
            <Row label={<>&nbsp;</>} helpText="" lastItem={!!details}>
              <input
                type="submit"
                className="btn btn-primary"
                value={saving ? 'Saving Changes...' : 'Save Changes'}
                disabled={disabled || saving}
              />
            </Row>
          )}
          {details && (
            <>
              <hr />
              <div className="row">
                <div className="col-2 fw-bold">Action Details</div>
                <div className="col-10 small text-muted">{details}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );

  return <ErrorBoundary fallback={<ActionWithError icon={icon} />}>{content}</ErrorBoundary>;
};

export default Action;

const ActionWithError: FC<{ icon: string }> = ({ icon }) => (
  <div className="Action--error">
    <div className="Action__Header">
      <div className="Action__IconContainer">
        <i className={`budicon budicon-${icon}`} />
      </div>
      <div className="Action__Summary">Oops, something went wrong when displaying this action.</div>
    </div>
  </div>
);

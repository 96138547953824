export const generateHours = (): string[] => {
  const hours: string[] = [];

  for (let hour = 0; hour < 24; hour++) {
    const formattedHour = hour === 0 ? '12' : hour > 12 ? hour - 12 : hour;
    const period = hour === 12 ? 'PM' : hour > 12 ? 'PM' : 'AM';

    const hourString = `${formattedHour} ${period}`;
    hours.push(hourString);
  }

  return hours;
};

import type { CalendarEntriesByUser, CalendarEntry } from '../types';

export const getCalendarEntriesByUser = (
  calendarEntries: CalendarEntriesByUser,
  userId: number
): CalendarEntry[] | null => {
  const calendarEntriesByUser = calendarEntries[String(userId)] || null;

  return calendarEntriesByUser;
};

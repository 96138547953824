import keyBy from 'lodash/keyBy';

import type { CalendarState } from '../calendarReducer';

import type { User } from '../../types';
import { sortUsers } from '../../Utils/sortUsers';

const DEFAULT_VISIBLE_NUMBER_OF_USERS = 5;

const loadActiveUsers: (state: CalendarState, users: User[]) => CalendarState = (state, users) => {
  const activeUsers = keyBy(users, 'id');

  const initialVisibleUserIds = localStorage.getItem('dhq:visibleUsers');
  const parsedInitialVisibleUserIds = initialVisibleUserIds ? JSON.parse(initialVisibleUserIds) : [];

  const allUserIds = Object.keys(activeUsers);

  const filteredUserIds = parsedInitialVisibleUserIds.filter(id => allUserIds.includes(id));

  const visibleUserIds =
    filteredUserIds.length > 0
      ? filteredUserIds
      : sortUsers(users)
          .slice(0, DEFAULT_VISIBLE_NUMBER_OF_USERS)
          .map(user => user.id.toString());

  return {
    ...state,
    users: activeUsers,
    visibleUserIds,
  };
};

export default loadActiveUsers;

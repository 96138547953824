import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { useDebounce } from '@react-hook/debounce';

import { getCSRFToken, throwOnError } from '@/utilities';

import PotentialMembers from '../PotentialMembers';

import Members from './Members';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import TextInput from '@shared/ui/Inputs/TextInput';
import type { Member } from '@models/TeamMember';

interface Props {
  teamId: number;
  members: Member[];
  setMembers: React.Dispatch<React.SetStateAction<Member[]>>;
}

const TeamMembers: FC<Props> = ({ teamId, members, setMembers }) => {
  const [{ searchTerm }, setSearchTermWithDebounce] = useDebounce({ searchTerm: '' }, 250);
  const [potentialMembers, setPotentialMembers] = useState<Member[]>([]);
  const [actualSearchTerm, setActualSearchTerm] = useState<string>('');
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  const fetchPotentialMembers = useCallback((): void => {
    setLoading(true);
    setActualSearchTerm(searchTerm);
  }, [searchTerm]);

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchTermWithDebounce({ searchTerm: e.target.value });
    setInputValue(e.target.value);
  };

  const addMember = (memberId: number): void => {
    const url = '/settings/teams/members';
    const body = { team_id: teamId, member_id: memberId, query: searchTerm };
    fetch(url, {
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRF-Token': getCSRFToken(),
      },
    })
      .then(throwOnError)
      .then(resp => resp.json())
      .then(json => {
        setMembers(json.members);
        setPotentialMembers(json.potentialMembers);
      });
  };

  const removeMember = (memberId: number): void => {
    const url = '/settings/teams/members';
    const body = { team_id: teamId, member_id: memberId, query: searchTerm };
    fetch(url, {
      method: 'DELETE',
      credentials: 'same-origin',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-CSRF-Token': getCSRFToken(),
      },
    })
      .then(throwOnError)
      .then(resp => resp.json())
      .then(json => {
        setMembers(json.members);
        setPotentialMembers(json.potentialMembers);
      });
  };

  useEffect(() => {
    if (searchTerm === '') {
      setLoading(false);
      setPotentialMembers([]);
    } else {
      fetchPotentialMembers();
    }
  }, [searchTerm, fetchPotentialMembers]);

  useEffect(() => {
    const url = `/settings/teams/members/search?query=${searchTerm}&team_id=${teamId}`;

    fetch(url, { headers: { Accept: 'application/json' } })
      .then(response => response.json())
      .then(json => {
        setLoading(false);
        setMembers(json.members);
        setPotentialMembers(json.potentialMembers);
      });
  }, [searchTerm, teamId, setMembers]);

  return (
    <div className="row mb-4">
      <div className="col-sm-8">
        <Members members={members} removeMember={removeMember} />
      </div>
      <div className="col-sm-4">
        <div className="card bg-light">
          <div className="card-body pb-0">
            <form className="form-inline mb-3">
              <label className="sr-only" htmlFor="teamMembersSearch">
                Search for users to add to this team
              </label>
              <TextInput
                id="teamsMemberSearch"
                name="time_zest_team"
                className="w-100"
                placeholder="Search for users to add to this team..."
                value={inputValue}
                onChange={handleSearchTermChange}
              />
            </form>
            {loading ? (
              <div className="mb-3">
                <FontAwesomeIcon spin icon="circle-notch" />
                &nbsp;&nbsp; Loading...
              </div>
            ) : (
              <PotentialMembers
                actualSearchTerm={actualSearchTerm}
                searchTerm={searchTerm}
                potentialMembers={potentialMembers}
                addMember={addMember}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamMembers;

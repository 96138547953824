import type { FC } from 'react';
import { useEffect, useState } from 'react';

import ConfirmationModal from '../ConfirmationModal';

import useStateWithCallback from '@shared/hooks/useStateWithCallback';

interface Props {
  dirty: boolean;
}

const UnsavedConfirmationModal: FC<Props> = ({ dirty }) => {
  const [nextUrl, setNextUrl] = useState<string>('');
  const [confirmLeavingPage, setConfirmLeavingPage] = useStateWithCallback<boolean>(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState<boolean>(false);

  useEffect(() => {
    const beforeUnloadListener = e => {
      if (!dirty || isConfirmationModalVisible) return;
      // Do not fire for Save&Close input
      if (e.target.activeElement.getAttribute('type') === 'submit') return;

      e.preventDefault();
      e.returnValue = '';
    };

    const turbolinkClickListener = e => {
      if (!dirty || isConfirmationModalVisible) return;

      e.preventDefault();
      setIsConfirmationModalVisible(true);
      setNextUrl(e.data.url);
    };

    if (!confirmLeavingPage) {
      window.addEventListener('onbeforeunload', beforeUnloadListener);
      // Solution for navigating via turbolinks
      window.addEventListener('turbolinks:before-visit', turbolinkClickListener);
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
      window.removeEventListener('turbolinks:before-visit', turbolinkClickListener);
    };
  }, [dirty, confirmLeavingPage, isConfirmationModalVisible]);

  return (
    <ConfirmationModal
      ariaLabel="Unsaved changes message"
      description="Are you sure you wish to continue?"
      title="You have unsaved changes."
      isOpen={!confirmLeavingPage && isConfirmationModalVisible}
      onConfirm={() => setConfirmLeavingPage(true, () => (window as any).Turbolinks.visit(nextUrl))}
      onClose={() => setIsConfirmationModalVisible(false)}
    />
  );
};

export default UnsavedConfirmationModal;

import type { FC } from 'react';

import { tw } from '@/utilities';

import PsaScheduledCancellationScreen from './PsaScheduledCancellationScreen';
import TimeZestScheduledCancellationScreen from './TimeZestScheduledCancellationScreen';

import H3 from 'design_system/Typography/Headings/H3';
import LoadingSpinner from 'design_system/Components/LoadingSpinner';
import { selectSelectedCalendarEntry } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/calendarReducer';
import { useAppSelector } from 'admin_ui/screens/NewUi/DispatchHQ/hooks';
import { selectDisableInteraction } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/uiReducer';

const DISABLED_OVERLAY_CLASSNAMES = tw`bg-tz-gray-200/30 absolute inset-0 z-20 flex h-full w-full cursor-default items-center justify-center`;

const AppointmentCancellation: FC = () => {
  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);
  const disableInteraction = useAppSelector(selectDisableInteraction);

  const timezestScheduledAppointment = !!selectedCalendarEntry?.schedulingRequestToken;

  return (
    <div className="flex-1">
      <H3 className="mt-6">Cancel Appointment</H3>
      <div className="my-6" />
      {disableInteraction && (
        <div className={DISABLED_OVERLAY_CLASSNAMES}>
          <LoadingSpinner size="small" />
        </div>
      )}
      {timezestScheduledAppointment ? <TimeZestScheduledCancellationScreen /> : <PsaScheduledCancellationScreen />}
    </div>
  );
};

export default AppointmentCancellation;

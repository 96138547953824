import type { CalendarState } from '../calendarReducer';

import type { ViewMode } from '../../types';
import { DateTimeManager } from '../../DateTimeManager';

const selectDateRange: (state: CalendarState, direction: 'previous' | 'next', viewMode: ViewMode) => CalendarState = (
  state,
  direction,
  viewMode
) => {
  return {
    ...state,
    dateRanges: viewMode === 'day' ? navigateDays(state, direction) : navigateWeeks(state, direction),
  };
};

export default selectDateRange;

const navigateDays = (state: CalendarState, direction: 'previous' | 'next') => {
  const newDay = new DateTimeManager(state.timezone, state.dateRanges.day).navigateDays(direction);

  return {
    week: { ...state.dateRanges.week },
    day: newDay,
  };
};

const navigateWeeks = (state: CalendarState, direction: 'previous' | 'next') => {
  const dayInANewWeek = new DateTimeManager(state.timezone, state.dateRanges.week.startDate).navigateWeeks(direction);

  const targetWeekDays = DateTimeManager.getWeekDays(state.timezone, dayInANewWeek);

  return {
    week: { startDate: targetWeekDays[0], endDate: targetWeekDays[6] },
    day: state.dateRanges.day,
  };
};

import type { ViewMode, Filter, FlyoutMode } from '../types';
import type { UIAction } from '../actions';
import type { RootState } from '../Store';

import toggleViewMode from './UIReducerFunctions/toggleViewMode';
import filterCalendarEntries from './UIReducerFunctions/setFilter';
import setZoom from './UIReducerFunctions/setZoom';
import setCellWidth from './UIReducerFunctions/setCellWidth';

export interface UIState {
  cellWidth: number;
  flyoutMode: FlyoutMode;
  mode: ViewMode;
  filter: Filter;
  zoom: number;
  disableInteraction: boolean;
}

const INITIAL_STATE: UIState = {
  cellWidth: 0,
  flyoutMode: 'close',
  mode: (localStorage.getItem('dhq:viewMode') as ViewMode) || 'week',
  filter: ['', '', ''],
  zoom: 1,
  disableInteraction: false,
};

const uiReducer: (state: UIState, action: UIAction) => UIState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'TOGGLE_VIEW_MODE':
      return toggleViewMode(state);
    case 'SET_FILTER':
      return filterCalendarEntries(state, action.payload.filter);
    case 'SET_ZOOM':
      return setZoom(state, action.payload.zoom);
    case 'SET_CELL_WIDTH':
      return setCellWidth(state, action.payload.cellWidth);
    case 'SET_FLYOUT_MODE':
      return {
        ...state,
        flyoutMode: action.payload.mode,
      };
    case 'SET_DISABLE_INTERACTION':
      return {
        ...state,
        disableInteraction: action.payload.disableInteraction,
      };
  }

  return state;
};

export default uiReducer;

export const selectViewMode = (state: RootState) => state.ui.mode;
export const selectFlyoutMode = (state: RootState) => state.ui.flyoutMode;
export const selectFilter = (state: RootState) => state.ui.filter;
export const selectCellWidth = (state: RootState) => state.ui.cellWidth;
export const selectZoom = (state: RootState) => state.ui.zoom;
export const selectDisableInteraction = (state: RootState) => state.ui.disableInteraction;

import type { FC } from 'react';

import { useDebounce } from '@react-hook/debounce';
import ReactPaginate from 'react-paginate';

import { ErrorMessage } from '../ErrorMessage';
import Board from '../Board';

import SearchBox from './SearchBox';

import { useGetBoardConfigurationsForConnectWiseQuery } from '@graphql/generated';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const PER_PAGE = 10;

const BoardsList: FC = () => {
  const [{ offset, searchTerm }, setSearchWithDebounce, setSearch] = useDebounce({ offset: 0, searchTerm: '' }, 500);

  const { data, error, loading } = useGetBoardConfigurationsForConnectWiseQuery({
    variables: {
      limit: PER_PAGE,
      offset: offset,
      searchTerm: searchTerm,
    },
  });

  const requestsData = data?.connectWise.boardConfigurations;

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchWithDebounce({ offset: 0, searchTerm: e.target.value });
  };

  if (loading) {
    return (
      <div>
        <SearchBox onSearchTermChange={handleSearchTermChange} />
        <FontAwesomeIcon spin icon="circle-notch" />
        &nbsp;&nbsp; Loading boards from ConnectWise PSA...
      </div>
    );
  }

  if (error) {
    return <ErrorMessage>Something went wrong while retrieving boards. (Error: {error.message})</ErrorMessage>;
  }

  if (!requestsData) return null;

  let { nodes: requests, total, page: currentPage } = requestsData;

  return (
    <div>
      <SearchBox onSearchTermChange={handleSearchTermChange} />

      {requests.map(board => (
        <Board {...board} key={board.id} />
      ))}

      {total > 1 && (
        <ReactPaginate
          pageCount={total}
          pageRangeDisplayed={currentPage}
          marginPagesDisplayed={2}
          containerClassName="pagination justify-content-center mb-0 mt-3"
          disableInitialCallback={true}
          forcePage={currentPage - 1}
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageClassName="page-item"
          previousClassName="page-item"
          nextClassName="page-item"
          pageLinkClassName="page-link"
          previousLinkClassName="page-link"
          nextLinkClassName="page-link"
          activeClassName="active"
          onPageChange={({ selected }) => {
            setSearch({ offset: selected * PER_PAGE, searchTerm });
          }}
        />
      )}
    </div>
  );
};

export default BoardsList;

import type { FC } from 'react';

import { tw } from '@/utilities';

import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';

import { useAppSelector } from '../../hooks';
import { selectDatesRange, selectTimezone } from '../../Reducers/calendarReducer';
import { DateTimeManager } from '../../DateTimeManager';

const CONTAINER_CLASS_NAMES = tw`border-tz-gray-900 mb-2 flex h-6 items-center space-x-2 border-e border-s px-4`;

const WeekPeriod: FC = () => {
  const datesRange = useAppSelector(selectDatesRange);
  const timezone = useAppSelector(selectTimezone);

  const startDate = new DateTimeManager(timezone, datesRange.week.startDate);
  const startDateString = startDate.day();
  const endDateString = new DateTimeManager(timezone, datesRange.week.endDate).day();
  const weekNumber = startDate.weekNumber();
  const year = startDate.year();

  return (
    <div className={CONTAINER_CLASS_NAMES}>
      <TextSmall>
        Week {weekNumber}, {year}
      </TextSmall>
      <span>·</span>
      <TextSmall>
        {startDateString} - {endDateString}
      </TextSmall>
    </div>
  );
};

export default WeekPeriod;

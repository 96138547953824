import { createSelector } from '@reduxjs/toolkit';

import type { Users, CalendarEntriesByUser, DatesRange, CalendarEntryTrees, CalendarEntry } from '../types';
import type { CalendarAction } from '../actions';
import type { RootState } from '../Store';
import { DateTimeManager } from '../DateTimeManager';

import loadSelectedCalendarEntry from './CalendarReducerFunctions/loadSelectedCalendarEntry';
import loadCalendarEntries from './CalendarReducerFunctions/loadCalendarEntries';
import selectDateRange from './CalendarReducerFunctions/selectDateRange';
import updateCalendarEntriesOnBroadcast from './CalendarReducerFunctions/updateCalendarEntriesOnBroadcast';
import loadActiveUsers from './CalendarReducerFunctions/loadActiveUsers';
import selectCalendarEntry from './CalendarReducerFunctions/selectCalendarEntry';
import removeCalendarEntry from './CalendarReducerFunctions/removeCalendarEntry';

export interface CalendarState {
  calendarEntries: CalendarEntriesByUser;
  calendarEntryTreesByUser: CalendarEntryTrees;
  dateRanges: { day: string; week: DatesRange };
  selectedUserId: number | null;
  selectedCalendarEntryId: string | null;
  timezone: string;
  users: Users;
  visibleUserIds: string[];
}

const INITIAL_DAYS_IN_A_WEEK = DateTimeManager.getWeekDays(Intl.DateTimeFormat().resolvedOptions().timeZone);
const INITIAL_CURRENT_DAY = new DateTimeManager(Intl.DateTimeFormat().resolvedOptions().timeZone).dayInWeek();

const INITIAL_STATE: CalendarState = {
  calendarEntries: {},
  calendarEntryTreesByUser: {},
  dateRanges: {
    day: INITIAL_DAYS_IN_A_WEEK[INITIAL_CURRENT_DAY],
    week: { startDate: INITIAL_DAYS_IN_A_WEEK[0], endDate: INITIAL_DAYS_IN_A_WEEK[6] },
  },
  selectedUserId: null,
  selectedCalendarEntryId: null,
  timezone: '',
  users: {},
  visibleUserIds: [],
};

const calendarReducer: (state: CalendarState, action: CalendarAction) => CalendarState = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case 'LOAD_CALENDAR_ENTRIES':
      return loadCalendarEntries(state, action.payload.calendarEntries, action.payload.viewMode);
    case 'LOAD_ACTIVE_USERS':
      return loadActiveUsers(state, action.payload.users);
    case 'LOAD_SELECTED_CALENDAR_ENTRY':
      return loadSelectedCalendarEntry(state, action.payload.data);
    case 'REMOVE_CALENDAR_ENTRY':
      return removeCalendarEntry(state, action.payload.calendarEntryId, action.payload.userId);
    case 'SELECT_DATE_RANGE':
      return selectDateRange(state, action.payload.direction, action.payload.viewMode);
    case 'UPDATE_CALENDAR_ENTRIES_ON_BROADCAST':
      return updateCalendarEntriesOnBroadcast(
        state,
        action.payload.calendarEntry,
        action.payload.operation,
        action.payload.viewMode
      );
    case 'SET_VISIBLE_USER_IDS':
      return {
        ...state,
        visibleUserIds: action.payload.visibleUserIds,
      };
    case 'SELECT_CALENDAR_ENTRY':
      return selectCalendarEntry(state, action.payload.calendarEntryId, action.payload.userId);
    case 'SET_TIMEZONE':
      return {
        ...state,
        timezone: action.payload.timezone,
      };
  }

  return state;
};

export default calendarReducer;

export const selectAreaSpecificTimezone = () =>
  new Date().toLocaleDateString(undefined, { day: '2-digit', timeZoneName: 'long' }).substring(4);
export const selectCalendarEntries = (state: RootState) => state.calendar.calendarEntries;
export const selectCalendarEntryTreesByUser = (state: RootState) => state.calendar.calendarEntryTreesByUser;
export const selectDatesRange = (state: RootState) => state.calendar.dateRanges;

export const selectSelectedCalendarEntry = (state: RootState) => {
  const { calendarEntries, selectedUserId, selectedCalendarEntryId } = state.calendar;

  if (!selectedUserId || !selectedCalendarEntryId) {
    return null;
  }

  return calendarEntries[selectedUserId].find(calendarEntry => calendarEntry.id === selectedCalendarEntryId) || null;
};

export const selectTimezone = (state: RootState) => state.calendar.timezone;
export const selectUsers = (state: RootState) => state.calendar.users;
export const selectVisibleUserIds = (state: RootState) => state.calendar.visibleUserIds;

export const selectDaysInAWeekWithEntries = createSelector(
  [selectTimezone, (_state, calendarEntries: CalendarEntry[]) => calendarEntries],
  (timezone: string, calendarEntries: CalendarEntry[]) => {
    return calendarEntries.reduce(
      (daysArray: CalendarEntry[][], entry) => {
        const dayInWeek = new DateTimeManager(timezone, entry.startTime).dayInWeek();

        daysArray[dayInWeek].push(entry);

        return daysArray;
      },
      Array(7)
        .fill(null)
        .map(() => [])
    );
  }
);

import type { FC } from 'react';

import asScreen from '@/Screen';

import EmailTemplate, { type EmailTemplateProps } from './EmailTemplate';

import H2 from 'design_system/Typography/Headings/H2';
import MainContainer from 'design_system/Components/MainContainer';
import Navigation from 'design_system/Components/Navigation';
import SideBarMenu from 'design_system/Components/SidebarMenu';
import StackedList from 'design_system/Components/StackedList';
import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';

interface Props {
  emailTemplates: EmailTemplateProps[];
}

const EmailTemplatesIndex: FC<Props> = ({ emailTemplates }) => {
  return (
    <>
      <Navigation activeItem="settings" />
      <SideBarMenu activeItem="email_templates" />
      <MainContainer>
        <H2 className="mb-4">Email Templates</H2>
        <div className="mb-4">
          <TextSmall>
            Email Templates are used by TimeZest&apos;s appointment types and workflows to generate the emails sent to
            end users.
          </TextSmall>
        </div>
        <hr className="border-tz-gray-200" />
        <StackedList>
          {emailTemplates.map(template => (
            <EmailTemplate key={`email-template-${template.id}`} {...template} />
          ))}
        </StackedList>
      </MainContainer>
    </>
  );
};

export default asScreen(EmailTemplatesIndex);

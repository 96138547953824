import { useEffect, type FC } from 'react';

import cable from '@/cable';

import { useAppDispatch, useAppSelector } from '../hooks';
import { updateCalendarEntriesOnBroadcast } from '../actions';
import type { BroadcastOperation, CalendarEntry } from '../types';
import { selectViewMode } from '../Reducers/uiReducer';

type BroadcastedPayload = {
  attributes: CalendarEntry;
  operation: BroadcastOperation;
};

const WebsocketReceiver: FC = () => {
  const dispatch = useAppDispatch();

  const viewMode = useAppSelector(selectViewMode);

  useEffect(() => {
    const accountChannelSubscription = cable.subscribeTo('TimeStash::AccountChannel');

    accountChannelSubscription.on('message', (broadcastedPayload: BroadcastedPayload) => {
      const { operation, attributes } = broadcastedPayload;

      dispatch(updateCalendarEntriesOnBroadcast({ operation, calendarEntry: attributes, viewMode }));
    });

    return () => {
      accountChannelSubscription.disconnect();
    };
  }, [dispatch, viewMode]);

  return null;
};

export default WebsocketReceiver;

import type { FC } from 'react';

import ExternalEmailHelp from './ExternalEmailHelp';

import useStateFromProp from '@shared/hooks/useStateFromProp';
import RadioButtons from 'design_system/Components/RadioButtons';
import TextInput from 'design_system/Components/TextInput';
import FormControl from 'design_system/Components/FormControl';

interface Props {
  error?: string;
  singleExternalEmail: string | null;
  useSingleExternalEmail: boolean;
}

const EmailExternalControl: FC<Props> = ({
  error,
  singleExternalEmail,
  useSingleExternalEmail: initialUseSingleExternalEmail,
}) => {
  const [useSingleExternalEmail, setSingleExternalEmail] = useStateFromProp(initialUseSingleExternalEmail);

  const handleChange = (value: string) => {
    setSingleExternalEmail(value === 'true');
  };

  return (
    <FormControl
      label="Expose Agents' Individual Emails"
      name="account[use_single_external_email]"
      helpPopup={<ExternalEmailHelp />}
    >
      <RadioButtons
        name="account[use_single_external_email]"
        mode="controlled"
        value={useSingleExternalEmail.toString()}
        options={[
          { value: 'false', label: "Expose each agents' email address to end users." },
          {
            value: 'true',
            label: 'Use a single email address for all agents:',
          },
        ]}
        onChange={handleChange}
      />
      <TextInput mode="uncontrolled" initialValue={singleExternalEmail || ''} name="account[single_external_email]" error={error} />
    </FormControl>
  );
};

export default EmailExternalControl;

import type { FC, PropsWithChildren } from 'react';

import { Dialog } from '@reach/dialog';
import classNames from 'classnames';

import '@reach/dialog/styles.css';
import './styles.scss';

interface Props {
  ariaLabel: string;
  className?: string;
  isOpen: boolean;
  onClose: () => void;
}

const Modal: FC<PropsWithChildren<Props>> = ({ children, ariaLabel, className, isOpen, onClose }) => (
  <Dialog
    aria-label={ariaLabel}
    // Hack for getting dynamic className value
    className={classNames('ui-modal', { [`${className}`]: className })}
    isOpen={isOpen}
    onDismiss={onClose}
  >
    {children}
  </Dialog>
);

export default Modal;

import type { FC } from 'react';

import type {
  ChartOptions} from 'chart.js';
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeSeriesScale
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeSeriesScale);

export const barOptions: ChartOptions<'bar'> = {
  plugins: {
    legend: { position: 'bottom' },
  },
  responsive: true,
  scales: {
    x: {
      ticks: {
        source: 'data',
      },
      stacked: true,
      type: 'timeseries',
      time: {
        tooltipFormat: 'd MMMM yyy',
        minUnit: 'day',
      },
    },
    y: {
      stacked: true,
      ticks: {
        precision: 0,
      },
    },
  },
  maintainAspectRatio: false,
};

const barColors = ['#3094ff', '#FFB620', '#0dd4f0'];

interface UsageChartProps {
  labelsByKey?: Record<string, string>;
  data: { stats: Record<string, number>; date: string }[];
}

export const UsageChart: FC<UsageChartProps> = ({ data, labelsByKey }) => {
  const stats = {
    labels: data.map(({ date }) => new Date(date)),
    // Keys should be common for all records
    datasets: Object.keys(data[0].stats).map((key, index) => ({
      label: labelsByKey ? labelsByKey[key] : key,
      data: data.map(data => data.stats[key]),
      backgroundColor: barColors[index],
    })),
  };

  return <Bar options={barOptions} data={stats} />;
};

export default UsageChart;

import { useState, type FC } from 'react';

import { tw } from '@/utilities';
import { setFlyoutMode } from '@/screens/NewUi/DispatchHQ/actions';
import { useCancelAppointment } from '@/screens/NewUi/DispatchHQ/hooks/useCancelAppointment';

import CancellationErrorMessage from '../CancellationErrorMessage';

import Description from './Description';

import { selectSelectedCalendarEntry } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/calendarReducer';
import { useAppDispatch, useAppSelector } from 'admin_ui/screens/NewUi/DispatchHQ/hooks';
import Button from 'design_system/Components/Button';
import LoadingSpinner from 'design_system/Components/LoadingSpinner';
import { selectDisableInteraction } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/uiReducer';
import type { CancelPsaAppointmentMutationVariables } from '@graphql/generated';

const ABORT_CANCELLATION_BUTTON_CLASSNAMES = tw`text-tz-blue-500 hover:text-tz-blue-600 ring-0 hover:bg-transparent`;
const DISABLED_OVERLAY_CLASSNAMES = tw`bg-tz-gray-200/30 absolute inset-0 z-20 flex h-full w-full cursor-default items-center justify-center`;
const BUTTON_CONTAINER_CLASSNAMES = tw`ms-auto mt-2 flex w-full items-center justify-end space-x-4`;

const PsaScheduledCancellationScreen: FC = () => {
  const dispatch = useAppDispatch();

  const [showError, setShowError] = useState(false);

  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);
  const disabled = useAppSelector(selectDisableInteraction);

  if (!selectedCalendarEntry) return null;

  const variables = () => {
    return {
      psaId: selectedCalendarEntry.psaId,
      psaType: selectedCalendarEntry.psaType,
    };
  };

  const { handleCancelAppointment } = useCancelAppointment<CancelPsaAppointmentMutationVariables>({
    scheduledBy: 'psa',
    variables,
    onShowError: setShowError,
  });

  return (
    <>
      {disabled && (
        <div className={DISABLED_OVERLAY_CLASSNAMES}>
          <LoadingSpinner size="small" />
        </div>
      )}
      {showError && <CancellationErrorMessage />}
      <Description />
      <div className={BUTTON_CONTAINER_CLASSNAMES}>
        <Button
          secondary
          disabled={disabled}
          className={ABORT_CANCELLATION_BUTTON_CLASSNAMES}
          label="Cancel"
          size="medium"
          onClick={() => dispatch(setFlyoutMode({ mode: 'info' }))}
        />
        <Button disabled={disabled} label="Cancel Appointment" size="medium" onClick={handleCancelAppointment} />
      </div>
    </>
  );
};

export default PsaScheduledCancellationScreen;

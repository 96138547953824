import type { FC } from 'react';

import { useAppDispatch, useAppSelector } from '../hooks';
import { selectViewMode } from '../Reducers/uiReducer';
import { toggleViewMode } from '../actions';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import Button from 'design_system/Components/Button';

const ViewToggler: FC = () => {
  const viewMode = useAppSelector(selectViewMode);

  const dispatch = useAppDispatch();

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();

    dispatch(toggleViewMode());
  };

  const weekView = viewMode === 'week';

  const label = weekView ? (
    <FontAwesomeIcon icon="calendar-day" height={15} width={15} className="text-tz-gray-500" />
  ) : (
    <FontAwesomeIcon icon="calendar-week" height={15} width={15} className="text-tz-gray-500" />
  );

  return <Button label={label} size="medium" secondary={true} onClick={handleClick} />;
};

export default ViewToggler;

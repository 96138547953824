import { useMemo, type FC } from 'react';

import TimeAgo from 'react-time-ago';

import StackedListItem from 'design_system/Components/StackedList/Item';
import H6 from 'design_system/Typography/Headings/H6';
import Badge from 'design_system/Components/Badge';
import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';
import MenuLink from 'design_system/Components/ActionMenu/MenuLink';

export interface EmailTemplateProps {
  id: string;
  name: string;
  builtIn: boolean;
  language: string;
  updatedAt: number;
}

const LANGUAGES = {
  en: 'English',
  de: 'Deutsch',
  fr: 'Français',
  nl: 'Nederlands',
  es: 'Español',
};

const MILLISECONDS = 1000;

const EmailTemplate: FC<EmailTemplateProps> = ({ builtIn, id, language, name, updatedAt }) => {
  const actionMenuItems = useMemo(() => {
    return (
      <>
        <MenuLink href={`/settings/email_templates/${id}`}>Edit</MenuLink>
        <MenuLink href={`/settings/email_templates/${id}`}>Delete</MenuLink>
      </>
    );
  }, [id]);

  return (
    <StackedListItem
      orientation="vertical"
      className="py-4"
      url={`/settings/email_templates/${id}`}
      actionMenuItems={actionMenuItems}
    >
      <div className="flex items-center gap-x-1.5">
        <div className="w-max">
          <H6 className="mb-0">{name}</H6>
          <span className="bg-tz-gray-500 dark:bg-tz-gray-400 block h-px max-w-0 transition-all duration-300 group-hover:max-w-full" />
        </div>
      </div>

      <div>
        <TextExtraSmall>
          <div className="flex gap-x-1.5">
            <span>{LANGUAGES[language]}</span>
            {!builtIn && (
              <>
                <span>&middot;</span>
                <span>
                  Updated <TimeAgo date={new Date(updatedAt * MILLISECONDS)} />
                </span>
              </>
            )}
            {builtIn && <Badge color="purple">Built-In</Badge>}
          </div>
        </TextExtraSmall>
      </div>
    </StackedListItem>
  );
};

export default EmailTemplate;

import type { FC } from 'react';

import PhoneAndAddressPsa from '../PhoneAndAddress';

import { selectSelectedCalendarEntry } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/calendarReducer';
import { useAppSelector } from 'admin_ui/screens/NewUi/DispatchHQ/hooks';
import type { Address } from '@graphql/generated';
import { useGetConnectWiseTicketSuspenseQuery } from '@graphql/generated';
import { clientAddress } from '@shared/utilities';

const PhoneAndAddress: FC = () => {
  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);
  const ticketId = Number(selectedCalendarEntry?.psaTicketNumber?.replace(/^#/, '') || '');
  const { data } = useGetConnectWiseTicketSuspenseQuery({
    skip: !ticketId,
    fetchPolicy: 'no-cache',
    variables: { ticketId },
  });
  const phone = data?.connectWise?.ticket?.clientPhone;
  const address = clientAddress(data?.connectWise?.ticket?.clientAddress as Address);

  return <PhoneAndAddressPsa phone={phone} address={address} />;
};

export default PhoneAndAddress;

import type { FC } from 'react';

import asScreen from '@/Screen';
import Flash from '@/Flash';
import Header from '@/Header';

import TwoFactorAuthentication from './TwoFactorAuthentication';
import Settings from './Settings';

interface Props {
  errors: Record<string, string>;
  userAvailabilityEditable: boolean;
  showTicketNamesUrlScheduling: boolean;
  signUpEmailDomains: string[];
  signUpEmailDomain: string;
}

const SecurityAndPersmissions: FC<Props> = props => {
  return (
    <>
      <Header title="Security & Permissions" />
      <Flash />
      <TwoFactorAuthentication />

      <hr />

      <Settings {...props} />
    </>
  );
};

export default asScreen(SecurityAndPersmissions);

import { twMerge } from 'tailwind-merge';

import { tw } from '@/utilities';

import Avatar from 'design_system/Components/Avatar';
import H6 from 'design_system/Typography/Headings/H6';
import StackedList from 'design_system/Components/StackedList';
import StackedListItem from 'design_system/Components/StackedList/Item';
import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';
import type { ItemModel } from '@models/SelectItem';

const ITEM_CLASS_NAMES = tw`cursor-pointer space-x-3 p-3`;

const UserItem = (
  item: ItemModel<{ avatarUrl: string; jobTitle: string }>,
  selected: boolean,
  active: boolean
): JSX.Element => {
  return (
    <StackedList>
      <StackedListItem
        orientation="horizontal"
        className={twMerge(
          ITEM_CLASS_NAMES,
          selected ? 'bg-tz-gray-200 dark:bg-tz-gray-700' : 'dark:bg-tz-gray-800',
          active && 'bg-tz-gray-100 dark:bg-tz-gray-600'
        )}
      >
        <Avatar size="sm" name={item.name} url={item.avatarUrl || undefined} />
        <div>
          <H6 className="mb-0">{item.name}</H6>
          <TextExtraSmall>{item.jobTitle}</TextExtraSmall>
        </div>
      </StackedListItem>
    </StackedList>
  );
};

export default UserItem;

import type { FC } from 'react';

import { useAppSelector } from '@/screens/NewUi/DispatchHQ/hooks';
import { selectSelectedCalendarEntry } from '@/screens/NewUi/DispatchHQ/Reducers/calendarReducer';

import ServiceCall from './ServiceCall';

const AutotaskAppointmentDetails: FC = () => {
  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);

  return <>{selectedCalendarEntry?.psaType.includes('ServiceCall') && <ServiceCall />}</>;
};

export default AutotaskAppointmentDetails;

import type { FC } from 'react';

import Flash from '@/Flash';
import asScreen from '@/Screen';

import DetailsTab from './DetailsTab';
import LoginsTab from './LoginsTab';
import SecurityTab from './SecurityTab';

import type { LoginActivityModel } from '@models/User';
import H2 from 'design_system/Typography/Headings/H2';
import MainContainer from 'design_system/Components/MainContainer';
import Navigation from 'design_system/Components/Navigation';
import Tabs from 'design_system/Components/Tabs';

interface Props {
  errors: Record<string, string>;
  loginActivities: LoginActivityModel[];
}

const ProfilesShow: FC<Props> = ({ loginActivities }) => {
  const TABS = {
    details: 'Details',
    security: 'Security & Password',
    logins: 'Logins',
  };
  return (
    <>
      <Navigation activeItem="profile" />
      <MainContainer>
        <H2>Profile</H2>
        <Flash />
        <Tabs tabs={TABS} />
        <DetailsTab />
        <SecurityTab />
        <LoginsTab loginActivities={loginActivities} />
      </MainContainer>
    </>
  );
};

export default asScreen(ProfilesShow);

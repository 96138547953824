import type { FC, PropsWithChildren } from 'react';

import { twJoin } from 'tailwind-merge';

import Row from '@/ui/Row';

interface Props {
  error: string | null;
  helpText?: string;
  label: string;
  name: string;
}

const FileInput: FC<PropsWithChildren<Props>> = ({ children, error, helpText, label, name }) => {
  const hasError = Boolean(error);

  return (
    <Row label={label} helpText={helpText}>
      {children}
      <div className="custom-file">
        <input className={twJoin('custom-file-input form-control', hasError && 'is-invalid')}  type="file" name={name} id={name} />
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    </Row>
  );
};

export default FileInput;

import type { FC, JSX, PropsWithChildren } from 'react';

import classNames from 'classnames';

import { tw } from '@/utilities';

interface Props {
  label: string | JSX.Element;
  name: string;
  helpPopup?: JSX.Element;
}

const BASE_LABEL_CLASS_NAMES = tw`text-tz-gray-500 dark:text-tz-gray-300 mb-2 block text-sm leading-5`;

const FormControl: FC<PropsWithChildren<Props>> = ({ children, helpPopup, label, name }) => {
  return (
    <div className="relative w-full">
      <label
        htmlFor={name}
        className={classNames(BASE_LABEL_CLASS_NAMES, { 'flex items-center justify-start': helpPopup })}
      >
        {label}
        {helpPopup && <>&ensp;{helpPopup}</>}
      </label>
      {children}
    </div>
  );
};

export default FormControl;

import type { FC } from 'react';

import HelpPopup from 'design_system/Components/HelpPopup';

const UserAvailabilityHelp: FC = () => {
  return (
    <HelpPopup position="below">
      <p className="mb-2 text-sm font-normal">
        This setting controls whether users can update their own availability - i.e. the times of day when end users can
        schedule appointments with them.
      </p>
      <p className="mb-2 text-sm font-normal">When enabled, users can update their availability in their profile.</p>
      <p className="text-sm font-normal">
        Otherwise, users&apos; availability can only be updated by TimeZest administrators in the users&apos;
        configuration pages.
      </p>
    </HelpPopup>
  );
};

export default UserAvailabilityHelp;

import type { FC } from 'react';

import EntryGroup from './EntryGroup';

import CalendarEntryGroupsGenerator from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/CalendarReducerFunctions/BuildCalendarEntryGroupsByUser/CalendarEntryGroupsGenerator';
import CalendarEntryTreesGenerator from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/CalendarReducerFunctions/BuildCalendarEntryGroupsByUser/CalendarEntryTreesGenerator';
import type { CalendarEntry } from 'admin_ui/screens/NewUi/DispatchHQ/types';
import { useAppSelector } from 'admin_ui/screens/NewUi/DispatchHQ/hooks';
import { selectTimezone } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/calendarReducer';

interface Props {
  entries: CalendarEntry[];
  userId: string;
}

const CalendarCell: FC<Props> = ({ entries, userId }) => {
  const timezone = useAppSelector(selectTimezone);

  if (entries.length === 0) return null;

  const calendarEntryGroupsGenerator = new CalendarEntryGroupsGenerator({ [userId]: entries }, [userId], timezone);
  const calendarEntryGroups = calendarEntryGroupsGenerator.generateEntryGroupsByUser();

  const calendarEntryTreeByUserGenerator = new CalendarEntryTreesGenerator(calendarEntryGroups, timezone);
  const calendarEntryTreeByUser = calendarEntryTreeByUserGenerator.buildCalendarEntryTrees();

  const { calendarEntryGroupMap, calendarEntryMap } = calendarEntryTreeByUser[userId];

  if (Object.keys(calendarEntryGroupMap).length === 0) return null;

  return (
    <>
      {Object.keys(calendarEntryGroupMap).map(id => (
        <EntryGroup key={`group-${id}`} entryGroup={calendarEntryGroupMap[id]} entryMap={calendarEntryMap} />
      ))}
    </>
  );
};

export default CalendarCell;

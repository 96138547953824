import type { FC } from 'react';

import { Calendar as ReactCalendar } from 'react-calendar';

import { tw } from '@/utilities';

import type { CalendarType, MinDetail } from '@models/Calendar';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const CONTAINER_CLASS_NAMES = tw`absolute bottom-0 top-[calc(100%+4px)] z-20 w-full`;
const TODAY_BUTTON_CLASS_NAMES = tw`text-tz-blue-500 hover:text-tz-blue-600 ms-px mt-3 w-max text-xs`;

interface Props {
  calendarType?: CalendarType;
  minDate?: Date;
  minDetail?: MinDetail;
  value: Date | null;
  formatCalendarTitle: (date: Date, locale: string | undefined, view: string) => JSX.Element | string;
  formatDisplayDate: (date: Date) => string;
  formatWeekday: (date: Date, locale: string) => string;
  setCalendarVisible: (calendarVisible: boolean) => void;
  setDisplayValue: (displayValue: string) => void;
  setValue: (value: Date | null) => void;
  isValid: () => boolean;
}

const Calendar: FC<Props> = ({
  calendarType,
  minDate,
  minDetail,
  value,
  formatCalendarTitle,
  formatDisplayDate,
  formatWeekday,
  setCalendarVisible,
  setDisplayValue,
  setValue,
  isValid,
}) => {
  const handleCalendarChange = (value: Date, e?: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (e) e.preventDefault();

    setCalendarVisible(false);
    setDisplayValue(formatDisplayDate(value));
    setValue(value);
  };

  const handleCalendarTodayClick = (e: React.MouseEvent): void => {
    e.preventDefault();

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    handleCalendarChange(today);
  };

  return (
    <div className={CONTAINER_CLASS_NAMES}>
      <div className="date-select-calendar">
        <ReactCalendar
          calendarType={calendarType ? calendarType : 'iso8601'}
          formatMonth={(locale: string, date: Date): string => date.toLocaleDateString(locale, { month: 'short' })}
          formatShortWeekday={(locale: string, date: Date): string => formatWeekday(date, locale)}
          minDate={minDate}
          minDetail={minDetail ? minDetail : 'year'}
          navigationLabel={({ date, locale, view }): JSX.Element | string => formatCalendarTitle(date, locale, view)}
          nextLabel={<FontAwesomeIcon icon="chevron-right" height={12} className="ms-auto" />}
          prevLabel={<FontAwesomeIcon icon="chevron-left" height={12} />}
          next2Label={null}
          prev2Label={null}
          tileClassName={(): string => (value ? 'react-calendar__tile--hasValueSelected' : '')}
          value={isValid() ? value : null}
          onChange={handleCalendarChange}
        />
        <div className={TODAY_BUTTON_CLASS_NAMES}>
          <button tabIndex={0} onClick={handleCalendarTodayClick}>
            Today
          </button>
        </div>
      </div>
    </div>
  );
};

export default Calendar;

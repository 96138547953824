import type { CalendarState } from '../calendarReducer';

const removeCalendarEntry: (state: CalendarState, calendarEntryId: string, userId: number) => CalendarState = (
  state,
  calendarEntryId,
  userId
) => {
  const calendarEntriesOfAUser = state.calendarEntries[userId];

  if (!calendarEntriesOfAUser) {
    return state;
  }

  const filteredCalendarEntries = calendarEntriesOfAUser.filter(entry => entry.id !== calendarEntryId);

  return {
    ...state,
    calendarEntries: {
      ...state.calendarEntries,
      [userId]: filteredCalendarEntries,
    },
  };
};

export default removeCalendarEntry;

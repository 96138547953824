import type { FC } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props {
  text: string;
  href: string;
  active: boolean;
}

const SidebarMenuItem: FC<Props> = ({ active, href, text }) => {
  return (
    <li
      className={twMerge(
        'text-tz-gray-800 dark:text-tz-gray-400 hover:bg-tz-gray-100 hover:dark:bg-tz-gray-700 hover:dark:text-tz-gray-300 rounded-md p-2 text-sm',
        active && 'bg-tz-gray-100 dark:bg-tz-gray-700 dark:text-tz-gray-300'
      )}
    >
      <a href={href} className="block">
        {text}
      </a>
    </li>
  );
};

export default SidebarMenuItem;

import { useState, type FC } from 'react';

import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import last from 'lodash/last';

import { tw } from '@/utilities';
import { useAppSelector } from '@/screens/NewUi/DispatchHQ/hooks';
import { selectSelectedCalendarEntry } from '@/screens/NewUi/DispatchHQ/Reducers/calendarReducer';

import Option from './Option';
import NewTagOption from './NewTagOption';

import FormDivider from 'design_system/Components/FormDivider';
import type { ItemModel } from '@models/SelectItem';
import type { Color } from 'design_system/Components/Badge';
import Badge from 'design_system/Components/Badge';
import { useGetTagsQuery } from '@graphql/generated';
import LoadingSpinner from 'design_system/Components/LoadingSpinner';

const CLOSED_CLASSNAMES = tw`ring-tz-gray-200 dark:ring-tz-gray-600 ring-1`;
const OPENED_CLASSNAMES = tw`dark:ring-tz-gray-500 ring-2 ring-blue-500`;
const SELECT_CONTROL_CLASSNAMES = tw`dark:bg-tz-gray-900 relative flex h-auto w-full items-center rounded-b-md rounded-t-md border-none p-1 text-sm font-normal ring-inset`;
const SELECT_BUTTON_CLASSNAMES = tw`flex grow items-center justify-start`;
const SELECT_OPTIONS_CLASSNAMES = tw`dark:bg-tz-gray-700 dark:text-tz-gray-300 absolute right-0 top-[100%] z-10 m-0 flex h-64 w-full list-none flex-col space-y-1 overflow-y-auto bg-white`;
const SELECT_INPUT_BOX_CLASSNAMES = tw`dark:bg-tz-gray-900 dark:text-tz-gray-300 dark:placeholder:text-tz-gray-400 h-6 w-full border-none p-0 text-xs focus:border-transparent focus:ring-0`;

interface Props {
  disabled: boolean;
  newTagName: string;
  onCreatingNewTag: (creatingNewTag: boolean) => void;
  onCreateNewTagging: (tagId: number) => void;
  onChangeNewTagName: (newTagName: string) => void;
  onDeleteTagging: (taggingId: number) => void;
}

const TagsSelectBox: FC<Props> = ({
  disabled,
  newTagName,
  onCreatingNewTag,
  onCreateNewTagging,
  onChangeNewTagName,
  onDeleteTagging,
}) => {
  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);

  const [search, setSearch] = useState('');
  const [opened, setOpened] = useState(false);

  const { data, loading } = useGetTagsQuery();

  const fetchedTags = data?.timezest.dispatchHq?.tags || [];

  const suggestedItems = search
    ? fetchedTags.filter(i => (i.name || '').toLowerCase().indexOf(search.toLowerCase()) >= 0)
    : fetchedTags;

  const selectedTags = selectedCalendarEntry?.taggings.map(tagging => tagging.tag) || [];

  const handleChange = (tags: ItemModel<{}, number>[]) => {
    if (last(tags) === null) {
      onCreatingNewTag(true);
    } else if (tags.length > selectedTags.length) {
      onCreateNewTagging(last(tags)?.id || 0);
    } else {
      const removedTagging = selectedCalendarEntry?.taggings.filter(tagging => !tags.includes(tagging.tag))[0];
      onDeleteTagging(removedTagging?.id || 0);
    }

    setSearch('');
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tagInput = e.target.value;
    setSearch(tagInput);

    const suggestedNames = fetchedTags.map(i => i.name?.toLowerCase());

    const newTagName = !suggestedNames.includes(tagInput.toLowerCase()) ? tagInput : '';

    onChangeNewTagName(newTagName);
  };

  return (
    <div className="relative w-full">
      <Combobox value={selectedTags} name="tag-selector" multiple={true} onChange={handleChange}>
        <>
          <div className={twMerge(SELECT_CONTROL_CLASSNAMES, opened ? OPENED_CLASSNAMES : CLOSED_CLASSNAMES)}>
            <ComboboxButton className={SELECT_BUTTON_CLASSNAMES} />
            <ComboboxInput
              disabled={disabled}
              id="tag-search-input"
              type="text"
              placeholder="Search or create a tag"
              className={SELECT_INPUT_BOX_CLASSNAMES}
              value={search}
              onChange={handleSearch}
              onFocus={() => setOpened(true)}
              onBlur={() => setOpened(false)}
            />
          </div>

          <FormDivider className="mb-2 mt-4" />

          {loading && <LoadingSpinner size="small" />}
          {!loading && (
            <ComboboxOptions static={true} className={SELECT_OPTIONS_CLASSNAMES}>
              {newTagName && (
                <ComboboxOption key="new-tag-option" value={null}>
                  {({ focus, selected }) => <NewTagOption focus={focus} selected={selected} newTagName={newTagName} />}
                </ComboboxOption>
              )}
              {suggestedItems.length > 0 &&
                suggestedItems.map(tag => (
                  <ComboboxOption key={tag.name} value={tag}>
                    {({ focus, selected }) => (
                      <Option focus={focus} selected={selected}>
                        <Badge color={tag.color as Color}>{tag.name}</Badge>
                      </Option>
                    )}
                  </ComboboxOption>
                ))}
            </ComboboxOptions>
          )}
        </>
      </Combobox>
    </div>
  );
};

export default TagsSelectBox;

import type { KeyboardEvent, MouseEvent } from 'react';

import Avatar from 'design_system/Components/Avatar';
import SelectedItem from 'design_system/Components/SearchableMultiSelect/SelectedItem';
import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';
import type { ItemModel } from '@models/SelectItem';

const SelectedUser = (
  canBeRemoved: boolean,
  item: ItemModel<{ avatarUrl: string; jobTitle: string }>,
  disabled: boolean,
  onRemoveItem: (id: string, event: MouseEvent | KeyboardEvent<HTMLButtonElement>) => void
): JSX.Element => {
  return (
    <SelectedItem key={item.id} {...item} canBeRemoved={canBeRemoved} disabled={disabled} onRemoveItem={onRemoveItem}>
      <div className="flex items-center justify-start space-x-2">
        <Avatar size="sm" name={item.name} url={item.avatarUrl || undefined} />
        <TextExtraSmall disabled={disabled}>{item.name}</TextExtraSmall>
      </div>
    </SelectedItem>
  );
};

export default SelectedUser;

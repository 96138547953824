import { useContext, type FC } from 'react';

import AccountContext from '@/AccountContext';
import Flash from '@/Flash';
import asScreen from '@/Screen';

import autotaskLogo from './autotask-logo.png';
import bambooHRLogo from './bamboo-hr-logo.png';
import connectWiseLogo from './connectwise-logo.png';
import haloPsaLogo from './halo-psa-logo.png';
import IntegrationCard from './IntegrationCard';
import microsoft365Logo from './microsoft-365-logo.png';
import microsoftTeamsLogo from './teams-logo.png';
import serviceNowLogo from './service-now-logo.png';
import zoomLogo from './zoom-logo.png';

import H2 from 'design_system/Typography/Headings/H2';
import MainContainer from 'design_system/Components/MainContainer';
import Navigation from 'design_system/Components/Navigation';
import { hasIntegration } from '@shared/utilities';

const IntegationsIndex: FC = () => {
  const { integrations, psaType } = useContext(AccountContext);

  return (
    <>
      <Navigation activeItem="integrations" />
      <MainContainer>
        <div className="mb-6">
          <H2>Integrations</H2>
          <Flash />
        </div>
        <div className="grid grid-cols-4 gap-6">
          {psaType === 'autotask' && (
            <IntegrationCard
              connected={hasIntegration(integrations, 'autotask')}
              image={autotaskLogo}
              name="Autotask"
              description="Update tickets and create appointments in Datto Autotask."
              url="/integrations/autotask"
            />
          )}
          <IntegrationCard
            connected={hasIntegration(integrations, 'bamboo_hr')}
            image={bambooHRLogo}
            name="BambooHR"
            description="Allow approved time off requests to block appointments."
            url="/integrations/bamboo_hr"
          />
          {psaType === 'connect_wise' && (
            <IntegrationCard
              connected={hasIntegration(integrations, 'connect_wise')}
              image={connectWiseLogo}
              name="ConnectWise PSA"
              description="Update tickets and create appointments in ConnectWise PSA."
              url="/integrations/connect_wise"
            />
          )}
          {psaType === 'halo_psa' && (
            <IntegrationCard
              connected={hasIntegration(integrations, 'halo_psa')}
              image={haloPsaLogo}
              name="HaloPSA"
              description="Update tickets and create appointments in HaloPSA."
              url="/integrations/halo_psa"
            />
          )}
          <IntegrationCard
            connected={hasIntegration(integrations, 'office_365')}
            image={microsoft365Logo}
            name="Microsoft 365"
            description="Read existing appointments from, and schedule new appointments to calendars in Microsoft 365."
            url="/integrations/office_365"
          />
          <IntegrationCard
            connected={hasIntegration(integrations, 'teams_meetings')}
            image={microsoftTeamsLogo}
            name="Microsoft Teams (Meetings)"
            description="Automatically create Microsoft Teams online meetings for your appointments."
            url="/integrations/teams_meetings"
          />
          <IntegrationCard
            connected={hasIntegration(integrations, 'teams_messaging')}
            image={microsoftTeamsLogo}
            name="Microsoft Teams (Messaging)"
            description="Post updates to users and channels in Microsoft Teams."
            url="/integrations/teams_messaging"
          />
          {psaType === 'service_now' && (
            <IntegrationCard
              connected={hasIntegration(integrations, 'service_now')}
              image={serviceNowLogo}
              name="ServiceNow"
              description="Schedule appointments from incidents in ServiceNow."
              url="/integrations/service_now"
            />
          )}
          <IntegrationCard
            connected={hasIntegration(integrations, 'zoom')}
            image={zoomLogo}
            name="Zoom"
            description="Automatically create Zoom online meetings for your appointments."
            url="/integrations/zoom"
          />
        </div>
      </MainContainer>
    </>
  );
};

export default asScreen(IntegationsIndex);

import { DateTimeManager } from '../DateTimeManager';
import type { CalendarEntry } from '../types';

export function sortEntries(entries: CalendarEntry[], timezone: string) {
  return entries.sort(
    (a, b) =>
      DateTimeManager.getMinuteValueFromTime(a.startTime, timezone) -
      DateTimeManager.getMinuteValueFromTime(b.startTime, timezone)
  );
}

import type { FC } from 'react';

import HelpPopup from 'design_system/Components/HelpPopup';

const TwoFactorAuthenticationHelp: FC = () => {
  return (
    <HelpPopup position="below">
      <p className="mb-2 text-sm font-normal">
        TimeZest supports the use of TOTP two-factor authentication (2FA), as supported in apps like Google
        Authenticator or Authy.
      </p>
      <p className="mb-2 text-sm font-normal">
        Users can individually setup 2FA on their profile page, or this setting can be used to require all users to
        setup 2FA when they next login.
      </p>
      <p className="text-sm font-normal">
        A grace period of 7 days applies, during which users can delay setting up 2FA &mdash; for example, if they
        don&apos;t have their phone with them. After this grace period, they will not be able to login until they have
        configured 2FA for TimeZest.
      </p>
    </HelpPopup>
  );
};

export default TwoFactorAuthenticationHelp;

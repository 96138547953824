import type { FC } from 'react';

import { useAppSelector } from '@/screens/NewUi/DispatchHQ/hooks';
import { selectSelectedCalendarEntry } from '@/screens/NewUi/DispatchHQ/Reducers/calendarReducer';

import ScheduledTime from '../ScheduledTime';

import Description from 'admin_ui/screens/NewUi/DispatchHQ/Components/Flyout/DescriptionList/Description';
import DescriptionList from 'admin_ui/screens/NewUi/DispatchHQ/Components/Flyout/DescriptionList';
import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';
import { displayDuration, sentence } from '@shared/text';

interface Props {
  location?: string | null;
  reminder?: string | null;
  status?: string | null;
  type?: string | null;
}

const Descriptions: FC<Props> = ({ location, reminder, status, type }) => {
  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);

  return (
    <DescriptionList>
      <Description title="Scheduled Time" span={2}>
        <TextSmall>
          <ScheduledTime />
        </TextSmall>
      </Description>
      <Description title="Scheduled User(s)">
        <TextSmall>{sentence(selectedCalendarEntry?.endUserNames || [])}</TextSmall>
      </Description>
      <Description title="Reminder">
        <TextSmall>{reminder || '(None)'}</TextSmall>
      </Description>
      <Description title="Status">
        <TextSmall>{status || '(None)'}</TextSmall>
      </Description>
      <Description title="Type">
        <TextSmall>{type || '(None)'}</TextSmall>
      </Description>
      <Description title="Duration">
        <TextSmall>{displayDuration(selectedCalendarEntry?.duration || 0)}</TextSmall>
      </Description>
      <Description title="Location">
        <TextSmall>{location || '(None)'}</TextSmall>
      </Description>
    </DescriptionList>
  );
};

export default Descriptions;

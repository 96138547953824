import { useState, type FC } from 'react';

import { setFlyoutMode } from '@/screens/NewUi/DispatchHQ/actions';
import { tw } from '@/utilities';

import CancellationErrorMessage from './CancellationErrorMessage';

import FormControl from 'design_system/Components/FormControl';
import Button from 'design_system/Components/Button';
import TextInput from 'design_system/Components/TextInput';
import Switch from 'design_system/Components/Switch';
import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';
import { useAppDispatch, useAppSelector } from 'admin_ui/screens/NewUi/DispatchHQ/hooks';
import { selectSelectedCalendarEntry } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/calendarReducer';
import { selectDisableInteraction } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/uiReducer';
import { useCancelAppointment } from 'admin_ui/screens/NewUi/DispatchHQ/hooks/useCancelAppointment';
import LoadingSpinner from 'design_system/Components/LoadingSpinner';
import type { CancelAppointmentMutationVariables } from '@graphql/generated';

const ABORT_CANCELLATION_BUTTON_CLASSNAMES = tw`text-tz-blue-500 hover:text-tz-blue-600 ring-0 hover:bg-transparent`;
const DISABLED_OVERLAY_CLASSNAMES = tw`bg-tz-gray-200/30 absolute inset-0 z-20 flex h-full w-full cursor-default items-center justify-center`;
const BUTTON_CONTAINER_CLASSNAMES = tw`ms-auto mt-2 flex w-full items-center justify-end space-x-4`;

const TimeZestScheduledCancellationScreen: FC = () => {
  const [showError, setShowError] = useState(false);
  const [runCancellationWorkflow, setRunCancellationWorkflow] = useState(false);
  const [reason, setReason] = useState('');

  const dispatch = useAppDispatch();

  const disabled = useAppSelector(selectDisableInteraction);
  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);

  if (!selectedCalendarEntry) return null;

  const showCancellationWorkflow = selectedCalendarEntry.triggers.includes('time_zest/technician_cancels_appointment');

  const variables = () => {
    return {
      schedulingRequestToken: selectedCalendarEntry.schedulingRequestToken || '',
      reason,
      workflow: runCancellationWorkflow,
    };
  };

  const { handleCancelAppointment } = useCancelAppointment<CancelAppointmentMutationVariables>({
    scheduledBy: 'timezest',
    variables,
    onShowError: setShowError,
  });

  return (
    <>
      {disabled && (
        <div className={DISABLED_OVERLAY_CLASSNAMES}>
          <LoadingSpinner size="small" />
        </div>
      )}
      {showError && <CancellationErrorMessage />}
      <FormControl label="Cancellation Reason" name="appointment-cancellation-form">
        <div className="mb-2">
          <TextInput
            name="appointment-cancellation-input"
            id="appointment-cancellation-form"
            mode="controlled"
            placeholder="Enter a reason for cancellation"
            value={reason}
            error={showError ? 'Please provide a cancellation reason' : undefined}
            onChange={setReason}
          />
        </div>
        {showCancellationWorkflow && (
          <Switch
            checked={runCancellationWorkflow}
            label={
              <TextExtraSmall>
                Run the <strong>Agent cancels Appointment</strong> workflow for this appointment.
              </TextExtraSmall>
            }
            size="small"
            onChange={() => setRunCancellationWorkflow(!runCancellationWorkflow)}
          />
        )}
        <div className={BUTTON_CONTAINER_CLASSNAMES}>
          <Button
            secondary
            disabled={disabled}
            className={ABORT_CANCELLATION_BUTTON_CLASSNAMES}
            label="Cancel"
            size="medium"
            onClick={() => dispatch(setFlyoutMode({ mode: 'info' }))}
          />
          <Button disabled={disabled} label="Cancel Appointment" size="medium" onClick={handleCancelAppointment} />
        </div>
      </FormControl>
    </>
  );
};

export default TimeZestScheduledCancellationScreen;

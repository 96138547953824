import type { FC, PropsWithChildren } from 'react';
import { useContext, useMemo, useState } from 'react';

import styled from 'styled-components';
import { useSortable } from '@dnd-kit/sortable';
import classNames from 'classnames';

import ConfigContext from '../ConfigContext';
import { useAppDispatch, useAppSelector } from '../hooks';
import DragHandle from '../DragHandle';
import { selectElement } from '../actions';
import type { IndexedMjmlSection } from '../EmailTemplate';
import { selectDraggingElement } from '../Reducers/uiReducer';

import { paddingStyle } from './utilities';

interface Props {
  node: IndexedMjmlSection;
  selected: boolean;
}

const Section = styled('div')<{ selected: boolean }>`
  height: fit-content;
  position: relative;
  padding: 0 20px;
`;

const ContentContainer = styled.div`
  margin: 0 auto;
  display: flex;
`;

const MjmlSection: FC<PropsWithChildren<Props>> = ({ children, node, selected }) => {
  const { readOnly } = useContext(ConfigContext);

  const [hovering, setHovering] = useState(false);
  const dispatch = useAppDispatch();

  const draggingElement = useAppSelector(selectDraggingElement);

  const { attributes, listeners, setNodeRef, transition, isDragging } = useSortable({
    id: node.id,
    data: { type: 'mjml-section' },
  });

  const style = useMemo(() => {
    return {
      zIndex: selected ? 999 : 'unset',
      transition,
      opacity: isDragging ? 0.5 : 1,
      backgroundColor: node.backgroundColor,
      padding: paddingStyle(node.padding),
      width: node.width,
    };
  }, [selected, node.padding, node.width, node.backgroundColor, transition, isDragging]);

  const handleMouseOver = (event: React.MouseEvent) => {
    event.stopPropagation();
    setHovering(true);
  };

  const handleMouseOut = (event: React.MouseEvent) => {
    event.stopPropagation();
    setHovering(false);
  };

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    dispatch(selectElement({ selectedElementId: node.id }));
  };

  return (
    <Section
      selected={selected}
      id={node.id}
      ref={setNodeRef}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <ContentContainer
        className={classNames({
          EmailEditor__Section: true,
          'EmailEditor__Section--highlighted': selected || (!draggingElement && hovering),
        })}
        style={style}
      >
        {children}
        <div className="EmailEditor__Section_Identifier">
          <span className={classNames({ 'pe-none': readOnly })} {...attributes} {...listeners}>
            <DragHandle selected={selected} />
          </span>
          Section
        </div>
      </ContentContainer>
    </Section>
  );
};

export default MjmlSection;

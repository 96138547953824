import { forwardRef, useEffect, useState } from 'react';

import { tw } from '@/utilities';

import CalendarArea from './CalendarArea';
import HoursColumn from './HoursColumn';

import useForwardedRef from '@shared/hooks/useForwardedRef';

const SCREEN_PADDING = 13;
const SCROLL_POSITION_OF_7_AM = 673;

const CONTAINER_CLASS_NAMES = tw`border-tz-gray-200 grid w-full overflow-y-auto overflow-x-hidden border border-t-0 [-ms-overflow-style:none] [grid-template-areas:'sidebar_main'] [grid-template-columns:80px_1fr] [scrollbar-width-horizontal:none] [&::-webkit-scrollbar-horizontal]:hidden [&::-webkit-scrollbar:vertical]:bg-transparent`;

const CalendarContainer = forwardRef<HTMLDivElement, {}>((_, ref) => {
  const containerRef = useForwardedRef<HTMLDivElement>(ref);

  const [containerOffsetTop, setContainerOffsetTop] = useState<number>(0);

  useEffect(() => {
    if (containerRef.current) {
      setContainerOffsetTop(containerRef.current.offsetTop || 0);
      containerRef.current.scrollTop = SCROLL_POSITION_OF_7_AM;
    }
  }, [containerRef]);

  return (
    <div
      ref={containerRef}
      className={CONTAINER_CLASS_NAMES}
      style={{ height: `calc(100vh - ${(containerOffsetTop || 0) + SCREEN_PADDING}px)` }}
    >
      <HoursColumn />
      <CalendarArea />
    </div>
  );
});

export default CalendarContainer;

import type { FC } from 'react';

import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { ErrorBoundary } from 'react-error-boundary';

import { tw } from '@/utilities';

import ErrorFallback from '../ErrorFallback';

import HeaderSection from './HeaderSection';
import MainSection from './MainSection';

import { useAppDispatch, useAppSelector } from 'admin_ui/screens/NewUi/DispatchHQ/hooks';
import { selectCalendarEntry, setFlyoutMode } from 'admin_ui/screens/NewUi/DispatchHQ/actions';
import { selectSelectedCalendarEntry } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/calendarReducer';
import { useFetchSelectedCalendarEntryData } from 'admin_ui/screens/NewUi/DispatchHQ/hooks/useFetchSelectedCalendarEntryData';
import { selectDisableInteraction, selectFlyoutMode } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/uiReducer';

const FLYOUT_BACKDROP_CLASS_NAMES = tw`bg-tz-gray-500 fixed inset-0 cursor-pointer bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0`;
const FLYOUT_CONTAINER_CLASS_NAMES = tw`fixed inset-y-0 right-0 flex max-w-full`;
const FLYOUT_MAIN_CONTENT_CLASS_NAMES = tw`flex h-full flex-col overflow-y-scroll bg-white p-6 shadow-xl`;
const FLYOUT_PANEL_CLASS_NAMES = tw`w-[600px] transform duration-500 ease-in-out data-[closed]:translate-x-full`;

const Flyout: FC = () => {
  const dispatch = useAppDispatch();

  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);
  const disabledInteraction = useAppSelector(selectDisableInteraction);
  const flyoutMode = useAppSelector(selectFlyoutMode);

  const open = flyoutMode !== 'close';

  dispatch(useFetchSelectedCalendarEntryData(selectedCalendarEntry?.id));

  const handleClose = () => {
    if (disabledInteraction) return;

    setTimeout(() => {
      dispatch(selectCalendarEntry({ calendarEntryId: null, userId: null }));
    }, 500); // Delay 500 milliseconds before deselecting the calendar entry for the flyout to animate out

    dispatch(setFlyoutMode({ mode: 'close' }));
  };

  return (
    <Dialog open={open} className="relative z-50" onClose={handleClose}>
      <DialogBackdrop transition className={FLYOUT_BACKDROP_CLASS_NAMES} />
      <div className={FLYOUT_CONTAINER_CLASS_NAMES}>
        <DialogPanel transition className={FLYOUT_PANEL_CLASS_NAMES}>
          <div className={FLYOUT_MAIN_CONTENT_CLASS_NAMES}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <HeaderSection onClick={handleClose} />
              <MainSection />
            </ErrorBoundary>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
};

export default Flyout;

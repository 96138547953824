import type { FC, PropsWithChildren } from 'react';

import H5 from 'design_system/Typography/Headings/H5';
import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';
interface Props {
  title: string;
  description?: string;
}

const FormSection: FC<PropsWithChildren<Props>> = ({ title, description, children }) => {
  return (
    <div className="mb-8 grid h-auto grid-cols-4 gap-x-8 leading-5">
      <div>
        <H5>{title}</H5>
        {description && <TextExtraSmall>{description}</TextExtraSmall>}
      </div>
      <div className="col-span-3 flex flex-col gap-y-8">{children}</div>
    </div>
  );
};

export default FormSection;

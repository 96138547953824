import type { FC } from 'react';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import useStateWithCallback from '@shared/hooks/useStateWithCallback';

const DarkModeToggler: FC = () => {
  const [isDarkTheme, setIsDarkTheme] = useStateWithCallback(false);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();

    setIsDarkTheme(!isDarkTheme, () => {
      document.body.classList.toggle('dark');
    });
  };

  return (
    <button
      type="button"
      className="d-block mx-auto h-8 w-8 rounded-md bg-tz-gray-200 p-1.5 text-tz-gray-400 transition duration-150 ease-in-out hover:bg-tz-gray-200 hover:text-tz-gray-600 hover:ring-0 dark:bg-tz-gray-700 dark:text-tz-gray-300 dark:hover:bg-tz-gray-600 dark:hover:text-tz-gray-400"
      onClick={handleClick}
    >
      {isDarkTheme ? (
        <FontAwesomeIcon icon="sun" height={20} width={20} className="text-tz-yellow-500" />
      ) : (
        <FontAwesomeIcon icon="moon" height={20} width={20} />
      )}
    </button>
  );
};

export default DarkModeToggler;

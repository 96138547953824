import type { CalendarState } from '../calendarReducer';

const selectCalendarEntry: (
  state: CalendarState,
  calendarEntryId: string | null,
  userId: number | null
) => CalendarState = (state, calendarEntryId, userId) => {
  if (userId === null || calendarEntryId === null) {
    return {
      ...state,
      selectedUserId: null,
      selectedCalendarEntryId: null,
    };
  }

  return {
    ...state,
    selectedUserId: userId,
    selectedCalendarEntryId: calendarEntryId,
  };
};

export default selectCalendarEntry;

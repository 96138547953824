import type { FC, PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

import { tw } from '@/utilities';
interface Props {
  className?: string;
}

const BASE_H2_CLASS = tw`mb-2 text-2xl font-bold tracking-tight text-tz-blue-950 dark:text-tz-gray-200`;

const H2: FC<PropsWithChildren<Props>> = ({ children, className }) => {
  return <h2 className={twMerge(BASE_H2_CLASS, className)}>{children}</h2>;
};

export default H2;

import type { FC } from 'react';

import classNames from 'classnames';

import { tw } from '@/utilities';

interface Props {
  id: string;
  displayName: string;
  selected: boolean;
  active: boolean;
  appendedElement?: JSX.Element;
}

const ITEM_DEFAULT_CLASS_NAMES = tw`mb-1 flex cursor-pointer items-center gap-x-1 p-2 text-sm font-normal last:mb-0 hover:bg-tz-gray-100 dark:hover:bg-tz-gray-700`;
const DISPLAY_NAME_CLASS_NAMES = tw`text-sm leading-5`;

const Item: FC<Props> = ({ id, selected, active, displayName, appendedElement }) => {
  return (
    <div
      id={id}
      role="option"
      aria-selected={Boolean(selected)}
      tabIndex={0}
      className={classNames(
        ITEM_DEFAULT_CLASS_NAMES,
        selected ? 'bg-tz-gray-200 dark:bg-tz-gray-700' : 'dark:bg-tz-gray-800',
        { 'bg-tz-gray-100 dark:bg-tz-gray-600': active }
      )}
    >
      <div className={classNames(DISPLAY_NAME_CLASS_NAMES)}>{displayName}</div>
      {appendedElement}
    </div>
  );
};

export default Item;

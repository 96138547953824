import type { FC } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props {
  className?: string;
}

const FormDivider: FC<Props> = ({ className }) => {
  return <hr className={twMerge('mb-8 border-tz-gray-200 dark:border-tz-gray-700', className)} />;
};

export default FormDivider;

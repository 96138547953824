import type { UIState } from '../uiReducer';

const toggleViewMode: (state: UIState) => UIState = state => {
  const mode = state.mode === 'week' ? 'day' : 'week';

  return {
    ...state,
    zoom: 1,
    cellWidth: 0,
    mode,
  };
};

export default toggleViewMode;

import type { FC } from 'react';

import { useAppSelector } from '@/screens/NewUi/DispatchHQ/hooks';
import { selectSelectedCalendarEntry } from '@/screens/NewUi/DispatchHQ/Reducers/calendarReducer';

import H3 from 'design_system/Typography/Headings/H3';

const Title: FC = () => {
  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);

  let title = '';

  if (selectedCalendarEntry?.psaTicketSummary) {
    title = selectedCalendarEntry.psaTicketSummary;
  } else if (selectedCalendarEntry?.psaTicketNumber) {
    title = `Ticket: ${selectedCalendarEntry.psaTicketNumber}`;
  } else {
    title = selectedCalendarEntry?.name || '';
  }

  return <H3 className="mt-6">{title}</H3>;
};

export default Title;

import { forwardRef, type CSSProperties } from 'react';

import { tw } from '@/utilities';

import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';

import { selectUsers, selectVisibleUserIds } from '../../Reducers/calendarReducer';
import { useAppSelector } from '../../hooks';
import { selectCellWidth, selectZoom } from '../../Reducers/uiReducer';

const CONTAINER_CLASS_NAMES = tw`border-tz-gray-200 flex h-fit w-full overflow-auto border [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden`;
const USERS_ROW_CLASS_NAMES = tw`sticky top-0 z-10 grid h-10 grow divide-x bg-white`;
const USER_CELL_CLASS_NAMES = tw`p-2 text-center`;
const PLACEHOLDER_CELL_CLASS_NAMES = tw`border-tz-gray-200 sticky left-0 top-0 z-20 h-10 w-20 shrink-0 border-r bg-white`;
const MOCK_SCROLL_BAR_PLACEHOLDER = { minWidth: '6px' };

const UsersRow = forwardRef<HTMLDivElement, {}>((_, ref) => {
  const users = useAppSelector(selectUsers);
  const visibleUserIds = useAppSelector(selectVisibleUserIds);
  const cellWidth = useAppSelector(selectCellWidth);
  const zoom = useAppSelector(selectZoom);

  const style: CSSProperties = {
    gridTemplateColumns: `repeat(${visibleUserIds.length}, ${cellWidth * zoom}px)`,
  };

  return (
    <div ref={ref} className={CONTAINER_CLASS_NAMES}>
      <div className={PLACEHOLDER_CELL_CLASS_NAMES} />
      <div className={USERS_ROW_CLASS_NAMES} style={style}>
        {visibleUserIds.map((id, i) => (
          <div key={`user_${i}`} className={USER_CELL_CLASS_NAMES}>
            <TextExtraSmall className="line-clamp-1">{users[id].name}</TextExtraSmall>
          </div>
        ))}
      </div>
      <div style={MOCK_SCROLL_BAR_PLACEHOLDER} />
    </div>
  );
});

export default UsersRow;

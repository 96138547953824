import type {
  CalendarEntry,
  CalendarEntryGroupMap,
  CalendarEntryMap,
  CalendarEntryTrees,
  MaxOverlapStreak,
} from 'admin_ui/screens/NewUi/DispatchHQ/types';
import { DateTimeManager } from 'admin_ui/screens/NewUi/DispatchHQ/DateTimeManager';
import { sortEntries } from 'admin_ui/screens/NewUi/DispatchHQ/Utils/sortEntries';

export default class CalendarEntryTreesGenerator {
  private calendarEntryGroups: Record<string, CalendarEntry[][]>;
  private timezone: string;

  constructor(calendarEntryGroups: Record<string, CalendarEntry[][]>, timezone: string) {
    this.calendarEntryGroups = calendarEntryGroups;
    this.timezone = timezone;
  }

  buildCalendarEntryTrees() {
    const calendarEntryTrees = Object.keys(this.calendarEntryGroups).reduce((treeStructure, userId) => {
      const allEntryGroups = this.calendarEntryGroups[userId];

      const entryGroupMap: CalendarEntryGroupMap = {};
      const entryMap: CalendarEntryMap = {};

      if (allEntryGroups.length === 0) {
        treeStructure[userId] = { calendarEntryMap: entryMap, calendarEntryGroupMap: entryGroupMap };

        return treeStructure;
      }

      this.buildEntryGroupMap(allEntryGroups, entryGroupMap, entryMap);

      treeStructure[userId] = { calendarEntryMap: entryMap, calendarEntryGroupMap: entryGroupMap };

      return treeStructure;
    }, {} as CalendarEntryTrees);

    return calendarEntryTrees;
  }

  private buildEntryGroupMap = (
    allEntryGroups: CalendarEntry[][],
    entryGroupMap: CalendarEntryGroupMap,
    entryMap: CalendarEntryMap
  ): void => {
    for (const group of allEntryGroups) {
      const groupId = this.generateId();

      const maxOverlapStreak = { currentStreak: 1, maxStreak: 1, lastUpdateIndex: group.length - 1 };

      const rootId = this.addEntryToTree(0, sortEntries(group, this.timezone), entryMap, maxOverlapStreak);

      entryGroupMap[groupId] = {
        id: groupId,
        rootEntryId: rootId[0],
        earliestStartTime: DateTimeManager.getMinuteValueFromTime(group[0].startTime, this.timezone),
        maxOverlapStreak: maxOverlapStreak.maxStreak,
      };
    }
  };

  private addEntryToTree = (
    currentIndex: number,
    entries: CalendarEntry[],
    entryMap: CalendarEntryMap,
    maxOverlapStreak: MaxOverlapStreak
  ): string[] => {
    // Pre
    const entry = { ...entries[currentIndex], leafId: this.generateId() };

    entryMap[entry.leafId] = { ...entry, childIds: [] };

    // Base case
    if (currentIndex === entries.length - 1) return [entry.leafId];

    // Recursion
    let returnedChildIds = this.addEntryToTree(currentIndex + 1, entries, entryMap, maxOverlapStreak);

    // Post
    let filteredReturnedChildIds = [...returnedChildIds];

    let firstOverlapDetected = false;

    for (let i = 0; i < returnedChildIds.length; i++) {
      if (this.detectOverlap(entryMap[entry.leafId], entryMap[returnedChildIds[i]])) {
        if (!firstOverlapDetected) {
          maxOverlapStreak.currentStreak++;
          firstOverlapDetected = true;
        }

        entryMap[entry.leafId].childIds.push(returnedChildIds[i]);

        filteredReturnedChildIds.splice(i, 1);
      }
    }

    this.setMaxOverlapStreak(firstOverlapDetected, currentIndex, maxOverlapStreak);

    filteredReturnedChildIds.unshift(entry.leafId); // Push current entry id to the beginning of the array

    return filteredReturnedChildIds;
  };

  private setMaxOverlapStreak(
    firstOverlapDetected: boolean,
    currentIndex: number,
    maxOverlapStreak: MaxOverlapStreak
  ): void {
    if (!firstOverlapDetected) {
      maxOverlapStreak.currentStreak = 1; // Reset the streak
    }

    if (maxOverlapStreak.maxStreak < maxOverlapStreak.currentStreak) {
      maxOverlapStreak.maxStreak = maxOverlapStreak.currentStreak;
      maxOverlapStreak.lastUpdateIndex = currentIndex;
    }

    if (currentIndex === 0 && maxOverlapStreak.lastUpdateIndex !== 0) {
      maxOverlapStreak.maxStreak++;
    }
  }

  private generateId(): string {
    const LENGTH = 10;
    const CHARACTERS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const CHARACTERS_LENGTH = CHARACTERS.length;

    let result = '';

    for (let i = 0; i < LENGTH; i++) {
      result += CHARACTERS.charAt(Math.floor(Math.random() * CHARACTERS_LENGTH));
    }

    return result;
  }

  private detectOverlap(previousEntry: CalendarEntry, afterEntry: CalendarEntry): boolean {
    const afterEntryEndsAfterPreviousEntryStarts =
      DateTimeManager.getMinuteValueFromTime(previousEntry.startTime, this.timezone) <
      DateTimeManager.getMinuteValueFromTime(afterEntry.endTime, this.timezone);

    const afterEntryStartsBeforePreviousEntryEnds =
      DateTimeManager.getMinuteValueFromTime(afterEntry.startTime, this.timezone) <
      DateTimeManager.getMinuteValueFromTime(previousEntry.endTime, this.timezone);

    return afterEntryEndsAfterPreviousEntryStarts && afterEntryStartsBeforePreviousEntryEnds;
  }
}

import type { FC } from 'react';

import { twJoin } from 'tailwind-merge';

import { tw } from '@/utilities';

type Size = 'sm' | 'md' | 'lg';

interface Props {
  url?: string;
  size: Size;
  name: string;
}

const SIZE_CLASSES = {
  sm: tw`h-6 w-6 text-xs`,
  md: tw`text-md h-10 w-10`,
  lg: tw`h-14 w-14 text-xl`,
};

const COLOR_CLASSES = [
  tw`bg-teal-400 dark:bg-teal-600`,
  tw`bg-rose-400 dark:bg-rose-600`,
  tw`bg-cyan-400 dark:bg-cyan-600`,
  tw`bg-stone-400 dark:bg-stone-600`,
  tw`bg-purple-400 dark:bg-purple-600`,
];

const initials: (name: string) => string = name => {
  if (name) {
    const allInitials = name
      .split(' ')
      .map(part => part[0])
      .join();
    return allInitials[0] + allInitials.slice(-1);
  } else {
    return '??';
  }
};

const color: (name: string) => string = name => {
  if (name) {
    const letterValue = parseInt(name.slice(0), 36) - 9 + (parseInt(name.slice(-1), 36) - 9);
    return COLOR_CLASSES[letterValue % 5];
  } else {
    return tw`bg-gray-400 dark:bg-gray-600`;
  }
};

const Avatar: FC<Props> = ({ name, size, url }) => {
  if (url) {
    return (
      <img src={url} className={twJoin('inline-block rounded-full', SIZE_CLASSES[size])} alt={`Avatar of ${name}`} />
    );
  }

  return (
    <span className={twJoin('inline-flex items-center justify-center rounded-full', SIZE_CLASSES[size], color(name))}>
      <abbr className="no-underline" title={name}>
        <span className="font-medium leading-none text-white">{initials(name)}</span>
      </abbr>
    </span>
  );
};

export default Avatar;

import { useState, type FC } from 'react';

import { useAppDispatch, useAppSelector } from '@/screens/NewUi/DispatchHQ/hooks';
import { selectSelectedCalendarEntry } from '@/screens/NewUi/DispatchHQ/Reducers/calendarReducer';
import { loadSelectedCalendarEntry } from '@/screens/NewUi/DispatchHQ/actions';

import TagsSelectBox from './TagsSelectBox';
import ColorSelectBox from './ColorSelectBox';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import SelectorPopover from 'admin_ui/screens/NewUi/DispatchHQ/Components/SelectorPopover';
import { useCreateTaggingMutation } from '@graphql/generated';

interface Props {
  deleteTaggingLoading: boolean;
  onDeleteTagging: (taggingId: number) => void;
}

const TagsSelector: FC<Props> = ({ deleteTaggingLoading, onDeleteTagging }) => {
  const dispatch = useAppDispatch();

  const [creatingNewTag, setCreatingNewTag] = useState(false);
  const [newTagName, setNewTagName] = useState('');
  const [newTagColor, setNewTagColor] = useState('');

  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);

  const [createNewTagging, { loading: createNewTaggingLoading }] = useCreateTaggingMutation();

  const handleRemoveNewTagCreation = () => {
    setCreatingNewTag(false);
    setNewTagName('');
    setNewTagColor('');
  };

  const handleCreateNewTagging = (tagId?: number) => {
    createNewTagging({
      variables: {
        color: newTagColor,
        name: newTagName,
        calendarEntryId: selectedCalendarEntry?.id || '',
        tagId: tagId || null,
      },
    }).then(res => {
      if (!selectedCalendarEntry || !res.data?.dispatchHq?.createTagging.tagging) return;

      dispatch(
        loadSelectedCalendarEntry({
          data: {
            ...selectedCalendarEntry,
            taggings: [
              ...selectedCalendarEntry.taggings,
              {
                id: res.data.dispatchHq.createTagging.tagging.id,
                tag: { ...res.data.dispatchHq.createTagging.tagging.tag },
              },
            ],
          },
        })
      );
      handleRemoveNewTagCreation();
    });
  };

  return (
    <SelectorPopover
      panelClassName="h-96 w-60"
      disabled={createNewTaggingLoading || deleteTaggingLoading}
      popoverButton={
        <>
          <FontAwesomeIcon icon="tag" height={12} />
          <span>Add tags</span>
        </>
      }
      title="Add or remove tags"
    >
      {!creatingNewTag && (
        <TagsSelectBox
          disabled={createNewTaggingLoading}
          newTagName={newTagName}
          onCreatingNewTag={setCreatingNewTag}
          onCreateNewTagging={handleCreateNewTagging}
          onChangeNewTagName={setNewTagName}
          onDeleteTagging={onDeleteTagging}
        />
      )}
      {creatingNewTag && (
        <ColorSelectBox
          newTagName={newTagName}
          newTagColor={newTagColor}
          onChangeNewTagColor={setNewTagColor}
          onCreateNewTagging={handleCreateNewTagging}
          onRemoveNewTagCreation={handleRemoveNewTagCreation}
        />
      )}
    </SelectorPopover>
  );
};

export default TagsSelector;

import type { FC } from 'react';
import { useContext } from 'react';

import { useDraggable } from '@dnd-kit/core';

import ConfigContext from '../ConfigContext';

import NewToolCard from './NewToolCard';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const Spacer: FC<{}> = () => {
  const { readOnly } = useContext(ConfigContext);

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: 'new-spacer',
    data: {
      type: 'mjml-spacer',
      defaultProperties: {
        backgroundColor: '',
        height: '20px',
        padding: { setAll: true, all: '0px', left: '0px', right: '0px', top: '0px', bottom: '0px' },
      },
    },
  });

  return (
    <NewToolCard ref={setNodeRef} {...listeners} {...attributes}>
      <p className="m-0 mb-2 small text-muted">Spacer</p>
      <FontAwesomeIcon icon="minus" size="3x" color="#585858" opacity={readOnly ? 0.5 : 1} />
    </NewToolCard>
  );
};

export default Spacer;

import { forwardRef, useState } from 'react';

import { Combobox } from '@headlessui/react';
import classNames from 'classnames';

import { tw } from '@/utilities';

import type { ItemModel } from '@models/SelectItem';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const UNIT_SELECTOR_DEFAULT_CLASSNAMES = tw`relative flex h-auto w-full items-center rounded-t-md border-none text-sm leading-6 ring-inset`;
const UNIT_OPTION_DEFAULT_CLASSNAMES = tw`text-tz-gray-800 dark:text-tz-gray-300 mb-1 flex cursor-pointer items-center gap-x-1 p-2 text-sm last:mb-0`;
const UNIT_OPTIONS_BUTTON_DEFAULT_CLASSNAMES = tw`border-tz-gray-300/20 dark:border-tz-gray-600/20 dark:bg-tz-gray-700 dark:text-tz-gray-300 absolute right-0 top-[28px] z-10 m-0 max-h-32 list-none overflow-y-auto border bg-white shadow-sm dark:border-t-0`;

interface Props {
  name: string;
  items: ItemModel[];
  disabled: boolean;
  initialItemId?: string;
  onChange: (newUnit: ItemModel) => void;
  onClick: () => void;
}

const UnitSelector = forwardRef<HTMLDivElement, Props>(
  ({ name, items, disabled, initialItemId, onChange, onClick }, ref) => {
    const initialSelectedItem = initialItemId ? items.find(i => i.id === initialItemId) : null;
    const withBusinessHours = items.some(i => i.id.startsWith('business'));

    const [selectedItem, setSelectedItem] = useState<ItemModel>(initialSelectedItem ? initialSelectedItem : items[0]);

    const handleChange = (item: ItemModel) => {
      setSelectedItem(item);
      onChange(item);
    };

    return (
      <div className="relative w-full" ref={ref}>
        <Combobox value={selectedItem} name="duration-select" disabled={disabled} onChange={handleChange}>
          {({ open }) => (
            <div>
              <Combobox.Button
                as="div"
                id={name}
                className={classNames(
                  UNIT_SELECTOR_DEFAULT_CLASSNAMES,
                  disabled
                    ? 'bg-tz-gray-100 text-tz-gray-300 dark:bg-tz-gray-800 dark:text-tz-gray-500 cursor-not-allowed ring-0'
                    : 'text-tz-gray-800 dark:bg-tz-gray-900 dark:text-tz-gray-300 cursor-pointer'
                )}
                onClick={onClick}
              >
                <div className="hover:bg-tz-gray-200 hover:text-tz-gray-600 dark:text-tz-gray-300 dark:hover:bg-tz-gray-600 dark:hover:text-tz-gray-200 absolute right-1 rounded-md p-2">
                  <FontAwesomeIcon icon="chevron-down" height={10} />
                </div>

                <div
                  className={classNames(
                    disabled ? 'text-tz-gray-300 dark:text-tz-gray-500' : 'text-tz-gray-800 dark:text-tz-gray-300',
                    withBusinessHours ? 'w-max min-w-24 pl-3 pr-8' : 'w-24 pl-4'
                  )}
                >
                  {selectedItem.name.replace('_', ' ')}
                </div>
              </Combobox.Button>

              <Combobox.Options
                className={classNames(UNIT_OPTIONS_BUTTON_DEFAULT_CLASSNAMES, withBusinessHours ? 'w-32' : 'w-full', {
                  'rounded-b-md': open,
                })}
              >
                {items.map(item => (
                  <Combobox.Option key={item.id} value={item}>
                    {({ active, selected }) => (
                      <div
                        id={item.id}
                        role="option"
                        aria-selected={Boolean(selected)}
                        tabIndex={0}
                        className={classNames(
                          UNIT_OPTION_DEFAULT_CLASSNAMES,
                          active ? 'bg-tz-gray-100 dark:bg-tz-gray-600' : 'dark:bg-tz-gray-800',
                          { 'bg-tz-gray-200 dark:bg-tz-gray-700': selected }
                        )}
                      >
                        <div>{item.name.replace('_', ' ')}</div>
                      </div>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </div>
          )}
        </Combobox>
      </div>
    );
  }
);

export default UnitSelector;

import type { FC } from 'react';

import { formatISO } from 'date-fns';
import type { QueryHookOptions, QueryResult } from '@apollo/client';
import classNames from 'classnames';

import DataLoader from '@/DataLoader';
import UsageChart from '@/UsageChart';

interface Props {
  query: (baseOptions: QueryHookOptions) => QueryResult;
  resourceId?: string;
  timeRange: { from: Date; to: Date };
}

const Stats: FC<Props> = ({ query, resourceId, timeRange }: Props) => {
  const {
    data: stats,
    previousData: prevStats,
    error,
    loading,
  } = query({
    variables: {
      from: formatISO(timeRange.from, { representation: 'date' }),
      to: formatISO(timeRange.to, { representation: 'date' }),
      resourceId: resourceId,
    },
  });

  const data = (stats || prevStats)?.timezest;

  return (
    <div>
      <div className={classNames('d-flex mb-4', error ? 'h-auto' : 'graph-container')}>
        <DataLoader
          error={error && `Something went wrong while retrieving statistics (Error: ${error.message}).`}
          loading={loading && !data && 'Loading stats...'}
        >
          {data && (
            <UsageChart
              labelsByKey={{
                booked: 'Appointments Booked',
                cancelled: 'Cancelled Appointments',
                unresponded: 'No Response',
              }}
              data={data.appointmentsStats.map(({ stats: { __typename, ...statsKeys }, ...rest }) => ({
                stats: { ...statsKeys },
                ...rest,
              }))}
            />
          )}
        </DataLoader>
      </div>
    </div>
  );
};

export default Stats;

import type { FC } from 'react';

import Domain from './Domain';
import DomainsBlankSlate from './DomainsBlanksSlate';

import type DomainModel from '@models/Domain';
import StackedList from 'design_system/Components/StackedList';

interface Props {
  domains: DomainModel[];
}

const Domains: FC<Props> = ({ domains }) => {
  if (domains.length === 0) {
    return <DomainsBlankSlate />;
  }

  return (
    <StackedList>
      {domains.map(domain => (
        <Domain key={`email-template-${domain.id}`} {...domain} />
      ))}
    </StackedList>
  );
};

export default Domains;

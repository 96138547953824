import type { FC } from 'react';

import classNames from 'classnames';

export interface OptionProps {
  description?: string | JSX.Element;
  disabled?: boolean;
  label: string | JSX.Element;
  value: string;
}

interface Props extends OptionProps {
  name: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Option: FC<Props> = ({ name, checked, description, onChange, disabled, label, value }) => {
  const id = `${name}-${value}`;

  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <input
          checked={checked}
          className={classNames('h-4 w-4 border-tz-gray-300 text-tz-green-600 outline-none focus:ring-tz-green-200', {
            'pointer-events-none opacity-50 filter-none': disabled,
          })}
          disabled={disabled}
          id={id}
          name={name}
          type="radio"
          value={value}
          onChange={onChange}
        />
      </div>
      <div className="ml-2">
        {typeof label === 'string' ? (
          <label className="text-sm font-medium text-tz-gray-800 dark:text-tz-gray-200" htmlFor={id}>
            {label}
          </label>
        ) : (
          label
        )}

        {typeof description === 'string' ? <div className="text-xs">{description}</div> : description}
      </div>
    </div>
  );
};

export default Option;

import type { CalendarEntriesByUser, CalendarEntry } from 'admin_ui/screens/NewUi/DispatchHQ/types';
import { DateTimeManager } from 'admin_ui/screens/NewUi/DispatchHQ/DateTimeManager';

export default class CalendarEntryGroupsGenerator {
  private calendarEntriesByUser: CalendarEntriesByUser;
  private visibleUserIds: string[];
  private timezone: string;

  constructor(calendarEntriesByUser: CalendarEntriesByUser, visibleUserIds: string[], timezone: string) {
    this.calendarEntriesByUser = calendarEntriesByUser;
    this.visibleUserIds = visibleUserIds;
    this.timezone = timezone;
  }

  generateEntryGroupsByUser() {
    if (!this.calendarEntriesByUser) return {};

    const entryGroupsByUser = this.visibleUserIds.reduce(
      (groupsOfOverlappingEntries, userId) => {
        if (this.initializeCalendarEntriesGroup(groupsOfOverlappingEntries, userId)) {
          return groupsOfOverlappingEntries;
        }

        const calendarEntries = this.calendarEntriesByUser[userId];

        let totalGroups: CalendarEntry[][] = [];

        this.addCalendarEntriesToGroup(calendarEntries, totalGroups);

        groupsOfOverlappingEntries[userId] = totalGroups;

        return groupsOfOverlappingEntries;
      },
      {} as Record<string, CalendarEntry[][]>
    );

    return entryGroupsByUser;
  }

  private initializeCalendarEntriesGroup(
    groupsOfOverlappingEntries: Record<string, CalendarEntry[][]>,
    userId: string
  ): Record<string, CalendarEntry[][]> | null {
    if (!this.calendarEntriesByUser[userId]) {
      groupsOfOverlappingEntries[userId] = [];

      return groupsOfOverlappingEntries;
    }

    return null;
  }

  private addCalendarEntriesToGroup(calendarEntries: CalendarEntry[], totalGroups: CalendarEntry[][]): void {
    if (calendarEntries.length === 0) return;

    let currentGroup: CalendarEntry[] = [calendarEntries[0]];

    let currentLargestEndTime = DateTimeManager.getMinuteValueFromTime(calendarEntries[0].endTime, this.timezone);

    for (let i = 0; i < calendarEntries.length - 1; i++) {
      if (i === calendarEntries.length - 1) continue;

      if (this.belongToSameGroup(calendarEntries, currentLargestEndTime, i)) {
        currentGroup.push(calendarEntries[i + 1]);
      } else {
        totalGroups.push(currentGroup);

        currentGroup = [calendarEntries[i + 1]];
      }

      currentLargestEndTime = Math.max(
        currentLargestEndTime,
        DateTimeManager.getMinuteValueFromTime(calendarEntries[i + 1].endTime, this.timezone)
      );
    }

    // This is to add the last remaining entries
    if (currentGroup.length > 0) {
      totalGroups.push(currentGroup);
    }
  }

  private belongToSameGroup(calendarEntries: CalendarEntry[], currentLargestEndTime: number, index: number): boolean {
    const currentEndTime = DateTimeManager.getMinuteValueFromTime(calendarEntries[index].endTime, this.timezone);
    const nextStartTime = DateTimeManager.getMinuteValueFromTime(calendarEntries[index + 1].startTime, this.timezone);

    const nextEntryStartsBeforeCurrentEntryEnds = nextStartTime < currentEndTime;
    const nextEntryStartsBeforeCurrentLargestEndTime = nextStartTime < currentLargestEndTime;

    return nextEntryStartsBeforeCurrentEntryEnds || nextEntryStartsBeforeCurrentLargestEndTime;
  }
}

import type { FC, PropsWithChildren } from 'react';

import { MenuItem } from '@headlessui/react';

import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';

import { BASE_MENU_ITEM_CLASS_NAMES } from '.';

interface Props {
  href: string;
  disabled?: boolean;
}

const MenuLink: FC<PropsWithChildren<Props>> = ({ children, href, disabled }) => {
  return (
    <MenuItem>
      <a href={href} className={BASE_MENU_ITEM_CLASS_NAMES(disabled)}>
        <TextExtraSmall>{children}</TextExtraSmall>
      </a>
    </MenuItem>
  );
};

export default MenuLink;

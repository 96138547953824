import type { FC, PropsWithChildren } from 'react';

import { useDroppable } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import transparentBodyBackground from './transparent-background.png';

import { dataTestId } from '@shared/test';

import { selectElement } from '../../actions';
import type { IndexedMjmlBody } from '../../EmailTemplate';

interface Props {
  node: IndexedMjmlBody;
  childIds: string[];
}

const Body = styled('div')<{
  $backgroundColor: string | undefined;
}>`
  flex-grow: 1;
  padding-top: 6px;
  padding-bottom: 50px;
  background-color: ${props => props.$backgroundColor};
  background-image: ${props => (!props.$backgroundColor ? `url(${transparentBodyBackground})` : 'none')};
`;

const MjmlBody: FC<PropsWithChildren<Props>> = ({ children, node, childIds }) => {
  const dispatch = useDispatch();

  const { setNodeRef } = useDroppable({
    id: node.id,
    data: {
      type: 'mjml-body',
    },
  });

  return (
    <Body
      id={node.id}
      ref={setNodeRef}
      className="position-relative"
      $backgroundColor={node.backgroundColor}
      onClick={() => {
        dispatch(selectElement({ selectedElementId: null }));
      }}
      {...dataTestId('body')}
    >
      <SortableContext items={childIds}>{children}</SortableContext>
    </Body>
  );
};

export default MjmlBody;

import type { FC } from 'react';

import HelpPopup from 'design_system/Components/HelpPopup';

const MailSendingModeHelp: FC = () => {
  return (
    <HelpPopup position="below">
      <p className="mb-2 text-sm font-normal">
        When sending emails, TimeZest will use this value in the &lsquo;Reply-To&rsquo; header.
      </p>
      <p className="mb-2 text-sm font-normal">
        It can be used if you wish replies to any emails TimeZest sends to go to a different email address, for example
        your main support email.
      </p>
      <p className="text-sm font-normal">
        Be aware that not all email clients respect this header, and may still send replies by default to the
        &lsquo;From&rsquo; address. TimeZest recommends using both the &lsquo;Reply-To&rsquo; header and a custom email
        domain to ensure all replies end users send go to the desired address.
      </p>
    </HelpPopup>
  );
};

export default MailSendingModeHelp;

import { forwardRef, type CSSProperties } from 'react';

import { getDate } from 'date-fns';
import { twMerge } from 'tailwind-merge';

import { tw } from '@/utilities';

import TextLarge from 'design_system/Typography/Paragraphs/TextLarge';
import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';
import { DAY_NAMES } from '@shared/constants';

import { selectDatesRange, selectTimezone } from '../../Reducers/calendarReducer';
import { useAppSelector } from '../../hooks';
import { selectCellWidth, selectZoom } from '../../Reducers/uiReducer';
import { DateTimeManager } from '../../DateTimeManager';

const CONTAINER_CLASSNAMES = tw`border-tz-gray-200 flex h-fit w-full overflow-auto border [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden`;
const DAYS_ROW_CLASSNAMES = tw`sticky top-0 z-10 grid h-14 grow divide-x bg-white`;
const DAY_CELL_CLASSNAMES = tw`px-3 py-1`;
const PLACEHOLDER_CELL_CLASSNAMES = tw`border-tz-gray-200 sticky left-0 top-0 z-20 h-14 w-28 shrink-0 border-r bg-white`;
const TODAY_CLASSNAMES = tw`bg-tz-blue-500 w-fit rounded-sm px-1 text-white`;
const MOCK_SCROLL_BAR_PLACEHOLDER = { minWidth: '6px' };

interface Props {
  containerOverflown: boolean;
}

const DaysRow = forwardRef<HTMLDivElement, Props>(({ containerOverflown }, ref) => {
  const datesRange = useAppSelector(selectDatesRange);
  const cellWidth = useAppSelector(selectCellWidth);
  const zoom = useAppSelector(selectZoom);
  const timezone = useAppSelector(selectTimezone);

  const style: CSSProperties = {
    gridTemplateColumns: `repeat(7, ${cellWidth * zoom}px)`,
  };

  const weekDays = DateTimeManager.getWeekDays(timezone, datesRange.week.startDate);
  const currentDay = getDate(new DateTimeManager(timezone).getDate());

  const today = (day: string): boolean => {
    return currentDay.toString() === day.slice(-2);
  };

  return (
    <div ref={ref} className={CONTAINER_CLASSNAMES}>
      <div className={PLACEHOLDER_CELL_CLASSNAMES} />
      <div className={DAYS_ROW_CLASSNAMES} style={style}>
        {weekDays.map((d, i) => (
          <div key={`day-${i}`} className={DAY_CELL_CLASSNAMES}>
            <TextLarge className={twMerge('text-xl', today(d.slice(-2)) && TODAY_CLASSNAMES)}>{d.slice(-2)}</TextLarge>
            <TextExtraSmall className={today(d.slice(-2)) ? 'text-tz-blue-600' : ''}>{DAY_NAMES[i]}</TextExtraSmall>
          </div>
        ))}
      </div>
      {containerOverflown && <div style={MOCK_SCROLL_BAR_PLACEHOLDER} />}
    </div>
  );
});

export default DaysRow;

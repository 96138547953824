import { add } from 'date-fns/add';
import { format } from 'date-fns/format';

import type { CalendarState } from '../Reducers/calendarReducer';
import type { ViewMode } from '../types';

export function getDateRange(
  viewMode: ViewMode,
  dateRanges: CalendarState['dateRanges']
): { startDate: string; endDate: string } {
  const startDate = viewMode === 'day' ? dateRanges.day : dateRanges.week.startDate;
  const endDate = viewMode === 'day' ? dateRanges.day : format(add(dateRanges.week.endDate, { days: 1 }), 'yyyy-MM-dd');

  return { startDate, endDate };
}

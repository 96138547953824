import type { FC, PropsWithChildren } from 'react';

import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';

interface Props {
  title?: string;
  span?: number;
}

const Description: FC<PropsWithChildren<Props>> = ({ children, span = 1, title }) => {
  return (
    <div className={`col-span-${span}`}>
      {title && (
        <dt>
          <TextSmall className="font-semibold">{title}</TextSmall>
        </dt>
      )}
      <dd className="mt-1">{children}</dd>
    </div>
  );
};

export default Description;

import type { UIState } from '../uiReducer';

const setZoom: (state: UIState, zoom: number) => UIState = (state, zoom) => {
  return {
    ...state,
    zoom,
  };
};

export default setZoom;

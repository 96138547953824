import { type FC, useEffect, useMemo, useState, Fragment } from 'react';

import { Tab } from '@headlessui/react';
import classNames from 'classnames';

interface Props {
  tabs: Record<string, string>;
  className?: string;
}

const Tabs: FC<Props> = ({ tabs: initialTabs, className }) => {
  const tabs = useMemo(() => {
    return Object.entries(initialTabs).map(([key, value]) => {
      return { id: key, name: value };
    });
  }, [initialTabs]);

  const [selectedTabId, setSelectedTabId] = useState(tabs[0].id);

  const handleTabChange = (tabIndex: number) => {
    document.getElementById(selectedTabId)?.classList.add('hidden');
    setSelectedTabId(tabs[tabIndex].id);
  };

  useEffect(() => {
    const currentTab = document.getElementById(selectedTabId);

    if (currentTab) {
      currentTab.classList.remove('hidden');
    }
  }, [selectedTabId]);

  return (
    <Tab.Group onChange={handleTabChange}>
      <Tab.List className={classNames('flex flex-wrap gap-x-5 gap-y-3', className)}>
        {tabs.map(t => (
          <Tab as={Fragment} key={`${t.id}`}>
            {({ selected }) => (
              <button
                tabIndex={0}
                type="button"
                className={classNames(
                  'relative h-max rounded-md text-sm font-normal leading-5 text-tz-gray-800 outline-none transition duration-150 ease-in-out hover:text-blue-600 dark:text-tz-gray-300 dark:hover:text-blue-600',
                  selected && '!text-blue-600 dark:!text-blue-500'
                )}
              >
                {t.name}
                {selected && (
                  <div className="absolute -bottom-1 left-0 right-0 mx-auto h-px w-full rounded-full bg-blue-500 dark:hidden dark:bg-blue-600" />
                )}
              </button>
            )}
          </Tab>
        ))}
      </Tab.List>
    </Tab.Group>
  );
};

export default Tabs;

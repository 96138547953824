import { useContext, type FC } from 'react';

import CurrentUserContext from '@/CurrentUserContext';

import Badge from 'design_system/Components/Badge';
import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';

import DescriptionList from '../../DispatchHQ/Components/Flyout/DescriptionList';
import Description from '../../DispatchHQ/Components/Flyout/DescriptionList/Description';

const DetailsTab: FC = () => {
  const user = useContext(CurrentUserContext);

  return (
    <div id="details" className="hidden pt-8" role="tabpanel">
      <DescriptionList>
        <Description title="Name" span={2}>
          <TextSmall>{user.name}</TextSmall>
        </Description>
        <Description title="Email Address" span={2}>
          <TextSmall>{user.email}</TextSmall>
        </Description>
        <Description title="Job Title" span={2}>
          <TextSmall>{user.jobTitle || 'Not yet set.'}</TextSmall>
        </Description>
        <Description title="TimeZest Role" span={2}>
          {user.role === 'administrator' ? <Badge color="blue">Administrator</Badge> : <Badge color="gray">User</Badge>}
        </Description>
        <Description title="TimeZest License Assigned" span={2}>
          <TextSmall>{user.schedulable ? 'Yes' : 'No'}</TextSmall>
        </Description>
      </DescriptionList>
    </div>
  );
};

export default DetailsTab;

import { type FC, useState } from 'react';

import classNames from 'classnames';

import { tw } from '@/utilities';

import { usePagination } from './usePagination';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const PAGE_NAVIGATOR_BUTTON_CLASS_NAME = tw`group inline-flex cursor-pointer items-center border-transparent text-sm font-medium`;
const PAGE_BUTTON_CLASS_NAME = tw`inline-flex items-center rounded-md border-transparent px-4 py-2 text-sm font-medium text-tz-gray-800 transition duration-150 ease-in-out hover:bg-blue-600 hover:text-white dark:text-white`;

interface Props {
  totalPages: number;
  onPageChange: (page: number) => void;
}

const Pagination: FC<Props> = ({ totalPages, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const paginationRange = usePagination({
    currentPage,
    totalPages,
  });

  if (paginationRange.length < 2) {
    return null;
  }

  const handleNext = () => {
    onPageChange(currentPage + 1);
    setCurrentPage(currentPage + 1);
  };

  const handlePrevious = () => {
    onPageChange(currentPage - 1);
    setCurrentPage(currentPage - 1);
  };

  const handlePageClick = (pageNumber: number) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber);
      onPageChange(pageNumber);
    }
  };

  return (
    <div className="flex items-center justify-between pt-1">
      <div className="w-20">
        <button
          type="button"
          className={classNames(
            PAGE_NAVIGATOR_BUTTON_CLASS_NAME,
            currentPage === 1
              ? 'pointer-events-none text-tz-gray-300 dark:text-tz-gray-500'
              : 'text-tz-gray-800 hover:text-blue-600 dark:text-tz-gray-300 hover:dark:text-blue-500'
          )}
          onClick={handlePrevious}
        >
          <FontAwesomeIcon icon="arrow-left-long" height={14} />
          <p className="ml-1 transition-all duration-150 ease-in-out group-hover:ml-2">Previous</p>
        </button>
      </div>

      <div>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === 'rightDot' || pageNumber === 'leftDot') {
            return (
              <button
                type="button"
                key={`page-${index + 1}`}
                className={PAGE_BUTTON_CLASS_NAME}
                onClick={() => handlePageClick(pageNumber === 'leftDot' ? 1 : totalPages)}
              >
                ...
              </button>
            );
          }

          return (
            <button
              type="button"
              key={`page-${index + 1}`}
              className={classNames(
                'relative',
                PAGE_BUTTON_CLASS_NAME,
                pageNumber === currentPage && '!text-blue-600 hover:bg-transparent dark:!text-blue-500'
              )}
              onClick={() => handlePageClick(pageNumber as number)}
            >
              {pageNumber === currentPage && (
                <FontAwesomeIcon
                  icon="circle"
                  className="absolute bottom-0 left-0 right-0 mx-auto text-blue-600 dark:text-blue-500"
                  height={4}
                />
              )}
              {pageNumber}
            </button>
          );
        })}
      </div>

      <div className="flex w-12 justify-end">
        <button
          type="button"
          className={classNames(
            PAGE_NAVIGATOR_BUTTON_CLASS_NAME,
            currentPage === totalPages
              ? 'pointer-events-none text-tz-gray-300 dark:text-tz-gray-500'
              : 'text-tz-gray-800 hover:text-blue-600 dark:text-tz-gray-300 hover:dark:text-blue-500'
          )}
          onClick={handleNext}
        >
          <p className="mr-1 transition-all duration-150 ease-in-out group-hover:mr-2">Next</p>
          <FontAwesomeIcon icon="arrow-right-long" height={14} />
        </button>
      </div>
    </div>
  );
};

export default Pagination;

import type { FC } from 'react';

import { selectSelectedCalendarEntry } from '@/screens/NewUi/DispatchHQ/Reducers/calendarReducer';
import { useAppSelector } from '@/screens/NewUi/DispatchHQ/hooks';

import ScheduledTime from '../ScheduledTime';

import DescriptionList from 'admin_ui/screens/NewUi/DispatchHQ/Components/Flyout/DescriptionList';
import Description from 'admin_ui/screens/NewUi/DispatchHQ/Components/Flyout/DescriptionList/Description';
import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';
import { displayDuration, sentence } from '@shared/text';
import { useGetServiceCallSuspenseQuery } from '@graphql/generated';

const ServiceCall: FC = () => {
  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);

  const { data } = useGetServiceCallSuspenseQuery({
    fetchPolicy: 'no-cache',
    variables: { id: selectedCalendarEntry?.psaId || '0' },
  });

  const serviceCall = data?.autotask?.serviceCall;

  return (
    <DescriptionList>
      <Description title="Scheduled Time" span={2}>
        <TextSmall>
          <ScheduledTime />
        </TextSmall>
      </Description>
      <Description title="Scheduled User(s)">
        <TextSmall>{sentence(selectedCalendarEntry?.endUserNames || [])}</TextSmall>
      </Description>
      <Description title="Status">
        <TextSmall>{serviceCall?.status || '(None)'}</TextSmall>
      </Description>
      <Description title="Duration">
        <TextSmall>{displayDuration(selectedCalendarEntry?.duration || 0)}</TextSmall>
      </Description>
      <Description title="Location">
        <TextSmall>{serviceCall?.location || '(None)'}</TextSmall>
      </Description>
    </DescriptionList>
  );
};

export default ServiceCall;

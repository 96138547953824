import type { FC } from 'react';

import { setFlyoutMode } from '@/screens/NewUi/DispatchHQ/actions';
import { DateTimeManager } from '@/screens/NewUi/DispatchHQ/DateTimeManager';

import TimeZestAppointmentDetails from './TimeZestAppointmentDetails';
import ExternalAppointmentDetails from './ExternalAppointmentDetails';

import { useAppDispatch, useAppSelector } from 'admin_ui/screens/NewUi/DispatchHQ/hooks';
import {
  selectSelectedCalendarEntry,
  selectTimezone,
} from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/calendarReducer';
import ActionMenu from 'design_system/Components/ActionMenu';
import MenuItem from 'design_system/Components/ActionMenu/MenuItem';
import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';

const AppointmentDetails: FC = () => {
  const dispatch = useAppDispatch();
  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);
  const timezone = useAppSelector(selectTimezone);

  const disableAppointmentCancellation =
    new DateTimeManager(timezone, selectedCalendarEntry?.startTime).getDate() <
    new DateTimeManager(timezone).getStartOfDay();

  return (
    <div className="relative">
      {selectedCalendarEntry?.schedulingRequestToken && <TimeZestAppointmentDetails />}
      {!selectedCalendarEntry?.schedulingRequestToken && <ExternalAppointmentDetails />}

      <ActionMenu disabled={false} size={14} top="top-0">
        <MenuItem disabled={disableAppointmentCancellation} onClick={() => dispatch(setFlyoutMode({ mode: 'cancel' }))}>
          <TextExtraSmall disabled={disableAppointmentCancellation}>Cancel</TextExtraSmall>
        </MenuItem>
        <MenuItem onClick={() => console.log('To be implemented')}>
          <TextExtraSmall>Reschedule</TextExtraSmall>
        </MenuItem>
        <MenuItem onClick={() => console.log('To be implemented')}>
          <TextExtraSmall>Reassign</TextExtraSmall>
        </MenuItem>
      </ActionMenu>
    </div>
  );
};

export default AppointmentDetails;

import type { ThunkAction } from 'redux-thunk';

import type { AppDispatch, RootState } from '../Store';
import type { LoadSelectedCalendarEntryAction } from '../actions';
import { loadSelectedCalendarEntry } from '../actions';

import type { DispatchHqCalendarEntry } from '@graphql/generated';
import { useGetCalendarEntryQuery } from '@graphql/generated';

export const useFetchSelectedCalendarEntryData =
  (calendarEntryId: string | undefined): ThunkAction<void, RootState, unknown, LoadSelectedCalendarEntryAction> =>
  (dispatch: AppDispatch) => {
    useGetCalendarEntryQuery({
      fetchPolicy: 'no-cache',
      skip: !calendarEntryId,
      variables: { calendarEntryId: calendarEntryId || '' },
      onCompleted(data) {
        if (data) {
          dispatch(
            loadSelectedCalendarEntry({
              data: data?.timezest.dispatchHq?.calendarEntry as DispatchHqCalendarEntry,
            })
          );
        }
      },
    });
  };

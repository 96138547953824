import type { FC } from 'react';
import { useState } from 'react';

import first from 'lodash/fp/first';
import { twMerge } from 'tailwind-merge';

import { tw } from '@/utilities';

import SingleSelect from '../SingleSelect';
import Button from '../Button';

import type { Attribute, FilterItem, Operator } from './types';
import ValueControl from './ValueControl';
import FilterHeader from './FilterHeader';

import useGetSingleCompanyName from '@shared/hooks/useGetSingleCompanyName';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import useStateFromProp from '@shared/hooks/useStateFromProp';
import type { Filter } from '@models/Filter';
import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';
import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';

import { POSSIBLE_OPERATORS, VALUE_FORMATTER } from '.';

const CONTAINER_CLASS_NAMES = tw`flex gap-2`;
const ERROR_MESSAGE_CLASS_NAMES = tw`mt-1 block text-red-600 dark:text-red-500`;
const FILTER_BLOCK_CONTAINER_CLASS_NAMES = tw`relative min-w-[260px]`;
const FILTER_HEADER_BUTTON_CLASS_NAMES = tw`flex w-full items-start justify-between border-0 p-2`;
const FILTER_BODY_CLASS_NAMES = tw`dark:bg-tz-gray-800 absolute top-[100%] z-10 grid w-full gap-2 rounded-b bg-white p-2 shadow-sm`;
const FILTER_REMOVE_BUTTON_CLASS_NAMES = tw`hover:bg-tz-gray-200 dark:text-tz-gray-200 dark:hover:bg-tz-gray-800 me-1 mt-px cursor-pointer rounded p-1`;

interface Props {
  filter: Filter;
  index: number;
  attributes: Attribute[];
  possibleValues: Record<string, { values: FilterItem[] }>;
  onApplyFilter: (filter: Filter) => void;
  onDeleteFilter: (e: React.MouseEvent) => void;
}

const FilterBlock: FC<Props> = ({ attributes, filter, index, possibleValues, onApplyFilter, onDeleteFilter }) => {
  const [open, setOpen] = useState(!filter.persisted);
  const [selectedAttributeId, setSelectedAttributeId] = useStateFromProp(filter.attribute);
  const [selectedOperatorId, setSelectedOperatorId] = useStateFromProp(filter.operator);
  const [value, setValue] = useStateFromProp(filter.value);
  const [valueName, setValueName] = useStateFromProp(filter.valueName);
  const [changed, setChanged] = useState(filter.persisted ? true : false);
  const [error, setError] = useState('');

  const attribute = attributes.find(a => a.value === filter.attribute);
  const operatorName = POSSIBLE_OPERATORS[attribute?.type || 'string'].find(o => o.value === filter.operator)?.name;

  const [companyName, companyLoading] = useGetSingleCompanyName(
    filter.attribute,
    Number(filter.value),
    filter.valueName
  );

  const unapplied = !open && !changed;

  const attributeItems = attributes.map(attribute => {
    return { id: attribute.value, name: attribute.name };
  });

  const operatorItems = POSSIBLE_OPERATORS[attributes.find(a => a.value === selectedAttributeId)?.type || 'string'].map(
    operator => {
      return { id: operator.value, name: operator.name };
    }
  );

  const eventualValueName = (): string => {
    const valueFormatter = VALUE_FORMATTER[attributes.find(a => a.value === filter.attribute)?.type || ''] || undefined;
    if (valueFormatter) return valueFormatter(filter.valueName);

    return companyName || filter.valueName;
  };

  const filterState = () => {
    if (companyLoading) return 'loading';
    if (unapplied) return 'unapplied';

    return 'applied';
  };

  const applyFilter = () => {
    const filterToBeApplied: Filter = {
      ...filter,
      attribute: selectedAttributeId,
      operator: selectedOperatorId,
      value: value,
      valueName: valueName,
    };

    if (value) {
      setOpen(false);
      setChanged(true);
      setError('');
      onApplyFilter(filterToBeApplied);
    } else {
      setOpen(true);
      setChanged(false);
      setError('Please provide a filter value.');
    }
  };

  const handleKeydown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      applyFilter();
    }
  };

  const handleToggleOpenFilterBody = () => {
    setOpen(!open);
  };

  const handleAttributeChange = (newValue: string) => {
    const valueType = attributes.find(a => a.value === newValue)?.type;

    const firstValue = first(possibleValues[newValue].values);

    if (valueType !== undefined) {
      const operator = valueType === 'string' ? 'LIKE' : POSSIBLE_OPERATORS[valueType][0].value;

      setSelectedAttributeId(newValue);
      setSelectedOperatorId(operator);
      setValue(firstValue?.value || '');
      setValueName(firstValue?.name || '');
      setChanged(false);
    }
  };

  const handleOperatorChange = (newValue: Operator) => {
    setSelectedOperatorId(newValue);
    setChanged(false);
  };

  const handleValueChange = (newValue: string, newName?: string) => {
    setValue(newValue);
    setValueName(newName || newValue);

    setChanged(false);
  };

  return (
    <div className={CONTAINER_CLASS_NAMES}>
      {index > 0 && <TextSmall className="m-0 mt-2">AND</TextSmall>}

      <div className={FILTER_BLOCK_CONTAINER_CLASS_NAMES}>
        <button
          type="button"
          className={twMerge(
            FILTER_HEADER_BUTTON_CLASS_NAMES,
            open ? 'rounded-t-md' : 'rounded-md',
            companyLoading && 'bg-tz-gray-300 pointer-events-none',
            unapplied
              ? 'bg-tz-yellow-100 hover:bg-tz-yellow-200 dark:border-tz-yellow-500/20 dark:bg-tz-yellow-500/10 dark:border'
              : 'bg-tz-gray-200 hover:bg-tz-gray-300 dark:bg-tz-gray-800 dark:hover:bg-tz-gray-700'
          )}
          onClick={handleToggleOpenFilterBody}
        >
          <FilterHeader
            attributeName={attribute?.name || ''}
            operatorName={operatorName || ''}
            filterState={filterState()}
            valueName={eventualValueName()}
          />

          {!companyLoading && (
            <FontAwesomeIcon
              className={twMerge(FILTER_REMOVE_BUTTON_CLASS_NAMES, unapplied && 'dark:text-tz-yellow-100')}
              icon="xmark"
              height={20}
              onClick={onDeleteFilter}
            />
          )}
        </button>

        {open && (
          <div className={FILTER_BODY_CLASS_NAMES}>
            <SingleSelect
              disabled={false}
              mode="controlled"
              name={`attribute_${index}`}
              value={selectedAttributeId}
              items={attributeItems}
              onChange={handleAttributeChange}
            />
            <SingleSelect
              disabled={false}
              mode="controlled"
              name={`operator_${index}`}
              value={selectedOperatorId}
              items={operatorItems}
              onChange={handleOperatorChange}
            />
            <ValueControl
              attribute={attributes.find(a => a.value === selectedAttributeId)}
              value={value}
              valueName={companyName || valueName}
              index={index}
              possibleValues={possibleValues}
              onKeyDown={handleKeydown}
              onValueChange={handleValueChange}
            />

            {error && <TextExtraSmall className={ERROR_MESSAGE_CLASS_NAMES}>{error}</TextExtraSmall>}

            <Button label="Apply" size="small" onClick={applyFilter} />
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterBlock;

import type { FC } from 'react';

import CalendarRow from './CalendarRow';

import { selectVisibleUserIds } from '../../../Reducers/calendarReducer';
import { useAppSelector } from '../../../hooks';

const BASE_VISIBLE_USERS = 5;

interface Props {
  containerHeight: number;
}

const CalendarArea: FC<Props> = ({ containerHeight }) => {
  const visibleUserIds = useAppSelector(selectVisibleUserIds);

  const rowsToDivide = visibleUserIds.length > BASE_VISIBLE_USERS ? BASE_VISIBLE_USERS : visibleUserIds.length;

  return (
    <>
      {visibleUserIds.map((userId, i) => (
        <CalendarRow
          key={`row-${i}`}
          minimumRowHeight={containerHeight / rowsToDivide}
          lastRow={i === visibleUserIds.length - 1}
          userId={userId}
        />
      ))}
    </>
  );
};

export default CalendarArea;

import { useMemo, type FC } from 'react';

import Avatar from 'design_system/Components/Avatar';
import Badge from 'design_system/Components/Badge';
import H6 from 'design_system/Typography/Headings/H6';
import StackedListItem from 'design_system/Components/StackedList/Item';
import MenuLink from 'design_system/Components/ActionMenu/MenuLink';
import type { User } from '@graphql/generated';

interface Props {
  user: User;
}

const Agent: FC<Props> = ({ user }) => {
  const actionMenuItems = useMemo(() => {
    return user.schedulable ? (
      <MenuLink href={`/agents/${user.id}`}>Unassign License</MenuLink>
    ) : (
      <>
        <MenuLink href={`/agents/${user.id}`}>Assign License</MenuLink>
        <MenuLink href={`/agents/${user.id}`}>Edit</MenuLink>
        <MenuLink href={`/agents/${user.id}`}>Delete</MenuLink>
      </>
    );
  }, [user.id, user.schedulable]);

  return (
    <StackedListItem
      orientation="horizontal"
      className="space-x-3 py-3"
      url={`/agents/${user.id}`}
      actionMenuItems={actionMenuItems}
    >
      <Avatar size="md" name={user.name} url={user.avatarUrl || undefined} />
      <div className="w-2/4">
        <div className="mb-px w-max">
          <H6 className="mb-0">{user.name}</H6>
          <span className="bg-tz-gray-500 dark:bg-tz-gray-400 block h-px max-w-0 transition-all duration-300 group-hover:max-w-full" />
        </div>
        <div className="text-tz-gray-500 mt-0.5 text-xs">
          {user.jobTitle && <>{user.jobTitle}&middot;</>}
          {user.email}
        </div>
      </div>
      <div className="text-tz-gray-500 w-1/4 pt-3 text-sm">
        {user.role === 'administrator' ? 'Administrator' : 'User'}
      </div>
      <div className="w-1/4">
        {user.schedulable && (
          <Badge color="blue" className="mt-2 inline-block">
            License Assigned
          </Badge>
        )}
      </div>
    </StackedListItem>
  );
};

export default Agent;

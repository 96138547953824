import type { FC } from 'react';

import type { LoginActivityModel } from '@models/User';
import StackedList from 'design_system/Components/StackedList';
import StackedListItem from 'design_system/Components/StackedList/Item';
import H6 from 'design_system/Typography/Headings/H6';
import Badge from 'design_system/Components/Badge';
import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';

interface Props {
  loginActivities: LoginActivityModel[];
}

const LoginsTab: FC<Props> = ({ loginActivities }) => {
  return (
    <div id="logins" className="hidden pt-8" role="tabpanel">
      <StackedList>
        <>
          <StackedListItem orientation="horizontal" tableColumns="grid-cols-5">
            <>
              <H6 className="mb-1">Status</H6>
              <div className="col-span-2">
                <H6 className="mb-1">Date/Time</H6>
              </div>
              <H6 className="mb-1">Location</H6>
              <H6 className="mb-1">IP Address</H6>
            </>
          </StackedListItem>
          {loginActivities.map((item, i) => (
            <StackedListItem
              key={`table-stacked-list-${i}`}
              orientation="horizontal"
              tableColumns="grid-cols-5"
              className="py-2"
            >
              <>
                {item.succeeded ? (
                  <Badge className="w-max text-xs" color="green">
                    Successful
                  </Badge>
                ) : (
                  <Badge className="w-max text-xs" color="red">
                    Failed
                  </Badge>
                )}
                <div className="col-span-2">
                  <TextSmall>{new Date(item.date).toLocaleString()}</TextSmall>
                </div>
                <TextSmall>{item.location}</TextSmall>
                <TextSmall>{item.ipAddress}</TextSmall>
              </>
            </StackedListItem>
          ))}
        </>
      </StackedList>
    </div>
  );
};

export default LoginsTab;

import type { FC } from 'react';

import TextInput from 'design_system/Components/TextInput';
import SingleSelect from 'design_system/Components/SingleSelect';

import type { ValueControlProps } from '.';

const StandardValueControl: FC<ValueControlProps> = ({
  attribute,
  possibleValues,
  value,
  index,
  onKeyDown,
  onValueChange,
}) => {
  if (!attribute) return;

  const possibleSelectItems = possibleValues[attribute.value].values.map(v => {
    return { id: v.value, name: v.name };
  });

  return (
    <>
      {possibleSelectItems.length < 1 ? (
        <TextInput
          name={`filter_value_text_${index}`}
          id={`filter_value_text_${index}`}
          mode="controlled"
          value={value}
          onChange={value => onValueChange(value)}
          onKeyDown={onKeyDown}
        />
      ) : (
        <SingleSelect
          disabled={false}
          mode="controlled"
          name={`filter_value_option_${index}`}
          value={value}
          items={possibleSelectItems}
          onChange={value => onValueChange(value || '', possibleSelectItems.find(i => i.id === value)?.name)}
        />
      )}
    </>
  );
};

export default StandardValueControl;

import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../Store';
import type { UIAction } from '../actions';

import clearInsertionPoint from './clearInsertionPoint';
import iterateLayerSelection from './iterateLayerSelection';
import selectElement from './selectElement';
import setDraggingElementId from './setDraggingElement';
import setInsertionPoint from './setInsertionPoint';

export interface UIState {
  selectedElementId: string | null;
  initialSelectedElementId: string | null;
  draggingElement: boolean;
  insertionPoint: { parentId: string; insertionIndex: number } | null;
}

const INITIAL_STATE: UIState = {
  selectedElementId: null,
  initialSelectedElementId: null,
  draggingElement: false,
  insertionPoint: null,
};

const uiReducer: (state: UIState, action: UIAction) => UIState = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'CLEAR_INSERTION_POINT':
      return clearInsertionPoint(state);
    case 'SELECT_ELEMENT':
      return selectElement(state, action.payload.selectedElementId);
    case 'SET_INSERTION_POINT':
      return setInsertionPoint(state, action.payload.parentId, action.payload.insertionIndex);
    case 'SET_DRAGGING_ELEMENT':
      return setDraggingElementId(state, action.payload.draggingElement);
    case 'ITERATE_LAYER_SELECTION':
      return iterateLayerSelection(
        state,
        action.payload.selectedElementId,
        action.payload.initialSelectedElementId,
        action.payload.index
      );
  }

  return state;
};

export default uiReducer;

export const selectDraggingElement = (state: RootState) => state.ui.draggingElement;

const selectCurrentSelectedElementId = (state: RootState) => state.ui.selectedElementId;
const selectInitialSelectedElementId = (state: RootState) => state.ui.initialSelectedElementId;
export const selectSelectedElementId = createSelector(
  [selectCurrentSelectedElementId, selectInitialSelectedElementId],
  (selectedElementId, initialSelectedElementId) => {
    return { selectedElementId, initialSelectedElementId };
  }
);

import type { FC } from 'react';
import { Suspense } from 'react';

import Tags from './Tags';
import Title from './Title';
import ConnectWisePhoneAndAddress from './ConnectWise/PhoneAndAddress';
import AutotaskPhoneAndAddress from './Autotask/PhoneAndAddress';
import HaloPsaPhoneAndAddress from './HaloPsa/PhoneAndAddress';

import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';
import { selectSelectedCalendarEntry } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/calendarReducer';
import { useAppSelector } from 'admin_ui/screens/NewUi/DispatchHQ/hooks';
import Divider from 'design_system/Components/FormDivider';
import LoadingSpinner from 'design_system/Components/LoadingSpinner';
import { psaType } from '@shared/utilities';

import DescriptionList from '../../DescriptionList';
import Description from '../../DescriptionList/Description';

const ContactInformation: FC = () => {
  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);
  const integration = psaType(selectedCalendarEntry?.psaType || '');

  return (
    <Suspense
      fallback={
        <div className="mt-6">
          <LoadingSpinner size="medium" />
        </div>
      }
    >
      {selectedCalendarEntry && (
        <>
          <Title />
          <Tags />
          <Divider className="my-6" />
          <DescriptionList>
            <Description>
              <TextSmall>
                {selectedCalendarEntry.endUserNames[0]} at {selectedCalendarEntry.psaCompanyName}
              </TextSmall>
            </Description>
            {integration === 'connect_wise' && <ConnectWisePhoneAndAddress />}
            {integration === 'autotask' && <AutotaskPhoneAndAddress />}
            {integration === 'halo_psa' && <HaloPsaPhoneAndAddress />}
            <Description>
              <TextSmall>{selectedCalendarEntry.endUserEmails[0]}</TextSmall>
            </Description>
          </DescriptionList>
        </>
      )}
    </Suspense>
  );
};

export default ContactInformation;

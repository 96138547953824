import type { FC } from 'react';

import { twMerge } from 'tailwind-merge';

import { tw } from '@/utilities';

interface Props {
  className?: string;
  disabled?: boolean;
  label: string | JSX.Element;
  secondary?: boolean;
  size: 'small' | 'medium' | 'large';
  submit?: boolean;
  url?: string;
  dataMethod?: 'GET' | 'PATCH' | 'POST' | 'DELETE';
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const BUTTON_SIZES = {
  small: 'px-2.5 py-1.5',
  medium: 'px-3 py-2',
  large: 'px-3.5 py-2.5',
};

const Button: FC<Props> = ({ className, dataMethod, disabled, label, secondary, size, submit, url, onClick }) => {
  const BUTTON_CLASS_NAME = secondary
    ? tw`text-tz-gray-800 ring-tz-gray-700/20 hover:bg-tz-gray-100 hover:text-tz-gray-800 dark:bg-tz-gray-700 dark:text-tz-gray-300 dark:hover:bg-tz-gray-600 dark:hover:text-tz-gray-200 bg-white ring-1 ring-inset dark:ring-0`
    : tw`bg-tz-green-600 text-tz-gray-100 hover:bg-tz-green-700 hover:text-tz-gray-200`;

  const buttonClassName = twMerge(
    'h-max w-max rounded-md text-xs font-semibold outline-none transition duration-150 ease-in-out',
    disabled
      ? 'bg-tz-gray-100 dark:bg-tz-gray-700 text-tz-gray-300 dark:text-tz-gray-500 cursor-not-allowed ring-0'
      : BUTTON_CLASS_NAME,
    BUTTON_SIZES[size],
    className
  );

  if (url) {
    return (
      <a href={url} className={buttonClassName} data-method={dataMethod}>
        {label}
      </a>
    );
  }

  return (
    <button type={submit ? 'submit' : 'button'} className={buttonClassName} onClick={onClick}>
      {label}
    </button>
  );
};

export default Button;

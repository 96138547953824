import type {
  Action,
  HubSpotCreateContactAction,
  HubSpotCreateMeetingAction,
} from '@graphql/generated';

export function isHubSpotCreateContactAction(action: Action): action is HubSpotCreateContactAction {
  return action.slug === 'hub_spot/create_contact';
}

export function isHubSpotCreateMeetingAction(action: Action): action is HubSpotCreateMeetingAction {
  return action.slug === 'hub_spot/create_meeting';
}

import type { FC } from 'react';

import HelpPopup from 'design_system/Components/HelpPopup';

const ExternalEmailHelp: FC = () => {
  return (
    <HelpPopup position="below">
      <p className="mb-2 text-sm font-normal">
        When TimeZest sends calendar invitations to end users, it needs to include an email address for the organizer of
        the meeting, which will be visible in the end user&apos;s calendar.
      </p>
      <p className="mb-2 text-sm font-normal">
        TimeZest can use the individual email address of the agent, and that will receive any accepted / declined
        notifications from the end user.
      </p>
      <p className="text-sm font-normal">
        Alternatively, TimeZest can use a single email address, such as your main support email, and that will receive
        any accepted / declined notifications.
      </p>
    </HelpPopup>
  );
};

export default ExternalEmailHelp;

import type { FC } from 'react';

import Flash from '@/Flash';
import asScreen from '@/Screen';

import FormDivider from 'design_system/Components/FormDivider';
import H2 from 'design_system/Typography/Headings/H2';
import MainContainer from 'design_system/Components/MainContainer';
import Navigation from 'design_system/Components/Navigation';
import SideBarMenu from 'design_system/Components/SidebarMenu';

interface Props {
  apiKeys: string;
}

const ApiKeysIndex: FC<Props> = _props => {
  return (
    <>
      <Navigation activeItem="settings" />
      <SideBarMenu activeItem="api_keys" />
      <MainContainer>
        <H2>API Keys</H2>
        <Flash />
        <FormDivider />
      </MainContainer>
    </>
  );
};

export default asScreen(ApiKeysIndex);

import type { FC } from 'react';
import { useState } from 'react';

import {
  useGetAutotaskCompaniesQuery,
  useGetHaloPsaCompaniesQuery,
  useGetConnectWiseCompaniesQuery,
} from '@graphql/generated';
import SingleSelect from 'design_system/Components/SingleSelect';

interface Props {
  value: string;
  selectedAttributeId: string;
  onValueChange?: (id: string, name: string) => void;
}

const CompanySearch: FC<Props> = ({ value, selectedAttributeId, onValueChange }) => {
  const [query, setQuery] = useState('');

  const { data: connectwiseCompaniesData, loading: connectwiseCompaniesLoading } = useGetConnectWiseCompaniesQuery({
    skip: !selectedAttributeId.includes('connectwise_psa_company_id'),
    variables: { query },
  });

  const { data: autotaskCompaniesData, loading: autotaskCompaniesLoading } = useGetAutotaskCompaniesQuery({
    skip: !selectedAttributeId.includes('autotask_company_id'),
    variables: { query },
  });

  const { data: haloPsaCompaniesData, loading: haloPsaCompaniesLoading } = useGetHaloPsaCompaniesQuery({
    skip: !selectedAttributeId.includes('halo_psa_company_id'),
    variables: { query },
  });

  const companies = (
    autotaskCompaniesData?.['autotask'] ||
    connectwiseCompaniesData?.['connectWise'] ||
    haloPsaCompaniesData?.['haloPsa']
  )?.companies;

  const formattedCompanies = (companies || []).map(company => {
    return {
      id: company.id.toString(),
      name: company.name,
    };
  });

  const companiesLoading = connectwiseCompaniesLoading || autotaskCompaniesLoading || haloPsaCompaniesLoading;

  const handleSelect = (value: string): void => {
    if (onValueChange) onValueChange(value, formattedCompanies.find(company => company.id === value)?.name || '');
  };

  const handleSearchChange = (value: string): void => {
    setQuery(value);
  };

  return (
    <SingleSelect
      mode="controlled"
      value={value}
      name="company-selector"
      items={formattedCompanies}
      search={true}
      disabled={false}
      loading={companiesLoading}
      noEntriesFoundMessage="No companies found."
      onChange={handleSelect}
      onSearchChange={handleSearchChange}
    />
  );
};

export default CompanySearch;

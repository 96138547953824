import type { FC } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import DaysRow from './DaysRow';
import CalendarContainer from './CalendarContainer';

import { useAppDispatch, useAppSelector } from '../../hooks';
import ZoomEngine from '../../Tools/ZoomEngine';
import PanEngine from '../../Tools/PanEngine';
import { setCellWidth } from '../../actions';
import { selectZoom } from '../../Reducers/uiReducer';

const BORDER_WIDTH = 1;
const PLACEHOLDER_CELL_WIDTH = 114;
const DAYS_IN_A_WEEK = 7;
const SCROLL_BAR_WIDTH = 6;

const LayoutEngine: FC = () => {
  const dispatch = useAppDispatch();

  const daysRowRef = useRef<HTMLDivElement>(null);
  const calendarContainerRef = useRef<HTMLDivElement>(null);

  const [calendarContainer, setCalendarContainer] = useState<HTMLDivElement | null>(null);
  const [daysRow, setDaysRow] = useState<HTMLDivElement | null>(null);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const [containerOverflown, setContainerOverflown] = useState(false);

  const zoom = useAppSelector(selectZoom);

  const isZooming = zoom > 1;

  const checkContainerOverflown = useCallback(() => {
    if (!calendarContainer) return;

    const { scrollWidth, scrollHeight, clientWidth, clientHeight } = calendarContainer;
    setContainerOverflown(scrollWidth > clientWidth || scrollHeight > clientHeight);
  }, [calendarContainer]);

  const handleSetCellWidth = useCallback(() => {
    if (!calendarContainer) return;

    dispatch(
      setCellWidth({
        cellWidth: (calendarContainer.offsetWidth - PLACEHOLDER_CELL_WIDTH - SCROLL_BAR_WIDTH) / DAYS_IN_A_WEEK,
      })
    );
  }, [dispatch, calendarContainer]);

  const handleSetContainerHeight = useCallback(() => {
    if (!calendarContainer) return;

    setContainerHeight(calendarContainer.offsetHeight - BORDER_WIDTH);
  }, [calendarContainer]);

  useEffect(() => {
    setCalendarContainer(calendarContainerRef.current);
    setDaysRow(daysRowRef.current);
  }, []);

  useEffect(() => {
    if (!calendarContainer) return;

    // Observe container overflow status
    const resizeObserver = new ResizeObserver(() => {
      checkContainerOverflown();
    });

    resizeObserver.observe(calendarContainer);

    // Observe browser window resize
    window.addEventListener('resize', () => {
      handleSetCellWidth();
      handleSetContainerHeight();
    });

    // Initial runs of handlers
    checkContainerOverflown();
    handleSetCellWidth();
    handleSetContainerHeight();

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleSetCellWidth);
      resizeObserver.unobserve(calendarContainer);
    };
  }, [calendarContainer, checkContainerOverflown, handleSetCellWidth, handleSetContainerHeight]);

  return (
    <ZoomEngine calendarHeader={daysRow} calendarContainer={calendarContainer}>
      <PanEngine calendarHeader={daysRow} calendarContainer={calendarContainer} isZooming={isZooming}>
        <DaysRow ref={daysRowRef} containerOverflown={containerOverflown} />
        <CalendarContainer ref={calendarContainerRef} containerHeight={containerHeight} />
      </PanEngine>
    </ZoomEngine>
  );
};

export default LayoutEngine;

import type { FC } from 'react';

import SidebarMenuItem from './SidebarMenuItem';

import H2 from 'design_system/Typography/Headings/H2';

type MenuItem = 'account' | 'api_keys' | 'branding' | 'custom_attributes' | 'email' | 'email_templates' | 'security';

interface Props {
  activeItem: MenuItem;
}

const SidebarMenu: FC<Props> = ({ activeItem }) => {
  return (
    <nav className="border-tz-gray-300/20 dark:bg-tz-gray-900 dark:border-tz-gray-800/50 h-full w-64 border-r bg-white px-6 py-3 leading-4 [grid-area:sidebar]">
      <H2 className="px-2">Settings</H2>
      <ul className="flex flex-col gap-y-2">
        <SidebarMenuItem text="Account & Billing" active={activeItem === 'account'} href="/settings/account" />
        <SidebarMenuItem text="API Keys" active={activeItem === 'api_keys'} href="/settings/api_keys" />
        <SidebarMenuItem text="Branding" active={activeItem === 'branding'} href="/settings/branding" />
        <SidebarMenuItem
          text="Custom Attributes"
          active={activeItem === 'custom_attributes'}
          href="/settings/custom_attributes"
        />
        <SidebarMenuItem text="Email Settings" active={activeItem === 'email'} href="/settings/email" />
        <SidebarMenuItem
          text="Email Templates"
          active={activeItem === 'email_templates'}
          href="/settings/email_templates"
        />
        <SidebarMenuItem text="Security & Permissions" active={activeItem === 'security'} href="/settings/security" />
      </ul>
    </nav>
  );
};

export default SidebarMenu;

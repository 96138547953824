import type { FC } from 'react';

import Alert from 'design_system/Components/Alert';

const CancellationErrorMessage: FC = () => {
  return (
    <Alert
      className="mb-6"
      icon="circle-xmark"
      color="red"
      title="TimeZest was unable to cancel this appointment, please try again later."
    />
  );
};

export default CancellationErrorMessage;

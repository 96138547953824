import type { FC } from 'react';

import classNames from 'classnames';

import { tw } from '@/utilities';

import TextInput from '../TextInput';

import type { InputProps } from 'design_system/shared/InputProps';

const DEFAULT_CLASSNAMES = tw`border-tz-gray-200 bg-tz-gray-100 text-tz-gray-800 dark:border-tz-gray-600 dark:text-tz-gray-300 inline-flex h-8 items-center border text-sm`;

interface InputGroupProps extends InputProps<HTMLInputElement> {
  attachment: string | JSX.Element;
  attachmentClassName?: string;
  placement: 'start' | 'end';
}

interface ControlledProps extends InputGroupProps {
  mode: 'controlled';
  value: string;
  onChange: (value: string) => void;
}

interface UncontrolledProps extends InputGroupProps {
  mode: 'uncontrolled';
  initialValue: string;
}

type Props = ControlledProps | UncontrolledProps;

const InputGroup: FC<Props> = props => {
  const { attachment, placement, disabled, attachmentClassName, ...inputProps } = props;

  const isPrefix = placement === 'start';

  const roundedBorderClass = isPrefix ? 'ring-s-transparent rounded-s-none' : 'ring-e-transparent rounded-e-none';

  return (
    <div className={classNames('flex', isPrefix ? 'flex-row' : 'flex-row-reverse')}>
      <div
        className={classNames(
          DEFAULT_CLASSNAMES,
          isPrefix ? 'rounded-e-0 rounded-s-md border-e-0' : 'rounded-s-0 rounded-e-md border-s-0',
          disabled ? 'dark:bg-tz-gray-800 border-0' : 'dark:bg-tz-gray-900',
          !!props.error && 'border-red-300 dark:border-red-900',
          attachmentClassName
        )}
      >
        {attachment}
      </div>
      <div className="w-full">
        <TextInput
          className={classNames(roundedBorderClass, inputProps.className)}
          disabled={disabled}
          {...inputProps}
        />
      </div>
    </div>
  );
};

export default InputGroup;

import type { FC, PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

import { tw } from '@/utilities';
interface Props {
  className?: string;
}

const BASE_H3_CLASS = tw`mb-2 text-xl font-bold tracking-tight text-tz-blue-950 dark:text-tz-gray-200`;

const H3: FC<PropsWithChildren<Props>> = ({ children, className }) => {
  return <h3 className={twMerge(BASE_H3_CLASS, className)}>{children}</h3>;
};

export default H3;

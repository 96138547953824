import type { FC } from 'react';

import { Table } from 'react-bootstrap';

import AllSetting from './AllSetting';
import NoneSetting from './NoneSetting';
import Permission from './Permission';
import Section from './Section';

const PermissionsMatrix: FC<{}> = () => {
  return (
    <Table className="w-100">
      <thead>
        <tr>
          <th className="bg-transparent">&nbsp;</th>
          <th className="bg-transparent text-center">Add Level</th>
          <th className="bg-transparent text-center">Edit Level</th>
          <th className="bg-transparent text-center">Delete Level</th>
          <th className="bg-transparent text-center">Inquire Level</th>
        </tr>
      </thead>
      <tbody>
        <Section name="Companies">
          <Permission name="Company Mantainance">
            <AllSetting>
              TimeZest requires the <strong>add</strong> permission for <strong>Company Maintenence</strong> so that it
              can create companies that don&apos;t already exist for clients who schedule an appointment using a
              shareable URL.
            </AllSetting>
            <NoneSetting />
            <NoneSetting />
            <AllSetting>
              TimeZest requires the <strong>inquire</strong> permission for <strong>Company Maintenence</strong> so that
              it can inquire about existing companies, to determine if it needs to create a new company record when a
              client schedules using a shareable URL.
            </AllSetting>
          </Permission>
          <Permission name="Manage Attachments">
            <NoneSetting />
            <NoneSetting />
            <NoneSetting />
            <AllSetting>
              TimeZest requires the <strong>inquire</strong> permission for <strong>Manage Attachments</strong> so that
              it can download user photos from ConnectWise PSA to display them in the TimeZest user interface.
            </AllSetting>
          </Permission>
          <Permission name="Contacts">
            <AllSetting>
              TimeZest requires the <strong>add</strong> permission for <strong>Contacts</strong> so that it can create
              new contact records for clients who schedule an appointment using a shareable URL, and who don&apos;t
              already exist in ConnectWise PSA.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>edit</strong> permission for <strong>Contacts</strong> so that it can update
              the phone numbers of contacts with the phone number provided by clients, when it is configured to do so.
            </AllSetting>
            <NoneSetting />
            <AllSetting>
              TimeZest requires the <strong>inquire</strong> permission for <strong>Contacts</strong> so that it can
              inquire about existing contacts, to determine if it needs to create a new contact record when a client
              schedules using a shareable URL.
            </AllSetting>
          </Permission>
          <Permission name="CRM/Sales Activities">
            <AllSetting>
              TimeZest requires the <strong>add</strong> permission for <strong>CRM/Sales Activities</strong> so that it
              can create activities in ConnectWise PSA when an appointment type is configured to create them instead of
              ticket-associated schedule entries.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>edit</strong> permission for <strong>CRM/Sales Activities</strong> so that
              it can update activities in ConnectWise PSA when a client reschedules an appointment they&apos;ve already
              booked.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>delete</strong> permission for <strong>CRM/Sales Activities</strong> so that
              it can remove activities in ConnectWise PSA when a client or technician cancels an appointment
              they&apos;ve already booked.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>inquire</strong> permission for <strong>CRM/Sales Activities</strong> so
              that it can retrieve details of existing appointments when determining appointment slots to offer a
              client.
            </AllSetting>
          </Permission>
        </Section>
        <Section name="Marketing">
          <Permission name="Marketing Management">
            <NoneSetting />
            <NoneSetting />
            <NoneSetting />
            <AllSetting>
              TimeZest requires the <strong>inquire</strong> permission for <strong>Marketing Management</strong> so
              that it can retrieve details of marketing campaigns to be associated with new opportunity when a client
              uses a shareable URL to book an appointment.
            </AllSetting>
          </Permission>
        </Section>
        <Section name="Project">
          <Permission name="Close Project Tickets">
            <AllSetting>
              TimeZest requires the <strong>add</strong> permission for <strong>Close Project Tickets</strong> so that
              it can move project tickets into a closed status if configured to do so &mdash; for example, if a client
              hasn&apos;t scheduled an appointment after being asked to do so.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>edit</strong> permission for <strong>Close Project Tickets</strong> so that
              it can change project tickets into a closed status if configured to do so.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>delete</strong> permission for <strong>Close Project Tickets</strong> so
              that it can reopen project tickets into a non-closed status if configured to do so.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>inquire</strong> permission for <strong>Close Project Tickets</strong>{' '}
              because ConnectWise PSA requires the <strong>inquire</strong> permission to be granted when any of the
              other <strong>add</strong>, <strong>edit</strong> or <strong>delete</strong> permisssions are granted.
            </AllSetting>
          </Permission>
          <Permission name="Project Headers">
            <NoneSetting />
            <NoneSetting />
            <NoneSetting />
            <AllSetting>
              TimeZest requires the <strong>inquire</strong> permission for <strong>Project Headers</strong> because
              this permission is required to retrieve the members of a project team, which TimeZest needs to know if it
              has to schedule appointments with a project team.
            </AllSetting>
          </Permission>
          <Permission name="Project Scheduling">
            <AllSetting>
              TimeZest requires the <strong>add</strong> permission for <strong>Project Scheduling</strong> so that it
              can create schedule entries associated with project tickets when a client books an appointment.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>edit</strong> permission for <strong>Project Scheduling</strong> so that it
              can update schedule entries associated with project tickets when a client reschedules a previously-booked
              appointment.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>delete</strong> permission for <strong>Project Scheduling</strong> so that
              it can remove schedule entries associated with project tickets when a client or technician cancels an
              appointment they&apos;ve already booked.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>inquire</strong> permission for <strong>Project Scheduling</strong> so that
              it can retrieve details of existing appointments when determining appointment slots to offer a client.
            </AllSetting>
          </Permission>
          <Permission name="Project Teams">
            <NoneSetting />
            <NoneSetting />
            <NoneSetting />
            <AllSetting>
              TimeZest requires the <strong>inquire</strong> permission for <strong>Project Teams</strong> so that it
              can retrieve details of project teams in ConnectWise PSA in order that appointments can be scheduled with
              them.
            </AllSetting>
          </Permission>
          <Permission name="Project Ticket - Dependencies">
            <AllSetting>
              TimeZest requires the <strong>delete</strong> permission for{' '}
              <strong>Project Ticket - Dependencies</strong> because granting this permission works around a bug in
              ConnectWise PSA which prevents status updates of project tickets in certain circumstances.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>delete</strong> permission for{' '}
              <strong>Project Ticket - Dependencies</strong> because granting this permission works around a bug in
              ConnectWise PSA which prevents status updates of project tickets in certain circumstances.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>delete</strong> permission for{' '}
              <strong>Project Ticket - Dependencies</strong> because granting this permission works around a bug in
              ConnectWise PSA which prevents status updates of project tickets in certain circumstances.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>inquire</strong> permission for{' '}
              <strong>Project Ticket - Dependencies</strong> because ConnectWise PSA requires the{' '}
              <strong>inquire</strong> permission to be granted when any of the other <strong>add</strong>,{' '}
              <strong>edit</strong> or <strong>delete</strong> permisssions are granted.
            </AllSetting>
          </Permission>
          <Permission name="Project Tickets">
            <NoneSetting />
            <AllSetting>
              TimeZest requires the <strong>edit</strong> permission for <strong>Project Tickets</strong> so that it can
              write notes to tickets and update project tickets and statuses in response to client actions like
              scheduling an appointment.
            </AllSetting>
            <NoneSetting />
            <AllSetting>
              TimeZest requires the <strong>inquire</strong> permission for <strong>Project Tickets</strong> so that it
              can retrieve details of project tickets that it&apos;s scheduling appointments for.
            </AllSetting>
          </Permission>
        </Section>
        <Section name="Sales">
          <Permission name="Opportunity">
            <AllSetting>
              TimeZest requires the <strong>add</strong> permission for <strong>Opportunity</strong> so that it can
              create new opportunities when a client uses a shareable URL to book an appointment.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>edit</strong> permission for <strong>Opportunity</strong> so that it can
              update opportunities in response to client actions like scheduling an appointment.
            </AllSetting>
            <NoneSetting />
            <AllSetting>
              TimeZest requires the <strong>inquire</strong> permission for <strong>Opportunity</strong> so that it can
              retrieve details of opportunities that it&apos;s scheduling appointments for.
            </AllSetting>
          </Permission>
        </Section>
        <Section name="Service Desk">
          <Permission name="Close Service Tickets">
            <AllSetting>
              TimeZest requires the <strong>add</strong> permission for <strong>Close Service Tickets</strong> so that
              it can move service tickets into a closed status if configured to do so &mdash; for example, if a client
              hasn&apos;t scheduled an appointment after being asked to do so.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>edit</strong> permission for <strong>Close Service Tickets</strong> so that
              it can change service tickets into a closed status if configured to do so.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>delete</strong> permission for <strong>Close Service Tickets</strong> so
              that it can reopen service tickets into a non-closed status if configured to do so.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>inquire</strong> permission for <strong>Close Service Tickets</strong>{' '}
              because ConnectWise PSA requires the <strong>inquire</strong> permission to be granted when any of the
              other <strong>add</strong>, <strong>edit</strong> or <strong>delete</strong> permisssions are granted.
            </AllSetting>
          </Permission>
          <Permission name="Resource Scheduling">
            <AllSetting>
              TimeZest requires the <strong>add</strong> permission for <strong>Resource Scheduling</strong> so that it
              can create schedule entries associated with service tickets when a client books an appointment.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>edit</strong> permission for <strong>Resource Scheduling</strong> so that it
              can update schedule entries associated with service tickets when a client reschedules a previously-booked
              appointment.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>delete</strong> permission for <strong>Resource Scheduling</strong> so that
              it can remove schedule entries associated with service tickets when a client or technician cancels an
              appointment they&apos;ve already booked.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>inquire</strong> permission for <strong>Resource Scheduling</strong> so that
              it can retrieve details of existing appointments when determining appointment slots to offer a client.
            </AllSetting>
          </Permission>
          <Permission name="Service Ticket - Dependencies">
            <AllSetting>
              TimeZest requires the <strong>delete</strong> permission for{' '}
              <strong>Service Ticket - Dependencies</strong> because granting this permission works around a bug in
              ConnectWise PSA which prevents status updates of service tickets in certain circumstances.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>delete</strong> permission for{' '}
              <strong>Service Ticket - Dependencies</strong> because granting this permission works around a bug in
              ConnectWise PSA which prevents status updates of service tickets in certain circumstances.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>delete</strong> permission for{' '}
              <strong>Service Ticket - Dependencies</strong> because granting this permission works around a bug in
              ConnectWise PSA which prevents status updates of service tickets in certain circumstances.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>inquire</strong> permission for{' '}
              <strong>Service Ticket - Dependencies</strong> because ConnectWise PSA requires the{' '}
              <strong>inquire</strong> permission to be granted when any of the other <strong>add</strong>,{' '}
              <strong>edit</strong> or <strong>delete</strong> permisssions are granted.
            </AllSetting>
          </Permission>
          <Permission name="Service Tickets">
            <AllSetting>
              TimeZest requires the <strong>add</strong> permission for <strong>Project Tickets</strong> so that it can
              create new service tickets when a client uses a shareable URL to book an appointment.
            </AllSetting>
            <AllSetting>
              TimeZest requires the <strong>edit</strong> permission for <strong>Project Tickets</strong> so that it can
              write notes to tickets and update project tickets and statuses in response to client actions like
              scheduling an appointment.
            </AllSetting>
            <NoneSetting />
            <AllSetting>
              TimeZest requires the <strong>inquire</strong> permission for <strong>Project Tickets</strong> so that it
              can retrieve details of project tickets that it&apos;s scheduling appointments for.
            </AllSetting>
          </Permission>
        </Section>
        <Section name="System">
          <Permission name="Member Maintenance">
            <NoneSetting />
            <NoneSetting />
            <NoneSetting />
            <AllSetting>
              TimeZest requires the <strong>inquire</strong> permission for <strong>Member Maintenance</strong> so that
              it can retrieve details of members for configuring their TimeZest accounts, and information needed to
              schedule them, such as their availability and time zone.
            </AllSetting>
          </Permission>
          <Permission name="My Company">
            <NoneSetting />
            <NoneSetting />
            <NoneSetting />
            <AllSetting>
              TimeZest requires the <strong>inquire</strong> permission for <strong>My Company</strong> so it can
              retrieve certain details of your company, such as default time zones for members who don&apos;t have one
              configured.
            </AllSetting>
          </Permission>
          <Permission name="Table Setup">
            <AllSetting>
              <p>
                TimeZest requires the <strong>add</strong> permission for <strong>Table Setup</strong> so that it can
                create a new entry in the <strong>Manage Hosted API</strong> setup table, which is required to install
                the TimeZest pod in ConnectWise PSA.
              </p>
              <p className="mb-0">
                You can remove this permission once the TimeZest pod has been installed if you wish.
              </p>
            </AllSetting>
            <NoneSetting />
            <NoneSetting />
            <AllSetting>
              TimeZest requires the <strong>inquire</strong> permission for <strong>Table Setup</strong> so that it can
              retrieve details of many things it needs to operate, such as lists of service boards, details of statuses,
              lists of service ticket priorities, sources and types, and details of calendars and configured holidays.
            </AllSetting>
          </Permission>
        </Section>
      </tbody>
    </Table>
  );
};

export default PermissionsMatrix;

import { type FC, useContext, useState } from 'react';

import Form from '@/Form';
import PlanContext from '@/PlanContext';
import RadioButtonRow from '@/ui/RadioButtonRow';
import Row from '@/ui/Row';
import AccountContext from '@/AccountContext';

import Select from '@shared/ui/Select';

interface Props {
  errors: Record<string, string>;
  userAvailabilityEditable: boolean;
  showTicketNamesUrlScheduling: boolean;
  signUpEmailDomains: string[];
  signUpEmailDomain: string;
}

const Settings: FC<Props> = ({
  errors,
  signUpEmailDomain,
  signUpEmailDomains,
  userAvailabilityEditable,
  showTicketNamesUrlScheduling,
}) => {
  const { enabledTimeStashCaching: initialEnabledTimeStashCaching } = useContext(AccountContext);
  const { allowsCustomAvailability, allowsUrlBasedScheduling } = useContext(PlanContext);

  const [allowCustomUserAvailability, setAllowCustomUserAvailability] = useState(userAvailabilityEditable);
  const [showTicketNumber, setShowTicketNumber] = useState(showTicketNamesUrlScheduling);
  const [selectedEmailDomain, setSelectedEmailDomain] = useState(signUpEmailDomain);
  const [enabledTimeStashCaching, setEnabledTimeStashCaching] = useState(initialEnabledTimeStashCaching);

  const handleAllowCustomUserAvailability = (value: string): void => {
    setAllowCustomUserAvailability(value === 'true');
  };

  const handleShowTicketNumber = (value: string): void => {
    setShowTicketNumber(value === 'true');
  };

  const handleAllowTimeStashCaching = (value: string): void => {
    setEnabledTimeStashCaching(value === 'true');
  };

  return (
    <Form url="/settings/security" method="patch">
      <>
        <RadioButtonRow
          label="Cache Calendar Appointments"
          helpText="These settings control whether TimeZest is allowed to cache existing appointments from your calendars to use as resources for forthcoming features."
          name="account[use_caching]"
          value={enabledTimeStashCaching.toString()}
          options={[
            { label: 'Allow TimeZest to cache existing appointments from your calendars', value: 'true' },
            {
              label: 'Don’t cache calendar appointments',
              value: 'false',
            },
          ]}
          onChange={handleAllowTimeStashCaching}
        />
        <div style={{ marginTop: '-15px' }}>
          <Row width={6}>
            <small className="Row__HelpText">
              If caching of calendar appointments is disabled, your account will not be able to access forthcoming
              features and your customers will see degraded performance when scheduling appointments.
            </small>
          </Row>
        </div>
      </>

      {allowsCustomAvailability && (
        <RadioButtonRow
          label="Custom User Availability"
          name="account[user_availability_editable]"
          helpText="These settings control whether non-administrator users can customize their availability and time zone."
          value={allowCustomUserAvailability.toString()}
          options={[
            { label: 'Allow users to customize their availability', value: 'true' },
            {
              label: 'Do not allow users to customize their availability',
              value: 'false',
            },
          ]}
          onChange={handleAllowCustomUserAvailability}
        />
      )}

      {allowsUrlBasedScheduling && (
        <RadioButtonRow
          label="Ticket Details Display"
          name="account[show_ticket_names_url_scheduling]"
          helpText={
            <>
              <div className="mb-1">
                As the ticket number is included in the URL for URL-based scheduling, it is possible for users to edit
                the URL, and see summaries for other tickets.
              </div>
              <div>
                Turning this off prevents this from happening, but can be confusing for end users who will only see a
                ticket number.
              </div>
            </>
          }
          value={showTicketNumber.toString()}
          options={[
            { label: 'Show ticket numbers and summaries for URL-based scheduling', value: 'true' },
            {
              label: 'Only show ticket numbers',
              value: 'false',
            },
          ]}
          onChange={handleShowTicketNumber}
        />
      )}

      <Row
        label="Email Domain for Auto Sign-up"
        helpText="Allow new users to sign up, with email confirmation, if their email address belongs to this domain."
      >
        <Select
          name="account[sign_up_email_domain]"
          value={selectedEmailDomain || ''}
          error={errors.signUpEmailDomain}
          options={[
            { name: 'Disabled', value: '' },
            ...signUpEmailDomains.map(domain => ({ value: domain, name: domain })),
          ]}
          onChange={e => setSelectedEmailDomain(e.target.value)}
        />
      </Row>

      <hr />

      <Row label="">
        <input className="btn btn-primary" type="submit" value="Save Changes" data-disable-with="Saving" />
      </Row>
    </Form>
  );
};

export default Settings;

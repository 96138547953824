import { useEffect, useState, type FC } from 'react';

import AppointmentType from './AppointmentType';

import { useGetAppointmentTypesTqlQuery } from '@graphql/generated';
import LoadingSpinner from 'design_system/Components/LoadingSpinner';
import StackedList from 'design_system/Components/StackedList';
import StackedListItem from 'design_system/Components/StackedList/Item';
import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';
import Alert from 'design_system/Components/Alert';
import Pagination from 'design_system/Components/Pagination';

interface Props {
  tqlString: string;
}

const PAGE_SIZE = 40;

const AppointmentTypes: FC<Props> = ({ tqlString }) => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setOffset(0);
  }, [tqlString]);

  const { data, error, loading } = useGetAppointmentTypesTqlQuery({
    variables: { filter: tqlString, offset: offset, limit: PAGE_SIZE },
  });

  if (loading) {
    return <LoadingSpinner size="large" />;
  }

  if (error) {
    return (
      <Alert
        color="red"
        icon="circle-xmark"
        content={<>TimeZest was unable to load the list of appointment types due to a server error.</>}
      />
    );
  }

  return (
    <>
      <StackedList>
        <StackedListItem
          orientation="horizontal"
          tableColumns="[grid-template-columns:1fr_12rem_12rem_12rem_12rem]"
          className="pb-2"
        >
          <TextSmall className="font-bold">Internal Name</TextSmall>
          <TextSmall className="font-bold">Duration</TextSmall>
          <TextSmall className="font-bold">Online Meeting</TextSmall>
          <TextSmall className="text-center font-bold">Buffer Before / After</TextSmall>
          <TextSmall className="text-center font-bold">Minimum Notice</TextSmall>
        </StackedListItem>
        {data?.timezest.appointmentTypes.nodes.map(appointmentType => (
          <AppointmentType key={`appointment-type-${appointmentType.id}`} appointmentType={appointmentType} />
        ))}
      </StackedList>
      <Pagination
        totalPages={Math.ceil((data?.timezest.appointmentTypes.total || 0) / PAGE_SIZE)}
        onPageChange={page => setOffset((page - 1) * PAGE_SIZE)}
      />
    </>
  );
};

export default AppointmentTypes;

import type { FC } from 'react';

import HelpPopup from 'design_system/Components/HelpPopup';

const TicketDetailsDisplayHelp: FC = () => {
  return (
    <HelpPopup position="below">
      <p className="mb-2 text-sm font-normal">
        When TimeZest&apos;s URL-based scheduling functionality is used, the ticket number of the associated ticket is
        included in the URL, and could be edited by end users to a different value.
      </p>
      <p className="mb-2 text-sm font-normal">
        Displaying the ticket summaries gives users the context of the ticket they&apos;re scheduling for, but it allows
        anyone to see the details of any other ticket by editing the URL.
      </p>
      <p className="text-sm font-normal">
        Hiding the ticket summary is more secure as only the ticket number is shown, but this provides less information
        to end users when they&apos;re scheduling their appointments.
      </p>
    </HelpPopup>
  );
};

export default TicketDetailsDisplayHelp;

import type { FC } from 'react';

import Expander from '@/ui/Expander';
import Alert from '@/Alert';

import TextAreaRow from '@ui/TextAreaRow';
import type AppointmentTypeModel from '@models/AppointmentType';
import type Errors from '@models/Errors';

export function messagesHasErrors(errors: Errors): boolean {
  return !!(errors.notification_message || errors.confirmation_message);
}

interface SummaryProps {
  confirmationMessage: string | null;
  notificationMessage: string | null;
}

const Summary: FC<SummaryProps> = ({ confirmationMessage, notificationMessage }) => {
  return (
    <>{`${notificationMessage ? 'Notification message configured' : 'No notification message set'}; ${
      confirmationMessage ? 'Confirmation message configured' : 'No confirmation message set'
    }.`}</>
  );
};

interface Props {
  url: string;
  appointmentType: AppointmentTypeModel;
  errors: Errors;
  expanded: boolean;
  canExpand: boolean;
  onExpand: (expanded: boolean) => void;
  dirty: boolean;
}

const MessagesConfig: FC<Props> = ({ appointmentType, canExpand, dirty, expanded, errors, url, onExpand }) => {
  return (
    <Expander
      title="Notification & Confirmation Messages"
      summary={
        <Summary
          notificationMessage={appointmentType.notificationMessage}
          confirmationMessage={appointmentType.confirmationMessage}
        />
      }
      url={url}
      method="PATCH"
      icon="email"
      hasErrors={messagesHasErrors(errors)}
      canExpand={canExpand}
      expanded={expanded}
      dirty={dirty}
      onExpand={onExpand}
    >
      <Alert
        icon="triangle-exclamation"
        color="yellow"
        className="mb-4"
        content="TimeZest will only support notification and confirmation messages until May 30, 2024. They should be
     replaced with an email template which includes the desired text, built using our new WYSIWYG email editor."
      />

      <TextAreaRow
        name="appointment_type[notification_message]"
        value={appointmentType.notificationMessage || ''}
        label="Notification Message"
        helpText={
          <>
            If present, this message will be included in the email we send to clients asking them to select an
            appointment &mdash; if the default template is used, or the{' '}
            <code>appointment_type.notification_message</code> is used in a custom template.
          </>
        }
      />
      <TextAreaRow
        name="appointment_type[confirmation_message]"
        value={appointmentType.confirmationMessage || ''}
        label="Confirmation Message"
        helpText={
          <>
            If present, this message will be included in the email we send to clients confirming the appointment
            they&apos;ve selected &mdash; if the default template is used, or the{' '}
            <code>appointment_type.confirmation_message</code> is used in a custom template.
          </>
        }
      />
    </Expander>
  );
};

export default MessagesConfig;

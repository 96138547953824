import type { FC } from 'react';
import { Suspense } from 'react';

import { useAppSelector } from '@/screens/NewUi/DispatchHQ/hooks';
import { selectSelectedCalendarEntry } from '@/screens/NewUi/DispatchHQ/Reducers/calendarReducer';

import ConnectWiseAppointmentDetails from './ConnectWise';
import AutotaskAppointmentDetails from './Autotask';

import LoadingSpinner from 'design_system/Components/LoadingSpinner';
import { psaType } from '@shared/utilities';

const ExternalAppointmentDetails: FC = () => {
  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);

  const psa = psaType(selectedCalendarEntry?.psaType || '');

  return (
    <Suspense fallback={<LoadingSpinner size="medium" />}>
      {psa === 'autotask' && <AutotaskAppointmentDetails />}
      {psa === 'bamboo_hr' && <div>To be implemented Bamboo HR</div>}
      {psa === 'connect_wise' && <ConnectWiseAppointmentDetails />}
      {psa === 'halo_psa' && <div>To be implemented Halo PSA</div>}
      {psa === 'office_365' && <div>To be implemented Office 365</div>}
    </Suspense>
  );
};

export default ExternalAppointmentDetails;

import type { FC, ChangeEvent } from 'react';

import { Combobox } from '@headlessui/react';
import classNames from 'classnames';

import { tw } from '@/utilities';

import type { ItemModel } from '@models/SelectItem';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';
import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';
import useControlledState, { type Controllable } from 'design_system/shared/useControlledState';
import useStateFromProp from '@shared/hooks/useStateFromProp';

const SELECT_BUTTON_CLASSNAMES = tw`disabled:bg-tz-gray-100 dark:disabled:bg-tz-gray-800 dark:bg-tz-gray-900 dark:bg-tz-gray-800 relative flex h-auto w-full cursor-pointer items-center rounded-t-md border-none p-1 text-sm font-normal ring-inset disabled:cursor-default disabled:ring-0`;
const SELECT_OPTIONS_CLASSNAMES = tw`border-tz-gray-300/20 text-tz-gray-800 dark:border-tz-gray-600/20 dark:bg-tz-gray-700 dark:text-tz-gray-300 absolute right-0 top-[100%] z-10 m-0 max-h-32 w-full list-none overflow-y-auto border bg-white shadow-sm dark:border-t-0`;
const ITEM_DEFAULT_CLASS_NAMES = tw`hover:bg-tz-gray-100 dark:hover:bg-tz-gray-700 mb-1 flex cursor-pointer items-center gap-x-1 p-2 text-sm font-normal last:mb-0`;
const LOADER_CONTAINER_CLASS_NAMES = tw`flex items-center gap-2 p-2`;

interface Props {
  name: string;
  items: ItemModel[];
  disabled: boolean;
  noEntriesFoundMessage?: string;
  search?: boolean;
  error?: string;
  loading?: boolean;
  onSearchChange?: (searchTerm: string) => void;
}

const SingleSelect: FC<Controllable<Props, string | null>> = ({
  disabled,
  name,
  items,
  noEntriesFoundMessage,
  search,
  error,
  loading,
  onSearchChange,
  ...props
}) => {
  const [selectedId, baseOnChange, _] = useControlledState(props);
  const value = items.find(item => item.id === selectedId);

  const [searchPhrase, setSearchPhrase] = useStateFromProp(value, v => v?.name || '');
  const [suggestedItems, setSuggestedItems] = useStateFromProp(items);

  const onChange = (item: ItemModel | null) => {
    baseOnChange(item?.id || null);
    setSearchPhrase(item?.name || '');
    setSuggestedItems(items);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!search) return;

    setSearchPhrase(e.target.value);
    setSuggestedItems(items.filter(i => i.name.toLowerCase().indexOf(e.target.value.toLowerCase()) >= 0));

    if (onSearchChange) onSearchChange(e.target.value);
  };

  const hasItems = !loading && suggestedItems.length > 0;
  const hasNoItems = !loading && suggestedItems.length === 0;

  return (
    <>
      <div className="relative w-full">
        <Combobox value={value} name="single-select" disabled={disabled} onChange={onChange}>
          {({ open }) => (
            <div>
              <Combobox.Button
                id={name}
                className={classNames(
                  SELECT_BUTTON_CLASSNAMES,
                  open
                    ? 'dark:ring-tz-gray-500 rounded-b-none ring-2 ring-blue-500'
                    : 'ring-tz-gray-200 dark:ring-tz-gray-600 rounded-b-md ring-1',
                  { 'ring-red-300 dark:ring-red-900': error }
                )}
              >
                <div className="text-tz-gray-800 hover:bg-tz-gray-200 hover:text-tz-gray-600 dark:text-tz-gray-300 dark:hover:bg-tz-gray-600 dark:hover:text-tz-gray-400 absolute right-1 rounded-md p-2">
                  <FontAwesomeIcon icon="chevron-down" height={10} />
                </div>

                <div className="flex h-6 w-full max-w-[calc(100%-30px)] flex-wrap items-center justify-start gap-1">
                  {(() => {
                    if (!search || (search && !open))
                      return (
                        <div className="mb-0 pl-1">
                          <TextSmall disabled={disabled}>{value?.name || ''}</TextSmall>
                        </div>
                      );
                  })()}

                  <Combobox.Input
                    id={`search-input-${name}`}
                    type="text"
                    className={classNames(
                      'dark:text-tz-gray-300 dark:placeholder:text-tz-gray-400 dark:bg-tz-gray-900 border-none p-0 text-sm focus:border-transparent focus:ring-0',
                      disabled || !search || (search && !open) ? 'w-0' : 'min-w-[50px] flex-1 pl-1'
                    )}
                    value={searchPhrase}
                    placeholder="Search..."
                    onChange={handleInputChange}
                  />
                </div>

                {error && (
                  <div className="pointer-events-none absolute bottom-0 right-8 top-0 my-auto flex items-center">
                    <FontAwesomeIcon icon="exclamation-circle" className="text-red-500" height={14} />
                  </div>
                )}
              </Combobox.Button>

              <input type="hidden" name={name} value={value?.id || ''} />

              <Combobox.Options className={classNames(SELECT_OPTIONS_CLASSNAMES, { 'rounded-b-md': open })}>
                {loading && (
                  <div className={LOADER_CONTAINER_CLASS_NAMES}>
                    <FontAwesomeIcon className="animate-spin" icon="spinner" height={15} />
                    <TextSmall>Loading...</TextSmall>
                  </div>
                )}
                {hasNoItems && <p className="p-2 text-sm">{noEntriesFoundMessage || 'No entries found.'}</p>}
                {hasItems && (
                  <>
                    {suggestedItems.map(item => (
                      <Combobox.Option key={item.id} value={item}>
                        {({ active, selected }) => (
                          <div
                            id={item.id}
                            role="option"
                            aria-selected={Boolean(selected)}
                            tabIndex={0}
                            className={classNames(
                              ITEM_DEFAULT_CLASS_NAMES,
                              selected ? 'bg-tz-gray-200 dark:bg-tz-gray-700' : 'dark:bg-tz-gray-800',
                              { 'bg-tz-gray-100 dark:bg-tz-gray-600': active }
                            )}
                          >
                            <div>{item.name}</div>
                          </div>
                        )}
                      </Combobox.Option>
                    ))}
                  </>
                )}
              </Combobox.Options>
            </div>
          )}
        </Combobox>
      </div>
      {error && (
        <TextExtraSmall>
          <span className="mt-1 text-xs text-red-600 dark:text-red-500">{error}</span>
        </TextExtraSmall>
      )}
    </>
  );
};

export default SingleSelect;

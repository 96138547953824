import type { UIState } from '../uiReducer';

const setFilter: (state: UIState, filter: any) => UIState = (state, _filter) => {
  return {
    ...state,
    // To be implemented
  };
};

export default setFilter;

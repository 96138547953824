import type { FC } from 'react';

import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';

import Description from '../../DescriptionList/Description';

interface Props {
  phone: string | null | undefined;
  address: string;
}

const PhoneAndAddress: FC<Props> = ({ phone, address }) => {
  return (
    <>
      <Description>
        <TextSmall>{phone || 'No phone number provided'}</TextSmall>
      </Description>
      <Description>
        <TextSmall>{address || 'No address provided'}</TextSmall>
      </Description>
    </>
  );
};

export default PhoneAndAddress;

import type { FC } from 'react';

import Row from '@/ui/Row';

import Plans from '../Plan';

import type { BillingModel } from '@models/Billing';

interface Props {
  billing: BillingModel;
}

const TrialAccount: FC<Props> = ({ billing }) => {
  const { eligibleFreePlanChange, chargebeePlanId } = billing;

  return (
    <>
      <Row
        label="Subscription Status"
        withInput={false}
        helpText={
          <>
            Invoices and other billing details can be accessed in our{' '}
            <a target="_blank" rel="noopener nofollow" data-method="post" href="/chargebee/portal_authentication">
              billing portal
            </a>
            .
          </>
        }
      >
        Your subscription of TimeZest has been cancelled. To continue using TimeZest, reactivate your subscription in
        the{' '}
        <a target="_blank" rel="noopener nofollow" data-method="post" href="/chargebee/portal_authentication">
          billing portal
        </a>
        .
      </Row>

      <Row label="Reactivate Account" width={6} withInput={false}>
        <Plans
          chargebeePlanId={chargebeePlanId}
          eligibleFreePlanChange={eligibleFreePlanChange}
          pricingModel="tiered"
        />
      </Row>
    </>
  );
};

export default TrialAccount;

import type { FC, PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

export type Color = 'gray' | 'red' | 'green' | 'yellow' | 'blue' | 'purple' | 'cyan';

interface Props {
  color: Color | null;
  className?: string;
}

const Badge: FC<PropsWithChildren<Props>> = ({ color, className, children }) => {
  return (
    <span
      className={twMerge(
        'inline-flex w-fit items-center rounded-md border px-1 py-0.5 text-xs font-normal',
        badgeColorClasses(color),
        className
      )}
    >
      {children}
    </span>
  );
};

export default Badge;

function badgeColorClasses(color: Color | null) {
  if (!color) return 'bg-transparent';

  switch (color) {
    case 'green':
      return 'bg-green-100/25 text-green-600 border-green-400/40 dark:bg-green-500/10 dark:text-green-400 dark:border-green-500/20';
    case 'red':
      return 'bg-red-100/25 text-red-500 border-red-400/40 dark:bg-red-400/10 dark:text-red-400 dark:border-red-400/20';
    case 'yellow':
      return 'bg-yellow-100/25 text-yellow-600 border-yellow-400/60 dark:bg-yellow-500/10 dark:text-yellow-400 dark:border-yellow-500/20';
    case 'blue':
      return 'bg-blue-100/25 text-blue-500 border-blue-400/40 dark:bg-blue-500/10 dark:text-blue-400 dark:border-blue-500/20';
    case 'purple':
      return 'bg-purple-100/25 text-purple-500 border-purple-400/40 dark:bg-purple-500/10 dark:text-purple-400 dark:border-purple-500/20';
    case 'cyan':
      return 'bg-cyan-100/25 text-cyan-600 border-cyan-400/40 dark:bg-cyan-500/10 dark:text-cyan-400 dark:border-cyan-500/20';
    default:
      return 'bg-tz-gray-100/25 text-tz-gray-500 border-tz-gray-400/40 dark:text-tz-gray-300 dark:bg-tz-gray-400/10 dark:border-tz-gray-400/20';
  }
}

import type { CalendarState } from '../calendarReducer';

import type { DispatchHqCalendarEntry } from '@graphql/generated';

const loadSelectedCalendarEntry: (state: CalendarState, data: DispatchHqCalendarEntry) => CalendarState = (
  state,
  data
) => {
  if (!state.selectedUserId || !state.selectedCalendarEntryId) {
    return state;
  }

  const updatedEntriesForAUser = state.calendarEntries[state.selectedUserId].map(entry =>
    entry.id === state.selectedCalendarEntryId ? { ...entry, ...data } : entry
  );

  const updatedEntries = { ...state.calendarEntries, [state.selectedUserId]: updatedEntriesForAUser };

  return {
    ...state,
    calendarEntries: updatedEntries,
  };
};

export default loadSelectedCalendarEntry;

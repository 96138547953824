import type { FC } from 'react';

import classNames from 'classnames';

import { tw } from '@/utilities';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';
import useControlledState, { type Controllable } from 'design_system/shared/useControlledState';
import type { InputProps } from 'design_system/shared/InputProps';

const INPUT_CLASS_NAMES = tw`text-tz-gray-800 h-8 w-full cursor-text items-center rounded-md border-0 px-2 py-0.5 text-sm font-normal`;
const RING_CLASS_NAMES = tw`ring-tz-gray-200 ring-1 ring-inset focus:outline-none focus:ring-2 focus:ring-inset`;
const DISABLED_CLASS_NAMES = tw`disabled:bg-tz-gray-100 disabled:text-tz-gray-300 disabled:dark:text-tz-gray-500 disabled:cursor-default disabled:ring-0`;
const DARK_MODE_CLASS_NAMES = tw`dark:bg-tz-gray-900 dark:text-tz-gray-300 dark:ring-tz-gray-600 dark:placeholder:text-tz-gray-400 focus:dark:ring-tz-gray-500 dark:disabled:bg-tz-gray-800`;

const PasswordInput: FC<Controllable<InputProps<HTMLInputElement>>> = ({ ariaLabel, className, error, ...allProps }) => {
  const [value, baseOnChange, props] = useControlledState<InputProps<HTMLInputElement>>(allProps);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    baseOnChange(e.target.value);
  };

  return (
    <>
      <div className="relative">
        <input
          type="password"
          aria-label={ariaLabel}
          value={value}
          className={classNames(
            INPUT_CLASS_NAMES,
            RING_CLASS_NAMES,
            DISABLED_CLASS_NAMES,
            DARK_MODE_CLASS_NAMES,
            error
              ? 'text-red-900 ring-red-300 focus:ring-red-500 dark:ring-red-900 focus:dark:ring-red-900'
              : 'focus:ring-blue-500',
            className
          )}
          onChange={onChange}
          {...props}
        />
        {error && (
          <div className="pointer-events-none absolute bottom-0 right-2.5 top-0 flex items-center">
            <FontAwesomeIcon icon="exclamation-circle" className="text-red-500" height={14} />
          </div>
        )}
      </div>
      {error && (
        <TextExtraSmall>
          <span className="mt-1 block text-xs text-red-600 dark:text-red-500">{error}</span>
        </TextExtraSmall>
      )}
    </>
  );
};

export default PasswordInput;
import type { FC } from 'react';

import ContactInformation from './ContactInformation';
import AppointmentDetails from './AppointmentDetails';

import Divider from 'design_system/Components/FormDivider';

const AppointmentInformation: FC = () => {
  return (
    <div className="flex-1">
      <ContactInformation />
      <Divider className="my-6" />
      <AppointmentDetails />
    </div>
  );
};

export default AppointmentInformation;

import type { FC } from 'react';

import { useAppSelector } from '@/screens/NewUi/DispatchHQ/hooks';
import { selectSelectedCalendarEntry } from '@/screens/NewUi/DispatchHQ/Reducers/calendarReducer';

import { psaName, psaType } from '@shared/utilities';
import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';
import type { PsaType } from '@models/Integration';

const Description: FC = () => {
  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);

  if (!selectedCalendarEntry) return;

  const isMS365Appointment = selectedCalendarEntry.psaType.includes('Microsoft');

  const microsoftDescription =
    'TimeZest will cancel this appointment in Microsoft 365, and Microsoft 365 will send notifications to all attendees.';

  const psa = psaType(selectedCalendarEntry.psaType) as PsaType;

  const psaDescription = `TimeZest will remove this appointment from the ${psaName(psa)} calendar, but take no other action.`;

  return <TextSmall>{isMS365Appointment ? microsoftDescription : psaDescription}</TextSmall>;
};

export default Description;

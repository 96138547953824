import React, { type FC } from 'react';

import Option, { type OptionProps } from './Option';

import useControlledState, { type Controllable } from 'design_system/shared/useControlledState';

interface Props {
  name: string;
  options: OptionProps[];
}

const RadioButtons: FC<Controllable<Props>> = ({ name, options, ...allProps }) => {
  const [value, baseOnChange, _] = useControlledState(allProps);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    baseOnChange(e.target.value);
  };

  return (
    <div className="space-y-3">
      {options.map((option, i) => (
        <Option key={`name-${i}`} checked={option.value === value} name={name} onChange={onChange} {...option} />
      ))}
    </div>
  );
};

export default RadioButtons;

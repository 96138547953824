import type { PropsWithChildren, FC } from 'react';
import { Fragment, useState } from 'react';

import { Menu, MenuButton, MenuItems, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

import { tw } from '@/utilities';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import useRefWithClickOutside from '@shared/hooks/useRefWithClickOutside';

const MENU_BUTTON_CLASSNAMES = tw`text-tz-gray-800 hover:ring-tz-gray-100 dark:text-tz-gray-300 hover:dark:ring-tz-gray-600 rounded-md p-2 hover:shadow-sm hover:ring-1 hover:ring-inset`;
const MENU_ITEMS_CLASSNAMES = tw`dark:bg-tz-gray-700 absolute right-0 top-8 z-10 w-max rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`;
const MENU_OPEN_CLASSNAMES = tw`ring-tz-gray-100 dark:ring-tz-gray-600 rounded-md shadow-sm ring-1 ring-inset`;

const DROPDOWN_OPTION_CLASSNAME = tw`hover:bg-tz-gray-100 dark:hover:bg-tz-gray-700 dark:bg-tz-gray-800 dark:hover:bg-tz-gray-700 flex w-full cursor-pointer px-3 py-2 text-left first:rounded-t-md last:rounded-b-md`;
const DISABLED_OPTION_CLASSNAME = tw`pointer-events-none px-3 py-2`;

export const BASE_MENU_ITEM_CLASS_NAMES = (disabled: boolean | undefined) =>
  twMerge(DROPDOWN_OPTION_CLASSNAME, disabled && DISABLED_OPTION_CLASSNAME);

interface Props {
  disabled: boolean;
  size: number;
  top?: string;
}

const ActionMenu: FC<PropsWithChildren<Props>> = ({ children, size, disabled, top = 'top-3' }) => {
  const [open, setOpen] = useState(false);

  const ref = useRefWithClickOutside<HTMLDivElement>(() => setOpen(false));

  const handleClick = e => {
    setOpen(!open);
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <Menu ref={ref}>
      <div className={`absolute right-0 ${top}`}>
        <div className="relative">
          <MenuButton
            disabled={disabled}
            className={twMerge(MENU_BUTTON_CLASSNAMES, open && MENU_OPEN_CLASSNAMES)}
            onClick={handleClick}
          >
            <FontAwesomeIcon icon="ellipsis-vertical" height={size} width={size} type="button" />
          </MenuButton>

          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <MenuItems className={twMerge(MENU_ITEMS_CLASSNAMES, open ? 'block' : 'hidden')}>{children}</MenuItems>
          </Transition>
        </div>
      </div>
    </Menu>
  );
};

export default ActionMenu;

import type { FC } from 'react';
import { useContext } from 'react';

import { ContentState } from 'draft-js';
import { useDraggable } from '@dnd-kit/core';
import invert from 'invert-color';

import AccountContext from '@/AccountContext';

import ConfigContext from '../ConfigContext';
import { serialize } from '../serialization';

import NewToolCard from './NewToolCard';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const Button: FC<{}> = () => {
  const { readOnly } = useContext(ConfigContext);
  const { brandColor } = useContext(AccountContext);

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: 'new-button',
    data: {
      type: 'mjml-button',
      defaultProperties: {
        alignment: 'center',
        backgroundColor: '#ffffff',
        buttonColor: brandColor ? `#${brandColor}` : '#007bff',
        content: serialize(ContentState.createFromText('Click to edit this text.')),
        innerPadding: { setAll: true, all: '10px', left: '15px', right: '15px', top: '10px', bottom: '10px' },
        outerPadding: { setAll: true, all: '10px', left: '10px', right: '10px', top: '10px', bottom: '10px' },
        textColor: brandColor ? invert(`#${brandColor}`, true) : '#ffffff',
        url: 'appointment.scheduling_url',
      },
    },
  });

  return (
    <NewToolCard ref={setNodeRef} {...listeners} {...attributes}>
      <p className="small text-muted m-0 mb-2">Button</p>
      <FontAwesomeIcon icon="rectangle-xmark" size="3x" color="#585858" opacity={readOnly ? 0.5 : 1} />
    </NewToolCard>
  );
};

export default Button;

import { useContext, type FC } from 'react';

import Form from '@/Form';
import AccountContext from '@/AccountContext';
import CurrentUserContext from '@/CurrentUserContext';

import NewPasswordHelp from './NewPasswordHelp';

import FormSection from 'design_system/Components/FormSection';
import FormControl from 'design_system/Components/FormControl';
import PasswordInput from 'design_system/Components/PasswordInput';
import Button from 'design_system/Components/Button';
import FormDivider from 'design_system/Components/FormDivider';
import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';
import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';

const SecurityTab: FC = () => {
  const { twoFactorEnabled } = useContext(CurrentUserContext);
  const { twoFactorForced } = useContext(AccountContext);

  return (
    <div id="security" className="hidden pt-8" role="tabpanel">
      <Form url="/profile" method="patch">
        <FormSection title="Change My Password">
          <FormControl label="Current Password" name="user[current_password]">
            <PasswordInput name="user[current_password]" mode="uncontrolled" initialValue="" />
          </FormControl>
          <FormControl label="New Password" name="user[password]" helpPopup={<NewPasswordHelp />}>
            <PasswordInput name="user[password]" mode="uncontrolled" initialValue="" />
          </FormControl>
          <FormControl label="Repeat New Password" name="user[password_confirmation]">
            <PasswordInput name="user[password_confirmation]" mode="uncontrolled" initialValue="" />
          </FormControl>
          <Button submit label="Change My Password" size="large" />
        </FormSection>
        <FormDivider />
        <FormSection
          title="2-Factor Authentication"
          description="Improve security of your TimeZest account with a 2FA app like Authy or Google Authenticator."
        >
          {twoFactorEnabled ? (
            <TextSmall className="mb-0 text-emerald-500">
              2-factor authentication is enabled for your user account.
            </TextSmall>
          ) : (
            <TextSmall className="mb-0 text-red-500">
              2-factor authentication is disabled for your user account.
            </TextSmall>
          )}
          {twoFactorEnabled ? (
            twoFactorForced ? (
              <TextExtraSmall>
                2-factor authentication is required for all users, and cannot be disabled, and can only be disabled by
                an administrator.
              </TextExtraSmall>
            ) : (
              <Button
                size="medium"
                url="/profile/two_factor"
                dataMethod="DELETE"
                label="Disable 2-Factor Authentication"
              />
            )
          ) : (
            <Button url="/profile/two_factor/new" size="medium" label="Enable 2-Factor Authentication" />
          )}
        </FormSection>
      </Form>
    </div>
  );
};

export default SecurityTab;

import { useContext, type FC } from 'react';

import { DialogTitle } from '@headlessui/react';

import AccountContext from '@/AccountContext';
import { tw } from '@/utilities';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import { psaName } from '@shared/utilities';
import { useAppSelector } from 'admin_ui/screens/NewUi/DispatchHQ/hooks';
import { selectSelectedCalendarEntry } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/calendarReducer';
import Link from 'design_system/Components/Link';

const FLYOUT_HEADER_CLASS_NAMES = tw`flex items-start justify-between`;
const REMOVE_ICON_CLASS_NAMES = tw`hover:bg-tz-gray-100 h-6 shrink-0 cursor-pointer rounded-md p-1`;

interface Props {
  onClick: () => void;
}

const HeaderSection: FC<Props> = ({ onClick }) => {
  const { psaType } = useContext(AccountContext);

  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);

  return (
    <div className={FLYOUT_HEADER_CLASS_NAMES}>
      <DialogTitle>
        {selectedCalendarEntry?.psaTicketNumber && (
          <Link href="/">
            {psaName(psaType)} Ticket {selectedCalendarEntry?.psaTicketNumber}
          </Link>
        )}
      </DialogTitle>
      <FontAwesomeIcon icon="xmark" className={REMOVE_ICON_CLASS_NAMES} onClick={onClick} />
    </div>
  );
};

export default HeaderSection;

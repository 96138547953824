import type { FC } from 'react';

import classNames from 'classnames';
import type { IconName } from '@fortawesome/free-solid-svg-icons';

import FontAwesomeIcon from '@/FontAwesomeIcon';

import type { FieldProps } from './Field';
import Field from './Field';

interface Props extends Omit<FieldProps, 'className' | 'error'> {
  value: string;
  options: { icon: string; value: string }[];
  disabled: boolean;
  onChange?: (value: string) => void;
}

const ButtonGroup: FC<Props> = ({ id, label, options, value, disabled, onChange }) => {
  const handleChange = (value: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (onChange) onChange(value);
  };

  return (
    <Field id={id} label={label}>
      <div
        className={classNames('btn-group d-flex align-items-center justify-content-center', {
          'opacity-50 pe-none': disabled,
        })}
      >
        {options.map(option => (
          <button
            type="button"
            key={option.value}
            className={classNames('btn', 'btn-outline-secondary', { active: option.value === value })}
            onClick={handleChange(option.value)}
          >
            <FontAwesomeIcon icon={option.icon as IconName} />
          </button>
        ))}
      </div>
    </Field>
  );
};

export default ButtonGroup;

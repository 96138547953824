import type { FC } from 'react';

import HelpPopup from 'design_system/Components/HelpPopup';

const UseCachingHelp: FC = () => {
  return (
    <HelpPopup position="below">
      <p className="mb-2 text-sm font-normal">
        This settings control whether TimeZest is allowed to cache existing appointments from your calendars to use as
        resources for forthcoming features.
      </p>
    </HelpPopup>
  );
};

export default UseCachingHelp;

import type { FC } from 'react';
import { useState } from 'react';

import classNames from 'classnames';

interface Props {
  value: boolean;
  attr: string;
  namespace: string;
  label: string;
  error?: string | null;
  disabled: boolean;
}

const Switch: FC<Props> = ({ value, attr, namespace, label, error, disabled }) => {
  const [checked, setChecked] = useState(value);
  const isInvalid = error && error !== '';

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setChecked(event.target.checked);
  };

  return (
    <div className="form-check form-switch">
      {!checked && (
        <input type="hidden" name={attr} value="false" />
      )}
      <input
        type="checkbox"
        name={attr}
        className="form-check-input"
        id={`action-${namespace}-${attr}`}
        checked={checked}
        disabled={disabled}
        value="true"
        onChange={onChange}
      />

      <label
        className={classNames('form-check-label', { 'is-invalid': isInvalid })}
        htmlFor={`action-${namespace}-${attr}`}
      >
        {label}
      </label>
      <div className="invalid-feedback">{error}</div>
    </div>
  );
};

export default Switch;

import type { FC } from 'react';

import HelpPopup from 'design_system/Components/HelpPopup';

const MailSendingModeHelp: FC = () => {
  return (
    <HelpPopup position="below">
      <p className="mb-2 text-sm font-normal">
        This setting determines the email address which emails sent by TimeZest are sent from.
      </p>
      <p className="mb-2 text-sm font-normal">
        By default, TimeZest emails are sent from &lsquo;noreply@timezest.com&rsquo;, but this address may not be
        familiar to your end users.
      </p>
      <p className="text-sm font-normal">
        Once you&apos;ve configured a custom sending domain, you can tell TimeZest to send email from any address on
        your domain, and the DKIM keys and Return-Path DNS entries configured for your domain will ensure it&apos;s
        accepted as legitimate by email servers.
      </p>
    </HelpPopup>
  );
};

export default MailSendingModeHelp;

import type { FC, ChangeEvent, KeyboardEvent, MouseEvent } from 'react';
import { useState } from 'react';

import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { twJoin } from 'tailwind-merge';

import { tw } from '@/utilities';

import SelectedItem from './SelectedItem';
import Item from './Item';

import type { ItemModel } from '@models/SelectItem';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';
import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';

const SELECT_CONTROL_CLASSNAMES = tw`disabled:bg-tz-gray-100 dark:bg-tz-gray-900 dark:disabled:bg-tz-gray-700 relative flex w-full items-center rounded-t-md border-none p-1 text-sm font-normal ring-inset disabled:ring-0`;
const SELECT_OPTIONS_CLASSNAMES = tw`border-tz-gray-300/20 dark:border-tz-gray-600/20 dark:bg-tz-gray-700 dark:text-tz-gray-300 absolute right-0 top-[100%] z-10 m-0 max-h-40 w-full list-none overflow-y-auto border bg-white shadow-sm dark:border-t-0`;
const SELECT_INPUT_BOX_CLASSNAMES = tw`dark:bg-tz-gray-900 dark:text-tz-gray-300 dark:placeholder:text-tz-gray-400 h-6 border-none p-0 text-xs focus:border-transparent focus:ring-0`;
const DROPDOWN_ICON_CLASSNAMES = tw`text-tz-gray-800 hover:bg-tz-gray-200 hover:text-tz-gray-600 dark:text-tz-gray-300 dark:hover:bg-tz-gray-600 dark:hover:text-tz-gray-200 absolute bottom-1 right-1 block h-[26px] w-[26px] rounded-md p-2`;

interface Props {
  name: string;
  items: ItemModel[];
  disabled: boolean;
  initialSelectedItemIds?: string[];
  maxSelectedItems?: number;
  minSelectedItems?: number;
  search?: boolean;
  error?: string;
  onChange?: (ids: string[]) => void;
  itemRenderer?: (item: ItemModel, selected: boolean, active: boolean) => JSX.Element;
  selectedItemRenderer?: (
    canBeRemoved: boolean,
    item: ItemModel,
    disabled: boolean,
    onRemoveItem: (id: string, event: MouseEvent | KeyboardEvent<HTMLButtonElement>) => void
  ) => JSX.Element;
}

const SearchableMultiSelect: FC<Props> = ({
  name,
  items,
  initialSelectedItemIds,
  maxSelectedItems,
  minSelectedItems,
  search,
  disabled,
  error,
  onChange,
  itemRenderer,
  selectedItemRenderer,
}) => {
  const initialSelectedItems = initialSelectedItemIds
    ? items.filter(i => initialSelectedItemIds.includes(i.id))
    : [items[0]];

  const [searchPhrase, setSearchPhrase] = useState('');
  const [selectedItems, setSelectedItems] = useState<ItemModel[]>(initialSelectedItems);

  const suggestedItems = search
    ? items.filter(i => i.name.toLowerCase().indexOf(searchPhrase.toLowerCase()) >= 0)
    : items;

  const maxSelectedItemsReached = maxSelectedItems && selectedItems.length === maxSelectedItems;
  const canBeRemoved = typeof minSelectedItems !== 'undefined' ? minSelectedItems < selectedItems.length : true;

  const allowSelectElements = (itemsArrayLength: number): boolean => {
    const canUnselect = typeof minSelectedItems !== 'undefined' ? itemsArrayLength >= minSelectedItems : true;
    const canSelect = typeof maxSelectedItems !== 'undefined' ? itemsArrayLength <= maxSelectedItems : true;

    if (canUnselect && canSelect) {
      return true;
    }

    return false;
  };

  const handleChange = (items: ItemModel[]) => {
    if (allowSelectElements(items.length)) {
      setSelectedItems(items);

      if (onChange) onChange(items.map(i => i.id));

      setSearchPhrase('');
    }
  };

  const handleSearchPhraseChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!maxSelectedItemsReached || !search) {
      setSearchPhrase(e.target.value);
    }

    return;
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' || e.key === 'Delete') {
      if (!searchPhrase) {
        handleChange(selectedItems.slice(0, -1));
      }
    }
  };

  const handleRemoveItem = (id: string, event: MouseEvent | KeyboardEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    handleChange(selectedItems.filter(item => item.id !== id));
  };

  return (
    <>
      <div className="relative w-full">
        <Combobox
          value={selectedItems}
          name="searchable_multiselect"
          multiple={true}
          disabled={disabled}
          onChange={handleChange}
        >
          {({ open }) => (
            <>
              <div
                id={name}
                className={twJoin(
                  SELECT_CONTROL_CLASSNAMES,
                  open
                    ? 'dark:ring-tz-gray-500 rounded-b-none ring-2 ring-blue-500'
                    : 'ring-tz-gray-200 dark:ring-tz-gray-600 rounded-b-md ring-1',
                  error && 'ring-red-300 dark:ring-red-900'
                )}
              >
                <div className="flex max-h-24 w-full max-w-[calc(100%-30px)] flex-wrap items-start gap-1 overflow-y-auto p-px">
                  {selectedItems.map(item => {
                    if (selectedItemRenderer) {
                      return selectedItemRenderer(canBeRemoved, item, disabled, handleRemoveItem);
                    }

                    return (
                      <SelectedItem
                        key={item.id}
                        {...item}
                        disabled={disabled}
                        canBeRemoved={canBeRemoved}
                        onRemoveItem={handleRemoveItem}
                      />
                    );
                  })}

                  <ComboboxButton className="flex grow items-center justify-start">
                    <FontAwesomeIcon icon="chevron-down" className={DROPDOWN_ICON_CLASSNAMES} />
                    <ComboboxInput
                      id={`search-input-${name}`}
                      type="text"
                      className={twJoin(
                        SELECT_INPUT_BOX_CLASSNAMES,
                        disabled || maxSelectedItemsReached || !search ? 'w-0' : 'w-auto'
                      )}
                      value={searchPhrase}
                      onChange={handleSearchPhraseChange}
                      onKeyDown={handleKeyDown}
                    />
                  </ComboboxButton>
                </div>

                {error && (
                  <div className="pointer-events-none absolute bottom-0 right-8 top-0 my-auto flex items-center">
                    <FontAwesomeIcon icon="exclamation-circle" className="text-red-500" height={14} />
                  </div>
                )}
              </div>

              <input type="hidden" name={name} value={selectedItems.map(item => item.id).join(',')} />

              <ComboboxOptions className={twJoin(SELECT_OPTIONS_CLASSNAMES, open && 'rounded-b-md')}>
                {suggestedItems.length > 0 ? (
                  suggestedItems.map(item => (
                    <ComboboxOption key={item.id} value={item}>
                      {({ active, selected }) => {
                        if (itemRenderer) return itemRenderer(item, selected, active);

                        return <Item id={item.id} displayName={item.name} selected={selected} active={active} />;
                      }}
                    </ComboboxOption>
                  ))
                ) : (
                  <div className="p-2">
                    <TextSmall>No matching entries found.</TextSmall>
                  </div>
                )}
              </ComboboxOptions>
            </>
          )}
        </Combobox>
      </div>
      {error && (
        <TextExtraSmall>
          <span className="mt-1 text-xs text-red-600 dark:text-red-500">{error}</span>
        </TextExtraSmall>
      )}
    </>
  );
};

export default SearchableMultiSelect;

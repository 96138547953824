import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';

import { useDebounce } from '@react-hook/debounce';
import classNames from 'classnames';

import useFetchWithTrigger from '@shared/hooks/useFetchWithTrigger';
import type Reference from '@models/Reference';

interface Props {
  name: string;
  url: string;
  prop: string;
}

const RemoteSelect: FC<Props> = ({ name, prop, url: initialUrl }) => {
  const container = useRef<HTMLDivElement>(null);
  const [displayValue, setDisplayValue] = useState('');
  const [listVisible, setListVisible] = useState(false);
  const [value, setValue] = useState<string>('');
  const [searchTerm, setSearchTerm] = useDebounce<string>('', 1000);
  const url = searchTerm ? `${initialUrl}?search=${searchTerm}` : initialUrl;
  const [data, _, loading] = useFetchWithTrigger(url, true);
  const records = data?.[prop] || [];

  const handleBlur = (e: React.FocusEvent): void => {
    if (e.relatedTarget && container.current?.contains(e.relatedTarget as Element)) return;

    setListVisible(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setDisplayValue(e.target.value);
    setListVisible(true);
    setSearchTerm(e.target.value.trim());
    setValue('');
  };

  const handleSelect = (record: Reference, e: React.MouseEvent): void => {
    e.preventDefault();
    e.stopPropagation();

    setDisplayValue(record.name);
    setListVisible(false);
    setValue(record.id.toString());
  };

  useEffect(() => {
    if (!value) setDisplayValue('');
    setListVisible(false);
  }, [value]);

  return (
    <div className="position-relative" ref={container}>
      <ul className={classNames('select-items', { 'd-none': !listVisible })}>
        {listVisible &&
          records.map((r: Reference) => (
            <li key={r.id} className="select-item">
              <div role="button" tabIndex={0} onMouseDown={handleSelect.bind(null, r)}>
                {r.name}
              </div>
            </li>
          ))}
      </ul>
      <input
        type="text"
        placeholder={loading ? 'Loading...' : ''}
        className="form-select"
        value={displayValue}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <input type="hidden" name={name} value={value} />
    </div>
  );
};

export default RemoteSelect;

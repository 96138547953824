import { parseISO } from 'date-fns/parseISO';
import { startOfDay } from 'date-fns/startOfDay';

import type { CalendarEntry } from '../types';
import { DateTimeManager } from '../DateTimeManager';

// Make sure that calendar entries does not expand beyond the selected date range
export default function trimCalendarEntries(
  calendarEntries: CalendarEntry[],
  start: string,
  end: string,
  timezone: string
): CalendarEntry[] {
  const startOfTheDay = startOfDay(parseISO(start));
  const endOfTheDay = startOfDay(parseISO(end));

  return calendarEntries.map(entry => {
    const entryStartTime = new DateTimeManager(timezone, entry.startTime).getDate();
    const entryEndTime = new DateTimeManager(timezone, entry.endTime).getDate();

    const startTime =
      entryStartTime < startOfTheDay
        ? DateTimeManager.toLocalISOString(startOfTheDay)
        : DateTimeManager.toLocalISOString(entryStartTime);
    const endTime =
      entryEndTime > endOfTheDay
        ? DateTimeManager.toLocalISOString(endOfTheDay)
        : DateTimeManager.toLocalISOString(entryEndTime);

    return {
      ...entry,
      startTime,
      endTime,
      originalStartTime: entryStartTime < startOfTheDay ? entry.startTime : null,
      duration: DateTimeManager.durationInMinutes(endTime, startTime),
    };
  });
}

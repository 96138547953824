import type { CSSProperties, FC } from 'react';

import { twMerge } from 'tailwind-merge';

import { tw } from '@/utilities';

import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';

import { generateHours } from '../../Utils/generateHours';
import { useAppSelector } from '../../hooks';
import { selectZoom } from '../../Reducers/uiReducer';

const CONTAINER_CLASS_NAMES = tw`border-tz-gray-200 sticky left-0 top-0 z-20 border-r bg-white [grid-area:sidebar]`;
const HOURS_COLUMN_CLASS_NAMES = tw`grid grid-cols-1`;
const HOUR_CELL_CLASS_NAMES = tw`border-tz-gray-200 text-center`;

const NUMBER_OF_HOURS = 24;
const BASE_ROW_HEIGHT = 6;

const HoursColumn: FC = () => {
  const zoom = useAppSelector(selectZoom);

  const hours = generateHours();

  const style: CSSProperties = {
    gridTemplateRows: `repeat(${NUMBER_OF_HOURS}, ${BASE_ROW_HEIGHT * zoom}rem)`,
  };

  return (
    <div className={CONTAINER_CLASS_NAMES}>
      <div className={HOURS_COLUMN_CLASS_NAMES} style={style}>
        {hours.map((hour, i) => (
          <div key={`hour-${i}`} className={twMerge(HOUR_CELL_CLASS_NAMES, i === hours.length - 1 && 'border-b-0')}>
            <TextExtraSmall>{hour}</TextExtraSmall>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HoursColumn;

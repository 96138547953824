import type { FC, KeyboardEvent, MouseEvent, PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

import { tw } from '@/utilities';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';

const CONTAINER_CLASS_NAMES = tw`bg-tz-gray-100 dark:bg-tz-gray-700 me-px flex h-6 w-max cursor-default items-center justify-start gap-x-2.5 rounded-md px-2 py-1`;

interface Props {
  disabled: boolean;
  canBeRemoved: boolean;
  id: string;
  name: string;
  className?: string;
  onRemoveItem: (id: string, event: MouseEvent | KeyboardEvent<HTMLButtonElement>) => void;
}

const SelectedItem: FC<PropsWithChildren<Props>> = ({
  canBeRemoved,
  children,
  className,
  id,
  disabled,
  name,
  onRemoveItem,
}) => {
  const handleClick = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    onRemoveItem(id, event);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    if (event.key !== 'Enter') return;

    onRemoveItem(id, event);
  };

  return (
    <div key={id} className={twMerge(CONTAINER_CLASS_NAMES, className)}>
      {children ? children : <TextExtraSmall disabled={disabled}>{name}</TextExtraSmall>}

      {canBeRemoved && (
        <button type="button" onClick={handleClick} onKeyDown={handleKeyDown}>
          <FontAwesomeIcon icon="times" className="mt-0.5" color="#8d8d8d" height={12} />
        </button>
      )}
    </div>
  );
};

export default SelectedItem;

import type { CSSProperties, FC } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { DateTimeManager } from 'admin_ui/screens/NewUi/DispatchHQ/DateTimeManager';
import { useAppSelector } from 'admin_ui/screens/NewUi/DispatchHQ/hooks';
import { selectTimezone } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/calendarReducer';
import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';
import { selectZoom } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/uiReducer';

const ONE_MINUTE_IN_PX = 1.6;

const CurrentTimeIndicator: FC = () => {
  const timezone = useAppSelector(selectTimezone);
  const zoom = useAppSelector(selectZoom);

  const getRemainingMillisecondsBeforeFullMinute = useCallback(() => {
    const now = new DateTimeManager(timezone).getDate();
    const seconds = now.getSeconds();
    const milliseconds = now.getMilliseconds();

    return (60 - seconds) * 1000 - milliseconds;
  }, [timezone]);

  const [currentMinute, setCurrentMinute] = useState(
    DateTimeManager.getMinuteValueFromTime(new Date().toISOString(), timezone)
  );

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    let timeout: NodeJS.Timeout | null = null;
    let animationFrameId: number | null = null;

    const updateNewMinute = () => {
      setCurrentMinute(DateTimeManager.getMinuteValueFromTime(new Date().toISOString(), timezone));
    };

    const handleClearEventListeners = () => {
      if (timeout) clearTimeout(timeout);
      if (interval) clearInterval(interval);
      if (animationFrameId) cancelAnimationFrame(animationFrameId);
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        updateNewMinute();

        const incrementCurrentMinute = () => {
          timeout = setTimeout(() => {
            updateNewMinute();
            interval = setInterval(() => {
              updateNewMinute();
              // requestAnimationFrame is used to ensure that the animation is smooth
              animationFrameId = requestAnimationFrame(incrementCurrentMinute);
            }, 60000);
          }, getRemainingMillisecondsBeforeFullMinute());
        };

        incrementCurrentMinute();
      } else {
        handleClearEventListeners();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Initial function call
    handleVisibilityChange();

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      handleClearEventListeners();
    };
  }, [timezone, getRemainingMillisecondsBeforeFullMinute]);

  const style: CSSProperties = {
    top: `${ONE_MINUTE_IN_PX * zoom * (currentMinute - 1)}px`,
  };

  const formattedNow = new DateTimeManager(timezone).shortTime();

  return (
    <div className="absolute w-full" style={style}>
      <div className="relative">
        <TextExtraSmall className="absolute -left-14 -top-3 z-30 rounded-md bg-red-500 p-1 text-white">
          {formattedNow}
        </TextExtraSmall>
        <div className="absolute -left-1 -top-[3px] z-30 h-2 w-2 rounded-full bg-red-400" />
        <hr className="absolute left-0 z-20 h-0.5 min-h-0.5 w-full bg-red-400" />
      </div>
    </div>
  );
};

export default CurrentTimeIndicator;

import type { FC } from 'react';

import { tw } from '@/utilities';

import { useAppDispatch, useAppSelector } from '../hooks';
import { selectDateRange } from '../actions';
import { selectViewMode } from '../Reducers/uiReducer';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const CONTAINER_CLASS_NAMES = tw`me-20 flex w-fit cursor-default items-center justify-start space-x-10 rounded-lg border-0`;
const ARROW_CLASS_NAMES = tw`text-tz-gray-500 hover:text-tz-gray-800 cursor-pointer`;

const DateSelector: FC = () => {
  const dispatch = useAppDispatch();

  const viewMode = useAppSelector(selectViewMode);

  const handleSelectDateRange = (direction: 'previous' | 'next') => {
    dispatch(selectDateRange({ direction, viewMode }));
  };

  return (
    <div className={CONTAINER_CLASS_NAMES}>
      <FontAwesomeIcon
        className={ARROW_CLASS_NAMES}
        icon="arrow-left-long"
        height={14}
        onClick={() => handleSelectDateRange('previous')}
      />
      <FontAwesomeIcon
        className={ARROW_CLASS_NAMES}
        icon="arrow-right-long"
        height={14}
        onClick={() => handleSelectDateRange('next')}
      />
    </div>
  );
};

export default DateSelector;

import type { FC } from 'react';
import { useContext } from 'react';

import { ContentState } from 'draft-js';
import { useDraggable } from '@dnd-kit/core';

import ConfigContext from '../ConfigContext';
import { serialize } from '../serialization';

import NewToolCard from './NewToolCard';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const Text: FC<{}> = () => {
  const { readOnly } = useContext(ConfigContext);

  const { attributes, listeners, setNodeRef } = useDraggable({
    id: 'new-text',
    data: {
      type: 'mjml-text',
      defaultProperties: {
        backgroundColor: '',
        content: serialize(ContentState.createFromText('Click to edit this text.')),
        font: { size: '14px', family: 'Sans-serif' },
        padding: { setAll: false, all: '0px', left: '0px', right: '0px', top: '0px', bottom: '15px' },
        text: { alignment: 'left', color: '#222222', linkColor: '' },
      },
    },
  });

  return (
    <NewToolCard ref={setNodeRef} {...listeners} {...attributes}>
      <p className="small text-muted m-0 mb-2">Text</p>
      <FontAwesomeIcon icon="t" size="3x" color="#585858" opacity={readOnly ? 0.5 : 1} />
    </NewToolCard>
  );
};

export default Text;

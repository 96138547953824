import { useContext } from 'react';

import { DirtyContext } from '@/DirtyContext';

import useStateFromProp from '@shared/hooks/useStateFromProp';

type ControlledProps<T, P> = P & {
  mode: 'controlled';
  value: T;
  onChange: (value: T) => void;
};

type UncontrolledProps<T, P> = P & {
  mode: 'uncontrolled';
  initialValue: T;
};

export type Controllable<P = {}, T = string> = UncontrolledProps<T, P> | ControlledProps<T, P>;

export default function useControlledState<P, T = string>(props: Controllable<P, T>): [T, (item: T) => void, P] {
  const { handleDirty } = useContext(DirtyContext);

  if (props.mode === 'controlled') {
    const value = props.value;

    const onChange = (value: T) => {
      if (handleDirty) {
        handleDirty();
      }

      props.onChange(value);
    };

    const { onChange: _onChange, mode: _mode, value: _value, ...rest } = props;
    return [value, onChange, rest as P];
  } else {
    let [stateValue, setValue] = useStateFromProp(props.initialValue);

    const value = stateValue;

    const onChange = (value: T) => {
      if (handleDirty) {
        handleDirty();
      }

      setValue(value);
    };

    const { mode: _mode, initialValue: _initialValue, ...rest } = props;
    return [value, onChange, rest as P];
  }
}

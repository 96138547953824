import type { FC, PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge';

import ActionMenu from 'design_system/Components/ActionMenu';

interface Props {
  orientation: 'horizontal' | 'vertical';
  className?: string;
  actionMenuItems?: JSX.Element;
  tableColumns?: string;
  url?: string;
}

const Item: FC<PropsWithChildren<Props>> = ({
  actionMenuItems,
  className,
  children,
  orientation,
  tableColumns,
  url,
}) => {
  const horizontalLayoutClassname = tableColumns ? twMerge('grid', tableColumns) : 'flex';

  const verticalLayoutClassname = 'grid gap-y-1';

  const layoutClassname = orientation === 'horizontal' ? horizontalLayoutClassname : verticalLayoutClassname;

  return (
    <li className={twMerge('group relative w-full', !url && `${layoutClassname} ${className}`)}>
      {url ? (
        <a href={url} className={twMerge(layoutClassname, className)}>
          {children}
        </a>
      ) : (
        children
      )}

      {actionMenuItems && (
        <ActionMenu disabled={false} size={14}>
          {actionMenuItems}
        </ActionMenu>
      )}
    </li>
  );
};

export default Item;

import type { FC } from 'react';
import { useMemo } from 'react';

import styled from 'styled-components';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';

import ConfigContext from './ConfigContext';
import DragDropManager from './DragDropManager';
import Preview from './Preview';
import Toolbar from './Toolbar';
import templateReducer from './Reducers/templateReducer';
import uiReducer from './Reducers/uiReducer';
import './EmailEditor.scss';

import type { EmailTemplate } from '@models/EmailTemplate';

interface Props {
  initialTemplate: EmailTemplate;
  name: string;
}

const Container = styled.div`
  color: var(--tz-grey-600);
  display: flex;
  height: 750px;
  overflow: hidden;
  box-shadow:
    rgba(0, 0, 0, 0.1) 0px 0px 3px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 2px 0px;
`;

const EmailEditor: FC<Props> = ({ initialTemplate, name }) => {
  const store = useMemo(() => {
    return configureStore({
      reducer: {
        ui: uiReducer,
        template: templateReducer,
      },
    });
  }, []);

  return (
    <Provider store={store}>
      <DragDropManager>
        <Container>
          <Preview initialTemplate={initialTemplate} name={name} />
          <Toolbar />
        </Container>
      </DragDropManager>
    </Provider>
  );
};

export { ConfigContext };

export default EmailEditor;

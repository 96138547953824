import type { FC } from 'react';

import HelpPopup from 'design_system/Components/HelpPopup';

const NewPasswordHelp: FC = () => {
  return (
    <HelpPopup position="below">
      <p className="mb-2 text-sm font-normal">Select a new password to use to login to TimeZest.</p>
      <p className="mb-2 text-sm font-normal">Passwords must be at least 8 characters long.</p>
    </HelpPopup>
  );
};

export default NewPasswordHelp;

import type { CSSProperties, FC } from 'react';

import classNames from 'classnames';

import type { CalendarEntryGroup, CalendarEntryMap } from 'admin_ui/screens/NewUi/DispatchHQ/types';
import { selectZoom } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/uiReducer';
import { useAppSelector } from 'admin_ui/screens/NewUi/DispatchHQ/hooks';
import { selectTimezone } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/calendarReducer';
import CalendarEntry from 'admin_ui/screens/NewUi/DispatchHQ/CalendarView/CalendarEntry';

interface Props {
  entryGroup: CalendarEntryGroup;
  entryMap: CalendarEntryMap;
}

const EntryGroup: FC<Props> = ({ entryGroup, entryMap }) => {
  const timezone = useAppSelector(selectTimezone);
  const zoom = useAppSelector(selectZoom);

  const { rootEntryId, maxOverlapStreak } = entryGroup;

  const style: CSSProperties = {
    margin: `${zoom}px`,
  };

  return <div style={style}>{renderCalendarEntry(entryMap, rootEntryId, zoom, maxOverlapStreak, timezone)}</div>;
};

export default EntryGroup;

// eslint-disable-next-line max-params
function renderCalendarEntry(
  entryMap: CalendarEntryMap,
  id: string,
  zoom: number,
  columns: number,
  timezone: string
): JSX.Element {
  const entry = entryMap[id];

  const childEntries = entry.childIds.map(id => {
    return renderCalendarEntry(entryMap, id, zoom, columns - 1, timezone);
  });

  const hasChildEntries = childEntries.length > 0;

  const parentEntryStyle: CSSProperties = {
    gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
  };

  const childEntryStyle: CSSProperties = {
    gridColumn: `2 / ${columns + 1}`,
  };

  return (
    <div key={id} className={classNames({ 'grid gap-x-px': hasChildEntries })} style={parentEntryStyle}>
      <div style={{ gridColumn: hasChildEntries ? '1 / 2' : 'auto' }}>
        <CalendarEntry key={entry.id} entry={entry} />
      </div>

      {hasChildEntries && <div style={childEntryStyle}>{childEntries}</div>}
    </div>
  );
}

import { useRef } from 'react';
import type { PropsWithChildren, FC } from 'react';

import { CSSTransition } from 'react-transition-group';

interface Props {
  display: boolean;
}

const FadeInTransition: FC<PropsWithChildren<Props>> = ({ children, display }) => {
  const ref = useRef(null);

  return (
    <CSSTransition ref={ref} in={display} timeout={200} classNames="fade">
      <div className={display ? 'opacity-1' : 'w-0 opacity-0'} ref={ref}>
        {children}
      </div>
    </CSSTransition>
  );
};

export default FadeInTransition;

import type { UIState } from '../uiReducer';

const setCellWidth: (state: UIState, cellWidth: number) => UIState = (state, cellWidth) => {
  return {
    ...state,
    cellWidth,
  };
};

export default setCellWidth;

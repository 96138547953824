import type { FC } from 'react';

import { tw } from '@/utilities';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';

const LOADER_CONTAINER_CLASS_NAMES = tw`flex items-center gap-2`;

interface Props {
  attributeName: string;
  operatorName: string;
  filterState: 'loading' | 'applied' | 'unapplied';
  valueName: string;
}

const FilterHeader: FC<Props> = ({ attributeName, operatorName, valueName, filterState }) => {
  if (filterState === 'loading') {
    return (
      <div className={LOADER_CONTAINER_CLASS_NAMES}>
        <FontAwesomeIcon className="dark:text-tz-gray-200 animate-spin" icon="spinner" height={15} />
        <TextSmall>Loading...</TextSmall>
      </div>
    );
  }

  if (filterState === 'unapplied') {
    return <TextSmall className="dark:text-tz-yellow-100">Filter is not applied</TextSmall>;
  }

  if (filterState === 'applied') {
    return (
      <TextSmall>
        <strong>{attributeName}</strong>
        {` ${operatorName?.toLowerCase()} ${valueName}`}
      </TextSmall>
    );
  }

  return <TextSmall>{`${attributeName} ${operatorName?.toLowerCase()} ${valueName}`}</TextSmall>;
};

export default FilterHeader;

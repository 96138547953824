import type { FC } from 'react';

import { useAppSelector } from '@/screens/NewUi/DispatchHQ/hooks';
import { selectSelectedCalendarEntry } from '@/screens/NewUi/DispatchHQ/Reducers/calendarReducer';

import Descriptions from './Descriptions';

import { useGetActivitySuspenseQuery } from '@graphql/generated';

const Activity: FC = () => {
  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);

  const { data } = useGetActivitySuspenseQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id: selectedCalendarEntry?.psaEntityId || '0',
      scheduleEntryId: selectedCalendarEntry?.psaId || '0',
    },
  });

  const activity = data?.connectWise?.activity;

  return (
    <Descriptions
      location={activity?.location}
      reminder={activity?.reminder}
      status={activity?.status}
      type={activity?.type}
    />
  );
};

export default Activity;

import type { FC } from 'react';

import { tw } from '@/utilities';
import { useAppDispatch, useAppSelector } from '@/screens/NewUi/DispatchHQ/hooks';
import { selectSelectedCalendarEntry } from '@/screens/NewUi/DispatchHQ/Reducers/calendarReducer';
import { loadSelectedCalendarEntry } from '@/screens/NewUi/DispatchHQ/actions';

import TagsSelector from './TagsSelector';

import type { Color } from 'design_system/Components/Badge';
import Badge from 'design_system/Components/Badge';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import { useDeleteTaggingMutation } from '@graphql/generated';

const CONTAINER_CLASS_NAMES = tw`flex items-center`;

const Tags: FC = () => {
  const dispatch = useAppDispatch();
  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);

  const [deleteTagging, { loading: deleteTaggingLoading }] = useDeleteTaggingMutation();

  const handleDeleteTagging = (taggingId: number) => {
    deleteTagging({
      variables: { taggingId },
    }).then(() => {
      if (!selectedCalendarEntry) return;

      const filteredTaggings = selectedCalendarEntry.taggings.filter(tagging => tagging.id !== taggingId);

      dispatch(
        loadSelectedCalendarEntry({
          data: {
            ...selectedCalendarEntry,
            taggings: filteredTaggings,
          },
        })
      );
    });
  };

  return (
    <div className={CONTAINER_CLASS_NAMES}>
      <div className="grow">
        {(selectedCalendarEntry?.taggings || []).map((tagging, i) => (
          <Badge className="me-2" key={`key-${i}`} color={tagging.tag.color as Color}>
            {tagging.tag.name}
            <FontAwesomeIcon
              className="ms-1 cursor-pointer"
              icon="xmark"
              height={12}
              onClick={() => handleDeleteTagging(tagging.id)}
            />
          </Badge>
        ))}
      </div>
      <TagsSelector
        deleteTaggingLoading={deleteTaggingLoading}
        onDeleteTagging={taggingId => handleDeleteTagging(taggingId)}
      />
    </div>
  );
};

export default Tags;

import { forwardRef, useEffect, useState } from 'react';

import { tw } from '@/utilities';

import CalendarArea from './CalendarArea';

import useForwardedRef from '@shared/hooks/useForwardedRef';

const CONTAINER_CLASS_NAMES = tw`border-tz-gray-200 w-full overflow-y-auto overflow-x-hidden border border-t-0 [-ms-overflow-style:none] [scrollbar-width-horizontal:none] [&::-webkit-scrollbar-horizontal]:hidden [&::-webkit-scrollbar:vertical]:bg-transparent`;
const SCREEN_PADDING = 12;

interface Props {
  containerHeight: number;
}

const CalendarContainer = forwardRef<HTMLDivElement, Props>(({ containerHeight }, ref) => {
  const containerRef = useForwardedRef<HTMLDivElement>(ref);

  const [containerOffsetTop, setContainerOffsetTop] = useState<number>(0);

  useEffect(() => {
    if (!containerRef.current) return;

    setContainerOffsetTop(containerRef.current.offsetTop);
  }, [containerRef]);

  return (
    <div
      ref={containerRef}
      className={CONTAINER_CLASS_NAMES}
      style={{ height: `calc(100vh - ${containerOffsetTop + SCREEN_PADDING}px)` }}
    >
      <CalendarArea containerHeight={containerHeight} />
    </div>
  );
});

export default CalendarContainer;

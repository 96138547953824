import type { FC } from 'react';

import H5 from 'design_system/Typography/Headings/H5';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  name: string;
  description: string;
  image: string;
  connected: boolean;
  url: string;
}

const IntegrationCard: FC<Props> = ({ connected, description, image, name, url }) => {
  return (
    <a className="group" href={url}>
      <div className="border-tz-gray-200 hover:border-tz-gray-300 rounded-md border p-4 text-center">
        <img className="w-12 h-12 mx-auto mb-2" src={image} alt={`${name} logo`}/>
        <H5 className="mb-2">{name}</H5>
        <div className="text-tz-gray-500 mb-2 h-12 text-xs">{description}</div>
        <hr className="border-tz-gray-200 group-hover:border-tz-gray-300 mb-4" />
        {connected ? (
          <div className="text-sm font-semibold text-green-600"><FontAwesomeIcon icon="check" className="inline w-4 h-4 me-2"/>Connected</div>
        ) : (
          <div className="text-tz-gray-400 group-hover:text-tz-gray-500 text-sm font-semibold">Connect</div>
        )}
      </div>
    </a>
  );
};

export default IntegrationCard;

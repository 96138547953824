import type { FC } from 'react';

import { useDraggable } from '@dnd-kit/core';

import { Option } from '@/EmailEditor/Properties/SectionProperties';
import { setDraggingElement } from '@/EmailEditor/actions';
import { useAppDispatch } from '@/EmailEditor/hooks';

import { dataTestId } from '@shared/test';

const SectionWithOneColumn: FC<{}> = () => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: 'new-section-1',
    data: {
      type: 'mjml-section',
      defaultProperties: {
        backgroundColor: '#ffffff',
        padding: { setAll: false, all: '0px', left: '20px', right: '20px', top: '0px', bottom: '0px' },
        width: '600px',
        columnProperties: [
          {
            backgroundColor: '',
            padding: { setAll: true, all: '0px', left: '0px', right: '0px', top: '0px', bottom: '0px' },
            width: '100%',
          },
        ],
      },
    },
  });

  const dispatch = useAppDispatch();

  return (
    <Option
      className="list-unstyled"
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      {...dataTestId('1-column section')}
    >
      <button
        type="button"
        className="w-100 p-0 border border-dark opacity-25 d-flex justify-content-center align-items-center"
        onMouseDown={() => dispatch(setDraggingElement({ draggingElement: true }))}
      >
        <div className="border border-dark flex-grow-1 py-4" />
      </button>
    </Option>
  );
};

export default SectionWithOneColumn;

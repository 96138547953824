import type { CalendarState } from '../calendarReducer';

import CalendarEntryGroupsGenerator from './BuildCalendarEntryGroupsByUser/CalendarEntryGroupsGenerator';
import CalendarEntryTreesGenerator from './BuildCalendarEntryGroupsByUser/CalendarEntryTreesGenerator';

import { sortEntries } from 'admin_ui/screens/NewUi/DispatchHQ/Utils/sortEntries';
import trimCalendarEntries from 'admin_ui/screens/NewUi/DispatchHQ/Utils/trimCalendarEntries';
import type {
  CalendarEntriesByUser,
  CalendarEntry,
  CalendarEntryTrees,
  ViewMode,
} from 'admin_ui/screens/NewUi/DispatchHQ/types';
import { getEndDateThreshold } from 'admin_ui/screens/NewUi/DispatchHQ/Utils/getEndDateThreshold';
import { getDateRange } from 'admin_ui/screens/NewUi/DispatchHQ/Utils/getDateRange';

const loadCalendarEntries: (
  state: CalendarState,
  calendarEntries: CalendarEntry[],
  viewMode: ViewMode
) => CalendarState = (state, calendarEntries, viewMode) => {
  let calendarEntryTreesByUser: CalendarEntryTrees = {};

  const trimmedCalendarEntries = trimCalendarEntries(
    calendarEntries,
    getDateRange(viewMode, state.dateRanges).startDate,
    getEndDateThreshold(getDateRange(viewMode, state.dateRanges).endDate),
    state.timezone
  );

  const calendarEntriesByUser: CalendarEntriesByUser = trimmedCalendarEntries.reduce(
    (calendarEntryGroup, calendarEntry) => {
      if (!calendarEntryGroup[calendarEntry.userId]) {
        calendarEntryGroup[calendarEntry.userId] = [];
      }

      calendarEntryGroup[calendarEntry.userId].push(calendarEntry);

      return calendarEntryGroup;
    },
    {}
  );

  const sortedCalendarEntriesByUser = Object.keys(calendarEntriesByUser).reduce((calendarEntriesByUser, userId) => {
    calendarEntriesByUser[userId] = sortEntries(calendarEntriesByUser[userId], state.timezone);

    return calendarEntriesByUser;
  }, calendarEntriesByUser);

  // This is to build tree structure for calendar entries group for day view
  if (viewMode === 'day') {
    const calendarEntryGroupsGenerator = new CalendarEntryGroupsGenerator(
      calendarEntriesByUser,
      state.visibleUserIds,
      state.timezone
    );
    const calendarEntryGroups = calendarEntryGroupsGenerator.generateEntryGroupsByUser();

    const calendarEntryTreesByUserGenerator = new CalendarEntryTreesGenerator(calendarEntryGroups, state.timezone);
    calendarEntryTreesByUser = calendarEntryTreesByUserGenerator.buildCalendarEntryTrees();
  }

  return {
    ...state,
    calendarEntries: sortedCalendarEntriesByUser,
    calendarEntryTreesByUser,
  };
};

export default loadCalendarEntries;

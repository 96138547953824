import { useContext, type FC } from 'react';

import asScreen from '@/Screen';
import Flash from '@/Flash';
import Form from '@/Form';
import AccountContext from '@/AccountContext';

import TicketDetailsDisplayHelp from './TicketDetailsDisplayHelp';
import TwoFactorAuthenticationHelp from './TwoFactorAuthenticationHelp';
import UserAvailabilityHelp from './UserAvailabilityHelp';
import UseCachingHelp from './UseCachingHelp';

import Button from 'design_system/Components/Button';
import FormControl from 'design_system/Components/FormControl';
import FormDivider from 'design_system/Components/FormDivider';
import FormSection from 'design_system/Components/FormSection';
import H2 from 'design_system/Typography/Headings/H2';
import MainContainer from 'design_system/Components/MainContainer';
import Navigation from 'design_system/Components/Navigation';
import RadioButtons from 'design_system/Components/RadioButtons';
import SideBarMenu from 'design_system/Components/SidebarMenu';
import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';

interface Props {
  errors: Record<string, string>;
  otpRequired: boolean;
  showTicketNamesUrlScheduling: boolean;
  userAvailabilityEditable: boolean;
}

const SecurityAndPermissions: FC<Props> = ({ showTicketNamesUrlScheduling, otpRequired, userAvailabilityEditable }) => {
  const { enabledTimeStashCaching } = useContext(AccountContext);

  return (
    <>
      <Navigation activeItem="settings" />
      <SideBarMenu activeItem="security" />
      <MainContainer>
        <H2>Security & Permissions</H2>
        <Flash />
        <FormDivider />
        <Form url="/settings/security" method="patch">
          <FormSection
            title="Security"
            description="Important options to balance the security and usability of TimeZest."
          >
            <FormControl
              helpPopup={<TwoFactorAuthenticationHelp />}
              label="Two Factor Authentication"
              name="account[otp_required]"
            >
              <RadioButtons
                mode="uncontrolled"
                name="account[otp_required]"
                initialValue={otpRequired.toString()}
                options={[
                  { value: 'true', label: 'Require all users to configure two-factor authentication.' },
                  { value: 'false', label: 'Allow users to configure two-factor authentication.' },
                ]}
              />
            </FormControl>
            <FormControl
              helpPopup={<TicketDetailsDisplayHelp />}
              label="Ticket Details Display in URL-based Scheduling"
              name="account[show_ticket_names_url_scheduling]"
            >
              <RadioButtons
                mode="uncontrolled"
                name="account[show_ticket_names_url_scheduling]"
                initialValue={showTicketNamesUrlScheduling.toString()}
                options={[
                  { value: 'true', label: 'Display ticket numbers and summaries to end users.' },
                  { value: 'false', label: 'Only display the ticket number.' },
                ]}
              />
            </FormControl>
            <FormControl
              helpPopup={<UseCachingHelp />}
              label="Cache Calendar Appointments"
              name="account[use_caching]"
            >
              <RadioButtons
                mode="uncontrolled"
                name="account[use_caching]"
                initialValue={enabledTimeStashCaching.toString()}
                options={[
                  { value: 'true', label: 'Allow TimeZest to cache existing appointments from your calendars.' },
                  { value: 'false', label: "Don't cache calendar appointments." },
                ]}
              />
              <TextExtraSmall className="mt-2 w-1/2">
                If caching of calendar appointments is disabled, your account will not be able to access forthcoming
                features and your customers will see degraded performance when scheduling appointments.
              </TextExtraSmall>
            </FormControl>
          </FormSection>
          <FormDivider />
          <FormSection title="Permissions" description="Options that control what users are permitted to do.">
            <FormControl
              helpPopup={<UserAvailabilityHelp />}
              label="Allow users to update their availability"
              name="account[user_availability_editable]"
            >
              <RadioButtons
                mode="uncontrolled"
                name="account[user_availability_editable]"
                initialValue={userAvailabilityEditable.toString()}
                options={[
                  { value: 'true', label: 'Users can update their own availability.' },
                  { value: 'false', label: 'Only administrators can customize availability.' },
                ]}
              />
            </FormControl>
          </FormSection>
          <FormDivider />
          <FormSection title="">
            <Button submit label="Save" size="large" />
          </FormSection>
        </Form>
      </MainContainer>
    </>
  );
};

export default asScreen(SecurityAndPermissions);

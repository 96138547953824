import type { FC } from 'react';

import ArrowRightEndOnRectangle from './ArrowRightEndOnRectangle';
import ArrowsRightLeftIcon from './ArrowsRightLeftIcon';
import CalendarIcon from './CalendarIcon';
import Cog8ToothIcon from './Cog8ToothIcon';
import DarkModeToggler from './DarkModeToggler';
import LogoItem from './LogoItem';
import NavItem from './NavItem';
import RectangleStackIcon from './RectangleStackIcon';
import UserCircleIcon from './UserCircleIcon';
import UsersIcon from './UsersIcon';

type MenuItem = 'dispatch_hq' | 'appointment_types' | 'agents' | 'integrations' | 'settings' | 'profile';
interface Props {
  activeItem: MenuItem;
}

const Navigation: FC<Props> = ({ activeItem }) => {
  return (
    <nav className="border-tz-gray-300/20 bg-tz-gray-100 dark:border-tz-gray-800/50 dark:bg-tz-gray-900 h-full w-16 border-r px-2 py-4 [grid-area:vertical-navbar]">
      <ul className="flex h-full flex-col gap-y-3">
        <LogoItem />
        <NavItem active={activeItem === 'dispatch_hq'} icon={<CalendarIcon />} url="/dispatch_hq" />
        <NavItem active={activeItem === 'appointment_types'} icon={<RectangleStackIcon />} url="/appointment_types" />
        <NavItem active={activeItem === 'agents'} icon={<UsersIcon />} url="/agents" />
        <NavItem active={activeItem === 'integrations'} icon={<ArrowsRightLeftIcon />} url="/integrations" />
        <NavItem active={activeItem === 'settings'} icon={<Cog8ToothIcon />} url="/integrations" />
        <li className="grow" />
        <DarkModeToggler />
        <NavItem active={activeItem === 'profile'} icon={<UserCircleIcon />} url="/profile" />
        <NavItem active={false} icon={<ArrowRightEndOnRectangle />} url="/logout" />
      </ul>
    </nav>
  );
};

export default Navigation;

import type { CalendarState } from '../calendarReducer';

import CalendarEntryGroupsGenerator from './BuildCalendarEntryGroupsByUser/CalendarEntryGroupsGenerator';
import CalendarEntryTreesGenerator from './BuildCalendarEntryGroupsByUser/CalendarEntryTreesGenerator';

import type {
  BroadcastOperation,
  CalendarEntriesByUser,
  CalendarEntry,
  CalendarEntryTrees,
  ViewMode,
} from 'admin_ui/screens/NewUi/DispatchHQ/types';
import { getCalendarEntriesByUser } from 'admin_ui/screens/NewUi/DispatchHQ/Utils/getCalendarEntriesByUser';
import { getDateRange } from 'admin_ui/screens/NewUi/DispatchHQ/Utils/getDateRange';
import { getEndDateThreshold } from 'admin_ui/screens/NewUi/DispatchHQ/Utils/getEndDateThreshold';
import { sortEntries } from 'admin_ui/screens/NewUi/DispatchHQ/Utils/sortEntries';
import trimCalendarEntries from 'admin_ui/screens/NewUi/DispatchHQ/Utils/trimCalendarEntries';

import { DateTimeManager } from '../../DateTimeManager';

type OperationParams = {
  calendarEntry: CalendarEntry;
  calendarEntries: CalendarEntriesByUser;
  calendarEntriesOfAUser: CalendarEntry[];
};

const updateCalendarEntriesOnBroadcast: (
  state: CalendarState,
  calendarEntry: CalendarEntry,
  operation: BroadcastOperation,
  viewMode: ViewMode
) => CalendarState = (state, calendarEntry, operation, viewMode) => {
  const { calendarEntries, calendarEntryTreesByUser, timezone } = state;

  const { userId } = calendarEntry;

  if (!isUpdateWithinCurrentDateRange(state, calendarEntry, viewMode) && operation !== 'delete') {
    return state;
  }

  let calendarEntriesOfAUser = getCalendarEntriesByUser(calendarEntries, userId);
  let calendarEntriesStateChange = {};
  let calendarEntryTreesStateChange = {};

  const trimmedCalendarEntry = trimCalendarEntries(
    [calendarEntry],
    getDateRange(viewMode, state.dateRanges).startDate,
    getDateRange(viewMode, state.dateRanges).endDate,
    timezone
  );

  if (!calendarEntriesOfAUser) {
    const newCalendarEntryByUser = { [userId]: [trimmedCalendarEntry[0]] };

    calendarEntriesStateChange = { ...calendarEntries, ...newCalendarEntryByUser };
  } else {
    const operationParams: OperationParams = {
      calendarEntry: trimmedCalendarEntry[0],
      calendarEntries,
      calendarEntriesOfAUser,
    };

    switch (operation) {
      case 'create':
        calendarEntriesStateChange = onBroadcastCreateCalendarEntry({ ...operationParams, timezone: state.timezone });
        break;
      case 'update':
        calendarEntriesStateChange = onBroadcastUpdateCalendarEntry({ ...operationParams, timezone: state.timezone });
        break;
      case 'delete':
        calendarEntriesStateChange = onBroadcastDeleteCalendarEntry(operationParams);
        break;
      default:
        break;
    }
  }

  calendarEntryTreesStateChange =
    viewMode === 'day'
      ? updateCalendarEntryTreeOnBroadcast(
          calendarEntriesStateChange,
          calendarEntryTreesByUser,
          state.timezone,
          userId.toString()
        )
      : {};

  return {
    ...state,
    calendarEntries: calendarEntriesStateChange,
    calendarEntryTreesByUser: calendarEntryTreesStateChange,
  };
};

export default updateCalendarEntriesOnBroadcast;

const isUpdateWithinCurrentDateRange = (state: CalendarState, calendarEntry: CalendarEntry, viewMode: ViewMode) => {
  const startDateTime = new DateTimeManager(
    state.timezone,
    getDateRange(viewMode, state.dateRanges).startDate
  ).getStartOfDay();

  const endDateTime = new DateTimeManager(
    state.timezone,
    getEndDateThreshold(getDateRange(viewMode, state.dateRanges).endDate)
  ).getStartOfDay();

  const newEntryStartDateWithinRange = new Date(calendarEntry.startTime) >= startDateTime;
  const newEntryEndDateWithinRange = new Date(calendarEntry.endTime) < endDateTime;

  const isWithinRange = newEntryStartDateWithinRange && newEntryEndDateWithinRange;

  if (!isWithinRange) {
    return false;
  }

  return true;
};

/* On Create action */
/* ----------------------------------- */
const onBroadcastCreateCalendarEntry = ({
  calendarEntry,
  calendarEntries,
  calendarEntriesOfAUser,
  timezone,
}: OperationParams & { timezone: string }): CalendarEntriesByUser => {
  const copiedCalendarEntriesOfAUser = calendarEntriesOfAUser.slice();

  copiedCalendarEntriesOfAUser.push(calendarEntry);

  return {
    ...calendarEntries,
    [calendarEntry.userId]: sortEntries(copiedCalendarEntriesOfAUser, timezone),
  };
};

/* On Update action */
/* ----------------------------------- */
const onBroadcastUpdateCalendarEntry = ({
  calendarEntry,
  calendarEntries,
  calendarEntriesOfAUser,
  timezone,
}: OperationParams & { timezone: string }): CalendarEntriesByUser => {
  const updatedCalendarEntriesArray = calendarEntriesOfAUser.map(entry => {
    return entry.id === calendarEntry.id ? calendarEntry : entry;
  });

  return {
    ...calendarEntries,
    [calendarEntry.userId]: sortEntries(updatedCalendarEntriesArray, timezone),
  };
};

/* On Delete action */
/* ----------------------------------- */
const onBroadcastDeleteCalendarEntry = ({
  calendarEntry,
  calendarEntries,
  calendarEntriesOfAUser,
}: OperationParams): CalendarEntriesByUser => {
  const copiedCalendarEntriesArray = calendarEntriesOfAUser.slice(0);

  const filterdCalendarEntriesArray = copiedCalendarEntriesArray.filter(entry => entry.id !== calendarEntry.id);

  return { ...calendarEntries, [calendarEntry.userId]: filterdCalendarEntriesArray };
};

/* Update tree structure for a user on broadcast */
/* ----------------------------------- */
const updateCalendarEntryTreeOnBroadcast = (
  calendarEntriesByUser: CalendarEntriesByUser,
  calendarEntryTreesByUser: CalendarEntryTrees,
  timezone: string,
  userId: string
): CalendarEntryTrees => {
  let newTreeStructureForAUser = { [userId]: { calendarEntryMap: {}, calendarEntryGroupMap: {} } };

  const calendarEntryGroupsGenerator = new CalendarEntryGroupsGenerator(calendarEntriesByUser, [userId], timezone); // Only work with the user that has the new entry
  const calendarEntryGroups = calendarEntryGroupsGenerator.generateEntryGroupsByUser();

  const calendarEntryTreesByUserGenerator = new CalendarEntryTreesGenerator(calendarEntryGroups, timezone);
  newTreeStructureForAUser = calendarEntryTreesByUserGenerator.buildCalendarEntryTrees();

  return {
    ...calendarEntryTreesByUser,
    ...newTreeStructureForAUser,
  };
};

import { useContext, type FC } from 'react';

import { useDebounce } from '@react-hook/debounce';

import AccountContext from '@/AccountContext';

import SearchableDropdownMenu from '../SearchableDropdownMenu';

import Alert from './Alert';

import { useGetCompaniesQuery } from '@graphql/generated';
import { idStringifier } from '@shared/text';

interface Props {
  name: string;
  value: number | null;
  displayName: string | null;
  error: string;
  readOnly: boolean;
  onChange?: (id: string, name: string) => void;
}

const CompanySearch: FC<Props> = ({
  name,
  value: initialValue,
  displayName: initialDisplayName,
  error: initialError,
  readOnly,
  onChange,
}) => {
  const { psaType } = useContext(AccountContext);

  const [searchTerm, setSearchTerm] = useDebounce('', 250);

  const handleSearchTermChange = (value: string): void => {
    setSearchTerm(value);
  };

  const { data, error, loading } = useGetCompaniesQuery({
    skip: readOnly,
    variables: {
      query: searchTerm,
      includeAutotaskCompanies: psaType === 'autotask',
      includeConnectWiseCompanies: psaType === 'connect_wise',
    },
  });

  const companies = (data?.['autotask'] || data?.['connectWise'])?.companies;

  if (error) return <Alert psaType={psaType} />;

  return (
    <div className="position-relative">
      <SearchableDropdownMenu
        data={(companies || []).map(c => idStringifier(c))}
        displayName={initialDisplayName || 'Select a Company...'}
        error={initialError}
        readOnly={readOnly}
        name={name}
        value={initialValue || undefined}
        loading={loading}
        filterPlaceholder="Filter by company name..."
        emptyDataMessage="No companies found."
        loadingMessage={`Loading companies from ${psaType === 'autotask' ? 'Autotask' : 'ConnectWise PSA'}...`}
        onSearchTermChange={handleSearchTermChange}
        onChange={onChange}
      />
    </div>
  );
};

export default CompanySearch;

import type { FC, PropsWithChildren } from 'react';

import { twMerge } from 'tailwind-merge'

interface Props {
  disabled?: boolean;
  className?: string;
}

const TextLarge: FC<PropsWithChildren<Props>> = ({ children, className, disabled }) => {
  return (
    <p
      className={twMerge(
        'text-lg font-medium leading-normal',
        disabled ? 'text-tz-gray-300 dark:text-tz-gray-500' : 'text-tz-gray-900 dark:text-tz-gray-100',
        className
      )}
    >
      {children}
    </p>
  );
};

export default TextLarge;

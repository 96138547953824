import type { CSSProperties, FC } from 'react';

import CurrentTimeIndicator from '../CurrentTimeIndicator';

import CalendarColumn from './CalendarColumn';

import { useAppSelector } from '../../../hooks';
import { selectCellWidth, selectZoom } from '../../../Reducers/uiReducer';
import { selectVisibleUserIds } from '../../../Reducers/calendarReducer';

const CalendarArea: FC = () => {
  const cellWidth = useAppSelector(selectCellWidth);
  const visibleUserIds = useAppSelector(selectVisibleUserIds);
  const zoom = useAppSelector(selectZoom);

  const style: CSSProperties = {
    gridTemplateColumns: `repeat(${visibleUserIds.length}, ${cellWidth * zoom}px)`,
  };

  return (
    <div className="relative">
      <CurrentTimeIndicator />
      <div className="grid" style={style}>
        {visibleUserIds.map((userId, i) => (
          <CalendarColumn key={`row-${i}`} firstColumn={i === 0} userId={userId} />
        ))}
      </div>
    </div>
  );
};

export default CalendarArea;

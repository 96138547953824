import type { FC } from 'react';

import classNames from 'classnames';

import type { ItemModel } from './index';

import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props extends ItemModel {
  selectedItems: ItemModel[];
  firstItemName: string;
  canUnselect: boolean;
  canSelect: boolean;
  isMulti: boolean;
  onBlur: (e?: MouseEvent | null) => void;
  onUpdateSelectedItems: (items: ItemModel[]) => void;
  onSetSearchPhrase: (searchPhrase: string) => void;
}

const Item: FC<Props> = ({
  id,
  name,
  type,
  videoCalls,
  selectedItems,
  firstItemName,
  canSelect,
  canUnselect,
  isMulti,
  onBlur,
  onUpdateSelectedItems,
  onSetSearchPhrase,
}) => {
  const selected = selectedItems.find(item => item.id === id);

  const selectItem = (item: ItemModel) => {
    // For single select replace current value
    if (!isMulti) {
      onUpdateSelectedItems([item]);
      return onBlur();
    }

    // For multi select add current value but only if there are less items than max
    if (canSelect) {
      onUpdateSelectedItems([...selectedItems, item]);
      canSelect ? onSetSearchPhrase('') : onBlur();
    }
  };

  const deselectItem = (itemId: string) => {
    if (isMulti && canUnselect) {
      onUpdateSelectedItems(selectedItems.filter(item => item.id !== itemId));
    }
  };

  const handleToggleSelect = ({ id, name, type }: { id: string; name: string; type?: string }) => {
    const selected = selectedItems.map(item => item.id).indexOf(id) >= 0;

    if (selected) {
      deselectItem(id);
    } else {
      selectItem({ id, name, type });
    }

    onBlur();
  };

  const handleMouseDown = () => {
    handleToggleSelect({ id, name: name, type });
  };

  const handleKeyDown = e => {
    if (e.key !== 'Enter') return;
    e.stopPropagation();
    handleToggleSelect({ id, name: name, type });
    document.getElementById('select-' + firstItemName)?.focus();
  };

  return (
    <div
      id={id}
      role="option"
      aria-selected={Boolean(selected)}
      tabIndex={0}
      className={classNames('select-item', { selected: selected })}
      onMouseDown={handleMouseDown}
      onKeyDown={handleKeyDown}
    >
      {name}

      {type && type === 'team' && <FontAwesomeIcon icon="user-friends" className="not-selected-item-icon" />}

      {videoCalls && videoCalls.length > 0 && <FontAwesomeIcon icon="video" className="not-selected-item-icon" />}
    </div>
  );
};

export default Item;

import type { FC } from 'react';

import { useAppSelector } from '@/screens/NewUi/DispatchHQ/hooks';
import {
  selectAreaSpecificTimezone,
  selectSelectedCalendarEntry,
  selectTimezone,
} from '@/screens/NewUi/DispatchHQ/Reducers/calendarReducer';
import { DateTimeManager } from '@/screens/NewUi/DispatchHQ/DateTimeManager';

const ScheduledTime: FC = () => {
  const areaSpecificTimezone = useAppSelector(selectAreaSpecificTimezone);
  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);
  const timezone = useAppSelector(selectTimezone);

  const startTime = new DateTimeManager(timezone, selectedCalendarEntry?.startTime);
  const shortTime = startTime.shortTime();
  const dayNameInWeek = startTime.dayNameInWeek();
  const dayNumber = startTime.dayNumber();
  const month = startTime.month();
  const year = startTime.year();

  return (
    <>
      {shortTime} on {dayNameInWeek}, {dayNumber} {month} {year} · {areaSpecificTimezone}
    </>
  );
};

export default ScheduledTime;

import type { FC } from 'react';
import { Fragment, useRef, useState } from 'react';

import FullCalendar from '@fullcalendar/react';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import classNames from 'classnames';
import type { EventDragStopArg, EventResizeDoneArg } from '@fullcalendar/interaction';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import type { CalendarApi, DateSelectArg, EventApi, EventDropArg } from '@fullcalendar/core';

import { isWithinElemenet } from '@shared/utilities';
import type { AvailabilityBlock, DayName } from '@models/Availability';

interface Props {
  availabilityBlocks?: AvailabilityBlock[] | null | undefined;
  enabled: boolean;
  name: string;
  onChange?: () => void;
}

const AvailabilityCalendar: FC<Props> = ({
  availabilityBlocks: initialAvailabilityBlocks,
  enabled,
  name,
  onChange,
}) => {
  const [availabilityBlocks, setAvailabilityBlocks] = useState(initialAvailabilityBlocks || []);

  const [showOutsideOfBusinessHours, setShowOutsideOfBusinessHours] = useState(
    hasBlockOutsideOfBusinessHours(initialAvailabilityBlocks as AvailabilityBlock[])
  );

  const container = useRef(null);

  const addEvent = ({ start, end, view: { calendar } }: DateSelectArg): void => {
    calendar.addEvent({ start, end });
    reloadAvalabilityBlocks(calendar);
  };

  const removeEvent = ({ event, jsEvent, view: { calendar } }: EventDragStopArg): void => {
    if (!container.current) return;
    if (isWithinElemenet({ x: jsEvent.pageX, y: jsEvent.pageY }, container.current)) return;

    event.remove(); // Remove event if dragged outside of the calendar
    reloadAvalabilityBlocks(calendar);
  };

  const moveEvent = ({ view: { calendar } }: EventDropArg): void => {
    reloadAvalabilityBlocks(calendar);
  };

  const resizeEvent = ({ view: { calendar } }: EventResizeDoneArg): void => {
    reloadAvalabilityBlocks(calendar);
  };

  const reloadAvalabilityBlocks = (calendar: CalendarApi): void => {
    if (onChange) onChange();

    setAvailabilityBlocks(conversions.fromEvents(calendar.getEvents()));
  };

  const classes = classNames('fullcalendar', { disabled: !enabled });

  return (
    <>
      <div className="ms-auto mb-2 mt-1" style={{ width: 'fit-content' }}>
        <div className="form-check form-switch">
          <input
            id="show_outside_business_hours"
            name="show_outside_business_hours"
            className="form-check-input"
            type="checkbox"
            disabled={!enabled}
            checked={showOutsideOfBusinessHours}
            value={showOutsideOfBusinessHours.toString()}
            onChange={() => setShowOutsideOfBusinessHours(!showOutsideOfBusinessHours)}
          />
          <label className="form-check-label" htmlFor="show_outside_business_hours">
            Show full 24-hour day
          </label>
        </div>
      </div>

      <div ref={container} className={classes}>
        {availabilityBlocks.length > 0 ? (
          availabilityBlocks.map((ab, i) => (
            <Fragment key={i}>
              <input type="hidden" name={`${name}[][start_time]`} value={ab.startTime} />
              <input type="hidden" name={`${name}[][end_time]`} value={ab.endTime} />
              <input type="hidden" name={`${name}[][day]`} value={ab.day} />
            </Fragment>
          ))
        ) : (
          <input type="hidden" name={`${name}[]`} value="" />
        )}

        <FullCalendar
          plugins={[bootstrap5Plugin, interactionPlugin, timeGridPlugin]}
          allDaySlot={false}
          contentHeight="auto"
          dayHeaderFormat={{ weekday: 'short' }}
          dragRevertDuration={0}
          editable={enabled}
          eventDragStop={removeEvent}
          eventDrop={moveEvent}
          eventOverlap={false}
          eventResizableFromStart={true}
          eventResize={resizeEvent}
          eventTimeFormat={{ hour: 'numeric', minute: '2-digit', meridiem: 'short' }}
          initialDate={conversions.now}
          initialEvents={conversions.toEvents(availabilityBlocks)}
          initialView="timeGridWeek"
          expandRows={false}
          headerToolbar={false}
          nowIndicator={false}
          select={addEvent}
          selectable={enabled}
          selectConstraint={{ startTime: '00:00', endTime: '24:00' }}
          eventConstraint={{ startTime: '00:00', endTime: '24:00' }}
          slotMinTime={showOutsideOfBusinessHours ? '00:00:00' : '06:00:00'}
          slotMaxTime={showOutsideOfBusinessHours ? '24:00:00' : '18:00:00'}
          selectOverlap={false}
          slotDuration="00:15:00"
          slotLabelInterval="01:00"
          themeSystem="bootstrap5"
        />
      </div>
    </>
  );
};

export default AvailabilityCalendar;

// FullCalendar requires full dates on events so we need to convert availability blocks times to full dates.
// We use fixed dates between 2020-03-01 and 2020-03-07 to represent weekly schedule.
const conversions = {
  now: new Date('2020-03-01T00:00:00'), // Date where Sunday is the first day of the month
  dateOnly(date: Date): Date {
    const d = new Date(date.getTime());
    d.setHours(0);
    d.setMinutes(0);

    return d;
  },
  days: ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
  toEvents(availabilityBlocks: AvailabilityBlock[]): { start: string; end: string }[] {
    return availabilityBlocks.map(b => {
      return { start: this.convertToStringDate(b.startTime, b.day), end: this.convertToStringDate(b.endTime, b.day) };
    });
  },
  fromEvents(events: EventApi[]): AvailabilityBlock[] {
    return events.map(e => {
      return {
        startTime: this.convertToStringTime(e.start),
        endTime:
          this.convertToStringDay(e.start) !== this.convertToStringDay(e.end)
            ? this.convertToStringTime(e.end, true)
            : this.convertToStringTime(e.end),
        day: this.convertToStringDay(e.start),
      };
    });
  },
  convertToStringDay(date: Date): DayName {
    return date.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase() as DayName;
  },
  convertToStringDate(time: string, day: string): string {
    const dayNumber = this.days.indexOf(day);

    return `2020-03-0${dayNumber + 1}T${time}`;
  },
  convertToStringTime(date: Date, hours24 = false): string {
    const time = date.toLocaleTimeString('en-US', { hourCycle: 'h23' });
    let [hours, minutes] = time.split(':');
    if (hours24 && hours === '00') hours = '24';
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}:00`;
  },
};

const hasBlockOutsideOfBusinessHours = (availabilityBlocks: AvailabilityBlock[]): boolean => {
  return !availabilityBlocks.every(block => block.startTime >= '06:00:00' && block.endTime <= '18:00:00');
};

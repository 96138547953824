import type { FC } from 'react';
import { useContext, useState } from 'react';

import Immutable from 'immutable';
import type { ContentState, ContentBlock } from 'draft-js';
import { EditorBlock, Modifier, SelectionState } from 'draft-js';

import ConditionModal from './ConditionModal';

import VariablesContext from '@shared/VariablesContext';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  block: ContentBlock;
  contentState: ContentState;
  selection: SelectionState;
  blockProps: {
    pushContentState: (newContentState: ContentState) => void;
    setConditionalShown: (value: boolean) => void;
    readOnly: boolean;
  };
}

const initialStateFromBlock: (block: ContentBlock) => {
  initialIsConditional: boolean;
  initialVariable?: string;
  initialCondition?: string;
} = block => {
  const data = block.getData();

  const initialIsConditional: boolean = data.get('isConditional') || false;
  const initialVariable = initialIsConditional ? data.get('variable') : undefined;
  const initialCondition = initialIsConditional ? data.get('condition') : undefined;

  return { initialIsConditional, initialVariable, initialCondition };
};

const Paragraph: FC<Props> = props => {
  const { block, contentState, blockProps } = props;
  const { variables } = useContext(VariablesContext);

  const { initialIsConditional, initialVariable, initialCondition } = initialStateFromBlock(block);

  const [isConditional, setIsConditional] = useState(initialIsConditional);
  const [variable, setVariable] = useState<string | undefined>(initialVariable);
  const [condition, setCondition] = useState<string | undefined>(initialCondition);
  const [conditionalShown, setConditionalShown] = useState(false);

  const onEditConditionClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();

    blockProps.setConditionalShown(true);
    setConditionalShown(true);
  };

  const onClose = () => {
    blockProps.setConditionalShown(false);
    setConditionalShown(false);
  };

  const onSave = (value: { variable?: string; condition?: string; isConditional: boolean }) => {
    const { variable, condition, isConditional } = value;

    setIsConditional(isConditional);
    setVariable(variable);
    setCondition(condition);

    const selection = SelectionState.createEmpty(block.getKey());
    const newContentState = Modifier.setBlockData(
      contentState,
      selection,
      Immutable.Map({ isConditional, variable, condition })
    );
    blockProps.pushContentState(newContentState);

    blockProps.setConditionalShown(false);
    setConditionalShown(false);
  };

  return (
    <div className="NoteEditor__Paragraph">
      <div className="NoteEditor__ParagraphContent">
        <EditorBlock {...props} />
      </div>
      <button
        type="button"
        className="NoteEditor__ParagraphConditional"
        contentEditable={false}
        disabled={blockProps.readOnly}
        onMouseDown={onEditConditionClick}
      >
        {isConditional ? (
          <>
            Only include when{' '}
            <strong>{variables.find(v => v.variable === variable)?.name || 'Unknown Variable'}</strong>{' '}
            {condition === 'present' ? 'is present.' : 'is not present.'}
          </>
        ) : (
          'Always include.'
        )}{' '}
        &nbsp;
        <FontAwesomeIcon icon="pen" className="NoteEditor__ParagraphConditionalEdit" />
      </button>

      <div contentEditable={false}>
        <ConditionModal
          isOpen={conditionalShown}
          conditional={isConditional}
          variable={variable}
          condition={condition}
          onClose={onClose}
          onSave={onSave}
        />
      </div>
    </div>
  );
};

export default Paragraph;

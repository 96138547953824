import type { FC } from 'react';

import StackedListItem from 'design_system/Components/StackedList/Item';
import H6 from 'design_system/Typography/Headings/H6';
import Badge from 'design_system/Components/Badge';

interface Props {
  id: number;
  name: string;
  verified: boolean;
}

const Domain: FC<Props> = ({ id, name, verified }) => {
  return (
    <StackedListItem orientation="vertical" url={`/settings/domains/${id}`}>
      <div className="flex items-center gap-x-1.5 py-2">
        <div className="w-max">
          <H6 className="mb-0">{name}</H6>
          <span className="block h-px max-w-0 bg-tz-gray-500 transition-all duration-300 group-hover:max-w-full dark:bg-tz-gray-400" />
        </div>
        <div className="grow" />
        <div>{verified ? <Badge color="green">Verified</Badge> : <Badge color="red">Not Verified</Badge>}</div>
      </div>
    </StackedListItem>
  );
};

export default Domain;

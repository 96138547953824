import type { FC } from 'react';

import type { AppointmentType as AppointmentTypeModel } from '@graphql/generated';
import StackedListItem from 'design_system/Components/StackedList/Item';
import H6 from 'design_system/Typography/Headings/H6';
import { displayDuration } from '@shared/text';
import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';
import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';
import { displayBusinessDuration } from '@shared/utilities';
import type { DurationUnit } from '@models/AppointmentType';
import Badge from 'design_system/Components/Badge';

interface Props {
  appointmentType: AppointmentTypeModel;
}

const AppointmentType: FC<Props> = ({ appointmentType }) => {
  return (
    <StackedListItem
      orientation="horizontal"
      tableColumns="[grid-template-columns:1fr_12rem_12rem_12rem_12rem]"
      className="pb-2 pt-2"
    >
      <div className="flex-grow">
        <a href={`/appointment_types/${appointmentType.id}`}>
          <div className="me-2 inline-block w-max">
            <H6 className="mb-0">{appointmentType.internalName}</H6>
            <span className="bg-tz-gray-700 dark:bg-tz-gray-400 block h-px max-w-0 transition-all duration-300 group-hover:max-w-full" />
          </div>
        </a>
        {appointmentType.workflow && <Badge color="gray">Workflow</Badge>}
      </div>
      <TextSmall>{displayDuration(appointmentType.durationMins)}</TextSmall>
      <div>
        <TextExtraSmall>
          {appointmentType.videoCallingModes?.find(vcm => vcm.type === 'teams_video') && (
            <Badge color="blue">Microsoft Teams</Badge>
          )}
        </TextExtraSmall>
        <TextExtraSmall>
          {appointmentType.videoCallingModes?.find(vcm => vcm.type === 'zoom_video') && (
            <Badge color="green">Zoom</Badge>
          )}
        </TextExtraSmall>
      </div>
      <TextSmall className="text-center">
        {(appointmentType.bufferBeforeMins > 0 && displayDuration(appointmentType.bufferBeforeMins)) || (
          <span className="text-tz-gray-400">&mdash;</span>
        )}
        &nbsp;/&nbsp;
        {(appointmentType.bufferAfterMins > 0 && displayDuration(appointmentType.bufferAfterMins)) || (
          <span className="text-tz-gray-400">&mdash;</span>
        )}
      </TextSmall>
      <TextSmall className="text-center">
        {(appointmentType.minimumNoticeDuration > 0 &&
          displayBusinessDuration(
            appointmentType.minimumNoticeDuration,
            appointmentType.minimumNoticeUnit as DurationUnit
          )) || <span className="text-tz-gray-400">&mdash;</span>}
      </TextSmall>
    </StackedListItem>
  );
};

export default AppointmentType;

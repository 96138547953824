import type { FC } from 'react';

import WeekPeriod from './WeekPeriod';
import DayPeriod from './DayPeriod';

import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';

import { useAppSelector } from '../../hooks';
import { selectViewMode } from '../../Reducers/uiReducer';
import { selectAreaSpecificTimezone } from '../../Reducers/calendarReducer';

const TimePeriod: FC = () => {
  const viewMode = useAppSelector(selectViewMode);
  const areaTimezoneName = useAppSelector(selectAreaSpecificTimezone);

  return (
    <div className="flex items-center">
      {viewMode === 'day' && <DayPeriod />}
      {viewMode === 'week' && <WeekPeriod />}
      <TextSmall className="text-tz-blue-600 mb-2 ps-4">All times are in {areaTimezoneName}</TextSmall>
    </div>
  );
};

export default TimePeriod;

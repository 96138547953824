import type { FC } from 'react';

import { useAppSelector } from '@/screens/NewUi/DispatchHQ/hooks';
import { selectSelectedCalendarEntry } from '@/screens/NewUi/DispatchHQ/Reducers/calendarReducer';

import ScheduleEntry from './ScheduleEntry';
import Activity from './Activity';

const ConnectWiseAppointmentDetails: FC = () => {
  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);

  return <>{selectedCalendarEntry?.psaAppointmentType === 'activity' ? <Activity /> : <ScheduleEntry />}</>;
};

export default ConnectWiseAppointmentDetails;

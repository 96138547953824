import type { CSSProperties, FC } from 'react';

import { formatInTimeZone } from 'date-fns-tz';

import { tw } from '@/utilities';

import FadeInTransition from 'design_system/FadeInTransition';
import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';
import type { CalendarEntry as CalendarEntryModel } from 'admin_ui/screens/NewUi/DispatchHQ/types';
import { useAppDispatch, useAppSelector } from 'admin_ui/screens/NewUi/DispatchHQ/hooks';
import { selectTimezone } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/calendarReducer';
import { selectViewMode, selectZoom } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/uiReducer';
import { selectCalendarEntry, setFlyoutMode } from 'admin_ui/screens/NewUi/DispatchHQ/actions';
import type { Color } from 'design_system/Components/Badge';
import Badge from 'design_system/Components/Badge';

const BASE_FONT_SIZE = 0.75;
const BASE_VERTICAL_PADDING = 0.25;
const BASE_ENTRY_HEIGHT = 42;
const VERTICAL_SIZE_DIVISOR = 30.0;

const ONE_MINUTE_IN_PX = 1.6;

const SHOW_PSA_TICKET_NUMBER_ZOOM_LEVEL = 1.7;
const SHOW_PSA_COMPANY_ZOOM_LEVEL = 1.9;
const SHOW_PSA_TICKET_SUMMARY_ZOOM_LEVEL = 2.3;
const SHOW_TAGS_ZOOM_LEVEL = 1.6;

const ENTRY_CLASS_NAMES = tw`bg-tz-gray-100 text-tz-gray-800 hover:bg-tz-gray-200 hover:text-tz-gray-900 relative left-0 top-0 overflow-hidden rounded hover:cursor-pointer`;
const ENTRY_DETAILS_AREA_CLASS_NAMES = 'flex items-start justify-start overflow-hidden';
const ENTRY_TIME_SECTION_WIDTH = 56;

interface Props {
  entry: CalendarEntryModel;
}

const CalendarEntry: FC<Props> = ({ entry }) => {
  const dispatch = useAppDispatch();

  const timezone = useAppSelector(selectTimezone);
  const zoom = useAppSelector(selectZoom);
  const viewMode = useAppSelector(selectViewMode);

  const verticalSizeRatio = entry.duration / VERTICAL_SIZE_DIVISOR;

  const paddingYRem = Math.min(BASE_VERTICAL_PADDING * verticalSizeRatio, BASE_VERTICAL_PADDING);

  const evaluatingStartTime = entry.originalStartTime || entry.startTime;
  const formattedStartTime = formatInTimeZone(new Date(evaluatingStartTime), timezone, 'h:mma').toLowerCase();

  const heightPx = viewMode === 'day' ? entry.duration * ONE_MINUTE_IN_PX * zoom : BASE_ENTRY_HEIGHT * zoom;

  const style: CSSProperties = {
    height: `${heightPx}px`,
    lineHeight: `${Math.min(zoom * verticalSizeRatio, 1)}rem`,
    fontSize: `${Math.min(BASE_FONT_SIZE * verticalSizeRatio * zoom, BASE_FONT_SIZE)}rem`,
    padding: `${paddingYRem}rem 0.5rem`,
  };

  const handleSelectCalendarEntry = () => {
    dispatch(selectCalendarEntry({ calendarEntryId: entry.id, userId: entry.userId }));
    dispatch(setFlyoutMode({ mode: 'info' }));
  };

  return (
    <div role="presentation" className={ENTRY_CLASS_NAMES} onClick={handleSelectCalendarEntry}>
      <div className={ENTRY_DETAILS_AREA_CLASS_NAMES} style={style}>
        <div>
          <TextExtraSmall className="w-14 shrink-0">{formattedStartTime}</TextExtraSmall>
          {entry.originalStartTime && (
            <TextExtraSmall className="w-14 shrink-0">
              {formatInTimeZone(evaluatingStartTime, timezone, 'd MMM')}
            </TextExtraSmall>
          )}
        </div>
        <div className={`w-[calc(100%-${ENTRY_TIME_SECTION_WIDTH}px)]`}>
          <div className="flex">
            <TextExtraSmall className="truncate font-bold">{entry.name}</TextExtraSmall>
            {entry.psaTicketNumber && (
              <FadeInTransition display={zoom >= SHOW_PSA_TICKET_NUMBER_ZOOM_LEVEL}>
                <TextExtraSmall className="ms-1"> ({entry.psaTicketNumber})</TextExtraSmall>
              </FadeInTransition>
            )}
          </div>
          {zoom >= SHOW_TAGS_ZOOM_LEVEL &&
            entry.taggings.map((tagging, i) => (
              <Badge className="my-px" key={`key-${i}`} color={tagging.tag.color as Color}>
                {tagging.tag.name}
              </Badge>
            ))}
          {entry.psaCompanyName && (
            <FadeInTransition display={zoom >= SHOW_PSA_COMPANY_ZOOM_LEVEL}>
              <div className="flex">
                <TextExtraSmall>{entry.psaCompanyName}</TextExtraSmall> &nbsp;-&nbsp;
                <TextExtraSmall>{entry.psaCompanyId}</TextExtraSmall>
              </div>
            </FadeInTransition>
          )}
          {entry.psaTicketSummary && (
            <FadeInTransition display={zoom >= SHOW_PSA_TICKET_SUMMARY_ZOOM_LEVEL}>
              <TextExtraSmall className="line-clamp-1">{entry.psaTicketSummary}</TextExtraSmall>
            </FadeInTransition>
          )}
        </div>
      </div>
      <div className="bg-tz-gray-500 absolute bottom-0 left-px top-0 w-1 rounded-bl rounded-tl" />
    </div>
  );
};

export default CalendarEntry;

import type { FC } from 'react';
import { useRef, useState } from 'react';

import UserItem from './UserItem';
import SelectedUser from './SelectedUser';

import Button from 'design_system/Components/Button';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';
import SearchableMultiSelect from 'design_system/Components/SearchableMultiSelect';
import { setVisibleUserIds, setZoom } from 'admin_ui/screens/NewUi/DispatchHQ/actions';
import { selectUsers, selectVisibleUserIds } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/calendarReducer';
import { useAppDispatch, useAppSelector } from 'admin_ui/screens/NewUi/DispatchHQ/hooks';
import { sortUsers } from 'admin_ui/screens/NewUi/DispatchHQ/Utils/sortUsers';
import SelectorPopover from 'admin_ui/screens/NewUi/DispatchHQ/Components/SelectorPopover';

const BASE_ZOOM = 1;

const UsersSelector: FC = () => {
  const dispatch = useAppDispatch();

  const allUsers = useAppSelector(selectUsers);
  const visibleUserIds = useAppSelector(selectVisibleUserIds);

  const [usersToBeVisible, setUsersToBeVisible] = useState(visibleUserIds);

  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const userItems = sortUsers(Object.values(allUsers)).map(user => {
    return {
      id: user.id.toString(),
      name: user.name,
    };
  });

  const handleChangeVisibleUsers = (ids: string[]) => {
    setUsersToBeVisible(ids);
  };

  const handleApplyVisibleUsers = () => {
    dispatch(setVisibleUserIds({ visibleUserIds: usersToBeVisible }));
    dispatch(setZoom({ zoom: BASE_ZOOM }));

    if (closeButtonRef.current) closeButtonRef.current.click();
  };

  return (
    <div className="me-20">
      <SelectorPopover
        ref={closeButtonRef}
        panelClassName="h-fit min-h-48 w-96 relative !overflow-visible flex flex-col justify-between"
        popoverButton={
          <>
            <span>Add users to view</span>
            <FontAwesomeIcon icon="user-plus" height={12} />
          </>
        }
        title="Add users to view"
      >
        <div className="absolute left-0 top-8 w-full px-3">
          <SearchableMultiSelect
            name="active-users-list"
            initialSelectedItemIds={visibleUserIds}
            items={userItems}
            search={true}
            minSelectedItems={1}
            disabled={false}
            itemRenderer={UserItem}
            selectedItemRenderer={SelectedUser}
            onChange={handleChangeVisibleUsers}
          />
        </div>
        <Button label="Update" size="small" onClick={handleApplyVisibleUsers} />
      </SelectorPopover>
    </div>
  );
};

export default UsersSelector;

import type { FC } from 'react';

import { format, isValid } from 'date-fns';

import { useAppSelector } from '@/screens/NewUi/DispatchHQ/hooks';
import { selectSelectedCalendarEntry } from '@/screens/NewUi/DispatchHQ/Reducers/calendarReducer';

import TextSmall from 'design_system/Typography/Paragraphs/TextSmall';

const Limit: FC = () => {
  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);

  if (!selectedCalendarEntry?.limit) return null;

  const { earliestDate, earliestTime, latestDate, latestTime } = selectedCalendarEntry.limit;

  if ([earliestDate, earliestTime, latestDate, latestTime].every(v => v === null)) {
    return <TextSmall>(None)</TextSmall>;
  }

  const formattedEarliestTime = earliestTime?.slice(0, -3);
  const formattedLatestTime = latestTime?.slice(0, -3);

  const formattedEarliestDate =
    earliestDate && isValid(new Date(earliestDate)) ? format(earliestDate, 'MMMM dd, yyyy') : null;

  const formattedLatestDate = latestDate && isValid(new Date(latestDate)) ? format(latestDate, 'MMMM dd, yyyy') : null;

  if (formattedEarliestDate && formattedLatestDate) {
    return (
      <TextSmall>
        {formattedEarliestDate} {formattedEarliestTime} - {formattedLatestDate} {formattedLatestTime}
      </TextSmall>
    );
  }

  if (formattedEarliestDate) {
    return (
      <TextSmall>
        After {formattedEarliestDate} {formattedEarliestTime}
      </TextSmall>
    );
  }

  if (formattedLatestDate) {
    return (
      <TextSmall>
        Before {formattedLatestDate} {formattedLatestTime}
      </TextSmall>
    );
  }
};

export default Limit;

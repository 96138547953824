import type { FC, PropsWithChildren } from 'react';
import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../hooks';
import { selectViewMode, selectZoom } from '../Reducers/uiReducer';
import { setZoom } from '../actions';
import type { ViewMode } from '../types';

const HOURS_COLUMN_WIDTH = 96;
const MAX_ZOOM_SCALE = 2.5;
const USERS_COLUMN_WIDTH = 114;
const ZOOM_RATIO = 0.05;

interface Props {
  calendarHeader: HTMLDivElement | null;
  calendarContainer: HTMLDivElement | null;
}

const ZoomEngine: FC<PropsWithChildren<Props>> = ({ calendarHeader, children, calendarContainer }) => {
  const dispatch = useAppDispatch();

  const currentZoom = useAppSelector(selectZoom);
  const viewMode = useAppSelector(selectViewMode);

  useEffect(() => {
    if (!calendarContainer) return;

    const wheelHandler = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    };

    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        calendarContainer.classList.add('overflow-hidden');
        calendarContainer.classList.remove('overflow-auto');
      }
    };

    const keyUpHandler = (e: KeyboardEvent) => {
      e.preventDefault();
      calendarContainer.classList.add('overflow-auto');
      calendarContainer.classList.remove('overflow-hidden');
    };

    document.body.addEventListener('wheel', wheelHandler, { passive: false });
    calendarContainer.addEventListener('keydown', keyDownHandler);
    calendarContainer.addEventListener('keyup', keyUpHandler);

    return () => {
      document.body.removeEventListener('wheel', wheelHandler);
      calendarContainer.removeEventListener('keydown', keyDownHandler);
      calendarContainer.removeEventListener('keyup', keyUpHandler);
    };
  }, [calendarContainer]);

  const handleWheel = (e: React.WheelEvent<HTMLDivElement>) => {
    if (e.ctrlKey) {
      if (!calendarContainer || !calendarHeader) return;

      const newZoom = calculateNewZoom(e, currentZoom);

      const { mouseX, mouseY } = calculateCursorPositionWithinContainer(e, calendarContainer, viewMode);

      const { x, y } = calculateNewScrollOffset(mouseX, mouseY, newZoom, currentZoom, calendarContainer);

      dispatch(setZoom({ zoom: newZoom }));

      requestAnimationFrame(() => {
        calendarContainer.scrollTo({
          left: x,
          top: y,
          behavior: 'auto',
        });
        calendarHeader.scrollTo({
          left: x,
          behavior: 'auto',
        });
      });
    }
  };

  return (
    <div role="presentation" onWheel={handleWheel}>
      {children}
    </div>
  );
};

export default ZoomEngine;

const calculateNewZoom = (e: React.WheelEvent<HTMLDivElement>, oldZoom: number): number => {
  const delta = Math.max(-1, Math.min(1, -e.deltaY || e.detail));
  const newZoom = oldZoom + delta * ZOOM_RATIO * oldZoom;
  const monitoredNewZoom = Math.max(1, Math.min(MAX_ZOOM_SCALE, newZoom));

  return monitoredNewZoom;
};

const calculateCursorPositionWithinContainer = (
  e: React.WheelEvent<HTMLDivElement>,
  calendarContainer: HTMLDivElement,
  viewMode: ViewMode
): { mouseX: number; mouseY: number } => {
  const rect = calendarContainer.getBoundingClientRect();

  // Hours column and Users column are not part of the actual calendar area, so we'll need to subtract their width
  const mouseX = e.clientX - rect.left - (viewMode === 'day' ? HOURS_COLUMN_WIDTH : USERS_COLUMN_WIDTH);
  const mouseY = e.clientY - rect.top;

  return { mouseX, mouseY };
};

const calculateNewScrollOffset = (
  mouseX: number,
  mouseY: number,
  newZoom: number,
  oldZoom: number,
  calendarContainer: HTMLDivElement
  // eslint-disable-next-line max-params
): { x: number; y: number } => {
  // The distance from the mouse position to the left edge of the calendar container, plus the current scroll position
  const currentZoomDistanceX = mouseX + calendarContainer.scrollLeft;
  const currentZoomDistanceY = mouseY + calendarContainer.scrollTop;

  // The distance from the new mouse position due to the new zoom level, to the old mouse position
  const newZoomDistanceX = (currentZoomDistanceX * newZoom) / oldZoom;
  const newZoomDistanceY = (currentZoomDistanceY * newZoom) / oldZoom;

  // Calculate the new scroll offset
  const x = newZoomDistanceX - mouseX;
  const y = newZoomDistanceY - mouseY;

  return { x, y };
};

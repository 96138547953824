import type { FC } from 'react';
import { StrictMode, useCallback, useContext, useRef, useState } from 'react';

import classNames from 'classnames';

import Form from '@/Form';
import FontAwesomeIcon from '@/FontAwesomeIcon';
import UnsavedConfirmationModal from '@/ui/UnsavedConfirmationModal';
import type { TimeElapsedTriggerModel } from '@/models';
import { DirtyContext } from '@/DirtyContext';
import asScreen from '@/Screen';
import PlanContext from '@/PlanContext';
import AccountContext from '@/AccountContext';

import AppointmentDetailsConfig, { appointmentDetailsHasErrors } from './AppointmentDetailsConfig';
import AvailabilityConfig, { availabilityHasErrors } from './AvailabilityConfig';
import AutotaskConfig, { autotaskHasErrors } from './AutotaskConfig';
import ConnectWiseConfig, { connectWiseHasErrors } from './ConnectWiseConfig';
import type { Fields } from './FieldsConfig';
import FieldsConfig, { fieldsHasErrors } from './FieldsConfig';
import MessagesConfig, { messagesHasErrors } from './MessagesConfig';
import NotificationsConfig, { notificationsHasErrors } from './NotificationsConfig';
import Office365Config, { office365HasErrors } from './Office365Config';
import OnlineMeetingsConfig from './OnlineMeetingsConfig';
import RemindersConfig, { remindersHasErrors } from './RemindersConfig';
import SchedulingOptionsConfig, { schedulingOptionsHasErrors } from './SchedulingOptionsConfig';
import ShareableUrlConfig, { shareableUrlHasErrors } from './ShareableUrlConfig';
import { UrlGenerator } from './UrlGenerator';

import type { EmailTemplate } from '@models/EmailTemplate';
import Workflow from '@ui/Workflow';
import type { ResourceModel } from '@models/AppointmentType';
import type AppointmentTypeModel from '@models/AppointmentType';
import type Errors from '@models/Errors';
import type Reference from '@models/Reference';
import type VariableModel from '@models/Variable';
import type { RailsTimezone } from '@models/Timezone';
import ConfirmationModal from '@ui/ConfirmationModal';
import type { Trigger as TriggerModel } from '@graphql/generated';
import { hasIntegration } from '@shared/utilities';
import type ReminderModel from '@models/Reminder';

export type ExpanderName =
  | 'appointmentDetails'
  | 'availabilityConfig'
  | 'schedulingOptions'
  | 'fieldsConfig'
  | 'legacyMessages'
  | 'onlineMeetings'
  | 'urlBasedScheduling'
  | 'notifications'
  | 'reminders'
  | 'autotask'
  | 'connectWise'
  | 'office365';

interface Props {
  appointmentType: AppointmentTypeModel;
  availableActionOptions: { name: string; slug: string }[];
  availableTriggerOptions: { name: string; slug: string }[];
  availableLanguages: [string, string][];
  availableVariables: VariableModel[];
  confirmationTemplateName: string;
  cancellationTemplateName: string;
  customAttributes: Reference[];
  defaultCancellationTemplateName: string;
  defaultConfirmationTemplateName: string;
  defaultNotificationTemplateName: string;
  errors: Errors;
  fieldsErrors: Record<string, Errors>;
  notificationTemplateName: string;
  requiredFields: Fields;
  reminderTemplates: Reference[];
  reminders: ReminderModel[];
  remindersErrors: Errors[];
  resources: ResourceModel[];
  timezones: RailsTimezone[];
  triggers: (TriggerModel | TimeElapsedTriggerModel)[];
  url: string;
  logoUrl: string | null;
  defaultEmailTemplate: null | EmailTemplate;
}

interface GroupProps {
  canExpand: boolean;
  dirty: boolean;
  isExpanded: (section: ExpanderName) => boolean;
  onExpand: (section: ExpanderName, expanded: boolean) => void;
}

const CommonConfig: FC<Props & GroupProps> = ({ canExpand, dirty, isExpanded, onExpand, ...props }) => {
  const expanderProps = (section: ExpanderName) => {
    return {
      expanded: isExpanded(section),
      canExpand,
      dirty,
      onExpand: (expanded: boolean) => onExpand(section, expanded),
    };
  };

  return (
    <>
      <AppointmentDetailsConfig {...props} {...expanderProps('appointmentDetails')} />
      <SchedulingOptionsConfig {...props} {...expanderProps('schedulingOptions')} />
      <AvailabilityConfig {...props} {...expanderProps('availabilityConfig')} />
      <FieldsConfig {...props} {...expanderProps('fieldsConfig')} />
      <MessagesConfig {...props} {...expanderProps('legacyMessages')} />
    </>
  );
};

const ClassicConfig: FC<Props & GroupProps> = ({ canExpand, isExpanded, onExpand, dirty, ...props }) => {
  const { integrations } = useContext(AccountContext);

  const expanderProps = (section: ExpanderName) => {
    return {
      expanded: isExpanded(section),
      canExpand,
      dirty,
      onExpand: (expanded: boolean) => onExpand(section, expanded),
    };
  };

  return (
    <>
      <OnlineMeetingsConfig {...props} {...expanderProps('onlineMeetings')} />
      <ShareableUrlConfig
        {...props}
        {...expanderProps('urlBasedScheduling')}
        onDetailsExpand={() => onExpand('appointmentDetails', true)}
      />
      <NotificationsConfig
        {...props}
        {...expanderProps('notifications')}
        onMessagesExpand={() => onExpand('legacyMessages', true)}
      />
      <RemindersConfig
        {...props}
        {...expanderProps('reminders')}
        url={`/settings/appointment_types/${props.appointmentType.id}/classic/reminders`}
      />
      {hasIntegration(integrations, 'autotask') && <AutotaskConfig {...props} {...expanderProps('autotask')} />}
      {hasIntegration(integrations, 'connect_wise') && (
        <ConnectWiseConfig {...props} {...expanderProps('connectWise')} />
      )}
      {hasIntegration(integrations, 'office_365') && <Office365Config {...props} {...expanderProps('office365')} />}
    </>
  );
};

const Tabs: FC<Props & GroupProps> = ({ appointmentType, ...props }) => {
  const { allowsWorkflowEdits } = useContext(PlanContext);
  const [activeTab, setActiveTab] = useState(appointmentType.workflow ? 'workflow' : 'classic');
  const [workflowModalVisible, setWorkflowModalVisible] = useState(false);
  const form = useRef<HTMLFormElement>(null);

  const handleClassicClick = (event: React.MouseEvent): void => {
    event.preventDefault();
    setActiveTab('classic');
  };

  const handleWorkflowClick = (event: React.MouseEvent): void => {
    event.preventDefault();
    setActiveTab('workflow');
  };

  const handleWorkflowSwitchClick = (event: React.MouseEvent): void => {
    event.preventDefault();
    setWorkflowModalVisible(true);
  };

  return (
    <>
      <ul className="nav nav-tabs">
        {!appointmentType.workflow && (
          <li className="nav-item">
            <button
              className={classNames('nav-link', { active: activeTab === 'classic' })}
              onClick={handleClassicClick}
            >
              Classic Configuration
            </button>
          </li>
        )}
        <li className="nav-item">
          <button
            className={classNames('nav-link', { active: activeTab === 'workflow' })}
            onClick={handleWorkflowClick}
          >
            Workflow
          </button>
        </li>
      </ul>
      {activeTab === 'classic' ? (
        <div className="border-top-0 rounded-bottom border bg-white p-3">
          <ClassicConfig {...{ ...props, appointmentType }} />
        </div>
      ) : (
        <div className="border-top-0 rounded-bottom border bg-white p-3">
          {!appointmentType.workflow && (
            <>
              {allowsWorkflowEdits ? (
                <div className="mb-2">
                  <p>
                    This appointment type is in <strong>classic mode</strong>, which means that TimeZest manages the
                    workflows, and makes changes to them based on the configuration in the &quot;Classic
                    Configuration&quot; tab. In this mode, it&apos;s not possible to edit workflows directly, so all
                    triggers and actions are read-only.
                  </p>
                  <p>
                    If you wish to edit the triggers and actions directly, you can switch this appointment type to{' '}
                    <strong>workflow mode</strong>. You&apos;ll no longer be able to use the configuration in the
                    &quot;Classic Configuration&quot; tab to update this appointment type, but will be able to edit the
                    triggers and actions directly.
                  </p>
                  <p>
                    <strong>
                      Once an appointment type has been switched to workflow mode, it cannot be changed back.
                    </strong>
                  </p>
                  <Form url={`/settings/appointment_types/${appointmentType.id}/workflow`} ref={form}>
                    <button className="btn btn-primary" type="button" onClick={handleWorkflowSwitchClick}>
                      Switch to Workflow Mode
                    </button>
                  </Form>
                  <hr />
                  <ConfirmationModal
                    ariaLabel="Confirmation for workflow mode switch"
                    description=""
                    title="Switch this appointment type to workflow mode?"
                    isOpen={workflowModalVisible}
                    onConfirm={() => form.current?.requestSubmit()}
                    onClose={() => setWorkflowModalVisible(false)}
                  >
                    <p>
                      Clicking <strong>Confirm</strong> will switch this appointment type to workflow mode, and allow
                      you to edit the triggers and actions directly. This change won&apos;t affect clients scheduling
                      their appointments.
                    </p>

                    <p>Once this is done, it cannot be changed back.</p>
                  </ConfirmationModal>
                </div>
              ) : (
                <div className="mb-2">
                  <p>
                    This is the actual workflow that TimeZest will execute when your clients schedule. It is being
                    automatically updated as you change the settings in the <strong>Classic Workflow</strong> tab.
                  </p>
                  <p>
                    Accounts on TimeZest&apos;s Professional Plan can edit the workflow directly, to customize it even
                    further, and change TimeZest&apos;s behavior to exactly match their business.
                  </p>
                  <p>
                    <a href="/settings/account" className="btn btn-primary">
                      Upgrade Now&nbsp;
                      <FontAwesomeIcon icon="angle-right" />
                    </a>
                  </p>
                </div>
              )}
            </>
          )}
          <Workflow
            appointmentTypeId={appointmentType.id}
            triggers={props.triggers}
            readOnly={!appointmentType.workflow || !allowsWorkflowEdits}
            availableTriggerOptions={props.availableTriggerOptions}
            availableActionOptions={props.availableActionOptions}
            availableLanguages={props.availableLanguages}
            availableVariables={props.availableVariables}
            logoUrl={props.logoUrl}
            defaultEmailTemplate={props.defaultEmailTemplate || undefined}
          />
        </div>
      )}
    </>
  );
};

function determineOpenExpanderFromErrors(
  errors: Errors,
  fieldsErrors: Record<string, Errors>,
  reminderErrors: Errors[]
): ExpanderName | null {
  if (appointmentDetailsHasErrors(errors)) {
    return 'appointmentDetails';
  }

  if (availabilityHasErrors(errors)) {
    return 'availabilityConfig';
  }

  if (schedulingOptionsHasErrors(errors)) {
    return 'schedulingOptions';
  }

  if (fieldsHasErrors(fieldsErrors)) {
    return 'fieldsConfig';
  }

  if (messagesHasErrors(errors)) {
    return 'legacyMessages';
  }

  if (shareableUrlHasErrors(errors)) {
    return 'urlBasedScheduling';
  }

  if (notificationsHasErrors(errors)) {
    return 'notifications';
  }

  if (remindersHasErrors(reminderErrors)) {
    return 'reminders';
  }

  if (connectWiseHasErrors(errors)) {
    return 'connectWise';
  }

  if (autotaskHasErrors(errors)) {
    return 'autotask';
  }

  if (office365HasErrors(errors)) {
    return 'office365';
  }

  return null;
}

const AppointmentType: FC<Props> = ({ errors, fieldsErrors, remindersErrors, ...props }) => {
  const [dirty, setDirty] = useState<boolean>(false);
  const [openExpander, setOpenExpander] = useState<ExpanderName | null>(
    determineOpenExpanderFromErrors(errors, fieldsErrors, remindersErrors)
  );

  const handleDirty = useCallback((cleanDirty?: boolean) => {
    setDirty(cleanDirty ? false : true);
  }, []);

  const canExpand = () => {
    return openExpander === null || dirty === false;
  };

  const isExpanded = (section: string) => {
    return openExpander === section;
  };

  const handleExpand = (section: ExpanderName, expanded: boolean) => {
    if (expanded) {
      setOpenExpander(section);
      setDirty(false);
    } else {
      setOpenExpander(null);
      setDirty(false);
    }
  };

  return (
    <StrictMode>
      <DirtyContext.Provider value={{ handleDirty: handleDirty }}>
        <UrlGenerator appointmentType={props.appointmentType} resources={props.resources} />

        <CommonConfig
          {...props}
          errors={errors}
          fieldsErrors={fieldsErrors}
          remindersErrors={remindersErrors}
          isExpanded={isExpanded}
          canExpand={canExpand()}
          dirty={dirty}
          onExpand={handleExpand}
        />
        <Tabs
          {...props}
          errors={errors}
          fieldsErrors={fieldsErrors}
          remindersErrors={remindersErrors}
          isExpanded={isExpanded}
          canExpand={canExpand()}
          dirty={dirty}
          onExpand={handleExpand}
        />
      </DirtyContext.Provider>
      <UnsavedConfirmationModal dirty={dirty} />
    </StrictMode>
  );
};

export default asScreen(AppointmentType);

import type { FC } from 'react';

import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

import { tw } from '@/utilities';

import Option from './Option';

import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';
import type { Color } from 'design_system/Components/Badge';
import Badge from 'design_system/Components/Badge';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

const BACKGROUND_COLORS = {
  green: 'bg-green-600 dark:bg-green-500/10',
  red: 'bg-red-500 dark:bg-red-400/10',
  yellow: 'bg-yellow-600 dark:bg-yellow-500/10',
  blue: 'bg-blue-500 dark:bg-blue-500/10',
  purple: 'bg-purple-500 dark:bg-purple-500/10',
  cyan: 'bg-cyan-600 dark:bg-cyan-500/10',
  gray: 'bg-tz-gray-500 dark:text-tz-gray-300',
};

const COMBOBOX_INPUT_CLASSNAMES = tw`absolute right-[999px] h-0 max-h-0 w-0 max-w-0 border-0 bg-transparent p-0`;
const PREVIEW_CLASSNAMES = tw`flex items-center justify-between space-x-1`;
const SELECT_OPTIONS_CLASSNAMES = tw`dark:bg-tz-gray-700 dark:text-tz-gray-300 absolute right-0 top-[100%] z-10 m-0 flex h-64 w-full list-none flex-col space-y-1 overflow-y-auto bg-white`;
const TRASH_ICON_CLASSNAMES = tw`text-tz-gray-600 hover:bg-tz-gray-100 h-5 w-5 shrink-0 cursor-pointer rounded-md p-1`;

interface Props {
  newTagName: string;
  newTagColor: string;
  onCreateNewTagging: () => void;
  onChangeNewTagColor: (newTagColor: Color) => void;
  onRemoveNewTagCreation: () => void;
}

const ColorSelectBox: FC<Props> = ({
  newTagName,
  newTagColor,
  onCreateNewTagging,
  onChangeNewTagColor,
  onRemoveNewTagCreation,
}) => {
  const colors = Object.keys(BACKGROUND_COLORS).sort();

  return (
    <>
      <TextExtraSmall>Select a color for the new tag</TextExtraSmall>
      <div className={PREVIEW_CLASSNAMES}>
        <Badge className="line-clamp-1" color={newTagColor as Color}>
          {newTagName}
        </Badge>
        <FontAwesomeIcon className={TRASH_ICON_CLASSNAMES} icon="trash-alt" onClick={onRemoveNewTagCreation} />
      </div>
      <div className="relative w-full">
        <Combobox onChange={() => onCreateNewTagging()}>
          {/* eslint-disable-next-line jsx-a11y/no-autofocus */}
          <ComboboxInput autoFocus={true} className={COMBOBOX_INPUT_CLASSNAMES} />
          <ComboboxOptions static={true} className={SELECT_OPTIONS_CLASSNAMES}>
            {colors.map(color => (
              <ComboboxOption key={color} value={color} onMouseOver={() => onChangeNewTagColor(color as Color)}>
                {({ focus, selected }) => (
                  <Option focus={focus} selected={selected} emitFocusValue={() => onChangeNewTagColor(color as Color)}>
                    <div className={twMerge('h-6 w-full rounded-md', BACKGROUND_COLORS[color])} />
                  </Option>
                )}
              </ComboboxOption>
            ))}
          </ComboboxOptions>
        </Combobox>
      </div>
    </>
  );
};

export default ColorSelectBox;

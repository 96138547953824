import type { FC, PropsWithChildren } from 'react';
import React, { useState } from 'react';

import { twJoin } from 'tailwind-merge';

interface Props {
  calendarHeader: HTMLDivElement | null;
  calendarContainer: HTMLDivElement | null;
  isZooming: boolean;
}

const PanEngine: FC<PropsWithChildren<Props>> = ({ calendarHeader, children, calendarContainer, isZooming }) => {
  const [panning, setPanning] = useState(false);
  const [mouseMoveStartPosition, setMouseMoveStartPosition] = useState({ x: 0, y: 0 });

  const panningWhileZooming = panning && isZooming;

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (!calendarContainer) return;

    setPanning(true);
    setMouseMoveStartPosition({
      x: calendarContainer.scrollLeft + e.clientX,
      y: calendarContainer.scrollTop + e.clientY,
    });
  };

  const handleMouseUp = () => {
    setPanning(false);
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (!panning || !calendarContainer || !calendarHeader) return;

    calendarHeader.scrollTo(mouseMoveStartPosition.x - e.clientX, mouseMoveStartPosition.y - e.clientY);
    calendarContainer.scrollTo(mouseMoveStartPosition.x - e.clientX, mouseMoveStartPosition.y - e.clientY);
  };

  return (
    <div
      className={twJoin('w-full cursor-default', isZooming && 'cursor-grab', panningWhileZooming && 'cursor-grabbing')}
      role="presentation"
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      {children}
    </div>
  );
};

export default PanEngine;

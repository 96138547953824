import { createAction } from '@reduxjs/toolkit';

import type { Index } from './Reducers/templateReducer';

import type { EmailTemplate, NodeType, Paragraph } from '@models/EmailTemplate';

// Template actions
// ----------------------------------------------------------------------------
export type TemplateAction =
  | ChangeNumberOfColumnsAction
  | DeleteElementAction
  | DeletePropertyAction
  | InsertElementAction
  | LoadTemplateAction
  | MoveElementAction
  | UpdatePropertyAction
  | UpdateTextContentAction;

interface ChangeNumberOfColumnsData {
  parentId: string;
  numberOfColumnDesired: number;
}

export const changeNumberOfColumns = createAction<ChangeNumberOfColumnsData, 'CHANGE_NUMBER_OF_COLUMNS'>(
  'CHANGE_NUMBER_OF_COLUMNS'
);
type ChangeNumberOfColumnsAction = ReturnType<typeof changeNumberOfColumns>;

interface InsertElementData {
  parentId: string;
  type: NodeType;
  insertionIndex: number;
  defaultProperties: {};
}

export const insertElement = createAction<InsertElementData, 'INSERT_ELEMENT'>('INSERT_ELEMENT');
type InsertElementAction = ReturnType<typeof insertElement>;

interface LoadTemplateData {
  template: EmailTemplate;
}

export const loadTemplate = createAction<LoadTemplateData, 'LOAD_TEMPLATE'>('LOAD_TEMPLATE');
type LoadTemplateAction = ReturnType<typeof loadTemplate>;

interface MoveElementData {
  elementId: string;
  parentId: string;
  insertionIndex: number;
}

export const moveElement = createAction<MoveElementData, 'MOVE_ELEMENT'>('MOVE_ELEMENT');
type MoveElementAction = ReturnType<typeof moveElement>;

interface UpdateTextContent {
  elementId: string;
  content: Paragraph[];
}

export const updateTextContent = createAction<UpdateTextContent, 'UPDATE_TEXT_CONTENT'>('UPDATE_TEXT_CONTENT');
type UpdateTextContentAction = ReturnType<typeof updateTextContent>;

interface UpdatePropertyData {
  elementId: string;
  property: string;
  value: any;
}

export const updateProperty = createAction<UpdatePropertyData, 'UPDATE_PROPERTY'>('UPDATE_PROPERTY');
type UpdatePropertyAction = ReturnType<typeof updateProperty>;

interface DeleteElementData {
  elementId: string;
}

export const deleteElement = createAction<DeleteElementData, 'DELETE_ELEMENT'>('DELETE_ELEMENT');
type DeleteElementAction = ReturnType<typeof deleteElement>;

interface DeletePropertyData {
  elementId: string;
  property: string;
}

export const deleteProperty = createAction<DeletePropertyData, 'DELETE_PROPERTY'>('DELETE_PROPERTY');
type DeletePropertyAction = ReturnType<typeof deleteProperty>;

// UI Actions
// ----------------------------------------------------------------------------
export type UIAction =
  | ClearInsertionPointAction
  | SelectElementAction
  | SetInsertionPointAction
  | SetDraggingElementAction
  | IterateLayerSelectionAction;

export const clearInsertionPoint = createAction<undefined, 'CLEAR_INSERTION_POINT'>('CLEAR_INSERTION_POINT');
type ClearInsertionPointAction = ReturnType<typeof clearInsertionPoint>;

interface SelectElementData {
  selectedElementId: string | null;
}

export const selectElement = createAction<SelectElementData, 'SELECT_ELEMENT'>('SELECT_ELEMENT');
type SelectElementAction = ReturnType<typeof selectElement>;

interface SetInsertionPointData {
  parentId: string;
  insertionIndex: number;
}

export const setInsertionPoint = createAction<SetInsertionPointData, 'SET_INSERTION_POINT'>('SET_INSERTION_POINT');
type SetInsertionPointAction = ReturnType<typeof setInsertionPoint>;

interface SetDraggingElementData {
  draggingElement: boolean;
}

export const setDraggingElement = createAction<SetDraggingElementData, 'SET_DRAGGING_ELEMENT'>('SET_DRAGGING_ELEMENT');
type SetDraggingElementAction = ReturnType<typeof setDraggingElement>;

interface IterateLayerSelectionData {
  selectedElementId: string;
  initialSelectedElementId: string;
  index: Index;
}

export const iterateLayerSelection = createAction<IterateLayerSelectionData, 'ITERATE_LAYER_SELECTION'>(
  'ITERATE_LAYER_SELECTION'
);
type IterateLayerSelectionAction = ReturnType<typeof iterateLayerSelection>;

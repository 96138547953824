import { useAppDispatch, useAppSelector } from '../hooks';
import { selectSelectedCalendarEntry } from '../Reducers/calendarReducer';
import { disableInteraction, removeCalendarEntry, selectCalendarEntry } from '../actions';

import type { CancelAppointmentMutationVariables, CancelPsaAppointmentMutationVariables } from '@graphql/generated';
import { useCancelAppointmentMutation, useCancelPsaAppointmentMutation } from '@graphql/generated';

interface Params<V> {
  scheduledBy: 'timezest' | 'psa';
  variables: () => V;
  onShowError: (value: boolean) => void;
}

export const useCancelAppointment = <V>({
  scheduledBy,
  variables,
  onShowError,
}: Params<V>): { handleCancelAppointment: () => void } => {
  const dispatch = useAppDispatch();

  const selectedCalendarEntry = useAppSelector(selectSelectedCalendarEntry);

  const [cancelAppointment] = useCancelAppointmentMutation();
  const [cancelPsaAppointment] = useCancelPsaAppointmentMutation();

  const initiateCancelAppointment = () => {
    return new Promise((resolve, reject) => {
      if (!selectedCalendarEntry) {
        reject(new Error('No selected calendar entry'));
        return;
      }

      dispatch(disableInteraction(true));

      const handleResponse = (success: boolean) => {
        if (success) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      const handleError = err => {
        reject(err.message);
      };

      if (scheduledBy === 'timezest') {
        cancelAppointment({
          variables: variables() as CancelAppointmentMutationVariables,
        })
          .then(res => handleResponse(res.data?.dispatchHq?.cancelAppointment.success || false))
          .catch(handleError);
      } else {
        cancelPsaAppointment({
          variables: variables() as CancelPsaAppointmentMutationVariables,
        })
          .then(res => handleResponse(res.data?.dispatchHq?.cancelPsaAppointment.success || false))
          .catch(handleError);
      }
    });
  };

  const handleCancelAppointment = () => {
    if (!selectedCalendarEntry) return;

    initiateCancelAppointment()
      .then(success => {
        if (success) {
          dispatch(
            removeCalendarEntry({ calendarEntryId: selectedCalendarEntry.id, userId: selectedCalendarEntry.userId })
          );
          dispatch(selectCalendarEntry({ calendarEntryId: null, userId: null }));
          onShowError(false);
        } else {
          onShowError(true);
        }
      })
      .finally(() => {
        dispatch(disableInteraction(false));
      });
  };

  return { handleCancelAppointment };
};

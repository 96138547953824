import type { FC } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props {
  active: boolean;
  icon: JSX.Element;
  url: string;
}

const NavItem: FC<Props> = ({ active, icon, url }) => {
  return (
    <li
      className={twMerge(
        'd-block text-tz-gray-400 hover:bg-tz-gray-200 hover:text-tz-blue-950 dark:hover:bg-tz-gray-600 dark:hover:text-tz-gray-400 mx-auto h-8 w-8 rounded-md p-1',
        active && 'bg-tz-gray-300 text-tz-blue-950 dark:bg-tz-gray-700 dark:text-tz-gray-300'
      )}
    >
      <a href={url} className="d-block h-6 w-6">
        {icon}
      </a>
    </li>
  );
};

export default NavItem;

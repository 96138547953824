import type { FC } from 'react';
import { useState } from 'react';

import Calendar from './Calendar';

import useRefWithClickOutside from '@shared/hooks/useRefWithClickOutside';
import useStateWithCallback from '@shared/hooks/useStateWithCallback';
import TextInput from 'design_system/Components/TextInput';

interface Props {
  value: Date | null;
  onValueChange: (newValue: string) => void;
}

const DateSelect: FC<Props> = ({ value: initialValue, onValueChange }) => {
  const calendarRef = useRefWithClickOutside<HTMLDivElement>(() => setCalendarVisible(false));

  const [calendarVisible, setCalendarVisible] = useState<boolean>(false);
  const [displayValue, setDisplayValue] = useState<string>(initialValue ? formatDisplayDate(initialValue) : '');
  const [value, setValue] = useStateWithCallback<Date | null>(initialValue);

  const isValid = (): boolean => {
    if (!Number.isNaN(value?.valueOf())) return true;

    return false;
  };

  const assignValue = (value: Date | null): void => {
    setValue(value, (value: Date | null) => {
      let currentValue = '';

      const valid = value && !Number.isNaN(value.valueOf());

      if (value && valid) {
        currentValue = new Date(value.getTime() - value.getTimezoneOffset() * 60 * 1000).toISOString().split('T')[0];
      }

      onValueChange(currentValue);
    });
  };

  const handleChange = (value: string): void => {
    const parsed = value.split(' ').pop()?.trim() || '';
    const date = parsed !== '' ? new Date(parsed) : null;

    setCalendarVisible(false);
    setDisplayValue(value);
    assignValue(date);
  };

  const handleDropdownClick = (e: React.MouseEvent | React.KeyboardEvent): void => {
    e.preventDefault();

    setCalendarVisible(true);
  };

  return (
    <div ref={calendarRef} className="relative">
      <div>
        <TextInput
          error={isValid() ? '' : 'Invalid date'}
          name="date_select"
          mode="controlled"
          value={displayValue}
          onChange={handleChange}
          onClick={handleDropdownClick}
        />
      </div>
      {calendarVisible && (
        <Calendar
          calendarType="gregory"
          minDetail="year"
          value={value}
          formatCalendarTitle={formatCalendarTitle}
          formatDisplayDate={formatDisplayDate}
          formatWeekday={formatWeekday}
          setCalendarVisible={setCalendarVisible}
          setDisplayValue={setDisplayValue}
          setValue={assignValue}
          isValid={isValid}
        />
      )}
    </div>
  );
};

export default DateSelect;

function formatDisplayDate(date: Date) {
  return date.toLocaleDateString('en-GB', { year: 'numeric', month: 'short', day: 'numeric' }).replace(/ /g, ' ');
}

function formatCalendarTitle(date: Date, locale: string) {
  return date.toLocaleDateString(locale, { month: 'long', year: 'numeric' });
}

function formatWeekday(date: Date, locale: string) {
  return date.toLocaleDateString(locale, { weekday: 'short' });
}

import type { FC } from 'react';
import { Fragment, forwardRef } from 'react';

import CreateScheduleEntry from './connectWise/CreateScheduleEntry';
import { MODES } from './constants';
import { actionIsCreateable } from './utils';
import {
  isConnectWiseCreateActivityAction,
  isConnectWiseCreateContactAction,
  isConnectWiseCreateNoteOnTicketAction,
  isConnectWiseCreateScheduleEntryAction,
  isConnectWiseCreateServiceTicketAction,
  isConnectWiseFindOrCreateOpportunityAction,
  isConnectWiseMoveTicketToBoardAndStatusAction,
  isConnectWiseUpdateTicketStatusAction,
} from './utils/connectwise';
import {
  isAutotaskCreateCompanyTodoAction,
  isAutotaskCreateContactAction,
  isAutotaskCreateNoteOnTicketAction,
  isAutotaskCreateServiceCallAction,
  isAutotaskCreateServiceTicketAction,
  isAutotaskUpdateTicketStatusAction,
  isAutotaskUpdateTicketToPresetStatusAction,
  isAutotaskFindOrCreateOpportunityAction,
} from './utils/autotask';
import {
  isHaloPSACreateTicket,
  isHaloPSACreateAppointment,
  isHaloPSAUpdateTicketStatus,
  isHaloPSAUpdateTicketToPresetStatus,
  isHaloPSACreateActionOnTicket,
  isHaloPSACreateUser,
} from './utils/halopsa';
import {
  isHubSpotCreateContactAction,
  isHubSpotCreateMeetingAction,
} from './utils/hubspot';
import {
  isServiceNowUpdateTaskToPresetStateAction,
  isServiceNowUpdateTaskState,
  isServiceNowWriteNoteToTaskAction,
} from './utils/serviceNow';
import { isOffice365CreateEventAction } from './utils/office365';
import { isTeamsSendMessageToScheduledUsers, isTeamsSendMessageToChannelAction } from './utils/teams';
import { isTimeZestSendEmailFromTemplateAction, isTimeZestSendEmailAction } from './utils/timezest';
import AutotaskCreateContact from './autotask/CreateContact';
import AutotaskCreateNoteOnTicket from './autotask/CreateNoteOnTicket';
import AutotaskCreateServiceTicket from './autotask/CreateServiceTicket';
import AutotaskSkipAllActionsForClosedTicket from './autotask/SkipAllActionsForClosedTicket';
import AutotaskUpdateContactPhoneNumber from './autotask/UpdateContactPhoneNumber';
import AutotaskUpdateTicketStatus from './autotask/UpdateTicketStatus';
import AutotaskUpdateTicketToPresetStatus from './autotask/UpdateTicketToPresetStatus';
import CloseSchedulingRequest from './timezest/CloseSchedulingRequest';
import ConnectWiseCreateContact from './connectWise/CreateContact';
import ConnectWiseCreateNoteOnTicket from './connectWise/CreateNoteOnTicket';
import ConnectWiseCreateServiceTicket from './connectWise/CreateServiceTicket';
import ConnectWiseMoveTicketToBoardAndStatus from './connectWise/MoveTicketToBoardAndStatus';
import ConnectWiseSkipAllActionsForClosedTicket from './connectWise/SkipAllActionsForClosedTicket';
import ConnectWiseUpdateContactPhoneNumber from './connectWise/UpdateContactPhoneNumber';
import ConnectWiseUpdateTicketStatus from './connectWise/UpdateTicketStatus';
import CreateActivity from './connectWise/CreateActivity';
import CreateCompanyTodo from './autotask/CreateCompanyTodo';
import CreateEvent from './office365/CreateEvent';
import CreateServiceCall from './autotask/CreateServiceCall';
import DeleteAppointments from './timezest/DeleteAppointments';
import SendEmailFromTemplate from './timezest/SendEmailFromTemplate';
import TeamsCreateMeeting from './teams/CreateMeeting';
import SendMessageToChannel from './teams/SendMessageToChannel';
import UpdateAppointments from './timezest/UpdateAppointments';
import UpdateTicketContactPhoneNumber from './connectWise/UpdateTicketContactPhoneNumber';
import UpdateTicketOwner from './connectWise/UpdateTicketOwner';
import UpdateTicketPrimaryResource from './autotask/UpdateTicketPrimaryResource';
import ZoomCreateMeeting from './zoom/CreateMeeting';
import SendMessageToScheduledUsers from './teams/SendMessageToScheduledUsers';
import SendEmail from './timezest/SendEmail';
import FindOrCreateOpportunityForAutotask from './autotask/FindOrCreateOpportunity';
import FindOrCreateOpportunityForConnectWise from './connectWise/FindOrCreateOpportunity';
import HaloPSACreateTicket from './haloPsa/CreateTicket';
import HaloPSACreateAppointment from './haloPsa/CreateAppointment';
import HaloPSAUpdateTicketStatus from './haloPsa/UpdateTicketStatus';
import HaloPSAUpdateTicketToPresetStatus from './haloPsa/UpdateTicketToPresetStatus';
import HaloPSACreateActionOnTicket from './haloPsa/CreateActionOnTicket';
import HaloPSACreateUser from './haloPsa/CreateUser';
import HaloPSAUpdateUserPhoneNumber from './haloPsa/UpdateUserPhoneNumber';
import HaloPSAUpdateAgentForTicket from './haloPsa/UpdateAgentForTicket';
import HubSpotCreateContact from './hubSpot/CreateContact';
import HubSpotCreateMeeting from './hubSpot/CreateMeeting';
import ServiceNowUpdateTaskOwner from './serviceNow/UpdateTaskOwner';
import ServiceNowWriteNoteToTask from './serviceNow/WriteNoteToTask';
import ServiceNowUpdateTaskState from './serviceNow/UpdateTaskState';
import ServiceNowUpdateTaskToPresetState from './serviceNow/UpdateTaskToPresetState';

import type Errors from '@models/Errors';
import { EVENT_TYPES } from '@shared/constants';
import type { Action as ActionModel } from '@graphql/generated';
import FontAwesomeIcon from '@shared/FontAwesomeIcon';

interface Props {
  action: ActionModel;
  errors?: Errors;
  readOnly: boolean;
  saveable: boolean;
}
export const Action = forwardRef<HTMLFormElement, Props>((props, ref) => {
  const { action, errors, ...commonProps } = props;

  if (action.slug === 'autotask/create_company_todo' && isAutotaskCreateCompanyTodoAction(action)) {
    return <CreateCompanyTodo action={action} ref={ref} {...commonProps} />;
  }

  if (isAutotaskCreateContactAction(action)) {
    return <AutotaskCreateContact action={action} ref={ref} modes={MODES} {...commonProps} />;
  }

  if (isAutotaskCreateNoteOnTicketAction(action)) {
    return <AutotaskCreateNoteOnTicket action={action} ref={ref} {...commonProps} />;
  }

  if (isAutotaskCreateServiceCallAction(action)) {
    return <CreateServiceCall action={action} ref={ref} {...commonProps} />;
  }

  if (isAutotaskCreateServiceTicketAction(action)) {
    return <AutotaskCreateServiceTicket action={action} ref={ref} {...commonProps} />;
  }

  if (isAutotaskFindOrCreateOpportunityAction(action)) {
    return <FindOrCreateOpportunityForAutotask action={action} ref={ref} {...commonProps} />;
  }

  if (action.slug === 'autotask/skip_all_actions_for_closed_ticket') {
    return <AutotaskSkipAllActionsForClosedTicket action={action} {...commonProps} />;
  }

  if (action.slug === 'autotask/update_contact_phone_number') {
    return <AutotaskUpdateContactPhoneNumber action={action} {...commonProps} />;
  }

  if (action.slug === 'autotask/update_ticket_primary_resource') {
    return <UpdateTicketPrimaryResource action={action} {...commonProps} />;
  }

  if (isAutotaskUpdateTicketStatusAction(action)) {
    return <AutotaskUpdateTicketStatus action={action} ref={ref} {...commonProps} />;
  }

  if (isAutotaskUpdateTicketToPresetStatusAction(action)) {
    return <AutotaskUpdateTicketToPresetStatus action={action} ref={ref} {...commonProps} />;
  }

  if (isConnectWiseCreateScheduleEntryAction(action)) {
    return <CreateScheduleEntry action={action} ref={ref} {...commonProps} />;
  }

  if (isConnectWiseCreateActivityAction(action)) {
    return <CreateActivity action={action} ref={ref} {...commonProps} />;
  }

  if (isConnectWiseCreateContactAction(action)) {
    return <ConnectWiseCreateContact action={action} ref={ref} modes={MODES} {...commonProps} />;
  }

  if (isConnectWiseCreateNoteOnTicketAction(action)) {
    return (
      <ConnectWiseCreateNoteOnTicket
        action={action}
        ref={ref}
        // todo: discuss the type
        // @ts-ignore
        template={action.template}
        {...commonProps}
      />
    );
  }

  if (isConnectWiseCreateServiceTicketAction(action)) {
    return <ConnectWiseCreateServiceTicket action={action} ref={ref} {...commonProps} />;
  }

  if (isConnectWiseFindOrCreateOpportunityAction(action)) {
    return <FindOrCreateOpportunityForConnectWise action={action} ref={ref} {...commonProps} />;
  }

  if (isConnectWiseMoveTicketToBoardAndStatusAction(action)) {
    return <ConnectWiseMoveTicketToBoardAndStatus action={action} ref={ref} {...commonProps} />;
  }

  if (action.slug === 'connect_wise/skip_all_actions_for_closed_ticket') {
    return <ConnectWiseSkipAllActionsForClosedTicket action={action} {...commonProps} />;
  }

  if (isConnectWiseUpdateTicketStatusAction(action)) {
    return <ConnectWiseUpdateTicketStatus action={action} ref={ref} {...commonProps} />;
  }

  if (action.slug === 'connect_wise/update_contact_phone_number') {
    return <ConnectWiseUpdateContactPhoneNumber action={action} {...commonProps} />;
  }

  if (action.slug === 'connect_wise/update_ticket_contact_phone_number') {
    return <UpdateTicketContactPhoneNumber action={action} {...commonProps} />;
  }

  if (action.slug === 'connect_wise/update_ticket_owner') {
    return <UpdateTicketOwner action={action} {...commonProps} />;
  }

  if (isHaloPSACreateTicket(action)) {
    return <HaloPSACreateTicket action={action} ref={ref} {...commonProps} />;
  }

  if (isHaloPSACreateAppointment(action)) {
    return <HaloPSACreateAppointment action={action} ref={ref} {...commonProps} />;
  }

  if (isHaloPSACreateActionOnTicket(action)) {
    return <HaloPSACreateActionOnTicket action={action} ref={ref} {...commonProps} />;
  }

  if (isHaloPSACreateUser(action)) {
    return <HaloPSACreateUser action={action} ref={ref} {...commonProps} />;
  }

  if (isHaloPSAUpdateTicketStatus(action)) {
    return <HaloPSAUpdateTicketStatus action={action} ref={ref} {...commonProps} />;
  }

  if (isHaloPSAUpdateTicketToPresetStatus(action)) {
    return <HaloPSAUpdateTicketToPresetStatus action={action} ref={ref} {...commonProps} />;
  }

  if (action.slug === 'halo_psa/update_user_phone_number') {
    return <HaloPSAUpdateUserPhoneNumber action={action} {...commonProps} />;
  }

  if (action.slug === 'halo_psa/update_ticket_agent') {
    return <HaloPSAUpdateAgentForTicket action={action} {...commonProps} />;
  }

  if (isHubSpotCreateContactAction(action)) {
    return <HubSpotCreateContact action={action} ref={ref} {...commonProps} />;
  }

  if (isHubSpotCreateMeetingAction(action)) {
    return <HubSpotCreateMeeting action={action} ref={ref} {...commonProps} />;
  }

  if (action.slug === 'service_now/update_task_owner') {
    return <ServiceNowUpdateTaskOwner action={action} {...commonProps} />;
  }

  if (isServiceNowWriteNoteToTaskAction(action)) {
    return <ServiceNowWriteNoteToTask action={action} ref={ref} {...commonProps} />;
  }

  if (isServiceNowUpdateTaskState(action)) {
    return <ServiceNowUpdateTaskState action={action} ref={ref} {...commonProps} />;
  }

  if (isServiceNowUpdateTaskToPresetStateAction(action)) {
    return <ServiceNowUpdateTaskToPresetState action={action} ref={ref} {...commonProps} />;
  }

  if (isOffice365CreateEventAction(action)) {
    return <CreateEvent action={action} eventTypes={EVENT_TYPES} ref={ref} {...commonProps} />;
  }

  if (action.slug === 'teams_meetings/create_meeting') {
    return <TeamsCreateMeeting action={action} {...commonProps} />;
  }

  if (isTeamsSendMessageToScheduledUsers(action)) {
    return <SendMessageToScheduledUsers action={action} ref={ref} {...commonProps} />;
  }

  if (isTeamsSendMessageToChannelAction(action)) {
    return <SendMessageToChannel action={action} errors={errors} ref={ref} {...commonProps} />;
  }

  if (action.slug === 'time_zest/close_scheduling_request') {
    return <CloseSchedulingRequest action={action} {...commonProps} />;
  }

  if (action.slug === 'time_zest/delete_appointments') {
    return <DeleteAppointments action={action} {...commonProps} />;
  }

  if (isTimeZestSendEmailFromTemplateAction(action)) {
    return <SendEmailFromTemplate action={action} ref={ref} {...commonProps} />;
  }

  if (isTimeZestSendEmailAction(action)) {
    return <SendEmail action={action} ref={ref} {...commonProps} />;
  }

  if (action.slug === 'time_zest/update_appointments') {
    return <UpdateAppointments action={action} {...commonProps} />;
  }

  if (action.slug === 'zoom/create_meeting') {
    return <ZoomCreateMeeting action={action} {...commonProps} />;
  }

  return <>{action.slug}</>;
});

const Actions: FC<{
  actions: ActionModel[];
  readOnly: boolean;
  saveable?: boolean;
}> = ({ actions, readOnly, saveable }) => {
  return (
    <>
      {actions.map((action, index) => (
        <Fragment key={action.slug + index}>
          <div className="ActionSpacer__Main">
            <FontAwesomeIcon icon="angle-down" />
          </div>

          <Action
            action={action}
            readOnly={readOnly}
            saveable={typeof saveable !== 'undefined' ? saveable : !readOnly}
          />
        </Fragment>
      ))}
    </>
  );
};

export default Actions;

export const isCreateable = actionIsCreateable;

import { useState, type FC } from 'react';

import Flash from '@/Flash';
import asScreen from '@/Screen';

import AppointmentTypes from './AppointmentTypes';

import type { Filter } from '@models/Filter';
import FilterControl from 'design_system/Components/FilterControl';
import FormDivider from 'design_system/Components/FormDivider';
import H2 from 'design_system/Typography/Headings/H2';
import MainContainer from 'design_system/Components/MainContainer';
import Navigation from 'design_system/Components/Navigation';
import usePersistedFilters from '@shared/hooks/usePersistedFilters';
import useStateWithCallback from '@shared/hooks/useStateWithCallback';
import { generateTQLString } from 'design_system/Components/FilterControl/utilities';

interface Props {
  tqlFilters: Filter[];
}

const ATTRIBUTES = [
  { name: 'Internal Name', value: 'appointment_type.internal_name', type: 'string' as const },
  {
    name: 'External Name ',
    value: 'appointment_type.external_name',
    type: 'string' as const,
  },
  { name: 'URL Slug', value: 'appointment_type.url_slug', type: 'string' as const },
  {
    name: 'Duration ',
    value: 'appointment_type.duration_mins',
    type: 'duration' as const,
    specialControlType: 'duration' as const,
  },
];

const POSSIBLE_VALUES = {
  'appointment_type.internal_name': { values: [], loading: false },
  'appointment_type.external_name': { values: [], loading: false },
  'appointment_type.url_slug': { values: [], loading: false },
  'appointment_type.duration_mins': { values: [], loading: false },
};

const AppointmentTypesIndex: FC<Props> = ({ tqlFilters }) => {
  const persistedFilters = usePersistedFilters(tqlFilters, POSSIBLE_VALUES);
  const [filters, setFilters] = useStateWithCallback<Filter[]>(persistedFilters);
  const [tqlString, setTqlString] = useState(generateTQLString(tqlFilters));

  return (
    <>
      <Navigation activeItem="appointment_types" />
      <MainContainer>
        <H2>Appointment Types</H2>
        <Flash />
        <FormDivider />
        <FilterControl
          attributes={ATTRIBUTES}
          filters={filters}
          possibleValues={POSSIBLE_VALUES}
          onSetFilter={setFilters}
          onUpdateTqlString={setTqlString}
        />
        <AppointmentTypes tqlString={tqlString} />
      </MainContainer>
    </>
  );
};

export default asScreen(AppointmentTypesIndex);

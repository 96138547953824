import type { FC, PropsWithChildren } from 'react';
import React, { useEffect, useState, useMemo, useContext } from 'react';

import { useSortable } from '@dnd-kit/sortable';
import styled from 'styled-components';
import classNames from 'classnames';
import isEmpty from 'lodash/fp/isEmpty';

import ConfigContext from '../ConfigContext';
import { useAppDispatch, useAppSelector } from '../hooks';
import DragHandle from '../DragHandle';
import { selectElement } from '../actions';
import type { IndexedMjmlColumn } from '../EmailTemplate';
import { selectTemplateIndex } from '../Reducers/templateReducer';
import { selectDraggingElement, selectSelectedElementId } from '../Reducers/uiReducer';

import { paddingStyle, useParentWidthAsFallback, selectElementHandler } from './utilities';

interface Props {
  node: IndexedMjmlColumn;
  selected: boolean;
}

const Column = styled.div`
  position: relative;
  height: fit-content;
`;

export const EmptyColumnPlaceholder = styled.div`
  height: 81.594px;
  border: 1px dashed #2faade;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #2faade;
`;

export const AddContentButton = styled.button`
  background-color: #87cefa;
  margin-top: 8px;
  border: none;
  color: #fff;
  padding: 4px 12px;

  &:hover {
    background-color: #2faade;
  }
`;

const MjmlColumn: FC<PropsWithChildren<Props>> = ({ children, node, selected }) => {
  const { readOnly } = useContext(ConfigContext);

  const [hovering, setHovering] = useState(false);

  const dispatch = useAppDispatch();

  const columnWidth = useParentWidthAsFallback(node.width, node.parentId as string, '%');

  const draggingElement = useAppSelector(selectDraggingElement);
  const index = useAppSelector(selectTemplateIndex);
  const { initialSelectedElementId, selectedElementId } = useAppSelector(selectSelectedElementId);

  useEffect(() => {
    if (!selected) {
      setHovering(false);
    }
  }, [selected]);

  const { attributes, listeners, setNodeRef, transition, isDragging } = useSortable({
    id: node.id,
    data: { type: 'mjml-column' },
  });

  const handleMouseOver = (event: React.MouseEvent) => {
    event.stopPropagation();
    setHovering(true);
  };

  const handleMouseOut = (event: React.MouseEvent) => {
    event.stopPropagation();
    setHovering(false);
  };

  const parentStyle = useMemo(() => {
    return {
      paddingTop: '1px',
      zIndex: selected ? 999 : 'unset',
      transition,
      opacity: isDragging ? 0.5 : 1,
      width: columnWidth,
      backgroundColor: node.backgroundColor,
    };
  }, [selected, node.backgroundColor, columnWidth, isDragging, transition]);

  const childrenStyle = useMemo(() => {
    return {
      height: !isEmpty(node.childIds) ? 'auto' : '81.594px',
      padding: paddingStyle(node.padding),
    };
  }, [node.padding, node.childIds]);

  return (
    <Column
      id={node.id}
      className={classNames({
        EmailEditor__Element: true,
        'EmailEditor__Element--highlighted': (hovering && !draggingElement) || selected,
      })}
      ref={setNodeRef}
      style={parentStyle}
      onClick={selectElementHandler(dispatch, node.id, index, selectedElementId, initialSelectedElementId)}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {React.Children.count(children) ? (
        <div style={childrenStyle}>{children}</div>
      ) : (
        <EmptyColumnPlaceholder style={childrenStyle}>
          <p className="pe-none m-0">No content here. Drag content from right.</p>
          {selected && (
            <AddContentButton
              onClick={() => {
                dispatch(selectElement({ selectedElementId: null }));
              }}
            >
              Add Content
            </AddContentButton>
          )}
        </EmptyColumnPlaceholder>
      )}

      <div className="EmailEditor__Element_Identifier">
        Column
        <span className={classNames({ 'pe-none': readOnly })} {...attributes} {...listeners}>
          <DragHandle selected={selected} />
        </span>
      </div>
    </Column>
  );
};

export default MjmlColumn;

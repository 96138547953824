import type { FC, PropsWithChildren } from 'react';

import { MenuItem as HeadlessMenuItem } from '@headlessui/react';

import { BASE_MENU_ITEM_CLASS_NAMES } from '.';

interface Props {
  disabled?: boolean;
  onClick: () => void;
}

const MenuItem: FC<PropsWithChildren<Props>> = ({ children, disabled, onClick }) => {
  return (
    <HeadlessMenuItem>
      <div role="presentation" className={BASE_MENU_ITEM_CLASS_NAMES(disabled)} onClick={onClick}>
        {children}
      </div>
    </HeadlessMenuItem>
  );
};

export default MenuItem;

import type { FC } from 'react';

import Alert from 'design_system/Components/Alert';

const ErrorMessage: FC = () => {
  return (
    <div className="mx-auto mt-11 w-1/2">
      <Alert
        color="red"
        icon="circle-xmark"
        title="Oops, something went wrong."
        content={<>TimeZest was unable to retrieve agent details from the server.</>}
      />
    </div>
  );
};

export default ErrorMessage;

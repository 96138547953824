import { EventType } from '@graphql/generated';

export const EVENT_TYPES: { value: EventType; name: string }[] = [
  { value: EventType.Busy, name: 'Busy' },
  { value: EventType.Free, name: 'Free' },
  { value: EventType.Oof, name: 'Away' },
  { value: EventType.Tentative, name: 'Tentative' },
  { value: EventType.WorkingElsewhere, name: 'Working elsewhere' },
];

export const LANGUAGES = [
  { name: 'Deutsch', code: 'de' },
  { name: 'English', code: 'en' },
  { name: 'Español', code: 'es' },
  { name: 'Français', code: 'fr' },
  { name: 'Nederlands', code: 'nl' },
];

export const FILTER_OPERATORS = {
  EQ: { name: 'Is', value: 'EQ' as const },
  NOT_EQ: { name: 'Is not', value: 'NOT_EQ' as const },
  LIKE: { name: 'Contains', value: 'LIKE' as const },
  NOT_LIKE: { name: 'Does not contain', value: 'NOT_LIKE' as const },
  GT: { name: 'Greater than', value: 'GT' as const },
  GTE: { name: 'Greater than or equals', value: 'GTE' as const },
  LT: { name: 'Less than', value: 'LT' as const },
  LTE: { name: 'Less than or equals', value: 'LTE' as const },
};

export const DAY_NAMES = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

import { createContext } from 'react';

export type FeatureFlagContextType = {
  useNewUi: boolean;
  useParallelRequests: boolean;
  useViralMarketing: boolean;
  useTimestashCaching: boolean;
};

export const NULL_FEATURE_FLAG: FeatureFlagContextType = {
  useNewUi: false,
  useParallelRequests: false,
  useViralMarketing: false,
  useTimestashCaching: false,
};

export default createContext<FeatureFlagContextType>(NULL_FEATURE_FLAG);

import type { FC } from 'react';
import { useEffect } from 'react';

import WeekViewLayout from './LayoutEngine';

import { useGetCalendarEntriesSuspenseQuery } from '@graphql/generated';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { loadCalendarEntries } from '../../actions';
import type { CalendarEntry } from '../../types';
import { selectDatesRange, selectTimezone, selectVisibleUserIds } from '../../Reducers/calendarReducer';
import { getEndDateThreshold } from '../../Utils/getEndDateThreshold';

const WeekView: FC = () => {
  const dispatch = useAppDispatch();

  const datesRange = useAppSelector(selectDatesRange);
  const visibleUserIds = useAppSelector(selectVisibleUserIds);
  const timezone = useAppSelector(selectTimezone);

  const { data } = useGetCalendarEntriesSuspenseQuery({
    fetchPolicy: 'no-cache',
    variables: {
      userIds: visibleUserIds.map(Number),
      from: datesRange.week.startDate,
      to: getEndDateThreshold(datesRange.week.endDate),
      timezone,
    },
  });

  const calendarEntries = data.timezest.calendarEntries as CalendarEntry[];

  useEffect(() => {
    dispatch(loadCalendarEntries({ calendarEntries, viewMode: 'week' }));
  }, [dispatch, calendarEntries]);

  return <WeekViewLayout />;
};

export default WeekView;

import type { CSSProperties, FC } from 'react';
import { useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

import { tw } from '@/utilities';

import CalendarCell from './CalendarCell';

import TextExtraSmall from 'design_system/Typography/Paragraphs/TextExtraSmall';
import { useAppSelector } from 'admin_ui/screens/NewUi/DispatchHQ/hooks';
import {
  selectCalendarEntries,
  selectDaysInAWeekWithEntries,
  selectUsers,
} from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/calendarReducer';
import { selectCellWidth, selectZoom } from 'admin_ui/screens/NewUi/DispatchHQ/Reducers/uiReducer';

interface Props {
  lastRow: boolean;
  minimumRowHeight: number;
  userId: string;
}

const ROW_CLASS_NAMES = tw`flex h-auto w-max`;
const USER_CELL_CLASS_NAMES = tw`border-tz-gray-200 sticky left-0 top-0 z-20 h-auto w-28 shrink-0 border-b border-r bg-white p-2`;
const CONTAINER_CLASS_NAMES = tw`relative grid grow grid-cols-7`;
const GRID_CONTAINER_CLASS_NAMES = tw`absolute inset-0 grid`;
const GRID_CELL_CLASS_NAMES = tw`border-tz-gray-200 border-b border-l`;

const CalendarRow: FC<Props> = ({ lastRow, minimumRowHeight, userId }) => {
  const calendarEntries = useAppSelector(selectCalendarEntries);
  const cellWidth = useAppSelector(selectCellWidth);
  const users = useAppSelector(selectUsers);
  const zoom = useAppSelector(selectZoom);

  const calendarEntriesForUser = useMemo(() => {
    return calendarEntries[userId] || [];
  }, [calendarEntries, userId]);

  const entriesInEachDay = useAppSelector(state => selectDaysInAWeekWithEntries(state, calendarEntriesForUser));

  const style: CSSProperties = {
    gridTemplateColumns: `repeat(7, ${cellWidth * zoom}px)`,
  };

  return (
    <div className={ROW_CLASS_NAMES} style={{ minHeight: `${minimumRowHeight}px` }}>
      <div key={`user-${userId}`} className={twMerge(USER_CELL_CLASS_NAMES, lastRow && 'border-b-0')}>
        <TextExtraSmall key={`name-of-user-${userId}`}>{users[userId].name}</TextExtraSmall>
      </div>
      <div className={CONTAINER_CLASS_NAMES} style={style}>
        <div className={GRID_CONTAINER_CLASS_NAMES} style={style}>
          {[...Array(7)].map((_, j) => (
            <div
              key={`cell-${j}-of-user-${userId}`}
              className={twMerge(GRID_CELL_CLASS_NAMES, j === 0 && 'border-l-0', lastRow && 'border-b-0')}
            />
          ))}
        </div>
        {entriesInEachDay.map((entries, dayIndex) => (
          <div
            key={`day-${dayIndex}-of-user-${userId}`}
            style={{
              gridColumn: `${dayIndex + 1} / ${dayIndex + 2}`,
            }}
          >
            <CalendarCell entries={entries} userId={userId} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarRow;

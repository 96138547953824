import type { FC } from 'react';

import Alert from 'design_system/Components/Alert';

const ErrorFallback: FC = () => {
  return (
    <div role="alert">
      <Alert
        icon="circle-xmark"
        color="red"
        title="TimeZest encountered an unexpected error. Please reload the page and try again."
      />
    </div>
  );
};

export default ErrorFallback;

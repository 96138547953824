import type { FC } from 'react';

import classNames from 'classnames';

import MailSendingModeHelp from './MailSendingModeHelp';

import type DomainModel from '@models/Domain';
import FormControl from 'design_system/Components/FormControl';
import InputGroup from 'design_system/Components/InputGroup';
import RadioButtons from 'design_system/Components/RadioButtons';
import SingleSelect from 'design_system/Components/SingleSelect';
import useStateFromProp from '@shared/hooks/useStateFromProp';

type MailSendingMode = 'postmark' | 'domain';

interface Props {
  domains: DomainModel[];
  error?: string;
  emailDomainId: number;
  fromLocalPart: string | null;
  mailSendingMode: MailSendingMode;
}

const FromAddressControl: FC<Props> = ({
  domains,
  error,
  emailDomainId,
  fromLocalPart,
  mailSendingMode: initialMailSendingMode,
}) => {
  const [mailSendingMode, setMailSendingMode] = useStateFromProp(initialMailSendingMode);
  const domainSendingDisabled = !domains.some(domain => domain.verified);

  return (
    <FormControl helpPopup={<MailSendingModeHelp />} label="From Address" name="account[mail_sending_mode]">
      <RadioButtons
        name="account[mail_sending_mode]"
        mode="controlled"
        value={mailSendingMode}
        options={[
          { value: 'postmark', label: "Send all emails from 'noreply@timezest.com'." },
          {
            value: 'domain',
            label: 'Send all emails from an address on your own domain:',
            disabled: domainSendingDisabled,
          },
        ]}
        onChange={setMailSendingMode as (value: MailSendingMode) => void}
      />
      <InputGroup
        mode="uncontrolled"
        type="text"
        name="account[from_local_part]"
        error={error}
        disabled={domainSendingDisabled}
        initialValue={fromLocalPart || ''}
        attachmentClassName={classNames(domainSendingDisabled ? 'bg-tz-gray-100' : 'bg-white')}
        attachment={
          <SingleSelect
            mode="uncontrolled"
            initialValue={emailDomainId?.toString()}
            name="account[email_domain_id]"
            disabled={domainSendingDisabled}
            // TODO: Change this
            items={domains.map(domain => {
              return { id: domain.id.toString(), name: `@${domain.name}` };
            })}
          />
        }
        placement="end"
      />
    </FormControl>
  );
};

export default FromAddressControl;

import type { FC, PropsWithChildren } from 'react';

const Description: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="col-span-1">
      <dd>{children}</dd>
    </div>
  );
};

export default Description;
